<template>
  <div class="Manuals">
    <PlainNavBar />
    <ParentPage class="Manuals" :menu="menu" :rootRouteName="rootRouteName"  />
    <Overlay />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { ManualsMenu } from '@/data/manuals'

import ParentPage from '@/components/manual/ParentPage.vue'
import PlainNavBar from '@/components/navbar/PlainNavBar.vue'
import Overlay from '@/components/common/Overlay'

export default {
  name: "Manuals",
  components: {
    ParentPage,
    PlainNavBar,
    Overlay
  },
  data() {
    return {
      menu: ManualsMenu,
      rootRouteName: 'Manuals'
    }
  },
  computed: {
    // ...mapGetters('user', [
    //   'canAccessManuals'
    // ]),
    ...mapGetters('currentUser', [
      'isSuperuser'
    ]),
    ...mapGetters('access', [
      'municipalityPlanModeAccessList'
    ]),
    canAccessManuals() {
      return this.isSuperuser || this.municipalityPlanModeAccessList.length !== 0
    }
  },
  created() {
    // Access check
    if (! this.canAccessManuals) {
      this.$router.push({ 
        name: 'Dashboard'
      })
    }
  }
};
</script>

<style>
.Manuals {
  height: 100%;
}
</style>
