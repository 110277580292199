<template>
  <div class="UserPanel d-flex flex-column">
    <div class="d-flex">
      <aside class="UserPanel__Aside flex-shrink-0 mr-3">
        <h3 class="UserPanel__Title">
          Gebruikers
        </h3>
        <div class="w-75 mt-3">
          <div v-if="minutes !== -1">
            <div class="mb-3">
              Laatst opgehaald:<br>
              {{ minutes }} {{ minutes === 1 ? 'minuut' : 'minuten' }} geleden
            </div>
            <b-button
              :disabled="minutes === 0"
              @click="getMunicipalityUsers"
            >
              Verversen
            </b-button>
          </div>
        </div>
      </aside>

      <div class="UserPanel__Main flex-grow-1">
        <div
          v-if="users.length !== 0"
          class="d-flex align-items-center"
        >
          <span class="UserPanel__Label mr-3">
            Naam
          </span>
          <span class="flex-grow-1 mr-3">
            Email
          </span>
          <span class="UserPanel__Role mr-3">
            Rol / Rechten
          </span>
          <span style="width: 22px" />
        </div>

        <ActiveUserRow
          v-for="user in users"
          :key="user.email"
          :user="user"
          :code="code"
          @roleChange="handleRoleChange"
        />

        <div
          v-if="users.length === 0"
          class="d-flex h-100 justify-content-center align-items-center flex-column"
        >
          <p
            v-if="loading"
            class="text-muted"
          >
            Loading...
          </p>
          <p
            v-if="!loading"
            class="text-muted mb-1"
          >
            Er zijn nog geen gebruikers verbonden aan deze gemeente.
          </p>
          <p
            v-if="!loading"
            class="text-muted mt-0"
          >
            Superusers kunnen de gemeente wel inzien.
          </p>
        </div>
      </div>
    </div>


    <div class="d-flex pt-5 mt-3">
      <aside class="UserPanel__Aside flex-shrink-0 mr-3">
        <h3 class="UserPanel__Title">
          Toevoegen
        </h3>
        <div class="w-75 mt-3">
          <p>
            Zoek een bestaande gebruiker om rechten aan te verlenen.
          </p>
          <input
            v-model="query"
            class="w-100"
          >
        </div>
      </aside>
      <div class="UserPanel__Main flex-grow-1">
        <div
          v-if="queryResults.length !== 0"
          class="d-flex align-items-center"
        >
          <span class="UserPanel__Label mr-3">
            Naam
          </span>
          <span class="flex-grow-1 mr-3">
            Email
          </span>
          <span class="UserPanel__Role mr-3">
            Rol / Rechten
          </span>
          <span style="width: 22px" />
        </div>

        <NewUserRow
          v-for="user in queryResults"
          :key="user.email"
          :user="user"
          :code="code"
          @roleChange="handleRoleChange"
        />

        <div
          v-if="queryResults.length === 0"
          class="d-flex h-100 justify-content-center align-items-center flex-column"
        >
          <p class="text-muted mb-1">
            Zoek op naam of e-mail...
          </p>
          <p
            class="text-muted"
            style="font-size: 1rem"
          >
            Voer minimaal 3 karakters in
          </p>
        </div>
      </div>
    </div>


    <div class="d-flex pt-5 mt-3">
      <aside class="UserPanel__Aside flex-shrink-0 mr-3">
        <h3 class="UserPanel__Title">
          Nieuwe gebruiker
        </h3>
        <div class="w-75 mt-3">
          <p>
            Maak een geheel nieuw gebruikersaccount aan
          </p>
        </div>
      </aside>
      <div class="UserPanel__Main flex-grow-1">
        <UserCreation
          :key="reload"
          :code="code"
          @createUser="handleCreateUser"
        />
      </div>
    </div>
  </div>
</template>


<script>
import ActiveUserRow from '@/components/admin/users/ActiveUserRow'
import NewUserRow from '@/components/admin/users/NewUserRow'
import UserCreation from '@/components/admin/users/UserCreation'

import { mapGetters, mapMutations } from 'vuex'

import { removeValue } from '@/helpers/array'
import { roles } from '@/../shared/valueholders/userRoles'
import { checkStatus, returnJson } from '@/helpers/api'
import { passwordErrorTranslations } from '@/services/auth0Translations'

export default {
  name: 'UserPanel',
  components: { ActiveUserRow, NewUserRow, UserCreation },
  props: {
    code: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      minutes: -1,
      minutesInterval: null,

      // Query
      query: '',
      queryTimeout: null,
      queryResults: [],
      reload: 0,
    }
  },
  computed: {
    ...mapGetters('users', [
      'getUsersByCode', 'getUserDataAgeByCode',
    ]),
    users() {
      let users = this.getUsersByCode({ code: this.code })

      if (! users) return []

      return users.map(user => {
        return user
      })
    },
  },
  watch: {
    /**
     * When the code changes, check the minutes
     *  If it's been more than 30 minutes since the last update, get fresh user data
     */
    code() {
      this.updateMinutes()

      // If there are no users yet, o if it's been more than 30 minutes since the
      // last update, get fresh user data
      if (this.minutes === -1 || this.minutes > 30) {
        this.getMunicipalityUsers()
      }
    },
    /**
     * React to changes in the search query
     */
    query(newValue, oldValue) {

      if (newValue.trim() === '') {
        this.queryResults = []
        return
      }

      if (newValue.trim() === oldValue.trim()) return // Ignore spaces
      if (newValue.trim().length < 3) return // Ignore less then 3 char searches TODO: Provide feedback


      if (this.queryTimeout) {
        clearTimeout(this.queryTimeout)
      }

      this.queryTimeout = setTimeout(this.runQuery, 300)
    },
  },
  created() {
    this.updateMinutes()
    setInterval(this.updateMinutes, 2000)

    // If there are no users yet, o if it's been more than 30 minutes since the
    // last update, get fresh user data
    if (this.minutes === -1 || this.minutes > 30) {
      this.getMunicipalityUsers()
    }
  },
  /**
   * Clean up to avoid mem leaks, etc.
   */
  beforeDestroy() {
    if (this.queryTimeout) {
      clearTimeout(this.queryTimeout)
    }
    if (this.minutesInterval) {
      clearInterval(this.minutesInterval)
    }
  },
  methods: {
    ...mapMutations('users', [
      'setUsersDataByCode',
      'setUserData',
    ]),

    /*********************************************************************
     * Auth0 User Management API methods
     */

    /**
     * Get the users that are connected to the active municipality
     *
     *  IMPORTANT: This Auth0 Endpoint has eventual data consistency!
     *             Do not use to refresh immediately after an update
     */
    getMunicipalityUsers: async function() {
      this.loading = true

      const token = await this.$auth.getTokenSilently()
      const result = await fetch('/api/userlist', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          code: this.code,
        }),
      })

      const { users } = await result.json()

      this.setUsersDataByCode({
        code: this.code, users,
      })

      this.updateMinutes()

      this.loading = false
    },

    /**
     * Update or add a user
     */
    updateOrAddUser: async function({ user }) {
      const token = await this.$auth.getTokenSilently()
      const result = await fetch('/api/usersave', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          code: this.code,
          user,
        }),
      })

      await result.json()

      this.getMunicipalityUsers()
    },

    /**
     * Query for users on email & name
     */
    runQuery: async function() {
      const token = await this.$auth.getTokenSilently()
      const result = await fetch('/api/usersearch', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          code: this.code,
          q: this.query,
        }),
      })

      const { users } = await result.json()

      this.queryResults = users
    },

    handleCreateUser: async function({ user, code }) {
      const token = await this.$auth.getTokenSilently()
      await fetch('/api/usercreate', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          code: code,
          user: user,
        }),
      })
        .then(await checkStatus)
        .then(returnJson)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Gelukt!',
            text: 'De gebruiker is aangemaakt.',
          })
          this.reload += 1
        })
        .catch((e) => {
          const text = passwordErrorTranslations({
            errorMessage: e.message,
            fallback: 'Er ging iets mis tijdens het aanmaken van de gebruiker.',
          })

          this.$notify({
            type: 'error',
            title: 'Niet gelukt!',
            text,
          })
        })

      this.getMunicipalityUsers()
    },

    /*********************************************************************
     * Regular methods
     */

    /**
     * Update the
     */
    updateMinutes() {
      this.minutes = this.getUserDataAgeByCode({ code: this.code })
    },

    /**
     * Handle a change in role & immediately trigger an update action
     */
    handleRoleChange({ user, role }) {
      user = this.changeUserRole({ user, role })

      this.updateOrAddUser({ user })
    },

    /**
     * Update the role of a user object
     */
    changeUserRole({ user, role }) {
      let meta = user.app_metadata.evmaps

      let missingRoles = roles

      // We always clean up the current role assignment
      meta.roles = (meta.roles || []).map(roleObj => {

        roleObj.municipalities = removeValue({
          arr: roleObj.municipalities,
          value: this.code,
        })

        // Also keep track whether we're missing data about a role
        missingRoles = removeValue({
          arr: missingRoles,
          value: roleObj.role,
        })

        return roleObj
      })

      // Add missing roles if there are any
      missingRoles.forEach(role => {
        meta.roles.push({
          role,
          municipalities: [],
        })
      })

      // If we're going to end up with no role at all, we're done
      if (role !== null) {
        meta.roles = meta.roles.map(roleObj => {
          if (roleObj.role === role) {
            roleObj.municipalities.push(this.code)
          }
          return roleObj
        })
      }

      // Set the new user meta data
      user.app_metadata.evmaps = meta

      return user
    },
  },
}
</script>

<style lang="scss">
.UserPanel {
  &__Aside {
    width: 200px;

    @media (min-width: 1280px) {
      width: 275px;
    }
  }
  &__Main {
    font-size: 1.25rem;

    .form-control {
      width: 100px;
      font-size: 1.25rem;
    }
  }
  &__Label {
    width: 175px;
  }
  &__Role {
    width: 150px;
  }
  .SvgIcon {
    flex-shrink: 0;
    font-size: 1.75rem;
  }
}
</style>
