<template>
  <div>
    <TileDataContent
      :fids="fids"
      :forecast-label="forecastLabel"
      :dataset="dataset"
      :data-by-id="dataById"
    >
      <template
        v-if="fids.length > 0"
        #sub-title
      >
        <div class="nav-items">
          <span
            v-for="(item, itemIndex) in navItems"
            :key="itemIndex"
            :class="{ 'active': activeItem === item.id }"
            @click="activeItem = item.id"
          >
            {{ item.label }}
            <SvgIcon
              v-for="icon in item.icons"
              :id="icon"
              :key="icon"
              :icon="icon"
            />
          </span>
        </div>
      </template>

      <template #content-header="{ showData }">
        <TileDataContentRow :nr-of-columns="2">
          <template #first-column>
            Respons

            <SvgIcon
              id="respons-tooltip"
              v-b-tooltip.hover
              icon="info-circle-regular"
              title="Respons geeft weer welk aandeel van de getoonde informatie is opgehaald uit de enquête"
            />
          </template>
          <template #second-column>
            {{ showData({ field: 'respons' }) }}
          </template>
        </TileDataContentRow>

        <TileDataContentHeader :nr-of-columns="4">
          <template
            v-for="slot in slots"
            #[`${slot.name}-column`]
          >
            {{ slot.label }}
          </template>
        </TileDataContentHeader>
      </template>

      <template #content="{ showData }">
        <TileDataContentGroupThreeColumn>
          <template #header>
            Depot
          </template>

          <TileDataContentRow
            v-for="(column, index) in columns"
            :key="index"
            :nr-of-columns="4"
          >
            <template #first-column>
              {{ column.label }}
              <SvgIcon
                v-if="column.tooltip"
                :id="column.label"
                v-b-tooltip.hover
                :title="column.tooltip"
                icon="info-circle-regular"
              />
            </template>
            <template
              v-for="slot in slots"
              #[`${slot.name}-column`]
            >
              <div
                :key="slot.name"
                v-b-tooltip.hover
                :title="showData({ field: getField({ slot, column }), decimals: 4 })"
              >
                {{ column.label ? showData({ field: getField({ slot, column }), decimals: 1 }) : '' }}
              </div>
            </template>
          </TileDataContentRow>
        </TileDataContentGroupThreeColumn>
      </template>

      <template #footer="{ showStringData }">
        Bedrijventerrein: {{ showStringData({ field: 'bedrijventerrein' }) }}
      </template>
    </TileDataContent>
  </div>
</template>

<script>
import TileDataContent from '@/components/map/tiles/TileDataContent'
import TiledataMixin from '@/mixins/map/tiledata-mixin'
import TileDataContentHeader from '@/components/map/tiles/TileDataContentHeader'
import TileDataContentRow from '@/components/map/tiles/TileDataContentRow'
import TileDataContentGroupThreeColumn from '@/components/map/tiles/TileDataContentGroupThreeColumn'
import SvgIcon from '@/components/common/SvgIcon'

export default {
  name: 'TileDataGoralLogistics',
  components: { TileDataContentGroupThreeColumn, TileDataContentRow, TileDataContentHeader, TileDataContent, SvgIcon },
  mixins: [TiledataMixin],
  data () {
    return {
      columns: [
        { label: 'Aantal' },
        { label: '' }, // spacer
        { label: 'MWh/jaar', type: 'mwh' },
        { label: 'MW', type: 'mw', tooltip: 'Maximale vermogen dat op een dagdeel gebruikt wordt' },
      ],
      navItems: [
        { label: 'Dag', id: 'dag', icons: ['sun'] },
        { label: 'Nacht', id: 'nacht', icons: ['moon'] },
        { label: 'Totaal', id: 'tot', icons: ['sun', 'moon'] },
      ],
      activeItem: 'dag',
      slots: [
        { field: 'ebestel', name: 'second', label: 'E-bestel' },
        { field: 'etruck', name: 'third', label: 'E-truck' },
        { field: 'ebak', name: 'fourth', label: 'E-bak' },
      ],
    }
  },
  methods: {
    getField ({ slot, column }) {
      return `${slot.field}${column.type ? '_' + column.type : ''}_${this.activeItem}_depot_${this.year}`
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .TileData__Content {
  table {
    margin-top: 1.5rem;

    tr {
      height: 1rem; // Spacer tr's
    }
  }
}
.nav-items {
  display: flex;
  position: absolute;
  width: 100%;

  > span {
    display: flex;
    margin-right: 0.8rem;
    cursor: pointer;
    &:hover, &.active {
      color: var(--secondary-text);
      ::v-deep .svg-container svg {
        fill: var(--secondary-text);
      }
    }
    .svg-container {
      margin-left: 0.2rem;
    }
  }
}

::v-deep .fa-info-circle {
  margin-left: 0.3rem;
  cursor: pointer;
}
</style>
