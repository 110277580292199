<template>
  <div class="NavBarMenu pt-4">
    <p class="d-flex align-items-center justify-content-between px-4">
      <strong>
        Menu
      </strong>
      <span
        class="NavBarMenu__logout d-inline-flex"
        @click="logout"
      >
        <span>Uitloggen</span>
        <SvgIcon
          class="ml-2"
          icon="sign-out-regular"
        />
      </span>
    </p>

    <router-link
      v-if="superuser"
      :to="{ name: 'Dashboard', params: { municipality: activeMunicipalitySlug } }"
      class="d-flex align-items-center px-4"
    >
      <SvgIcon
        icon="cog-regular"
        :has-fill="false"
        class="mr-3"
      />
      <span class="d-flex flex-wrap flex-shrink-1">
        Beheerpaneel
      </span>
    </router-link>

    <router-link
      v-if="isPrognoseEnabled || canPreAccessPrognose"
      :to="{ name: 'Viewer', params: { municipality: activeMunicipalitySlug } }"
      class="d-flex align-items-center px-4"
    >
      <SvgIcon
        icon="map-regular"
        :has-fill="false"
        class="mr-3"
      />
      <span class="d-flex flex-wrap flex-shrink-1">
        Laadpaal prognose {{ isPlanmodeEnabled ? '& planning' : '' }}
      </span>
    </router-link>

    <router-link
      v-if="isUploadEnabled || admin || superuser"
      :to="{ name: 'Upload', params: { municipality: activeMunicipalitySlug } }"
      class="d-flex align-items-center px-4"
    >
      <SvgIcon
        icon="file-upload-regular"
        :has-fill="false"
        class="mr-3"
      />
      <span class="d-flex flex-wrap flex-shrink-1">
        Upload portaal
      </span>
    </router-link>

    <div class="d-flex align-items-center px-4 toggle-button">
      <SvgIcon
        icon="chevron-down-regular"
        :has-fill="false"
        class="mr-3"
      />

      <span class="d-flex flex-wrap flex-shrink-1">
        Factsheets
      </span>
    </div>

    <b-collapse
      v-model="factsheetShow"
      class="factsheet-collapse"
    >
      <router-link
        v-if="isFactsheetEnabled || admin || superuser"
        :to="{ name: 'Factsheet', params: { municipality: activeMunicipalitySlug } }"
        class="d-flex align-items-center px-4"
      >
        <SvgIcon
          icon="chart-bar-regular"
          :has-fill="false"
          class="mr-3"
        />
        <span class="d-flex flex-wrap flex-shrink-1">
          Regulier laden
        </span>
      </router-link>

      <router-link
        v-if="isParticipationEnabled || admin || superuser"
        :to="{ name: 'Participation', params: { municipality: activeMunicipalitySlug } }"
        class="d-flex align-items-center px-4"
      >
        <SvgIcon
          icon="users-regular"
          :has-fill="false"
          class="mr-3"
        />
        <span class="d-flex flex-wrap flex-shrink-1">
          Participatie
        </span>
      </router-link>

      <router-link
        v-if="isParticipationEnabled || admin || superuser"
        :to="{ name: 'Bedrijventerreinen', params: { municipality: activeMunicipalitySlug } }"
        class="d-flex align-items-center px-4"
      >
        <SvgIcon
          icon="truck-fast-solid"
          :has-fill="false"
          class="mr-3"
        />
        <span class="d-flex flex-wrap flex-shrink-1">
          Bedrijventerreinen
        </span>
      </router-link>
    </b-collapse>

    <!-- isExportEnabled ||  -->
    <router-link
      v-if="admin || superuser"
      :to="{ name: 'FactsheetExport', params: { municipality: activeMunicipalitySlug } }"
      class="d-flex align-items-center px-4"
    >
      <SvgIcon
        icon="file-arrow-down-solid"
        :has-fill="false"
        class="mr-3"
      />
      <span class="d-flex flex-wrap flex-shrink-1">
        Factsheet Export
      </span>
    </router-link>

    <!-- isExportEnabled ||  -->
    <router-link
      v-if="admin || superuser"
      :to="{ name: 'Export', params: { municipality: activeMunicipalitySlug } }"
      class="d-flex align-items-center px-4"
    >
      <SvgIcon
        icon="download-solid"
        :has-fill="false"
        class="mr-3"
      />
      <span class="d-flex flex-wrap flex-shrink-1">
        Laadpalen Export
      </span>
    </router-link>

    <!-- superusers only  -->
    <router-link
      v-if="superuser"
      :to="{ name: 'Import' }"
      class="d-flex align-items-center px-4"
    >
      <SvgIcon
        icon="upload-solid"
        :has-fill="false"
        class="mr-3"
      />
      <span class="d-flex flex-wrap flex-shrink-1">
        Laadpalen Import
      </span>
    </router-link>

    <router-link
      :to="{ name: 'Manuals' }"
      class="d-flex align-items-center px-4"
    >
      <SvgIcon
        icon="info-circle-solid"
        :has-fill="false"
        class="mr-3"
      />
      <span class="d-flex flex-wrap flex-shrink-1">
        Handleidingen
      </span>
    </router-link>

    <!-- <p class="NavBarMenu__logout d-flex align-items-center px-4" @click="logout">
      <SvgIcon icon="sign-out-regular" :hasFill="false" class="mr-3" />
      <span class="d-flex flex-wrap flex-shrink-1">
        Uitloggen
      </span>
    </p> -->
  </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon'

import { EventBus } from '@/services/eventbus'
import { codeToSlug } from '@/services/municipalities'
import { mapGetters } from 'vuex'

export default {
  name: 'NavBarMenu',
  components: { SvgIcon },
  data() {
    return {
      busy: false,
      factsheetShow: true,
    }
  },
  computed: {
    ...mapGetters('config', [
      'isPrognoseEnabled',
      'isFactsheetEnabled',
      'isParticipationEnabled',
      'isPlanmodeEnabled',
      'isUploadEnabled',
      'isExportEnabled',
      'hasLayers',
    ]),
    ...mapGetters('access', [
      'getActiveMunicipality',
      'hasAdminAccess',
    ]),

    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
    admin() {
      return this.hasAdminAccess({ code: this.getActiveMunicipality })
    },
    activeMunicipalitySlug() {
      return codeToSlug({ code: this.getActiveMunicipality })
    },
    canPreAccessPrognose() {
      return this.hasLayers({ code: this.getActiveMunicipality }) && ( this.superuser || this.admin )
    },
  },
  created() {
    EventBus.$on('overlay-click', this.maybeClose)
  },
  beforeDestroy() {
    EventBus.$off('overlay-click', this.maybeClose)
  },
  methods: {
    maybeClose() {
      if (this.busy) return

      EventBus.$emit('overlay-hide')
    },
    handleProcessing({ busy }) {
      this.busy = busy
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
  },
}
</script>

<style lang="scss">
.NavBarMenu {
  position: relative;
  background: white;
  z-index: 999999;
  opacity: 0.999;
  min-width: 450px;
  max-width: 450px;

  .SvgIcon {
    width: 1rem;
  }

  a, p, .SvgIcon.SvgIcon, .toggle-button {
    font-size: 1.15rem;
    cursor: auto;
  }

  a, .toggle-button {
    margin-bottom: 1rem;
    color: var(--primary-text);

    &:hover {
      text-decoration: none;
      color: #b04300;
      cursor: pointer;
    }
  }

  .toggle-button {
    color: var(--primary-text) !important;
    cursor: default !important;
  }

  .factsheet-collapse {
    margin-left: 2rem;
  }

  &__logout, &__logout .SvgIcon {
    cursor: pointer !important;

    &:hover {
      color: #b04300
    }
  }
}
</style>
