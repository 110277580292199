<template>
  <AdminApp>
    <div class="UsersDashboard d-flex flex-column w-100 align-items-center">
      <UsersPanel class="UsersDashboard__Panel mx-3" />
    </div>
  </AdminApp>
</template>

<script>
import AdminApp from '@/views/admin/AdminApp'
import UsersPanel from '@/components/admin/UsersPanel'

// import { slugToCode, codeToSlug, labelByCode } from '@/services/municipalities'

import { mapGetters, mapMutations } from 'vuex'

/**
 *
 */
export default {
  components: {
    AdminApp, UsersPanel,
  },
  computed: {
    ...mapGetters('config', [
      'loaded',
    ]),
  },
  created() {
    this.setPageTitle({
      title: 'EV Maps Beheer - Gebruikers',
    })
  },
  methods: {
    ...mapMutations(['setPageTitle']),
  },
}
</script>

<style lang="scss">
.UsersDashboard {
  &__Panel {
    // width: calc(100% - 2rem);
    min-width: 900px;
    max-width: 900px;

    // border: 1px solid red;
    background: white;
    box-shadow: 0px 1px 3px 0px #dfe2e5;

    margin-top: 1rem;

    @media (min-width: 1280px) {
      margin-top: 3rem;
      max-width: 1000px;
    }
    @media (min-width: 1440px) {
      min-width: 1100px;
      max-width: 1100px;
    }
  }
  .vSelect {
    min-width: 300px;
  }
}
</style>
