<template>
  <CollapsableSegment :label="label">
    <template #header>
      <ToggleSwitch
        v-model="visibility"
        class="m-0"
      />
    </template>

    <ul class="m-0 p-0 mb-3 list-unstyled">
      <li class="d-flex my-2 mr-3 align-items-center">
        <span class="position-relative">
          <Hexagon
            style="position: absolute"
            color="#f6f6f4"
          />
          <Hexagon
            color="hsl(213, 55%, 75%)"
            :opacity="opacity"
          />
        </span>
        <span class="mx-2 flex-grow-1">0 t/m {{ breakpointsReadable[0] }} {{ legendLabel }}</span>
      </li>
      <li class="d-flex my-2 mr-3 align-items-center">
        <span class="position-relative">
          <Hexagon
            style="position: absolute"
            color="#f6f6f4"
          />
          <Hexagon
            color="hsl(213, 65%, 60%)"
            :opacity="opacity"
          />
        </span>
        <span class="mx-2 flex-grow-1">> {{ breakpointsReadable[0] }} t/m {{ breakpointsReadable[1] }} {{ legendLabel }}</span>
      </li>
      <li class="d-flex my-2 mr-3 align-items-center">
        <span class="position-relative">
          <Hexagon
            style="position: absolute"
            color="#f6f6f4"
          />
          <Hexagon
            color="hsl(213, 80%, 50%)"
            :opacity="opacity"
          />
        </span>
        <span class="mx-2 flex-grow-1">> {{ breakpointsReadable[1] }} t/m {{ breakpointsReadable[2] }} {{ legendLabel }}</span>
      </li>
      <li class="d-flex my-2 mr-3 align-items-center">
        <span class="position-relative">
          <Hexagon
            style="position: absolute"
            color="#f6f6f4"
          />
          <Hexagon
            color="hsl(213, 95%, 20%)"
            :opacity="opacity"
          />
        </span>
        <span class="mx-2 flex-grow-1">> {{ breakpointsReadable[2] }} {{ legendLabel }}</span>
      </li>
      <li class="d-flex my-2 mr-3 align-items-center">
        <span class="position-relative">
          <Hexagon
            style="position: absolute"
            color="#f6f6f4"
          />
          <Hexagon
            color="hsl(23, 100%, 42%)"
            :opacity="opacity"
          />
        </span>
        <span class="mx-2 flex-grow-1">Geselecteerd</span>
      </li>
    </ul>

    <p v-if="legendLabel === '*'">
      {{ legendDescription }}
    </p>
  </CollapsableSegment>
</template>

<script>
import CollapsableSegment from '@/components/common/CollapsableSegment'
import ToggleSwitch from '@/components/form/ToggleSwitch'
import Hexagon from '@/components/common/Hexagon'
import { mapGetters } from 'vuex'

import { numberWithDots } from '@/helpers/number'

const HEXAGON_PROGNOSE_LAYER = [
  'determinative',
  'inhabitants',
  'visitors',
  'commuters',
]

export default {
  name: 'LegendPrognose',
  components: {
    CollapsableSegment, ToggleSwitch, Hexagon,
  },
  props: {
    label: {
      type: String,
      default: 'Prognose',
    },
  },
  data() {
    return {
      opacity: 0.7,
    }
  },
  computed: {
    ...mapGetters('config', {
      defaultBreakpoints: 'breakpoints',
    }),
    ...mapGetters('scenarios', [
      'forecast',
    ]),
    ...mapGetters('prognose', [
      'ready',
    ]),
    ...mapGetters('deployment', [
      'DeploymentCode',
    ]),
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('layers', [
      'getForecastLayerDetails',
    ]),
    layer() {
      return this.getForecastLayerDetails.find(layer => layer.id === this.forecast)
    },
    legendLabel() {
      if (this.layer?.config?.unit) {
        return this.layer.config.unit
      }

      return this.isVlaamsGewest ? 'CPE\'s' : 'EV\'s'
    },
    legendDescription() {
      if (this.layer?.config?.unitDescription) {
        return this.layer.config.unitDescription
      }

      return ''
    },
    breakpoints() {
      if (! this.layer?.config?.breakpoints) {
        return this.defaultBreakpoints
      }

      return this.layer.config.breakpoints
    },
    breakpointsReadable() {
      return this.breakpoints.map(breakpoint => `${numberWithDots(breakpoint)}`)
    },
    /**
     * This BE prognose is a special presentation, which required different legend labels
     */
    isVlaamsGewest() {
      return this.DeploymentCode === 'be' && this.getActiveMunicipality === '2000'
    },
    visibility: {
      get() {
        return this.$store.getters['prognose/visibility']
      },
      set(visibility) {
        this.$store.commit('prognose/setVisibility', { visibility })
      },
    },
    forecastLayerName() {
      if (HEXAGON_PROGNOSE_LAYER.includes(this.forecast)) {
        return 'hexagons'
      }

      return this.forecast
    },
  },
  watch: {
    visibility(visibility) {
      if (this.ready && this.hasPrognoseLayer()) {
        this.$store.map.setLayoutProperty(this.forecastLayerName, 'visibility', visibility ? 'visible' : 'none')
      }
    },
  },
  mounted() {
    if (this.ready) {
      this.initTransparencyLink()
    } else {
      this.$watch('ready', this.initTransparencyLink)
    }
  },
  beforeDestroy() {
    if (this.ready) {
      this.$store.map.off('zoom', this.onZoom)
    }
  },
  methods: {
    hasPrognoseLayer() {
      return !! this.$store.map.getLayer(this.forecastLayerName)
    },
    /**
     * The Hexagon transparency changes depending on the zoom level.
     *  The legend should change along with it
     */
    initTransparencyLink() {
      this.setHexoganOpacityFromZoom({ zoom: this.$store.map.getZoom() })
      this.$store.map.on('zoom', this.onZoom)
    },
    onZoom(event) {
      this.setHexoganOpacityFromZoom({ zoom: event.target.getZoom() })
    },
    /**
     * The opacity of the hexagons in the legend is matched with the
     * opacity of the layer, which is based on the zoom level of the map
     */
    setHexoganOpacityFromZoom({ zoom }) {
      if (zoom < 10) {
        this.opacity = 0.7
      } else if (zoom > 13) {
        this.opacity = 0.4
      } else {
        this.opacity = 0.7 - (((zoom - 10) / 3) * 0.3)
      }
    },
  },
}
</script>

<style>

</style>
