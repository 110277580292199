<template>
  <div>
    <evtools-tour
      v-if="isAppReady"
      :tour-name="tourName"
      :steps="steps"
      :begin="begin"
      route="Viewer"
      @start-tour="begin = true"
      @end="onEndTour"
    >
      <template #introduction>
        <p>Graag informeren we je over een aantal nieuwe functionaliteiten die zijn toegevoegd aan Maps:</p>

        <ul>
          <li>Vernieuwde legenda</li>
          <li>Vernieuwde manier van status en type selecteren bij het aanmaken en updaten van laadlocaties</li>
          <li>Mogelijkheid om private laadlocaties aan te maken</li>
        </ul>

        <p>
          Feedback, suggesties en vragen zijn welkom op  <MailtoSupport />.
        </p>
      </template>
    </evtools-tour>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import MailtoSupport from '@/components/common/MailtoSupport'
import { CHARGINGPOINT_STATUS } from '@/../shared/valueholders/chargingpoint-statuses'
import { ACTION_SIDEBAR_TAB } from '@/../shared/valueholders/action-sidebar-tabs'


export default {
  name: 'LegendaTour',
  components: {
    MailtoSupport,
  },
  data () {
    return {
      tourName: 'legenda-tour',
      begin: false,
    }
  },
  computed: {
    ...mapGetters('app', [
      'isAppReady',
    ]),
    ...mapGetters('planmode', [
      'getChargingPoints',
      'isChargingpointSelected',
    ]),
    ...mapGetters('config', [
      'isPlanmodeEnabled',
      'isFactsheetEnabled',
    ]),
    steps () {
      return [
        {
          // TODO: Update to select one status dropdown header
          target: '.layer-chargingpoints-realized',
          header: {
            title: 'Status weergave',
          },
          content: '<p>Je kan per status kiezen of deze getoond wordt in de kaart.</p>',
          params: {
            placement: 'right-start',
          },
        },
        {
          // TODO: Update to select individual filters
          target: '.chargingpoint-group.realized',
          header: {
            title: 'Individuele laag',
          },
          content: '<p>Voor meer detail kan je een status uitklappen om per type laadlocatie de kaartlaag aan of uit te zetten door op de individuele icoontjes te klikken. Hier kan nu onderscheid gemaakt worden tussen gewone laders, snelladers en laadpleinen. Ook is er een onderverdeling tussen publieke, semi-publieke en private locaties.</p>',
          params: {
            placement: 'right-start',
          },
          before: () => {
            // open collapsable segment
            const isCollapsed = !document.querySelector('.layer-chargingpoints-realized .collapse').classList.contains('show')
            if (isCollapsed) {
              const header = document.querySelector('.layer-chargingpoints-realized .CollapsableSegment__Header')
              header.click()
            }
          },
        },
        {
          // TODO: Update to select status
          target: '.chargingpoint-group.not-legend',
          header: {
            title: 'Status kiezen',
          },
          content: '<p>Dezelfde herkenbare icoontjes kunnen worden gevonden bij zowel het aanmaken als het aanpassen van een locatie. Op deze manier kan het type laadlocatie gekozen worden. Je kunt nu ook private laadlocaties aanmaken.</p>',
          params: {
            placement: 'bottom-start',
          },
          before: () => new Promise((resolve) => {
            // close collapsable segment
            const isCollapsed = !document.querySelector('.layer-chargingpoints-realized .collapse').classList.contains('show')
            if (!isCollapsed) {
              const header = document.querySelector('.layer-chargingpoints-realized .CollapsableSegment__Header')
              header.click()
            }

            // open sidebar in plan mode
            this.setPlanmodeState({ active: true })

            this.$nextTick(() => {
              if (this.isChargingpointSelected === false) {
                const [chargingpoint] = this.getChargingPoints

                if (chargingpoint) {
                  this.selectChargingPoint({ refId: chargingpoint.ref['@ref'].id })
                }
              }
              this.selectTab({ tabName: ACTION_SIDEBAR_TAB.INFO })
              // TODO: Update to select status
              this.resolveWhenTargetExists('.chargingpoint-group.not-legend', resolve)
            })
          }),
        },
      ]
    },
  },
  methods: {
    ...mapActions('app', ['selectTab']),
    ...mapActions('planmode', [
      'selectChargingPoint',
      'deselectChargingPoint',
    ]),
    ...mapMutations('planmode', {
      setPlanmodeState: 'setActiveState',
    }),
    resolveWhenTargetExists(target, resolve) {
      const interval = setInterval(() => {
        const elem = document.querySelector(target)
        if (elem) {
          // Apparently vue-tour doesn't scroll to the element in Edge, so we do it
          elem.scrollIntoView()
          clearInterval(interval)
          resolve()
        }
      }, 50)
    },
    onEndTour() {
      this.deselectChargingPoint()
      this.setPlanmodeState({ active: false })
    },
  },
}
</script>
