<template>
  <div style="display:none" />
</template>

<script>
import { mapGetters } from 'vuex'

// todo:: find bug that layer is grey until different prognose layer was chosen
export default {
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('deployment', [
      'MapboxAccount',
      'DeploymentHasLayer',
      'DeploymentLayerName',
    ]),
    ...mapGetters('config', {
      defaultBreakpoints: 'breakpoints',
      configLayers: 'layers',
    }),
    ...mapGetters('scenarios', [
      'year', 'forecast',
    ]),
    ...mapGetters('prognose', [
      'getHighlighted', 'labelLayer', 'visibility',
    ]),
    ...mapGetters('layers', {
      forecastLayers: 'getForecastLayerDetails',
    }),
    layer() {
      return this.forecastLayers.find(layer => layer.id === this.forecast)
    },
    isConfigurable() {
      return this.layer.config.isConfigurable
    },
    relevantForecast() {
      return !! this.layer
    },
    hasLayerData() {
      if (this.isConfigurable) {
        return !! this.configLayers[this.layer.id]?.source !== ''
      }

      return this.DeploymentHasLayer({ layer: this.layer?.id })
    },
    sourceName() {
      if (this.isConfigurable) {
        return this.configLayers[this.layer.id]?.source
      }

      return this.DeploymentLayerName({ layer: this.layer?.id })
    },
    url() {
      return `mapbox://${this.MapboxAccount}.${this.sourceName}`
    },
    breakpoints() {
      if (! this.layer.config.breakpoints) {
        return this.defaultBreakpoints
      }

      return this.layer.config.breakpoints
    },
  },
  watch: {
    loaded() {
      if (! this.$store.map.getLayer(this.layer?.id)) {
        this.addLayer()
      }
    },
    year() {
      this.updatePrognose()
    },
    getHighlighted() {
      this.updatePrognose()
    },
    forecast(next, previous) {
      if (this.$store.map.getLayer(previous)) {
        this.removeLayer({ id: previous })
      }

      if (! this.$store.map.getLayer(next)) {
        this.addLayer()
      } else {
        this.updatePrognose()
      }
    },
  },
  created() {
    if (this.loaded) {
      this.addLayer()
    }
  },
  beforeDestroy() {
    if (this.loaded) {
      this.removeLayer({ id: this.layer?.id })
    }
  },
  methods: {
    addLayer() {
      if (! this.layer) return
      if (! this.hasLayerData) return
      if (! this.relevantForecast) return

      this.$store.map.addSource(this.layer.id, {
        type: 'vector',
        url: this.url,
      })
      this.$store.map.addLayer({
        'id': this.layer.id,
        'type': 'fill',
        'source': this.layer.id,
        'source-layer': this.sourceName,
        'paint': {
          'fill-color': this.fillHexagons(),
          // Note: The legend opacity is linked
          'fill-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            10,
            0,
            10.2,
            0.7,
            13,
            0.4,
          ],
          'fill-outline-color': [
            'interpolate',
            ['linear'],
            ['zoom'],
            10,
            'hsla(232, 67%, 60%, 0)',
            22,
            'hsla(232, 67%, 60%, 0.9)',
          ],
        },
        'layout': {
          'visibility': this.visibility ? 'visible' : 'none',
        },
      }, this.labelLayer)
    },
    removeLayer({ id }) {
      if (this.$store.map.getLayer(id)) {
        this.$store.map.removeLayer(id)
        this.$store.map.removeSource(id)
      }
    },
    updatePrognose() {
      if (this.$store.map.getLayer(this.layer?.id)) {
        this.$store.map.setPaintProperty(this.layer?.id, 'fill-color', this.fillHexagons())
      }
    },
    fillHexagons() {
      let value = ['to-number', ['get', `${this.layer.config.propertyWithData}${this.year}`]]

      let styleCondition = [
        'case',
      ]

      // The selected tiles
      this.getHighlighted.forEach(tileID => {
        styleCondition.push([
          '==',
          [
            'get',
            this.layer.config.propertyWithTileID,
          ],
          tileID,
        ])
        styleCondition.push('hsl(23, 100%, 42%)')
      })

      return styleCondition.concat([
        // The layer we want to ignore
        [
          '<',
          value,
          0.05,
        ],
        'hsla(0, 0%, 0%, 0)',
        // 0 - 1
        [
          '<=',
          value,
          this.breakpoints[0],
        ],
        'hsl(213, 50%, 80%)',
        // 1+ - 2
        [
          '<=',
          value,
          this.breakpoints[1],
        ],
        'hsl(213, 65%, 55%)',
        // 2+ - 4
        [
          '<=',
          value,
          this.breakpoints[2],
        ],
        'hsl(213, 80%, 35%)',
        // 4+
        [
          '>',
          value,
          this.breakpoints[2],
        ],
        'hsl(213, 95%, 20%)',
        // Default (required by MapBox)
        'hsla(0, 0%, 0%, 0)',
      ])
    },
  },
}
</script>
