var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SideBar',{staticClass:"AdminSideBar"},[_c('Tabs',{attrs:{"tabs":_vm.tabs,"active":_vm.activeTab},on:{"selected":_vm.handleTabClick}}),(_vm.activeTab === 'search')?[_c('Fuse',{staticClass:"p-4",attrs:{"list":_vm.list,"options":_vm.fuseOptions,"placeholder":"Gemeentenaam, code of provincie"},on:{"resultsChanged":_vm.handleResultsChanged,"enterButton":_vm.selectFirstMunicipality}}),(_vm.results)?_c('ul',{staticClass:"SearchResults py-3"},_vm._l((_vm.results),function(municipality){return _c('li',{key:municipality.code,staticClass:"SearchResult pr-3 pl-5 py-1",class:{
          'SearchResult__Stored': municipality.state !== 'new',
          'SearchResult__Active': municipality.selected,
          'SearchResult__Live': municipality.state === 'live',
          'SearchResult__Upload': municipality.state === 'upload-only',
          'SearchResult__Disabled': municipality.state === 'disabled',
        },on:{"click":function($event){return _vm.handleSelectMunicipality({ municipality })}}},[_vm._v(" "+_vm._s(municipality.label)+" "),_c('span',{staticClass:"SearchResult__Icon"})])}),0):_vm._e()]:_vm._e(),(_vm.activeTab === 'info')?_c('div',{staticClass:"p-4"},[_c('p',[_vm._v(" Instructies over het gebruik van het dashboard ... ")])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }