import { isProxyByCode, proxyCodesByCode } from '@/services/municipalities'
import { fetchCompressedData } from '@/helpers/api'
import { chunk } from '@/helpers/array'
import { mapMutations, mapGetters } from 'vuex'
import { Bugfender } from '@bugfender/sdk'
import Vue from 'vue'

export default {
  data() {
    return {
      isLoadingFauna: false,
    }
  },
  computed: {
    ...mapGetters('planmode', ['getChargingPoints']),
  },
  methods: {
    ...mapMutations('planmode', [
      'setChargingPoints',
    ]),
    $_chargingpointsLoadMixin_getChargingPointsByCodes ({ code, codes, token }) {
      return fetchCompressedData('/api/chargingpointsload', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({ codes, code }),
      })
        .catch((e) => {
          Bugfender.error('$_chargingpointsLoadMixin_getChargingPointsByCodes: ', e)

          Vue.notify({
            type: 'error',
            title: 'Er is iets misgegaan!',
            text: 'De locaties konden niet worden geladen',
          })
        })
    },

    /**
     * Load the charging points for a municipality by code
     */
    async $_chargingpointsLoadMixin_loadChargingPoints ({ code }) {
      if (this.getChargingPoints.length && this.getChargingPoints[0].data.code === code) {
        return this.getChargingPoints
      }

      this.isLoadingFauna = true

      // Quick & Dirty solution to avoid rate limits
      function sleep({ ms }) {
        return new Promise(resolve => setTimeout(resolve, ms))
      }

      let codes = [code]
      if (isProxyByCode({ code })) {
        codes = proxyCodesByCode({ code })
      }

      const token = await this.$auth.getTokenSilently()
      const chunkes = chunk({ arr: codes, size: 5 }) // At more than 5 we frequently get timeouts

      let chargingpoints = []
      await chunkes.reduce( async (result, chunk) => {
        await sleep({ ms: 500 })
        let response = await this.$_chargingpointsLoadMixin_getChargingPointsByCodes({ code, codes: chunk, token })

        if (! response) {
          return result
        }

        chargingpoints = chargingpoints.concat(response.chargingpoints)
        return result
      }, [])

      this.setChargingPoints({ chargingpoints })

      this.isLoadingFauna = false
    },
  },
}
