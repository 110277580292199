<template>
  <div class="Page--Home h-100 d-flex flex-column align-items-center justify-content-center">
    <b-card
      v-if="loaded && municipalities.length !== 1"
      tag="article"
      :img-src="image"
      class="mt-5"
    >
      <b-card-text v-if="!loaded">
        <p class="mt-3 text-center">
          Loading...
        </p>
      </b-card-text>
      <b-card-text
        v-else-if="municipalities.length === 0"
        class="px-4 pb-4"
      >
        <p class="mt-3">
          Beste {{ nickname }},
        </p>
        <p>
          Er zijn geen gebruikersrechten verbonden aan uw account op de {{ DeploymentCode === 'nl' ? 'Nederlandse' : 'Belgische' }} versie van Maps: <LinkToEVMaps />.
        </p>
        <p>
          Indien u wel toegang tot een {{ DeploymentCode === 'nl' ? 'Nederlandse' : 'Belgische' }} kaart had verwacht, neem dan contact op met <MailtoSupport />.
        </p>
        <p>
          Indien u op zoek bent naar een {{ DeploymentCode === 'nl' ? 'Belgische' : 'Nederlandse' }} kaart, ga dan naar onze {{ DeploymentCode === 'nl' ? 'Belgische' : 'Nederlandse' }} versie van Maps: <LinkToEVMaps switch-country />.
        </p>
      </b-card-text>
      <b-card-text
        v-else
        class="px-4 pb-4"
      >
        <p class="mt-3 text-center">
          Kies een gemeente om verder te gaan
        </p>
        <div class="d-flex flex-column mt-4 align-items-center justify-content-center">
          <a
            v-for="municipality in municipalities"
            :key="municipality.slug"
            class="mt-3 municipality-route"
            @click="goToMunicipalityRoute({ municipality })"
          >
            {{ municipality.label }}
          </a>
        </div>
      </b-card-text>
    </b-card>
    <a
      v-if="loaded"
      class="text-muted mt-3"
      style="cursor: pointer"
      @click="logout"
    >
      Uitloggen
    </a>
    <div
      v-else
      class="h-100 w-100 d-flex flex-column justify-content-center align-items-center"
    >
      <!-- TODO: Make this looking nicer & offer a back button to Home / Contact address -->
      <div class="d-flex align-items-center">
        <b-spinner
          class="mr-3"
          label="Loading..."
        />
        <span>Loading...</span>
      </div>
      <a
        class="text-muted mt-5"
        style="cursor: pointer"
        @click="logout"
      >
        Uitloggen
      </a>
    </div>
  </div>
</template>

<script>
import { loadThemeCss } from '../main'
import { mapGetters } from 'vuex'

import MailtoSupport from '@/components/common/MailtoSupport'
import LinkToEVMaps from '@/components/common/LinkToEVMaps'

import { municipalityByCode } from '@/services/municipalities'

import { image } from '@/helpers/assets'
import { capitalizeFirstLetter } from '@/helpers/string'


/**
 * TODO: Apply layout, styling, etc. In other words: make it pretty.
 */
export default {
  name: 'Home',
  components: {
    MailtoSupport,
    LinkToEVMaps,
  },
  data() {
    return {
      image: image({ name: 'evtools.png' })
    }
  },
  computed: {
    ...mapGetters('access', [
      'municipalityCodeList',
      'hasAdminAccess',
    ]),
    ...mapGetters('config', [
      'loaded',
      'isEnabledByCode',
      'isPrognoseEnabledByCode',
      'isUploadEnabledByCode',
      'hasLayers',
    ]),
    ...mapGetters('deployment', [
      'DeploymentCode',
    ]),
    municipalities() {
      // Go through all municipalities the user has access to
      return this.municipalityCodeList
        // Remove municipalities from the list if the are entirely unavailable
        .filter(code => this.isEnabledByCode({ code }) || ( this.hasAdminAccess({ code }) && this.hasLayers({ code }) ))
        // Load the municipality meta data by code
        .map(code => {
          return municipalityByCode({ code })
        })
        // Sort the municipalities alphabetically
        .sort((a, b) => a.label.localeCompare(b.label, 'nl', { ignorePunctuation: true }))
    },
    nickname() {
      return capitalizeFirstLetter(
        this.$auth.user.nickname,
      )
    },
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
  },
  watch: {
    municipalities: {
      immediate: true,
      handler() {
        if (this.superuser || this.municipalities.length === 1) this.redirect()
      }
    }
  },
  methods: {
    // load theme css based on selected municipality (mra-e, default ...), prior to loading a route //
    async goToMunicipalityRoute ({ municipality }) {
      await loadThemeCss({ path: municipality.slug })
      this.$router.push(this.municipalityRoute({ code: municipality.code, slug: municipality.slug }))
    },
    async redirect() {
      if (this.superuser) {
        this.$router.push({
          name: 'Dashboard',
        })
      }
      else {
        let code = this.municipalities[0].code
        if (this.isEnabledByCode({ code }) || this.hasAdminAccess({ code })) {
          // If there is only one municipality available for the user, load the appropriate theme prios to redirect //
          await loadThemeCss({ path: this.municipalities[0].slug })

          this.$router.push(this.municipalityRoute({
            code: this.municipalities[0].code,
            slug: this.municipalities[0].slug,
          }))
        }
      }
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
    municipalityRoute({ code, slug }) {
      if (this.isPrognoseEnabledByCode({ code }) || (this.hasAdminAccess({ code }) && this.hasLayers({ code })) ) {
        return { name: 'Viewer', params: { municipality: slug } }
      } else {
        return { name: 'Upload', params: { municipality: slug } }
      }
    },
  },
}
</script>

<style lang="scss">

.municipality-route {
  cursor: pointer;
}

.Page--Home {
  .card {
    max-height: 85%;
    max-width: 330px;

    .card-img {
      background-color: var(--primary);
      padding: 0.5rem 0;
    }

    .card-body {
      overflow-y: auto;
    }
  }
}
</style>
