<template>
  <div class="PieChart">
    <GChart
      v-if="hasData"
      type="PieChart"
      :settings="{packages: ['corechart', 'table'], language: 'nl'}"
      :data="chartData"
      :options="chartOptions"
    />
    <div
      class="Text Text--fallback"
      v-text="'Datawaarden zijn te laag om cirkeldiagram weer te geven'"
    />
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'

import getCssProperty from '@/helpers/cssProperty'
import { labelColor } from '@/helpers/charts'

export default {
  name: 'PieChart',
  components: { GChart },
  props: {
    title: {
      type: String,
      default: '',
    },
    labels: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasData() {
      return Object.values(this.data).some((value) => value > 0)
    },
    chartOptions() {
      return {
        chart: {
          title: this.title,
        },
        pieSliceText: 'value',
        legend: { position: 'right', textStyle: { color: labelColor, fontSize: 12 } },
        colors: [
          getCssProperty('secondary'),
          '#E4EDF5',
          getCssProperty('primary'),
        ],
        height: 210,
        width: 300,
        chartArea: { left: 0, right: 0 },
        slices: {
          1: { textStyle: { color: 'black' } },
        },
        animation: {
          duration: 500,
        },
      }
    },
    chartData() {
      return Object.entries(this.data).reduce((acc, [vehicleType, data]) => {
        acc.push([this.labels[vehicleType], data, `${data}%`])
        return acc
      }, [['count', 'VehicleType', { role: 'annotation' }]])
    },
  },
}
</script>
