<template>
  <nav class="NavBar d-flex flex-row">
    <div class="NavBar__logo">
      <router-link :to="{ name: 'Home'}">
        <Logo
          :organisation="getCurrentTheme.details.organization"
          :image="getCurrentTheme.details.logo"
        />
      </router-link>
    </div>
    <p class="NavBar__description align-self-center my-0 flex-grow-1">
      <span class="ml-2 d-flex">
        {{ pageTitle }}
      </span>
    </p>
    <div class="d-flex flex-row justify-content-end flex-grow-1 align-self-center align-items-center">
      <slot />

      <span
        class="NavBar__Profile d-inline-flex align-center mr-3"
        :class="{ 'NavBar__Profile--visible': profileActive }"
        :value="profileActive"
        @click="showActiveItem('profileActive')"
      >
        <SvgIcon
          icon="user-regular"
          :has-fill="true"
        />
      </span>

      <span
        class="NavBar__Logout d-inline-flex align-center mr-3"
        :class="{ 'NavBar__Menu--visible': menuActive }"
        :value="menuActive"
        @click="showActiveItem('menuActive')"
      >
        <SvgIcon
          icon="bars-regular"
          :has-fill="true"
        />
      </span>
    </div>
    <ProfileCard
      v-if="profileActive"
      :style="{ right: profileCardOffset }"
    />
    <NavBarMenu
      v-if="menuActive"
      :style="{ right: profileCardOffset }"
    />
  </nav>
</template>

<script>
import Logo from '@/components/navbar/Logo'
import SvgIcon from '@/components/common/SvgIcon'

import ProfileCard from '@/components/profile/ProfileCard'
import NavBarMenu from '@/components/common/NavBarMenu'

import { mapGetters, mapState } from 'vuex'

// import { getScrollbarWidth } from '@/helpers/dom'
import { EventBus } from '@/services/eventbus'
import { image } from '@/helpers/assets'

export default {
  name: 'NavBar',
  components: {
    Logo, SvgIcon, ProfileCard, NavBarMenu,
  },
  data() {
    return {
      profileActive: false,
      menuActive: false,
    }
  },
  computed: {
    ...mapState(['pageTitle']),
    ...mapGetters('config', ['getCurrentTheme']),
    /**
     * We need to avoid the profile card covering up the scrollbar of the main page content
     */
    profileCardOffset() {
      return '1.25em'
    },
    organization () {
      return this.getCurrentTheme.details.organization
    },
  },
  mounted() {
    EventBus.$on('overlay-hide', () => this.profileActive = this.menuActive = false)
  },
  beforeDestroy() {
    EventBus.$off('overlay-hide')
  },
  methods: {
    showActiveItem(item) {
      this[item] = true
      EventBus.$emit('overlay-show')
    },
  },
}
</script>

<style lang="scss">
.NavBar {
  position: relative;
  height: 80px;
  min-height: 80px;
  background: var(--primary);
  box-shadow: 0px -1px 0px 0px rgba(223,226,229,1) inset;
  user-select: none;

  &__logo {
    width: 240px;
    height: 100%;
    padding: 7px;

    @media (max-width: 1050px) {
      width: 200px;
    }
  }
  &__description {
    height: 1.1rem;
    font-size: 1.1rem;
    color: var(--grey);

    @media (max-width: 1200px) {
      span {
        display: none;
      }
    }

    @media (max-width: 920px) {
      display: none;
    }
  }
  .form-group {
    margin-bottom: 0
  }
  .SvgIcon {
    font-size: 1.75rem;
    fill: white;
    cursor: pointer;

    &:hover {
      fill: darken(white, 20%);
    }
  }

  &__Profile--visible .SvgIcon {
    fill: var(--cta);
  }

  .ProfileCard, .NavBarMenu {
    position: absolute;
    top: calc(80px - 0.75rem);
    box-sizing: 0px 1px 3px 0px #dfe2e5;
  }
}
</style>
