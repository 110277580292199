

export const ManualsMenu = [
  {
    type: 'segment',
    label: 'Maps handleiding',
    items: [
      { 
        type: 'link',
        label: 'Inleiding',
        to: {
          name: 'ManualPage',
          params: { slug: 'inleiding' }
        },
        securityCheck: 'manuals'
      },
      { 
        type: 'link',
        label: 'Inloggen en wachtwoord wijzigen',
        to: {
          name: 'ManualPage',
          params: { slug: 'inloggen-en-wachtwoord-wijzigen' }
        },
        securityCheck: 'manuals'
      },
      { 
        type: 'link',
        label: 'Gebruikersrechten',
        to: {
          name: 'ManualPage',
          params: { slug: 'gebruikersrechten' }
        },
        securityCheck: 'manuals'
      },
      { 
        type: 'link',
        label: 'Uploaden data',
        to: {
          name: 'ManualPage',
          params: { slug: 'uploaden-data' }
        },
        securityCheck: 'manuals'
      },
      { 
        type: 'link',
        label: 'De plankaart',
        to: {
          name: 'ManualPage',
          params: { slug: 'de-plankaart' }
        },
        securityCheck: 'manuals'
      },
      { 
        type: 'link',
        label: 'Prognose van de laadvraag',
        to: {
          name: 'ManualPage',
          params: { slug: 'prognose-van-de-laadvraag' }
        },
        securityCheck: 'manuals'
      },{ 
        type: 'link',
        label: 'Factsheet',
        to: {
          name: 'ManualPage',
          params: { slug: 'factsheet' }
        },
        securityCheck: 'manuals'
      },
      { 
        type: 'link',
        label: 'Laadlocaties inplannen',
        to: {
          name: 'ManualPage',
          params: { slug: 'laadlocaties-inplannen' }
        },
        securityCheck: 'manuals'
      },
      { 
        type: 'link',
        label: 'Valideren laadvoorstellen',
        to: {
          name: 'ManualPage',
          params: { slug: 'valideren-laadvoorstellen' }
        },
        securityCheck: 'manuals'
      },
      { 
        type: 'link',
        label: 'Participatieportaal',
        to: {
          name: 'ManualPage',
          params: { slug: 'participatieportaal' }
        },
        securityCheck: 'manuals'
      },
    ],
  },
]

