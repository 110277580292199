/*
* helpers for mapbox
*/

const createMarker = async ({ coordinates, options, map }) => {
  const { default: mapboxgl } = await import('mapbox-gl') // Dynamically import mapbox-gl

  const defaults = {
    allowOverlap: false,
  }

  options = {
    ...defaults,
    ...options,
  }
  // Create a marker to display the distance
  return new mapboxgl.Marker(options)
    .setLngLat(coordinates)
    .addTo(map)
}

module.exports = {
  createMarker,
}
