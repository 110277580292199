<template>
  <div class="LocatieInformatie__Content">
    <div class="LocatieInformatie__Attachments mb-2">
      <strong>Bijlagen</strong>
      <template v-if="!isLoadingAws">
        <Lightbox
          v-if="images.length > 0"
          :gallery="images"
        >
          <template #silentbox-item="{ item }">
            {{ item.description }}
            <div class="ratio ratio-16x9">
              <div class="inner">
                <div
                  class="LocatieInformatie__Attachments__Image__Inline"
                  title="Download afbeelding"
                  @click.stop
                >
                  <a
                    :href="item.src"
                    :download="item.filename"
                  >
                    <SvgIcon icon="cloud-download-alt-solid" />
                  </a>
                </div>
                <img :src="item.src">
              </div>
            </div>
          </template>
        </Lightbox>
        <p v-else>
          Geen bijlagen beschikbaar
        </p>
      </template>
      <p v-else>
        Loading... <b-spinner
          small
          variant="primary"
          label="Spinning"
        />
      </p>
    </div>

    <template v-if="address">
      <p>
        <strong>
          Adres <ButtonCopyValue :value="address.formatted_address || ''" />
        </strong>

        <br>

        {{ address.simple_address }} <br>
        {{ address.postalcode }} {{ address.city }}
      </p>

      <p v-if="address.source">
        <strong>Adresbron</strong> <br>
        {{ addressSource }}
      </p>

      <p v-if="evse">
        <strong>EVSE</strong><br>
        {{ evse }}
      </p>

      <p v-if="coordinates.lat && coordinates.lng">
        <strong>
          Coordinaten <SvgIcon
            v-if="importedWithoutCoordinates"
            id="no-coordinates-hint"
            icon="info-circle-regular"
          />
        </strong> <br>
        {{ coordinates.lat }}, <br>
        {{ coordinates.lng }}
      </p>
      <b-tooltip
        v-if="importedWithoutCoordinates"
        target="no-coordinates-hint"
        triggers="hover"
      >
        Deze locatie werd door Vattenfall zonder coördinaten aangeleverd.
      </b-tooltip>
    </template>
    <p v-else>
      <HintAddressIncomplete />

      <strong>Adres</strong><br>
      Onbekend
    </p>

    <p v-if="status">
      <strong>Status</strong><br>
      {{ statusLabel }}
    </p>

    <p v-if="currentCpo">
      <strong>Charge Point Operator</strong><br>
      {{ currentCpo.name }}
    </p>

    <div v-if="prio && prio.order">
      <strong>Prioriteit</strong>
      <p>{{ prio.order }}</p>
    </div>

    <div v-if="hasPhase">
      <strong>Fase</strong>
      <p>{{ phase }}</p>
    </div>

    <p v-if="introducedBy">
      <strong>Ingevoerd door</strong><br>
      {{ introducedBy }}
    </p>

    <p v-if="remark">
      <strong>Toelichting</strong><br>
      {{ remark }}
    </p>

    <p v-if="power">
      <strong>Power</strong><br>
      max. {{ power | formatTokW }}
    </p>

    <p v-if="nroutlets">
      <strong>Aantal laadpunten</strong><br>
      {{ nroutlets }}
    </p>

    <p v-if="types">
      <strong>Type connector(en)</strong><br>
      <span
        v-for="type of types"
        :key="type"
      >{{ type }}<br></span>
    </p>

    <AlternativeLocation :predecessor="predecessor" />

    <p>
      <strong>Locatiebeheer</strong>
    </p>

    <p>
      <strong>Beheerder</strong><br>
      {{ beheerder ? beheerder.name : 'Geen' }}
    </p>

    <div class="the-validators">
      <p class="mb-0">
        <strong>Validatoren</strong>
      </p>

      <ul v-if="hasValidators">
        <li
          v-for="validator in validators"
          :key="validator.user_id"
        >
          {{ validator.name }}
        </li>
      </ul>

      <span v-else>
        Geen
      </span>
    </div>
  </div>
</template>

<script>
import chargingpointDataMixin from '@/mixins/chargingpoint/chargingpointDataMixin'
import chargingpointAttachmentsMixin from '@/mixins/chargingpoint/chargingpointAttachmentsMixin'
import Lightbox from '@/components/common/lightbox/Lightbox'
import SvgIcon from '@/components/common/SvgIcon'

import { attachmentIDToDescription } from '@/../shared/valueholders/attachments'
import ButtonCopyValue from '@/components/common/ButtonCopyValue'
import HintAddressIncomplete from '@/components/map/sidebar/HintAddressIncomplete'
import AlternativeLocation from '@/components/map/sidebar/AlternativeLocation'

export default {
  components: { AlternativeLocation, HintAddressIncomplete, Lightbox, SvgIcon, ButtonCopyValue },
  mixins: [chargingpointDataMixin, chargingpointAttachmentsMixin],
  computed: {
    images() {
      return Object.values(this.attachments)
          .filter(attachment => !!attachment.url)
          .map(attachment => ({
            src: attachment.url,
            description: attachmentIDToDescription({ id: attachment.id }),
            filename: attachment.filename,
          }))
    },
    hasPhase () {
      return this.prio?.fase || this.prio?.customPhase
    },
    phase () {
      return (this.prio?.fase && `Fase ${this.prio.fase}`) || this.customPhase
    },
  },
  watch: {
    id() {
      this.$_chargingpointAttachmentsMixin_cleanFetchAttachments()
    },
  },
  created() {
    this.$_chargingpointAttachmentsMixin_cleanFetchAttachments()
  },
}
</script>
