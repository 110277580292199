<template>
  <TileDataContent
    :fids="fids"
    :forecast-label="forecastLabel"
    :dataset="dataset"
    :data-by-id="dataById"
  >
    <template #content-header>
      <TileDataContentHeader>
        <template #second-column>
          kWh / jaar
        </template>
        <template #third-column>
          Laadpunten <small><SvgIcon
            id="occupancy-rate-footnote"
            class="tooltip-icon"
            icon="info-circle-regular"
          /></small>
          <b-tooltip
            target="occupancy-rate-footnote"
            triggers="hover"
          >
            <p>Hoge bezettingsgraad: 20% per 24u, gemiddelde laadsnelheid 120 kW</p>
            <p class="mb-0">
              Lage bezettingsgraad: 11% per 24 u, gemiddelde laadsnelheid 100 kW
            </p>
          </b-tooltip>
        </template>
      </TileDataContentHeader>
    </template>

    <template #content="{ showData }">
      <TileDataContentRowThreeColumn>
        <template #first-column>
          > 150 kW <small><SvgIcon
            id="150-kw-corridors-footnote"
            class="tooltip-icon"
            icon="info-circle-regular"
          /></small>
          <b-tooltip
            target="150-kw-corridors-footnote"
            triggers="hover"
          >
            <p class="mb-0">
              Personenvoertuigen (bewoners, bezoekers en forenzen)
            </p>
          </b-tooltip>
        </template>
        <template #second-column>
          {{ showData({ field: `kWh_jr_${year}`, decimals: 0 }) | numberWithDots }}
        </template>
        <template #third-column>
          {{ showData({ field: `punten_${year}_hoog`, decimals: 1 }) }} - {{ showData({ field: `punten_${year}_laag`, decimals: 1 }) }}
        </template>
      </TileDataContentRowThreeColumn>
    </template>

    <template #footer>
      ID: CO{{ fids[0] }}
    </template>
  </TileDataContent>
</template>

<script>
import TileDataContent from '@/components/map/tiles/TileDataContent'
import TileDataContentRowThreeColumn from '@/components/map/tiles/TileDataContentRowThreeColumn'
import TiledataMixin from '@/mixins/map/tiledata-mixin'
import SvgIcon from '@/components/common/SvgIcon'
import TileDataContentHeader from '@/components/map/tiles/TileDataContentHeader'

export default {
  name: 'TileDataOMCorridors',
  components: { TileDataContentHeader, SvgIcon, TileDataContentRowThreeColumn, TileDataContent },
  mixins: [TiledataMixin],

}
</script>
