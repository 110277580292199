<template>
  <div>
    <!-- popup that is displays the confirmation of updating a location with realisation process -->
    <MapBoxPopup
      v-if="locked && ! refId"
      :coordinates="coordinates"
      :offset="[0, -30]"
      :show="true"
      @close="handleClose"
    >
      <div>
        <div class="locked-hint">
          <p class="mb-2">
            <SvgIcon
              class="locked-hint-icon d-inline-block mr-1"
              icon="info-circle-regular"
            /> <strong> LET OP </strong>
          </p>

          <p class="mb-2">
            Het is niet de bedoeling dat laadpalen met een fase verplaatst worden, weet u zeker dat de laadpaal verplaatst moet worden?
          </p>

          <p class="mb-1">
            <strong>Houd ingedrukt om slepen te ontgrendelen </strong>
          </p>
          <b-progress
            :value="unlockTimerCountDown"
            :max="unlockTimerDuration"
          />
        </div>
      </div>
    </MapBoxPopup>

    <!-- popup that handles creation and moving of a location in two steps -->
    <ChargerTwoStepGeocodingPopup
      v-else-if="isGeocodingNecessary"
      :is-update="isLocationUpdate"
      :coordinates="coordinates"
      :show="show"
      @create="handleSubmitAdd"
      @update="handleSubmitUpdate"
      @cancel="handleSubmitCancel"
      @close="handleClose"
    />

    <!-- popup that is displayed while the sidebar is open -->
    <MapBoxPopup
      v-else-if="show && refId"
      :coordinates="coordinates"
      :offset="[0, -30]"
      :show="show"
      @close="handleClose"
    >
      <div class="d-flex flex-column">
        <div v-if="address">
          {{ address }}
          <ButtonFlyTo :coordinates="coordinates" />
        </div>
        <div>
          {{ statusLabel }}
        </div>
        <div v-if="currentCpo">
          CPO: {{ currentCpo.name }}
        </div>
        <div>
          ID: {{ `${code}-${id}` }}
        </div>
        <p v-if="prio && prio.order">
          Prioriteit: {{ prio.order }} {{ prio.fase ? `(Fase ${prio.fase})` : '' }}
        </p>
        <!-- todo:: needs to use dataMixin and introducedBY; to be done in: https://ev-it.atlassian.net/browse/TOOLS-969 -->
        <div v-if="user.name">
          <span class="text-muted">{{ user.name }}</span>
        </div>
        <div
          v-if="remark"
          class="mt-3"
        >
          {{ setChargingpointRemark }}
        </div>

        <div v-if="features.length > 1">
          <hr class="mt-3 mb-2">

          <b-pagination
            v-model="currentPage"
            class="justify-content-center pagination-sm m-0"

            hide-goto-end-buttons
            :total-rows="features.length"
            :per-page="1"
          />
        </div>
      </div>
    </MapBoxPopup>

    <!-- modal that displays the confirmation of address change -->
    <b-modal
      ref="confirm-move-location-modal"
      title="Locatie verplaatsen"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <p>
        Wilt u het adres behouden of wijzigen bij het verplaatsen van dit punt?
      </p>
      <template #modal-footer>
        <b-button
          variant="light"
          @click="moveCanceled"
        >
          Annuleren
        </b-button>
        <b-button
          variant="secondary"
          @click="moveAndKeepAddress"
        >
          Behouden
        </b-button>
        <b-button
          variant="danger"
          @click="moveAndStartGeocodingProcess"
        >
          Wijzigen
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>

/**
 * TODO: Refactor to better quality code
 */

import MapBoxPopup from '@/components/common/MapBoxPopup'

import { subscribe, unsubscribe } from '@/services/pusher'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { EventBus } from '@/services/eventbus'
import { statusSlugToLabel } from '@/../shared/services/statusTranslations'

import chargingpointEditMixin from '@/mixins/chargingpoint/chargingpointEditMixin'
import chargingpointCpoMixin from '@/mixins/chargingpoint/chargingpointCpoMixin'
import chargingpointsLoadMixin from '@/mixins/chargingpoint/chargingpointsLoadMixin'
import userMixin from '@/mixins/common/userMixin'
import { provincieCodeByMunicipalityCode } from '@/services/municipalities'
import SvgIcon from '@/components/common/SvgIcon'
import { workflowLockedForEditingMessage } from '@/services/commonMessages'

import ChargerTwoStepGeocodingPopup from '@/components/map/modals/ChargerTwoStepGeocodingPopup'
import { CHARGINGPOINT_STATUS } from '@/../shared/valueholders/chargingpoint-statuses'
import ButtonFlyTo from '@/components/common/ButtonFlyTo.vue'

export default {
  name: 'ChargerEditorPopup',
  components: { ButtonFlyTo, ChargerTwoStepGeocodingPopup, SvgIcon, MapBoxPopup },
  mixins: [chargingpointEditMixin, chargingpointsLoadMixin, chargingpointCpoMixin, userMixin],
  data() {
    return {
      coordinates: null,

      show: false,

      // Properties (matches ChargerEditorPopup)
      refId: null,
      id: null,
      code: null,
      remark: null,
      user: {
        name: null,
      },
      stakeholders: [],
      status: null,
      address: null,

      validators: [],

      prio: {
        order: null,
        exact: null,
        fase: null,
        customPhase: null,
      },

      // lock if point with fase assigned or is VOLT loc.
      locked: false,

      unlockTimerInterval: null,
      unlockTimerDuration: 4,
      unlockTimerCountDown: null,

      // drag & drop
      draggingPoint: null,

      // if this flag is set, we don't want to deselect the chargingpoint in the store to avoid a flickr,
      // while switching between chargingpoints on the map
      doNotDeselectChargingpoint: false,

      isLocationUpdate: false,
      isLockedForEditing: false,
      isWorkflowCreated: false,

      // pagination
      currentPage: 1,
      features: [],
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
      'hasAdminAccess',
    ]),
    ...mapGetters('config', [
      'hasValidationSettings',
      'getValidationSettings',
      'getDefaultValidationSettingProfile',
    ]),
    ...mapGetters('planmode', [
      'getChargingPoints',
      'getChargingPointByRefId',
      'getPlanmodeLayers',
      'getChargingPointIndexByRefId',
      'isChargingpointSelected',
      'getLockedPhases',
    ]),

    isBraLiMunicipality() {
      return ([31, 30].includes(provincieCodeByMunicipalityCode({ code: this.getActiveMunicipality })))
    },

    statusLabel() {
      return statusSlugToLabel({ status: (this.status || 'definitive') })
    },

    isGeocodingNecessary() {
      if (! this.show) return false

      if (this.isLocationUpdate) return true

      return (! this.refId && this.coordinates)
    },

    setChargingpointRemark () {
      return this.isWorkflowCreated ? this.remark : 'Je kunt de laadpaal bewerken aan de rechterzijkant onder de locatieinformatie tab'
    },

    lockedLocationType () {
      return this.isWorkflowCreated ? 'Workflow gecreëerde' : 'RHDHV VOLT'
    },

    /**
     * Duplicate ChargingPoint Id check
     *  In edge cases, users may create charging points with the same ID
     *  This computed works together with a watcher to take corrective action when duplicates appear
     */
    duplicateIdUsage() {
      let duplicates = this.getChargingPoints
        .map(item => item.data.properties.id)
        .reduce((result, id, index) => {
          result[id] = result[id] ? result[id].concat([index]) : [index]
          return result
        }, {})

      duplicates = Object
        .values(duplicates)
        .filter(entries => entries.length !== 1)
        .map(entries => entries.map(index => this.getChargingPoints[index].ref['@ref'].id))

      return duplicates
    },

    // Pagination
    visibleChargingPoints() {
      return [this.features[this.currentPage - 1] || false]
    },
  },
  watch: {
    // Whenever the chargingpoint is changed by pagination, update the popup
    currentPage() {
      this.handleOpen({ refId: this.visibleChargingPoints[0]?.properties.refId })
    },

    /**
     * Hide the popup whenever the municiaplity changes
     */
    getActiveMunicipality: async function(code, previous) {
      this.activatePlanmode()

      this.handleClose()

      // Update Pusher connection
      unsubscribe({ channel: previous })
      let { channel } = await subscribe({ channel: code })
      this.channel = channel
    },

    /**
     * Changes to the pusher channel
     * There are none for this component at this moment
     */
    // channel(channel) {
    //   if (channel === null) return
    // },

    /**
     * Handle the existance of duplicate id usage
     */
    duplicateIdUsage: async function(duplicates) {
      if (duplicates.length === 0) return

      let originalRefId = duplicates[0][0]

      /*
       * check if original chargingpoint still exists, so that only in case of real duplication the id is changed
       * fix for: https://ev-it.atlassian.net/browse/TOOLS-793
      */
      const { chargingpointExists } = await this.$_chargingpointEditMixin_exists({
        code: this.getActiveMunicipality,
        refId: originalRefId,
      })

      if (chargingpointExists === false) {
        // if selected
        this.handleClose()

        await this.$_chargingpointsLoadMixin_loadChargingPoints({
          code: this.getActiveMunicipality,
        })

        return
      }

      // Only adjust one chargingpoint ( the last one ). This will change the dataset and trigger the watcher again
      const duplicationRefId = duplicates[0][duplicates[0].length -1]

      const chargingpoint = this.getChargingPointByRefId({ refId: duplicationRefId })

      // Save with new id
      // HINT: by leaving the id empty it'll get automatically regenerated in the backend!
      await this.$_chargingpointEditMixin_save({
        data: {
          code: chargingpoint.data.code,
          ref: chargingpoint.ref,
          stakeholders: chargingpoint.data.properties.stakeholders || [],
          status: chargingpoint.data.properties.status,
          user: {
            name: chargingpoint.data.properties.user.name,
          },
          coordinates: chargingpoint.data.coordinates,
          remark: chargingpoint.data.properties.remark,
        },
      })
    },
  },
  /**
   * Bind event handlers to MapBox & connect to Pusher
   */
  created: async function(){
    this.$store.map.on('click', 'chargingpoints', this.handleClickMarkerEvent)
    this.$store.map.on('click', this.handleAddMarkerEvent)

    EventBus.$on('select-chargingpoint', this.handleSelectChargingpointEvent)
    EventBus.$on('deselect-chargingpoint', this.handleClose)

    // Cursor
    this.$store.map.on('mouseenter', 'chargingpoints', this.showPointer)
    this.$store.map.on('mouseleave', 'chargingpoints', this.hidePointer)

    // Drag & drop
    this.$store.map.on('mousedown', 'chargingpoints', this.startDrag)
    this.$store.map.on('touchstart', 'chargingpoints', this.startDrag)

    await this.$_chargingpointEditMixin_connectToPusher()
  },
  /**
   * Disconnect the event handlers from Mapbox
   */
  beforeDestroy() {
    this.$store.map.off('click', 'chargingpoints', this.handleClickMarkerEvent)
    this.$store.map.off('click', this.handleAddMarkerEvent)

    EventBus.$off('select-chargingpoint', this.handleSelectChargingpointEvent)
    EventBus.$off('deselect-chargingpoint', this.handleClose)

    // Cursor
    this.$store.map.off('mouseenter', 'chargingpoints', this.showPointer)
    this.$store.map.off('mouseleave', 'chargingpoints', this.hidePointer)

    this.$store.map.off('mousedown', 'chargingpoints', this.startDrag)
    this.$store.map.off('touchstart', 'chargingpoints', this.startDrag)
  },


  /************************************************************************************
   * START COPY & PASTE CODE + minimal modifications
   *  TODO: Review everything below this line...
   */
  methods: {
    ...mapActions('planmode', [
      'selectChargingPoint',
      'deselectChargingPoint',
      'addOrUpdateChargingPoint',
      'activatePlanmode',
      'hideAlternativeLocations',
    ]),
    ...mapMutations('planmode', [
      'updateChargingpoint',
    ]),

    /**
     * Mouse hover effects
     */
    showPointer() {
      this.$store.map.getCanvas().style.cursor = 'pointer'
    },
    hidePointer() {
      this.$store.map.getCanvas().style.cursor = ''

      // cancel unlock popup when mouse leaves point
      this.handleCancel()
    },
    // Check if the location is used in Workflow //
    workflowActiveLocation ({ chargingpoint }) {
      return chargingpoint.data.properties.isWorkflowCreated || !!chargingpoint.data.workflowUuid
    },

    /**
     * Drag & Drop
     */
    startUnlockTimer() {
      this.unlockTimerCountDown = this.unlockTimerDuration
      const startedAt = Date.now()

      return new Promise(resolve => {
        this.unlockTimerInterval = setInterval(() => {
          const time = this.unlockTimerDuration - (Date.now() - startedAt) / 1000

          // if negative value, progress-bar won't update properly
          if (time < 0) {
            this.unlockTimerCountDown = 0
          } else {
            this.unlockTimerCountDown = time
          }

          if (this.unlockTimerCountDown <= 0) {
            // give vue time to render the progress-bar to 0 before popup get's removed
            setTimeout(resolve, 500)
          }
        }, 500)

      })
    },

    async startDrag(e) {
      if (! e.features.length) return

      // Prevent the default map drag behavior.
      e.preventDefault()

      this.$store.map.getCanvas().style.cursor = 'grab'

      // Get the charging point to get the prio information
      let chargingpoint = this.getChargingPointByRefId({
        refId: e.features[0].properties.refId,
      })

      this.isWorkflowCreated = this.workflowActiveLocation({ chargingpoint })

      // Location created from Workflow or VOLT location can not be moved, trigger notify
      this.isLockedForEditing = chargingpoint.data.isLockedForEditing || this.isWorkflowCreated

      // if chargingpoint was assigned to a fase, then this point should not be edited anymore, so
      // make it harder by locking the point for X seconds
      if (this.getLockedPhases.includes(chargingpoint.data.prio?.fase)) {

        this.coordinates = chargingpoint.data.coordinates

        this.locked = true

        this.$store.map.on('mouseup', this.handleCancel)

        await this.startUnlockTimer()

        this.$store.map.off('mouseup', this.handleCancel)

        this.unlock()
      }

      this.$store.map.getCanvas().style.cursor = 'grabbing'

      this.$store.map.on('mousemove', this.handleMove)
      this.$store.map.once('mouseup', this.handleUp)
    },

    unlock() {
      clearInterval(this.unlockTimerInterval)
      this.locked = false
    },

    handleCancel() {
      this.unlock()
    },

    /*
     * Programmatically open the location
    */
    handleSelectChargingpointEvent(refId) {
      let chargingpoint = this.getChargingPointByRefId({ refId })

      this.handleOpen({ chargingpoint })
    },

    /**
     * Load the clicked point's properties
     */
    handleClickMarkerEvent(e) {
      if (!e.features.length) return

      this.features = e.features.filter(feature => feature.layer.paint['icon-opacity'] !== 0)

      // if layer is not visible don't react to clicks
      if (!this.features.length) return

      // Cancel other map events
      e.preventDefault()
      e.originalEvent.stopPropagation()

      // DEBUGGING mapbox features:
      // console.log('event:: ',e.features[0])

      // Get the charging point to get the exact coordinates & user details
      let chargingpoint = this.getChargingPointByRefId({
        refId: this.features[0].properties.refId,
      })

      this.$store.map.getCanvas().style.cursor = '' // auto config cursor

      // if there is already a popup opened, set a flag to not deselect the chargingpoint in the store.
      // This is to avoid a flickering of the sidebar, since we need to wait for the nextTick and the current popup is closed

      if (this.isChargingpointSelected && this.show) {
        this.doNotDeselectChargingpoint = true
      }

      this.show = false
      this.$nextTick(() => {
        this.handleOpen({ chargingpoint })

        this.doNotDeselectChargingpoint = false
      })
    },

    /**
     * hydrate with chargingpoint data
     */
    setChargingpointData({ chargingpoint }) {
      this.refId = chargingpoint.ref['@ref'].id
      this.isWorkflowCreated = this.workflowActiveLocation({ chargingpoint })
      const remark = this.isWorkflowCreated
       ? workflowLockedForEditingMessage({ caseRef: chargingpoint.data.workflowCaseRef, cpo: chargingpoint.data.cpo })
       : chargingpoint.data.properties.remark

      this.id = chargingpoint.data.properties.id
      this.code = chargingpoint.data.code
      this.remark = remark
      this.user.name = chargingpoint.data.properties.user
        ? chargingpoint.data.properties.user.name
        : (chargingpoint.data.properties.user_name || 'EVtools')
      this.stakeholders = chargingpoint.data.properties.stakeholders || []
      this.status = chargingpoint.data.properties.status || 'definitive'

      this.address = chargingpoint.data.address ? chargingpoint.data.address.simple_address : ''
      this.validators = chargingpoint.data.properties.validators || []

      this.coordinates = chargingpoint.data.coordinates

      this.prio = {
        order: chargingpoint.data.prio?.order ?? null,
        exact: chargingpoint.data.prio?.exact ?? null,
        fase: chargingpoint.data.prio?.fase ?? null,
      }
    },

    /**
     * Upon opening, either by click or event
     */
    handleOpen({ chargingpoint, refId }) {
      if (refId) {
        chargingpoint = this.getChargingPointByRefId({ refId })
      }

      this.setChargingpointData({ chargingpoint })
      this.selectChargingPoint({ refId: chargingpoint.ref['@ref'].id })

      this.show = true
    },

    /**
     * Upon the close event of the popup
     */
    handleClose() {
      this.show = false
      this.refId = null

      if (this.doNotDeselectChargingpoint === false) {
        this.deselectChargingPoint()
      }
    },

    /**
     * Show the form to add a charging point
     */
    handleAddMarkerEvent(e) {
      if (e._defaultPrevented) return

      this.show = false

      this.$nextTick(() => {
        this.id = null
        this.coordinates = e.lngLat.toArray()

        this.show = true
      })
    },

    /**
     * Track movement
     */
    handleMove(e) {
      // don't allow to drag if there is a popup opened already
      if (this.show || this.isLockedForEditing) {
        this.$notify({
          type: 'warn',
          title: this.isLockedForEditing ? `${this.lockedLocationType} locatie` : 'Popup staat al open',
          text: this.isLockedForEditing
            ? `${this.lockedLocationType} locatie kan niet worden verplaatst!`
            : 'Het is niet mogelijk een locatie te verslepen terwijl een popup open staat.',
        })

        this.$store.map.getCanvas().style.cursor = ''
        this.$store.map.off('mousemove', this.handleMove)
        this.$store.map.off('mouseup', this.handleUp)

        return
      }

      if (e._defaultPrevented) {
        this.$store.map.off('mousemove', this.handleMove)
        this.$store.map.off('touchmove', this.handleMove)
        return
      }

      if (this.refId !== null) {
        this.$store.map.off('mousemove', this.handleMove)
        this.$store.map.off('touchmove', this.handleMove)
        return
      }

      let features = this.$store.map.queryRenderedFeatures(e.point, {
        layers: ['chargingpoints'],
      })

      // if not already dragging a point, and no feature is at the cursor, exit
      if (this.draggingPoint === null && features.length === 0) {
        this.$store.map.off('mousemove', this.handleMove)
        this.$store.map.off('touchmove', this.handleMove)
        return null
      }

      // store the point being dragged
      if (this.draggingPoint === null) {
        const chargingpoint = this.getChargingPointByRefId({
          refId: features[0].properties.refId,
        })

        this.draggingPoint = {
          refId: chargingpoint.ref['@ref'].id,
          coordinates: chargingpoint.data.coordinates,
          original: chargingpoint.data.coordinates,
          rollback: structuredClone(chargingpoint),
        }
      }

      // Set a UI indicator for dragging.
      this.$store.map.getCanvas().style.cursor = 'grabbing'

      // Note that we're updating the dataset without persisting the location
      // persisting occurs when the user lets go of the marker
      let coords = e.lngLat

      this.draggingPoint.coordinates = [coords.lng, coords.lat]

      // Make a local update to the chargingpoint collection
      let index = this.getChargingPointIndexByRefId({ refId: this.draggingPoint.refId })
      let chargingpoint = this.getChargingPointByRefId({ refId: this.draggingPoint.refId })
      chargingpoint.data.coordinates = [coords.lng, coords.lat]
      this.updateChargingpoint({ chargingpoint, index })
    },

    /**
     * End of movement
     */
    async handleUp() {
      this.$store.map.getCanvas().style.cursor = ''

      if (this.draggingPoint === null) {
        this.$store.map.off('mousemove', this.handleMove)
        this.$store.map.off('touchmove', this.handleMove)
        return
      }

      this.showModal()
    },

    showModal() {
      this.$refs['confirm-move-location-modal'].show()
    },
    hideModal() {
      this.$refs['confirm-move-location-modal'].hide()
    },

    moveCanceled() {
      if (! this.draggingPoint) return

      // revert changes by updating the chargingpoint with the rollback data
      this.addOrUpdateChargingPoint({ chargingpoint: this.draggingPoint.rollback })

      this.stopDragging()
      this.cleanUp()
    },

    moveAndKeepAddress() {
      let chargingpoint = this.getChargingPointByRefId({ refId: this.draggingPoint.refId })

      this.$_chargingpointEditMixin_save({
        data: {
          code: chargingpoint.data.code,
          ref: chargingpoint.ref, // full ref
          id: chargingpoint.data.properties.id,
          stakeholders: chargingpoint.data.properties.stakeholders,
          status: chargingpoint.data.properties.status,
          user: {
            name: chargingpoint.data.properties.user_name ?? chargingpoint.data.properties.user.name,
          },
          coordinates: chargingpoint.data.coordinates,
          remark: chargingpoint.data.properties.remark,
          beheerder: chargingpoint.data.beheerder,
          predecessor: chargingpoint.data.properties.predecessor,
          validators: chargingpoint.data.validators,
          address: chargingpoint.data.address,
          prio: chargingpoint.data.prio,
        },
        rollback: this.draggingPoint.rollback,
      })

      this.stopDragging()
      this.cleanUp()
    },

    async moveAndStartGeocodingProcess() {
      let chargingpoint = this.getChargingPointByRefId({ refId: this.draggingPoint.refId })

      this.stopDragging()

      this.coordinates = chargingpoint.data.coordinates
      this.isLocationUpdate = true
      this.show = true
    },

    handleSubmitUpdate({ address }) {
      let chargingpoint = this.getChargingPointByRefId({ refId: this.draggingPoint.refId })

      // positive UI, we expect the API call to be successful, otherwise there will be an error notification and rollback
      this.$_chargingpointEditMixin_save({
        data: {
          code: chargingpoint.data.code,
          ref: chargingpoint.ref, // full ref
          id: chargingpoint.data.properties.id,
          stakeholders: chargingpoint.data.properties.stakeholders,
          status: chargingpoint.data.properties.status,
          user: {
            name: chargingpoint.data.properties.user_name ?? chargingpoint.data.properties.user.name,
          },
          coordinates: chargingpoint.data.coordinates,
          remark: chargingpoint.data.properties.remark,
          predecessor: chargingpoint.data.properties.predecessor,
          beheerder: chargingpoint.data.beheerder,
          validators: chargingpoint.data.validators,
          address,
        },
        rollback: this.draggingPoint.rollback,
      })

      this.cleanUp()
    },

    handleSubmitCancel() {
      this.moveCanceled()
      this.cleanUp()
    },

    cleanUp() {
      this.refId = null
      this.coordinates = null
      this.isLocationUpdate = false
      this.show = false
      this.draggingPoint = null
    },

    stopDragging() {
      this.hideModal()

      // Unbind mouse/touch events
      this.$store.map.off('mousemove', this.handleMove)
      this.$store.map.off('touchmove', this.handleMove)
    },

    handleSubmitAdd({ status, predecessor, address, cpo, remark }) {
      // update field so that the mixin can update stakeholders
      this.fields.cpo.value = cpo

      status = status || CHARGINGPOINT_STATUS.SUGGESTION

      const stakeholders = this.$_chargingpointCpoMixin_removeOrUpdateCpoFromStakeholders()

      let beheerder
      let validators
      if (status.includes(CHARGINGPOINT_STATUS.SUGGESTION)) {
        const settings = this.getDefaultValidationSettingProfile

        beheerder = settings.beheerder
        validators = settings.validators
      }

      this.$_chargingpointEditMixin_save({
        data: {
          code: this.getActiveMunicipality,
          stakeholders: stakeholders,
          status,
          user: {
            name: this.currentUserName,
          },
          coordinates: this.coordinates,
          address,
          remark,
          predecessor,
          beheerder,
          validators,
        },
      })

      this.show = false
    },
  },
}
</script>

<style lang="scss">


.mapboxgl-popup-content {
  padding: 10px 20px 10px 10px !important;

  p {
    margin: 0;
    user-select: auto;
  }
}
.FormField .invalid-feedback {
  top: 0 !important;
}
.locked-hint {
  font-size: .95rem;

  &-icon {
    font-size: 1.2rem;
    color: var(--danger)
  }
}
</style>
