<template>
  <div>
    <!-- Please make sure that there is only 1 tour per route, otherwise
      multiple tours could open at once -->
    <LegendaTour />
  </div>
</template>

<script>
import LegendaTour from '@/components/tour/tours/LegendaTour'

export default {
  components: {
    LegendaTour,
  },
}
</script>
