<template>
  <div class="FuseSearch d-flex flex-column flex-grow-1 flex-shrink-1">
    <div class="FuseSearch__header p-4">
      <Fuse
        :list="list"
        :options="fuseOptions"
        :placeholder="placeholder"
        @resultsChanged="handleResultsChanged"
        @enterButton="handleSelectFirst"
      />
    </div>

    <div class="border-top px-3 py-1 m-0 pl-4 ">
      <SvgIcon
        icon="eye-solid"
        class="d-inline-block mr-1 mt-1 color-primary"
      /> {{ results.length }} van {{ total }}
    </div>

    <ul
      v-if="results"
      class="FuseSearch__results py-3"
    >
      <FuseSearchItem
        v-for="item in results"
        :key="item.id"
        :item="item"
        class="pl-2 d-flex justify-content-between"
        @select="handleSelect"
      >
        <InlineChargingpoint
          :chargingpoint="item"
          @click.native="() => handleSelect({ item })"
        />

        <SvgIcon
          :id="`preview-icon-${item.id}`"
          class="d-inline-block ml-1 z-index"
          icon="crosshairs-solid"
          @click.native="() => handlePreview({item})"
        />
        <b-tooltip
          :target="`preview-icon-${item.id}`"
          triggers="hover"
        >
          Toon locatie
        </b-tooltip>
      </FuseSearchItem>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Fuse from '@/components/common/Fuse'
import SvgIcon from '@/components/common/SvgIcon'

import FuseSearchItem from '@/components/common/FuseSearchItem'
import InlineChargingpoint from '@/components/common/InlineChargingpoint'

export default {
  name: 'FuseSearch',
  components: {
    InlineChargingpoint,
    Fuse,
    SvgIcon,
    FuseSearchItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
      validator: values => {
        return values.every(value => value.id && value.text)
      },
    },
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    placeholder: {
      type: String,
      required: false,
      default: () => 'Zoeken',
    },
  },
  data() {
    return {
      /**
       * Generated by Fuse
       */
      results: [],
    }
  },
  computed: {
    ...mapGetters('planmode', ['getChargingPointByUuid']),
    list() {
      return this.items
        .slice()
        .sort((a, b) => (a[this.options.sortBy] < b[this.options.sortBy] ? -1 : 1))
    },

    /**
     * Fuse.js options with defaults
     */
    fuseOptions() {
      return Object.assign({
        sortBy: 'id',
        threshold: 0.2,
        keys: ['id'],
      }, this.options.fuseOptions)
    },
    total() {
      return this.items.length
    },
  },
  methods: {
    /**
     * Handle results from Fuse.js
     */
    handleResultsChanged({ results }) {
      this.results = results.map((result) => result.item)
    },

    /**
     * Handle selection of an item
     */
    handleSelect({ item }) {
      this.$emit('select', item)
    },

    /**
     * Handle preview
     */
    handlePreview({ item }) {
      const chargingpoint = this.getChargingPointByUuid({ uuid: item.uuid })

      if (chargingpoint && this.$store.map) {
        this.$store.map.flyTo({
          center: chargingpoint.data.coordinates,
          zoom: 16,
        })
      }
    },

    /**
     * if enter button is pressed, select first result
     */
    handleSelectFirst() {
      if (! this.results[0]) {
        return
      }

      this.handleSelect({ item: this.results[0] })
    },
  },
}
</script>

<style lang="scss">
.color-primary {
  color: var(--primary)
}

.FuseSearch {
  max-height: calc(100% - 49px);
  overflow: hidden;

  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;

    .in-modal & {
      padding-top: 0 !important;
    }
  }

  &__filter {
    font-size: 1.3rem;
    line-height: 0;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 0 0.4em;
    display: flex;
    align-items: center;

    &:hover {
      color: #4f5c6e;
      border: 1px solid black;
      box-shadow: inset 0 0 0 1px black;
    }
  }

  &__results {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    max-height: 100%;
    flex-shrink: 1;
    flex-grow: 1;
    border-top: 1px solid var(--grey-darker-10);
  }
}
</style>
