<template>
  <div class="d-flex">
    <ScenarioInput
      :disabled="isLimitedForecast"
      class="mr-3"
      scenario="model"
      :options="models"
    />
    <ScenarioInput
      class="mr-3"
      scenario="year"
      :options="availableYears"
    />
    <ScenarioInput
      :disabled="isLimitedForecast"
      class="mr-3"
      scenario="access"
      :options="access"
    />
    <ScenarioInput
      :key="forecastOptionsKey"
      class="mr-3"
      scenario="forecast"
      :options="availableForecastOptions"
    />
  </div>
</template>

<script>
import ScenarioInput from '@/components/navbar/ScenarioInput'
import { mapGetters, mapMutations } from 'vuex'
import userMixin from '@/mixins/common/userMixin'

export default {
  name: 'ScenarioInputs',
  components: {
    ScenarioInput,
  },
  mixins: [userMixin],
  data() {
    return {
      forecastOptionsKey: 0,
    }
  },
  computed: {
    /**
     * The scenario options for years & models are part of the municipality configuration
     */
    ...mapGetters('config', [
      'years', 'models', 'forecastOptions',
    ]),
    ...mapGetters('scenarios', [
      'year', 'model', 'forecast',
    ]),
    ...mapGetters('layers', {
      forecastLayers: 'getForecastLayerDetails',
    }),
    /**
     * The scenario options for access & forecast are fixed
     */
    access() {
      return [
        {
          value: 'public',
          text: 'Publiek',
        },
        {
          value: 'private',
          text: 'Privaat',
        },
      ]
    },

    // For these forecast variations have no distinction between public / private or data models
    isLimitedForecast() {
      const forecastLayer = this.forecastLayers.find(layer => layer.id === this.forecast)
      return !! forecastLayer?.config?.isLimitedForecast
    },

    availableYears() {
      const forecastLayer = this.forecastLayers.find(layer => layer.id === this.forecast)

      if (forecastLayer?.config?.availableYears) {
        return forecastLayer.config.availableYears
      }

      if (this.isLimitedForecast) {
        return [2022, 2025, 2030].filter(year => this.years.includes(year))
      }

      return this.years
    },

    /**
     * Only show the 'gemeenteprognoses' option to superusers
     */
    availableForecastOptions() {
      if (this.superuser) {
        return this.forecastOptions
      }

      return this.forecastOptions.filter(o => o !== 'gemeenteprognoses')
    },

    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
  },
  watch: {
    /**
     * Track changes to the scenario options that can change per municipality
     *  If the active option is no longer available, select the first available option
     */
    availableYears() {
      this.checkYearOptions()
    },
    models() {
      this.checkModelOptions()
    },
    availableForecastOptions() {
      this.checkForecastOptions()
    },
  },
  created() {
    this.checkYearOptions()
    this.checkModelOptions()
    this.checkForecastOptions()
  },
  methods: {
    ...mapMutations('scenarios', [
      'setYear', 'setModel', 'setForecast',
    ]),

    /**
     * If the selected option is (no longer) available, select the first available option
     */
    checkYearOptions() {
      if (!this.availableYears.includes(this.year)) {
        this.setYear({
          scenario: this.availableYears[0] || null,
        })
      }
    },
    /**
     * If the selected option is (no longer) available, select the first available option
     */
    checkModelOptions() {
      if (!this.models.includes(this.model)) {
        this.setModel({
          scenario: this.models[0] || null,
        })
      }
    },
    /**
     * If the selected option is (no longer) available, select the first available option
     */
    checkForecastOptions() {
      if (!this.availableForecastOptions.includes(this.forecast)) {
        this.setForecast({
          scenario: this.availableForecastOptions[0].value || null,
        })
      }
    },
  },
}
</script>
