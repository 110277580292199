/**
 * Import Dependency
 */

import { USER_ROLE } from '@/../shared/valueholders/userRoles'
import { layers as layerDetails } from '@/data/layerDetails'

/**
 * Declare Variable
 */
const state = {
  layerDetails: layerDetails,
  contextLayers: [],
  inMapLegendLayers: [],
}

const getters = {
  /*
  * All base layer details we do have
  */
  getLayerDetails: (state, getters, rootState, rootGetters) => {
    if (rootGetters['currentUser/getCurrentUserRole'] === USER_ROLE.SUPERUSER) {
      return state.layerDetails
        .slice()
    }

    return state.layerDetails
      .slice()
      .filter(layer => rootGetters['access/canRoleAccessLayer']({ layer }))
      .filter(layer => rootGetters['access/canAccessRegionalLayer']({ layer }))
  },
  getLayerDetailsById: (state, getters) => ({ id }) => getters['getLayerDetails'].find(layer => layer.id === id),
  getLayerDetailsByType: (state, getters) => ({ type }) => getters['getLayerDetails'].filter(layer => layer.type === type),
  getPlanmodeLayerDetails: (state, getters) => getters['getLayerDetails'].filter(layer => layer.type === 'planmode'),
  getDataLayerDetails: (state, getters) => getters['getLayerDetails'].filter(layer => layer.type !== 'planmode' && ! layer.ignore),
  getContextLayerDetails: (state, getters) => getters['getLayerDetailsByType']({ type: 'context' }),
  getForecastLayerDetails: (state, getters) => getters['getLayerDetailsByType']({ type: 'forecast' }),
  getResultsLayerDetails: (state, getters) => getters['getLayerDetailsByType']({ type: 'results' }),

  /*
  * In Map Legend
  */
  isLegendInMapVisible: state => state.inMapLegendLayers.length > 0,
  getLegendInMapLayers: state => state.inMapLegendLayers,

  /**
   * Context Information Layers
   * Not all layers will be available for each municipality
   */

  // these are stateful data layers, configured per municipality. Initiated by actions/storeContextLayers
  getContextLayers: state => state.contextLayers,
  getContextLayerById: state => ({ id }) => state.contextLayers.find(layer => layer.id === id) || state.contextLayers.find(layer => layer.layers?.includes(id)),
  getActiveLayers: (state, getters) => getters['getContextLayers'].filter(layer => layer.status !== 'hidden'),
  getExpectedActiveLayers: (state, getters, rootState, rootGetters) => Object.entries(rootGetters['config/contextLayers']).filter(([_, layer]) => ['primary', 'secondary'].includes(layer.status) && !layer.ignore),
  getPrimaryLayers: (state, getters) => getters['getActiveLayers'].filter(layer => layer.status === 'primary'),
  getSecondaryLayers: (state, getters) => getters['getActiveLayers'].filter(layer => layer.status === 'secondary'),

  /**
   * Layers that are _not_ active for this deployment
   */
  getExcludedLayers: (state, getters, rootState, rootGetters) => Object.keys(rootGetters['deployment/DeploymentConfig'].layers).filter(layer => !rootGetters['deployment/DeploymentConfig'].layers[layer]),
}

const actions = {
  /*
  * store municipality layers
  */
  storeContextLayers({ commit }, { layers }) {
    commit('setContextLayers', { layers })
  },

  /*
  * Legend(s)
  */
  addLayerToInMapLegend({ commit, getters }, { layerId }) {
    const layer = getters.getLayerDetailsById({ id: layerId })
    if (layer.legendItems || layer.layers) {
      const layers = getters.getLegendInMapLayers.filter(({ id }) => id !== layerId)
      commit('setInMapLegendLayers', { layers: [...layers, layer] })
    }
  },
  removeLayerFromInMapLegend({ commit, getters }, { layerId }) {
    const layers = getters.getLegendInMapLayers.filter(({ id }) => id !== layerId)
    commit('setInMapLegendLayers', { layers })
  },
}

const mutations = {
  setLayer(state, { id, layer }) {
    const index = state.contextLayers.findIndex(layer => layer.id === id)
    if (index !== -1) {
      state.contextLayers.push(layer)
    }
    state.contextLayers[index] = layer
    // trigger update
    state.contextLayers = [...state.contextLayers]
  },
  setContextLayers(state, { layers }) {
    state.contextLayers = layers
  },
  setInMapLegendLayers(state, { layers }) {
    state.inMapLegendLayers = layers
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
