const FILTER_TYPE = {
  RANGE_FILTER: 'range-filter',
  COMPARISON_FILTER: 'comparison-filter',
  TOGGLE_FILTER: 'toggle-filter',
  SELECT_FILTER: 'select-filter'
}

module.exports = {
  FILTER_TYPE,
}
