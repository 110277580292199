<template>
  <div :class="['ActiveTab ValidationTab', { isLockedForEditing }]">
    <LocatieInformatieHeader :chargingpoint="chargingpoint">
      <h4>Opmerkingen</h4>
    </LocatieInformatieHeader>

    <div class="ActiveTab__Content">
      <b-alert :show="isLockedForEditing" variant="warning">
        {{ RHDHVMessage }}
      </b-alert>
      <TheComments :chargingpoint="chargingpoint" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LocatieInformatieHeader from '@/components/map/sidebar/LocatieInformatieHeader'
import TheComments from '@/components/map/sidebar/TheComments'
import { RHDHVLockedForEditingMessage } from '@/services/commonMessages'

export default {
  components: { TheComments, LocatieInformatieHeader },
  data () {
    return {
      RHDHVMessage: RHDHVLockedForEditingMessage
    }
  },
  computed: {
    ...mapGetters('planmode', {
      chargingpoint: 'getSelectedChargingpoint',
    }),
    isLockedForEditing () {
      return this.chargingpoint.data.isLockedForEditing
    }
  },
}
</script>

<style lang="scss">
.ActiveTab__Content {
  p {
    margin-bottom: .5rem
  }
}
</style>
