
const indexToYears = (result, item, index) => {
  result[index+2020] = (item)
  return result
}

export const decielen = {
  SparkCity: {
    D1: {
      '2020': 0.0213606327593112,
      '2021': 0.0219079906268307,
      '2022': 0.0253671203800475,
      '2023': 0.0281448747195213,
      '2024': 0.032,
      '2025': 0.037,
      '2026': 0.055,
      '2027': 0.072,
      '2028': 0.094,
      '2029': 0.124,
      '2030': 0.156,
    },
    D2: {
      '2020': 0.0226171405686824,
      '2021': 0.0242140949033392,
      '2022': 0.0299793240855107,
      '2023': 0.0333568885564697,
      '2024': 0.041,
      '2025': 0.05,
      '2026': 0.07,
      '2027': 0.085,
      '2028': 0.113,
      '2029': 0.14,
      '2030': 0.182,
    },
    D3: {
      '2020': 0.0301561874249099,
      '2021': 0.0392037727006444,
      '2022': 0.0472750879809976,
      '2023': 0.0562897494390426,
      '2024': 0.065,
      '2025': 0.089,
      '2026': 0.11,
      '2027': 0.133,
      '2028': 0.161,
      '2029': 0.207,
      '2030': 0.257,
    },
    D4: {
      '2020': 0.0326692030436524,
      '2021': 0.0426629291154072,
      '2022': 0.0518872916864608,
      '2023': 0.0625441660433807,
      '2024': 0.073,
      '2025': 0.098,
      '2026': 0.115,
      '2027': 0.145,
      '2028': 0.18,
      '2029': 0.22,
      '2030': 0.274,
    },
    D5: {
      '2020': 0.0439777733279936,
      '2021': 0.0588056590509666,
      '2022': 0.0749483102137767,
      '2023': 0.0833922213911743,
      '2024': 0.094,
      '2025': 0.115,
      '2026': 0.145,
      '2027': 0.18,
      '2028': 0.224,
      '2029': 0.285,
      '2030': 0.331,
    },
  },
  // The NAL Model
  NAL: {
    D1: {
      '2020': 0.017,
      '2021': 0.019,
      '2022': 0.022,
      '2023': 0.0244090501121915,
      '2024': 0.0271502564899736,
      '2025': 0.0291019642169409,
      '2026': 0.0427641356936416,
      '2027': 0.055982141506945,
      '2028': 0.0730877958854877,
      '2029': 0.0964136885575555,
      '2030': 0.121294637905457,
    },
    D2: {
      '2020': 0.018,
      '2021': 0.021,
      '2022': 0.026,
      '2023': 0.0289292445774121,
      '2024': 0.0347862661277786,
      '2025': 0.0393269786715417,
      '2026': 0.0544270817919075,
      '2027': 0.0660900281679212,
      '2028': 0.0878608610112777,
      '2029': 0.108854164500466,
      '2030': 0.1415104108897,
    },
    D3: {
      '2020': 0.024,
      '2021': 0.034,
      '2022': 0.041,
      '2023': 0.0488181002243829,
      '2024': 0.0551489584952588,
      '2025': 0.0700020220353443,
      '2026': 0.0855282713872832,
      '2027': 0.103411455839218,
      '2028': 0.125182288697484,
      '2029': 0.160948657511403,
      '2030': 0.199825140651939,
    },
    D4: {
      '2020': 0.026,
      '2021': 0.037,
      '2022': 0.045,
      '2023': 0.0542423335826477,
      '2024': 0.0619365226177522,
      '2025': 0.0770808781962218,
      '2026': 0.0894159200867052,
      '2027': 0.112741812757042,
      '2028': 0.139955353823274,
      '2029': 0.171056544215018,
      '2030': 0.213043146064713,
    },
    D5: {
      '2020': 0.035,
      '2021': 0.051,
      '2022': 0.065,
      '2023': 0.0723231114435303,
      '2024': 0.0797538784392974,
      '2025': 0.090452050944546,
      '2026': 0.112741812283237,
      '2027': 0.139955353767362,
      '2028': 0.17416666253563,
      '2029': 0.221595977733091,
      '2030': 0.257362340684014,
    },
  },
  GENT: {
    D1: [0, 0.0187761607431245, 0.0212464855072692, 0.0229341115781085, 0.0250874071668978, 0.0279421484198498, 0.0381366422647652, 0.0487891763901015, 0.0622486691642012, 0.0802481685195191, 0.103151208316974].reduce(indexToYears, {}),
    D2: [0, 0.020752598716085, 0.0251094828722273, 0.0283137179976648, 0.0321432404325878, 0.0369557446843174, 0.0485375447006103, 0.0575983332383143, 0.0748308469739866, 0.0906027709091343, 0.120343076369803].reduce(indexToYears, {}),
    D3: [0, 0.0335994455403281, 0.0395957229908199, 0.045868223156217, 0.0509587958077612, 0.0631478462992574, 0.0762732845295306, 0.0901244508317155, 0.106617401440813, 0.133962668415649, 0.169935003445271].reduce(indexToYears, {}),
    D4: [0, 0.0365641024997688, 0.0434587203557779, 0.0509646923957966, 0.0572306475994856, 0.0695335835654745, 0.0797402520081455, 0.0982559802300655, 0.119199579250598, 0.142375782857211, 0.181175840249043].reduce(indexToYears, {}),
    D5: [0, 0.0503991683104922, 0.0627737071805681, 0.0679529231943955, 0.0736942585527623, 0.0815955317349955, 0.100542056879836, 0.121972940975254, 0.148337254178522, 0.184441355065023, 0.218865704826399].reduce(indexToYears, {}),
  },
  VL: {
    D1: [0, 0.0281642411146870, 0.0329320525362670, 0.0366945785249740, 0.0413942218253810, 0.0475016523137450, 0.0640695590048060, 0.0809900328075690, 0.1020878174292900, 0.1300020330016210, 0.1650419333071580].reduce(indexToYears, {}),
    D2: [0, 0.0311288980741280, 0.0389196984519520, 0.0453019487962640, 0.0530363467137700, 0.0628247659633400, 0.0815430750970250, 0.0956132331756020, 0.1227225890373380, 0.1467764888727980, 0.1925489221916850].reduce(indexToYears, {}),
    D3: [0, 0.0503991683104920, 0.0613733706357710, 0.0733891570499470, 0.0840820130828060, 0.1073513387087380, 0.1281391180096110, 0.1496065883806480, 0.1748525383629330, 0.2170195228333510, 0.2718960055124340].reduce(indexToYears, {}),
    D4: [0, 0.0548461537496530, 0.0673610165514560, 0.0815435078332750, 0.0944305685391510, 0.1182070920613070, 0.1339636233736840, 0.1631049271819090, 0.1954873099709810, 0.2306487682286820, 0.2898813443984690].reduce(indexToYears, {}),
    D5: [0, 0.0755987524657380, 0.0972992461298810, 0.1087246771110330, 0.1215955266120580, 0.1387124039494920, 0.1689106555581240, 0.2024750820189220, 0.2432730968527760, 0.2987949952053370, 0.3501851277222380].reduce(indexToYears, {}),
  },
}
