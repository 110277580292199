
import { image } from '@/helpers/assets'
import { USER_ROLE } from '@/../shared/valueholders/userRoles'
import { GEOPGRAPHY_TYPE } from '@/../shared/valueholders/geography-type'
import { planmodeLayerDetails } from '@/data/planmodeLayerDetails'

export const layers = [
  /**
   * Planmode layers (statuses)
   */
  ...planmodeLayerDetails,

  /**
   * The results layer (RegularPrognoseLayer)
   */
  {
    id: 'hexagons',
    label: 'De prognose resultaten',
    ignore: true,
    required: true,
    type: 'results',
    sourceNameHint: 'prognose',
    tooltip: 'De resultaten van de prognose analyse, weergegeven in hexagonen',
  },

  /**
   * UI layers
   */
  {
    id: 'line-with-distance',
    label: 'Afstandsmeting lijn',
    ignore: false,
    required: false,
    type: 'ui',
    sourceNameHint: 'charger-geocoder-line',
    tooltip: 'a line connecting the chosen location and the geocoded location',
  },

  /**
   * forecast layers
   */
  {
    id: 'determinative',
    label: 'Bewoners / bezoekers / forenzen',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: false,
    },
  },
  {
    id: 'fastcharge_elaad',
    label: 'Snelladen Elaad',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      forecastDataTemplateComponent: 'TileDataElaadFastcharge',
      isLimitedForecast: true,
      availableYears: [2025, 2030, 2035],
      dataset: ['laadpunt'],
      propertyWithData: 'laadpunt_',
      propertyWithTileID: 'buurtcode',
    },
  },
  {
    id: 'fastcharge_overmorgen_neighborhoods',
    label: 'Snelladen buurten',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      forecastDataTemplateComponent: 'TileDataOMNeighborhoods',
      isLimitedForecast: true,
      availableYears: [2025, 2030],
      propertyWithData: 'ev_kortp_binnencor_',
      propertyWithTileID: 'bu_code',
      breakpoints: [7, 14, 28],
    },
    restrictions: {
      include: {
        regional: [
          {
            type: GEOPGRAPHY_TYPE.REGION,
            slug: 'west-nederland',
          },
        ],
      },
      exclude: {
        roles: [USER_ROLE.CPO],
      },
    },
  },
  {
    id: 'fastcharge_overmorgen_corridors',
    label: 'Snelladen corridors',
    ignore: true,
    required: false,
    status: 'primary',
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      forecastDataTemplateComponent: 'TileDataOMCorridors',
      isLimitedForecast: true,
      availableYears: [2025, 2030],
      propertyWithData: 'kWh_jr_km_',
      propertyWithTileID: 'corridor_nummer',
      unit: '*',
      unitDescription: '* kWh / jaar / km, beiden rijrichtingen',
      breakpoints: [55000, 125000, 180000],
    },
    restrictions: {
      include: {
        regional: [
          {
            type: GEOPGRAPHY_TYPE.REGION,
            slug: 'west-nederland',
          },
        ],
      },
      exclude: {
        roles: [USER_ROLE.CPO],
      },
    },
  },
  {
    id: 'logistics_elaad',
    label: 'Logistiek Elaad',
    ignore: true,
    required: false,
    status: 'primary',
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      forecastDataTemplateComponent: 'TileDataElaadLogistics',
      isLimitedForecast: true,
      availableYears: [2025, 2030, 2035, 2040, 2045, 2050],
      propertyWithData: 'mwj_tot_trucks_bestel_',
      propertyWithTileID: 'bu_code',
      breakpoints: [50, 150, 300],
      unit: 'MWh/jaar',
    },
  },
  {
    id: 'goral_logistiek',
    label: 'GORAL logistiek',
    ignore: true,
    required: false,
    status: 'primary',
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      forecastDataTemplateComponent: 'TileDataGoralLogistics',
      isLimitedForecast: true,
      isConfigurable: true, // this forecast layers' source has to be configured in the layer config panel
      availableYears: [2025, 2030, 2035],
      propertyWithData: 'bedrijfsterrein_mwh_tot_depot_',
      propertyWithTileID: 'bt_terrein',
      breakpoints: [25, 50, 75],
      unit: 'MWh/jaar',
    },
    sourceNameHint: 'logistiek_goral', // source name hint is used in this way: -> nl_g_0202_${sourceNameHint}
    tooltip: 'De resultaten van de GORAL prognose voor bedrijventerreinen, deze zijn op kavelniveau',
  },
  {
    id: 'logistics_overmorgen',
    label: 'Logistiek WestNL',
    ignore: true,
    required: false,
    status: 'primary',
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      forecastDataTemplateComponent: 'TileDataOMLogistics',
      isLimitedForecast: true,
      availableYears: [2025, 2026, 2027, 2028, 2029, 2030],
      propertyWithData: 'mwj_e-bestel_e-trucks_depot_',
      propertyWithTileID: 'bu_code',
      breakpoints: [50, 150, 300],
      unit: 'MWh/jaar',
    },
  },
  {
    id: 'logistics_overmorgen_ac',
    label: 'Regulier laden buurtniveau',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      forecastDataTemplateComponent: 'TileDataRegularChargingNeighborhood',
      isLimitedForecast: true,
      availableYears: [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
      propertyWithData: 'ldpt_tot_',
      propertyWithTileID: 'bu_code',
    },
    restrictions: {
      include: {
        regional: [
          {
            type: GEOPGRAPHY_TYPE.MUNICIPALITY,
            code: '0202',
            slug: 'arnhem',
          },
        ],
      },
    },
  },
  {
    id: 'logistics_overmorgen_ac_provinces',
    label: 'Regulier laden WestNL',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      forecastDataTemplateComponent: 'TileDataRegularChargingNeighborhood',
      isLimitedForecast: true,
      availableYears: [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
      propertyWithData: 'ldpt_tot_',
      propertyWithTileID: 'bu_code',
      province: true,
    },
  },
  {
    id: 'inhabitants',
    label: 'Bewoners',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: false,
    },
  },
  {
    id: 'visitors',
    label: 'Bezoekers',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: false,
    },
  },
  {
    id: 'commuters',
    label: 'Forenzen',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: false,
    },
  },
  {
    id: 'gemeenteprognoses',
    label: 'Gemeenten (bewoners)',
    ignore: true,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      forecastDataTemplateComponent: 'TileDataGemeenteprognose',
      isLimitedForecast: true,
      availableYears: [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
      dataset: [''],
      propertyWithData: 'laadpunt_',
      propertyWithTileID: 'gemeentecode',
    },
  },
  {
    id: 'carsharing',
    label: 'Deelautos',
    ignore: true,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      isLimitedForecast: true,
      dataset: ['deelauto'],
      propertyWithData: 'deelauto_ev_',
      propertyWithTileID: 'buurtcode',
    },
    restrictions: {
      include: {
        regional: [
          {
            type: GEOPGRAPHY_TYPE.PROVINCE,
            code: 28,
            slug: 'zuid-holland',
          },
        ],
      },
    },
  },
  {
    id: 'logistics',
    label: 'Stadslogistiek',
    ignore: true,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      isLimitedForecast: true,
      propertyWithData: 'logistiek_totaal_ev_',
      propertyWithTileID: 'buurtcode',
      dataset: ['logistiek_totaal', 'bouw', 'detailhandel', 'post'],
    },
    restrictions: {
      include: {
        regional: [
          {
            type: GEOPGRAPHY_TYPE.PROVINCE,
            code: 28,
            slug: 'zuid-holland',
          },
        ],
      },
    },
  },
  {
    id: 'taxis',
    label: 'Taxis',
    ignore: true,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      isLimitedForecast: true,
      propertyWithData: 'taxi_ev_',
      propertyWithTileID: 'buurtcode',
      dataset: ['taxi'],
    },
    restrictions: {
      include: {
        regional: [
          {
            type: GEOPGRAPHY_TYPE.PROVINCE,
            code: 28,
            slug: 'zuid-holland',
          },
        ],
      },
    },
  },

  /**
   * Data Layers
   */
  {
    id: 'fuelstations',
    label: 'Pompstation',
    icon: {
      slug: image({ name: 'legend/fuelpump.svg' }),
      width: 20,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'tankstations',
    tooltip: 'De huidige benzine stations',
  },
  {
    id: 'fossilfuel_stations',
    label: 'Pompstation',
    icon: {
      slug: image({ name: 'legend/fuelpump.svg' }),
      width: 20,
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    // sourceNameHint: 'middenspanning',
    tooltip: 'De huidige benzine stations',
  },
  {
    id: 'lngcng',
    label: 'LNG / CNG',
    icon: {
      slug: image({ name: 'legend/fuelpump.svg' }),
      width: 20,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'lngcng',
    tooltip: 'De huidige LNG / CNG pompstations',
  },
  {
    id: 'pplusr',
    label: 'Park + Ride',
    icon: {
      svg: false,
      slug: image({ name: 'legend/Parkandride-icon.svg' }),
      width: 20,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'penr',
    tooltip: 'P & R - Let op: deze informatie is niet altijd compleet',
  },
  {
    id: 'park_n_ride',
    label: 'Park + Ride',
    icon: {
      svg: false,
      slug: image({ name: 'legend/Parkandride-icon.svg' }),
      width: 20,
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    // sourceNameHint: 'penr',
    tooltip: 'P & R',
  },
  {
    id: 'hoppin',
    label: 'Hoppin',
    icon: {
      slug: image({ name: 'legend/hoppin.png' }),
      width: 15,
    },
    visible: true,
    status: 'primary',
    type: 'national',
    sourceNameHint: 'hoppin',
    tooltip: 'Hoppin locaties (mobiliteithubs).',
  },
  {
    id: 'ls',
    label: 'Laagspanning',
    icon: {
      slug: image({ name: 'legend/ls.png' }),
      width: 15,
    },
    visible: true,
    layers: ['ls', 'ovkasten', 'ovkasten-labels'],
    status: 'primary',
    type: 'context',
    sourceNameHint: 'ls_kabels',
    tooltip: 'Het laagspanningsnet. Data van de netbeheerder',
  },
  {
    id: 'ls_kabels',
    label: 'Laagspanning',
    icon: {
      slug: image({ name: 'legend/ls.png' }),
      width: 15,
    },
    visible: true,
    layers: ['ls_kabels', 'ovkasten', 'ovkasten-labels'],
    status: 'primary',
    type: 'national',
    tooltip: 'Het laagspanningsnet. Data van de netbeheerder',
  },
  {
    id: 'ms_kabels',
    label: 'Middenspanning',
    icon: {
      slug: image({ name: 'legend/middenspanning.png' }),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    // sourceNameHint: 'middenspanning',
    tooltip: 'Het Middenspanningsnet. Data van de netbeheerder',
  },
  {
    id: 'ls_stations',
    label: 'Laagspanningskasten',
    icon: {
      slug: image({ name: 'legend/middenspanning.png' }),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    // sourceNameHint: 'middenspanning',
    tooltip: 'De laagspanningskasten. Data van de netbeheerder',
  },
  {
    id: 'ms_stations',
    label: 'Middenspanningskasten',
    icon: {
      slug: image({ name: 'legend/middenspanning.png' }),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    // sourceNameHint: 'middenspanning',
    tooltip: 'De Middenspanningskasten. Data van de netbeheerder',
  },
  {
    id: 'ms_ls_stations',
    label: 'Laag-/Middenspanningskasten',
    icon: {
      slug: image({ name: 'legend/middenspanning.png' }),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    // sourceNameHint: 'middenspanning',
    tooltip: 'De Laag-/Middenspanningskasten. Data van de netbeheerder',
  },
  {
    id: 'nwegen',
    label: 'N Wegen',
    icon: {
      slug: image({ name: 'legend/ls.png' }),
      width: 15,
    },
    visible: true,
    status: 'primary',
    type: 'context',
    sourceNameHint: 'nwegen',
    tooltip: 'De N Wegen',
  },
  {
    id: 'verkeersstromen',
    label: 'Verkeersstromen',
    icon: {
      slug: image({ name: 'legend/trafficflow.png' }),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    // sourceNameHint: 'gemeentelijke_eigendom',
    tooltip: 'De verkeersintensiteit in rood tinten.',
  },
  {
    id: 'parking',
    label: 'Parkeervlak',
    icon: {
      slug: image({ name: 'legend/parkeren.png' }),
      width: 15,
    },
    visible: true,
    status: 'primary',
    type: 'context',
    sourceNameHint: 'parkeervlakken',
    tooltip: 'Parkeervlakken vanuit de GIS analyse. Geen exacte data.',
  },

  {
    id: 'parkeerdruk',
    label: 'Parkeerdruk',
    icon: {
      slug: image({ name: 'legend/parkeerdruk.png' }),
      width: 15,
    },
    visible: true,
    status: 'primary',
    type: 'context',
    sourceNameHint: 'parkeerdruk',
    tooltip: 'Parkeerdruk.',
  },
  {
    id: 'trees',
    label: 'Boom',
    icon: {
      slug: image({ name: 'legend/boom.png' }),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'bomenregister',
    tooltip: 'De bomen binnen de gemeente, veelal aangeleverd door de gemeente',
  },
  {
    id: 'trees_zone',
    label: 'Boom (met zone)',
    icon: {
      slug: image({ name: 'legend/boom.png' }),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    layers: ['trees_zone', 'trees_zone_z'],
    sourceNameHint: 'boombeschermzone_p',
    tooltip: 'De bomen binnen de gemeente, inclusief beschermzone',
  },
  {
    id: 'zh_nieuwbouwlocaties',
    label: 'Bouwlocaties',
    icon: {
      slug: image({ name: 'legend/construction-regular.png' }),
      width: 15,
    },
    visible: true,
    status: 'primary',
    type: 'national',
    tooltip: 'Klik op de locatie voor details',
  },
  {
    id: 'zh_toplocaties',
    label: 'Top Locaties',
    icon: {
      slug: image({ name: 'legend/top-location.png' }),
      width: 15,
    },
    visible: true,
    status: 'primary',
    type: 'national',
    tooltip: 'Winkelcentra, zembaden, religieuze gebouwen, etc. Klik op de locatie voor details.',
  },

  {
    id: 'mrae_plankaart',
    label: 'SPOL OM (2017-2019)',
    icon: {
      slug: image({ name: 'chargingpoint/svg/disabled.svg' }),
      width: 15,
    },
    visible: false,
    status: 'secondary',
    type: 'national',
    tooltip: 'Oude plankaart MRA-E 2017 - 2019',
    restrictions: {
      include: {
        roles: [USER_ROLE.ADMIN],
        regional: [
          {
            type: GEOPGRAPHY_TYPE.REGION,
            slug: 'mrae',
          },
        ],
      },
    },
  },

  {
    id: 'visitorlocations',
    label: 'Bezoekerslocatie',
    icon: {
      svg: false,
      slug: image({ name: 'legend/locaties.png' }),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'bezoekers',
    tooltip: 'Locaties waar vermoedelijk verkeer op af komt',
  },
  {
    id: 'worklocations',
    label: 'Werklocaties',
    icon: {
      svg: false,
      slug: image({ name: 'legend/werklocaties.png' }),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'forenzen',
    tooltip: 'Locaties waar vermoedelijk forenzen verkeer op af komt',
  },
  {
    id: 'licht_infrastructuur',
    label: 'Lichtinfrastructuur',
    icon: {
      svg: false,
      slug: image({ name: 'legend/licht-infrastructuur.png' }),
      width: 15,
    },
    layers: [
      'lichtnet',
    ],
    hasLegend: true,
    legendItems: [
      {
        label: 'Lichtmasten',
        icon: {
          svg: false,
          slug: image({ name: 'legend/licht-infrastructuur.png' }),
          width: 15,
        },
      },
      {
        label: 'lichtnet',
        icon: {
          svg: false,
          slug: image({ name: 'legend/lichtnet.png' }),
          width: 15,
        },
      },
    ],
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'licht_infrastructuur',
    tooltip: 'Lichtinfrastructuur',
  },
  {
    id: 'lichtnet',
    label: 'Lichtnet',
    ignore: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'lichtnet',
    tooltip: 'Lichtnet',
  },
  {
    id: 'straatmeubilair',
    label: 'Straat meubilair',
    icon: {
      svg: false,
      slug: image({ name: 'legend/locaties.png' }),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'straatmeubilair',
    tooltip: 'Straat meubilair',
  },

  {
    id: 'nieuwbouw',
    label: 'Gebiedsontwikkeling',
    icon: {
      slug: image({ name: 'legend/nieuwbouw.png' }),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'nieuwbouw_locaties',
    tooltip: 'Gebiedsontwikkeling locaties in de gemeente.',
  },

  {
    id: 'monuments',
    label: 'Gemeentelijke monumenten',
    icon: {
      slug: image({ name: 'legend/monuments.png' }),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'gemeente_monument',
    tooltip: 'Gemeentelijke monumenten.',
  },
  {
    id: 'deelgebied_vlak',
    label: 'Deelgebieden',
    icon: {
      slug: image({ name: 'legend/deelgebieden.png' }),
      width: 15,
    },
    layers: ['deelgebied_vlak', 'deelgebied_vlak_text', 'deelgebied_lijn'],
    visible: true,
    status: 'primary',
    type: 'context',
    sourceNameHint: 'deelgebied_vlak',
    tooltip: 'De gemeente opgesplitst in 4 types buurten.',
  },
  {
    id: 'deelgebied_lijn',
    label: 'Deelgebiedgrensen',
    ignore: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'deelgebied_lijn',
    tooltip: 'Gestippelde lijn die de grensen van de deelgebieden markeren',
  },

  {
    id: 'snellaadzoekgebieden',
    label: 'Snellaadzoekgebieden',
    icon: {
      slug: image({ name: 'legend/deelgebieden.png' }),
      width: 15,
    },
    layers: ['snellaadzoekgebieden'], // @prop layers: When selected, will be visible in MapLegend //
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'snellaadzoekgebieden',
    tooltip: 'Snellaadzoekgebieden in de gemeente.',
  },
  {
    id: 'grondeigendom_gemeente',
    label: 'Grondeigendom Gemeente',
    icon: {
      slug: image({ name: 'legend/grondeigendom_gemeente.png' }),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'gemeente_eigendom',
    tooltip: 'De grond welke in eigendom is van de gemeente / overheid.',
  },

  {
    id: 'grondeigendom_provincie',
    label: 'Grondeigendom Provincie',
    icon: {
      slug: image({ name: 'legend/grondeigendom_provincie.png' }),
      width: 15,
    },
    layers: [
      'p_ut_grondeigendom',
      'p_fl_grondeigendom',
      'p_nh_grondeigendom',
    ],
    visible: true,
    status: 'secondary',
    type: 'national',
    tooltip: 'De grond welke in eigendom is van de provincie.',
    restrictions: {
      include: {
        regional: [
          {
            type: GEOPGRAPHY_TYPE.REGION,
            slug: 'have-provincial-grondeigendom',
          },
        ],
      },
      exclude: {
        roles: [USER_ROLE.CPO],
      },
    },
  },
  {
    id: 'p_ut_grondeigendom',
    ignore: true,
    status: 'secondary',
    type: 'national',
  },
  {
    id: 'p_fl_grondeigendom',
    ignore: true,
    status: 'secondary',
    type: 'national',
  },
  {
    id: 'p_nh_grondeigendom',
    ignore: true,
    status: 'secondary',
    type: 'national',
  },

  {
    id: 'grondeigendom_rws',
    label: 'Grondeigendom Rijkswaterstaat',
    icon: {
      slug: image({ name: 'legend/grondeigendom_rws.png' }),
      width: 15,
    },
    visible: false,
    status: 'secondary',
    type: 'national',
    tooltip: 'De grond welke in eigendom is van Rijkswaterstaat.',
    restrictions: {
      exclude: {
        roles: [USER_ROLE.CPO],
      },
    },
  },
  {
    id: 'nl_l_rws_vzp',
    label: 'RWS verzorgingsplaatsen',
    icon: {
      slug: image({ name: 'legend/rws-parking.png' }),
      width: 15,
    },
    visible: false,
    status: 'secondary',
    type: 'national',
    tooltip: 'De verzorgingsplaatsen langs de weg',
  },

  {
    id: 'highway_rest_areas',
    label: 'Dienstenzones',
    icon: {
      slug: image({ name: 'legend/car-parking.png' }),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    sourceNameHint: 'highway_rest_areas',
    tooltip: 'Snelweg rustplaatsen.',
  },

  {
    id: 'car_pool_parking_areas',
    label: 'Carpoolplaatsen',
    icon: {
      slug: image({ name: 'legend/car-pool-parking.png' }),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    sourceNameHint: 'car_pool_parking_areas',
    tooltip: 'Car-pool autos mogen hier worden geplaatst.',
  },

  {
    id: 'truckparkings',
    label: 'Truckparkings',
    icon: {
      slug: image({ name: 'legend/truck-parking.png' }),
      width: 20,
    },
    ignore: true,
    status: 'primary',
    type: 'national',
    tooltip: 'Vrachtwagens mogen hier parkeren.',
  },

  {
    id: 'truckparkings_secured',
    label: 'Beveiligde truckparkings',
    icon: {
      slug: image({ name: 'legend/truck-parking-secured.png' }),
      width: 20,
    },
    ignore: true,
    status: 'primary',
    type: 'national',
    tooltip: 'Vrachtwagens mogen hier beveiligd worden geplaatst.',
  },

  {
    id: 'almere_leidingen',
    label: 'Kabels & leidingen',
    icon: {
      slug: image({ name: 'legend/almere_leidingen.png' }),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'almere_leidingen',
    tooltip: 'Alle soorten kabels en leidingen in een laag met buffer',
  },

  {
    id: 'riool',
    label: 'Riool',
    icon: {
      slug: image({ name: 'legend/riool-line.png' }),
      width: 18,
    },
    layers: ['riool'],
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'riool',
    tooltip: 'Alle riool leidingen',
  },

  {
    id: 'telecom',
    label: 'Telecom',
    icon: {
      slug: image({ name: 'legend/telecom-line.png' }),
      width: 18,
    },
    layers: ['telecom'],
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'telecom',
    tooltip: 'Alle telecom leidingen',
  },

  {
    id: 'ecowater',
    label: 'Ecowater',
    icon: {
      slug: image({ name: 'legend/ecowater-line.png' }),
      width: 18,
    },
    layers: ['ecowater'],
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'ecowater',
    tooltip: 'Alle ecowater leidingen',
  },

  {
    id: 'gasleiding',
    label: 'Gas',
    icon: {
      slug: image({ name: 'legend/gasleiding-line.png' }),
      width: 18,
    },
    layers: ['gasleiding'],
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'gasleiding',
    tooltip: 'Alle gasleidingen',
  },

  {
    id: 'rioolpers',
    label: 'Rioolpers',
    icon: {
      slug: image({ name: 'legend/rioolpers-line.png' }),
      width: 18,
    },
    layers: ['rioolpers'],
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'rioolpers',
    tooltip: 'Alle rioolpers leidingen',
  },

  {
    id: 'stadsverwarming',
    label: 'Stadsverwarming',
    icon: {
      slug: image({ name: 'legend/stadsverwarming-line.png' }),
      width: 18,
    },
    layers: ['stadsverwarming'],
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'stadsverwarming',
    tooltip: 'Alle stadsverwarming leidingen',
  },

  {
    id: 'milieuprocedure',
    label: 'Milieuprocedures bodem',
    icon: {
      slug: image({ name: 'legend/milieuprocedure.png' }),
      width: 15,
    },
    hasLegend: true,
    legendItems: [
      {
        label: 'Automatisch',
        color: '#005000',
      }, {
        label: 'Handmatig',
        color: '#000050',
      },
    ],
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'milieuprocedure',
    tooltip: 'Milieuprocedures bodem',
  },

  {
    id: 'stortingskaart_bodem',
    label: 'Stortingskaart bodem',
    icon: {
      slug: image({ name: 'legend/stortingskaart_bodem.png' }),
      width: 15,
    },
    hasLegend: true,
    legendItems: [
      {
        label: 'Basishygiëne',
        color: '#808000',
      }, {
        label: 'Voorzorgsmaatregelen',
        color: '#800000',
      },
    ],
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'stortingskaart_bodem',
    tooltip: 'Stortingskaart bodem',
  },

  {
    id: 'truckparkings_bundle',
    label: 'Truckparkings',
    icon: {
      slug: image({ name: 'legend/truck-parking.png' }),
      width: 20,
    },
    layers: [
      'truckparkings',
      'truckparkings_secured',
    ],
    hasLegend: true,
    visible: false,
    status: 'secondary',
    type: 'national',
  },

  {
    id: 'visitorslocations_bundle',
    label: 'Cluster bezoekerslocaties',
    icon: {
      slug: image({ name: 'legend/cluster-visitorslocations.png' }),
      width: 15,
    },
    layers: [
      'bl_winkelgebieden',
      'bl_bedrijventerrein',
      'bl_grotewinkels',
      'bl_restaurants_langs_weg',
    ],
    hasLegend: true,
    visible: true,
    status: 'secondary',
    type: 'national',
    restrictions: {
      exclude: {
        roles: [USER_ROLE.CPO],
      },
    },
  },
  {
    id: 'bl_winkelgebieden',
    label: 'Winkelgebied',
    icon: {
      slug: image({ name: 'legend/winkelgebieden.png' }),
      width: 15,
    },
    ignore: true,
    status: 'secondary',
    type: 'national',
    sourceNameHint: 'bl_winkelgebieden',
    tooltip: 'Cluster van Bedrijventerreinen',
  },
  {
    id: 'bl_bedrijventerrein',
    label: 'Bedrijventerreinen',
    icon: {
      slug: image({ name: 'legend/bedrijventerrein.png' }),
      width: 15,
    },
    ignore: true,
    status: 'secondary',
    type: 'national',
    sourceNameHint: 'bl_bedrijventerrein',
    tooltip: 'Cluster van Bedrijventerreinen',
  },
  {
    id: 'bl_grotewinkels',
    label: 'Grote winkel',
    icon: {
      slug: image({ name: 'legend/grotewinkels.png' }),
      width: 15,
    },
    ignore: true,
    status: 'secondary',
    type: 'national',
    sourceNameHint: 'bl_grotewinkels',
    tooltip: 'Cluster van Grote winkel',
  },
  {
    id: 'bl_restaurants_langs_weg',
    label: 'Restaurants langs de weg',
    icon: {
      slug: image({ name: 'legend/restaurants_langs_weg.png' }),
      width: 15,
    },
    ignore: true,
    status: 'secondary',
    type: 'national',
    sourceNameHint: 'bl_restaurants_langs_weg',
    tooltip: 'Cluster van Restaurants langs de weg (500m)',
  },

  /**
   * Ignore - added for Admin panel
   */
  {
    id: 'ovkasten',
    label: 'Laagspanning - OV Kasten',
    icon: {
      slug: image({ name: 'legend/ovkasten.png' }),
      width: 15,
    },
    ignore: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'ov_kasten',
    tooltip: 'De locatie van OV kasten is optionele data in de laagspannings kaart laag.',
  },
  {
    id: 'trees_zone_z',
    label: 'Bomen - beschermzone',
    ignore: true,
    type: 'context',
    status: 'secondary',
    sourceNameHint: 'boombeschermzone',
    tooltip: 'De beschermzone om bomen.',
  },
]
