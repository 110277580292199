<template>
  <div class="FactsheetExportDashboard d-flex flex-column w-100 align-items-center">
    <div class="PanelWrapper mt-5 mb-4 flex-grow-1">
      <header class="PanelHeader d-flex justify-content-between py-3 px-4 align-items-center">
        Factsheet exports
      </header>
      <section class="d-flex flex-column p-4">
        <!-- Factsheet Export Panel -->
        <div class="Panel d-flex mt-3 pb-3">
          <aside class="Panel__Aside flex-shrink-0 mr-3">
            <h3 class="Panel__Title">
              Factsheet Export
            </h3>
            <div class="w-75 mt-3">
              <p>
                Met deze tool kun je factsheets bedrijventerreinen downloaden voor de geselecteerde gemeentes. Als je een provincie selecteer, worden er alle gemeentes van die provincie geselecteerd.
              </p>

              <p>
                <strong>LET OP!</strong>
              </p>
              <p>
                Dit gaat automatisch alle factsheets downloaden voor de geselecteerde gemeentes, door naar de
                factsheet pagina per gemeente te gaan en alle factsheets te downloaden. Alleen als er een factsheet
                beschikbaar is wordt deze gedownload. Dus er zullen geen "geen factsheet beschikbaar" downloads zijn.
              </p>
              <p>
                De browser gaat automatisch navigeren en normaliter zou je je computer normaal kunnen gebruiken,
                maar voor beste werking laat je de browser open staan en ga je niet naar een ander tabblad.
              </p>
              <p>
                De factsheets worden gedownload in je Downloads map.
              </p>
              <p>
                Als je een gemeente mist in de lijst, heeft deze gemeente nog geen config
                opgeslagen. In dat geval kan ook geen factsheet worden gedownload.
              </p>
            </div>
          </aside>

          <!-- SUCCESS / REPORTING PANEL -->
          <div
            v-if="status && counter"
            class="Panel__Main flex-grow-1 mb-3"
          >
            <div>
              <b-alert
                v-if="status ==='canceled'"
                variant="warning"
                show
              >
                <h4 class="alert-heading">
                  Canceled
                </h4>

                <p class="mt-3 mb-0">
                  Toch zijn er Factsheets gedownload. Je kunt deze nu zien in je Downloads map.
                </p>
              </b-alert>

              <b-alert
                v-if="status ==='success'"
                variant="success"
                show
              >
                <h4 class="alert-heading">
                  Success
                </h4>

                <p class="mt-3 mb-0">
                  Factsheets zijn gedownload. Je kunt deze nu zien in je Downloads map.
                </p>
              </b-alert>

              <p class="mt-4">
                <strong>Totale downloads:</strong>
              </p>
              <p>
                Bedrijventerreinen (PDFs totaal): {{ counter.pdfsTotal }} <br>
                Voor gemeentes: {{ counter.municipalitiesWithDownload }} <br>
              </p>

              <hr>

              <p>
                Gemeentes zonder factsheets: {{ counter.municipalitiesTotal - counter.municipalitiesWithDownload }}
              </p>

              <b-button
                variant="cta"
                class="mt-3"
                @click="handleRestart"
              >
                Begin opnieuw
              </b-button>
            </div>
          </div>


          <!-- CONFIGURATION PANEL -->
          <div
            v-else
            class="Panel__Main flex-grow-1 mb-3"
          >
            <div v-if="error">
              <b-alert
                variant="danger"
                show
              >
                {{ error }}
              </b-alert>
            </div>

            <div class="d-flex align-items-start pb-4">
              <span class="Panel__Label mr-3">
                Wat wil je exporteren?
              </span>

              <div class="flex-grow-1">
                <vSelect
                  v-model="selection.geography"
                  label="label"
                  :options="geographyOptions"
                  class="flex-grow-1 mr-3"
                  select-group-label="fases"
                />
              </div>
            </div>

            <div class="d-flex align-items-start pb-4">
              <span class="Panel__Label mr-3">
                Selecteer gewenste gebieden
              </span>

              <div class="flex-grow-1">
                <vSelect
                  v-model="selection.items"
                  multiple
                  label="label"
                  :options="options"
                  class="flex-grow-1 mr-3"
                  select-group-label="fases"
                />
              </div>
            </div>

            <div class="d-flex justify-content-end pb-4 mr-4">
              <b-button
                variant="cta"
                class="ml-3"
                @click="handleStartExport"
              >
                Draai export
              </b-button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { allMunicipalities, allProvinces } from '@/services/municipalities'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

export default {
  name: 'FactsheetExportPanel',
  components: { vSelect },
  data() {
    return {
      status: false,
      error: '',
      counter: {
        municipalitiesTotal: 0,
        municipalitiesWithDownload: 0,
        pdfsTotal: 0,
      },
      selection: {
        geography: '',
        items: [],
        factsheetTypes: [],
      },
    }
  },
  computed: {
    ...mapGetters('config', ['getAvailableConfigCodes']),
    geographyOptions() {
      return [
        { label: 'Provincies', value: 'provinces' },
        { label: 'Gemeentes', value: 'municipalities' },
        // { label: 'Regios', value: 'regions' },
      ]
    },
    options() {
      if (!this.selection.geography) {
        return []
      }

      if (this.selection.geography.value === 'provinces') {
        return allProvinces()
            .map(province => ({ label: province.label, value: province.provincie }))
      }

      if (this.selection.geography.value === 'municipalities') {
        return allMunicipalities()
            .map(municipality => ({ label: municipality.label, value: municipality.code, slug: municipality.slug }))
      }

      return []
    },
  },
  watch: {
    'selection.geography': {
      handler() {
        // reset selection if geography changes
        this.selection.items = []
      },
    },
  },
  created() {
    // after a round trip of screenshotting confirm to the user that all factsheets are downloaded
    if (localStorage.getItem('export-factsheets-success') === 'true') {
      this.counter = JSON.parse(localStorage.getItem('export-factsheets-counter'))
      this.status = 'success'
    }

    // after a round trip of screenshotting confirm to the user that all factsheets are downloaded
    if (localStorage.getItem('export-factsheets-canceled') === 'true') {
      this.counter = JSON.parse(localStorage.getItem('export-factsheets-counter'))
      this.status = 'canceled'
    }

    // reset storage
    this.resetStorage()
  },
  methods: {
    resetStorage() {
      localStorage.removeItem('export-factsheets-data')
      localStorage.removeItem('export-factsheets-counter')

      localStorage.removeItem('export-factsheets-in-progress')
      localStorage.removeItem('export-factsheets-success')
      localStorage.removeItem('export-factsheets-canceled')
    },

    /**
     * handle restart (resets)
     * */
    handleRestart() {
      this.status = false
      this.error = false

      this.counter = {
        municipalitiesTotal: 0,
        municipalitiesWithDownload: 0,
        pdfsTotal: 0,
      }
    },

    /**
     * start exporting factsheets
     * */
    async handleStartExport() {
      let municipalities = this.selection.items

      // gather all municipalities if provinces are selected
      if (this.selection.geography.value === 'provinces') {
        municipalities = this.selection.items.flatMap(option => {
          return allMunicipalities()
              .filter(municipality => municipality.provincie === option.value)
              .map(municipality => ({ label: municipality.label, value: municipality.code, slug: municipality.slug }))
        })
      }

      municipalities = municipalities
          .filter(municipality => this.getAvailableConfigCodes.includes(municipality.value))
          .map(municipality => municipality.slug)

      // todo:: maybe this should be before selecting the municipalities
      if (! municipalities.length) {
        this.error = 'Geen gemeentes (met een opgeslagen configuratie) geselecteerd'
        return
      }

      this.counter.municipalitiesTotal = municipalities.length

      localStorage.setItem('export-factsheets-in-progress', 'true')
      localStorage.setItem('export-factsheets-data', JSON.stringify(municipalities))
      localStorage.setItem('export-factsheets-counter', JSON.stringify(this.counter))

      await this.$router.push({ name: 'Bedrijventerreinen', params: { municipality: municipalities[0] } })
    },
  },
}
</script>

<style lang="scss">


.PanelWrapper {

  min-width: 1100px;
  max-width: 1100px;
  background: white;
  box-shadow: 0px 1px 3px 0px #dfe2e5;

  header {
    background: var(--primary);
    color: white;
    font-size: 1.5rem;
  }

  form {
    label {
      font-size: 1.1rem;
    }

    small {
      outline: none !important;
      font-size: 1rem;
    }
  }
}

.Panel {
  &__Aside {
    width: 275px;
  }

  &__Main {
    font-size: 1.25rem;

    .form-control {
      width: 100px;
      font-size: 1.25rem;
    }
  }

  &__Label {
    width: 300px;
  }
}

// double to override the default v-select styles
.v-select.v-select {
  margin-right: 20px !important;

  .vs__actions {
    margin-inline: 5px;
  }
}
</style>
