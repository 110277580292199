<template>
  <div class="CollapsableSegment">
    <h4
      :class="`${!disabled && 'CollapsableSegment__Header'} d-flex justify-content-between`"
      @click="!disabled && togggleVisibility()"
    >
      <span
        v-if="label"
        class="flex-grow-1"
      >{{ label }}</span>
      <span>
        <slot name="header" />
      </span>
      <SvgIcon
        :class="['ml-3', { disabled }]"
        :icon="icon"
        :has-fill="true"
      />
    </h4>
    <b-collapse v-model="visibilityState">
      <div class="pt-3">
        <slot />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon.vue'

export default {
  name: 'CollapsableSegment',
  components: { SvgIcon },
  props: {
    label: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visibilityState: false,
    }
  },
  computed: {
    /**
     * Change the open / close icon
     */
    icon() {
      return this.visibilityState ? 'chevron-up-regular' : 'chevron-down-regular'
    },
    hidden () {
      return this.disabled
    }
  },
  watch: {
    /**
     * If visibility prop changes, it overrides the current visibilityState
     */
    visible() {
      this.visibilityState = this.visible
    },
  },
  created() {
    // The visibility prop dictates the initial visibility state
    this.visibilityState = this.visible
  },
  methods: {
    /**
     * Toggle the visibility of the legend items
     */
    togggleVisibility() {
      this.visibilityState = ! this.visibilityState
    },
  },
}
</script>

<style lang="scss">
.CollapsableSegment {
  &__Header {
    cursor: pointer;
    fill: #7F8FA4;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: -2px;
      width: calc(100% + 10px);
      left: -5px;
      border-bottom: 1px solid lighten(#7F8FA4, 20%);
    }

    &:hover {
      color: darken(#7F8FA4, 20%);
      fill: darken(#7F8FA4, 20%);
    }
  }
  &.layer-category-segment {
    padding-left: 0.8em;
    margin-bottom: 1em;
    h4 {
      font-size: 1.2rem;
      padding-bottom: 0.3em;
    }
  }
  .disabled {
    visibility: hidden;
  }
}
</style>
