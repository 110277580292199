<template>
  <ul
    v-show="tags.length !== 0"
    class="PMS-Active-Filter"
  >
    <li
      v-for="filter in tags"
      :key="filter.label"
      class="PMS-Active-Filter__tag"
    >
      <template v-if="filter.type === FILTER_TYPE.RANGE_FILTER">
        {{ filter.tag }} van {{ filter.from }} t/m {{ filter.to }} {{ filter.unit }}
      </template>

      <template v-if="filter.type === FILTER_TYPE.TOGGLE_FILTER || filter.type === FILTER_TYPE.SELECT_FILTER">
        {{ filter.tag }}
      </template>

      <span @click="resetFilter({ id: filter.id, optionID: filter.optionID } )">
        <SvgIcon icon="times-regular" />
      </span>
    </li>
  </ul>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon'
import { mapActions, mapGetters } from 'vuex'
import { FILTER_TYPE } from '@/../shared/valueholders/filter-types'

export default {
  name: 'FilterTags',
  components: { SvgIcon },
  computed: {
    ...mapGetters('filters', ['getActiveFilters']),
    tags() {
      return this.getActiveFilters.flatMap(filter => {

        // for multi-select filters, we need to create one tag per selection
        if (filter.type === FILTER_TYPE.SELECT_FILTER) {
          return filter.values.map(value => ({
            ...value,
            id: filter.id,
            optionID: value.id,
            type: FILTER_TYPE.SELECT_FILTER,
          }))
        }

        return [filter]
      })
    },
  },
  created() {
    this.FILTER_TYPE = FILTER_TYPE
  },
  methods: {
    ...mapActions('filters', ['resetFilter']),
  },
}
</script>

<style lang="scss">
$hover-color: #4f5c6e;

.PMS-Active-Filter {
  list-style: none;
  margin: 0;
  padding: 0;
  grid-column: 1 / span 2;
  display: flex;
  flex-wrap: wrap;
  max-width: 320px;

  &__tag {
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 0 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;

    span {
      display: flex;
      align-content: center;
    }

    .svg-container {
      cursor: pointer;
      margin-left: 0.5rem;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover, &:focus, &:active {
      .svg-container {
        color: $hover-color;
      }
    }

    &:focus, &:active {
      color: $hover-color;
      border: 1px solid black;
    }
  }
}

</style>
