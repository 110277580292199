const ACTION_SIDEBAR_TAB = {
  INFO: 'info',
  MERGE: 'merge',
  MONITORING: 'monitoring',
  OPMERKINGEN: 'opmerkingen',
  PARTICIPATION: 'participation',
}

const DEFAULT_TAB = ACTION_SIDEBAR_TAB.INFO

module.exports = {
  ACTION_SIDEBAR_TAB,
  DEFAULT_TAB,
}
