<template>
  <div>
    <b-button
      v-b-modal.change-statuses-modal
      class="mt-1"
      v-text="'Wijzigen'"
    />

    <b-modal
      id="change-statuses-modal"
      size="lg"
      title="Status laadlocaties wijzigen"
      hide-header-close
      cancel-title="Annuleren"
      ok-title="Opslaan"
      ok-variant="danger"
      @ok="handleSubmit"
    >
      <div class="modal__select-statuses d-flex justify-content-center">
        <div class="modal__select-statuses__active-statuses pr-3 mr-3">
          <strong>Actuele selectie</strong>
          <ul class="list-group list-group-flush">
            <li
              v-for="status in statuses"
              :key="status.value"
              class="list-group-item px-2"
            >
              <InlineChargingpointIcon
                :status="status.value"
              />

              {{ status.label }}

              <b-button
                variant="outline-danger"
                size="sm"
                class="ml-2 float-right"
                @click="() => removeStatus({ value: status.value })"
              >
                x
              </b-button>
            </li>
          </ul>
        </div>

        <div class="modal__select-statuses__status-selector flex-grow-1">
          <strong>Status toevoegen</strong>
          <p>Klik op een icoon om hem toe te voegen</p>
          <LocationStatusSelector
            :disabled-statuses="disabledStatuses"
            @select="handleInput"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import LocationStatusSelector from '@/components/admin/chargingpoints/LocationStatusSelector.vue'
import InlineChargingpointIcon from '@/components/common/InlineChargingpointIcon.vue'
import { mapGetters } from 'vuex'
import { CHARGINGPOINT_STATUS, CHARGINGPOINT_TYPE } from '../../../../shared/valueholders/chargingpoint-statuses'

export default {
  components: { InlineChargingpointIcon, LocationStatusSelector },
  props: {
    preselected: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
      disabledStatuses: [CHARGINGPOINT_STATUS.ALERT, ...CHARGINGPOINT_TYPE.SUGGESTION, ...CHARGINGPOINT_TYPE.REJECTED],
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    statuses() {
      // should sorting be needed, this is the place
      return this.selected
    },
  },
  watch: {
    getActiveMunicipality() {
      this.init()
    },
  },
  mounted() {
    // reset on close
    this.$root.$on('bv::modal::hidden', () => {
      this.init()
    })
  },
  destroyed() {
    // deactivate listeners
    this.$root.$off('bv::modal::hidden', () => {
      this.init()
    })
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.selected = JSON.parse(JSON.stringify(this.preselected))
    },
    removeStatus({ value }) {
      const index = this.selected.findIndex(selection => selection.value === value)
      this.selected.splice(index, 1)
    },
    handleInput({ value, label }) {
      const match = this.selected.find(selection => selection.value === value)
      if (match) return

      this.selected.push({ value, label })
    },
    handleSubmit() {
      this.$emit('select', { selected: this.selected })
    },
  },
}
</script>

<style lang="scss">
.modal {
  &__select-statuses {
    &__active-statuses {
      min-width: 250px;
      border-right: 1px solid var(--border-color)
    }
    &__status-selector {
      max-width: 250px;
    }
  }
}
</style>
