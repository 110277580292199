import { mapGetters } from 'vuex'
import { statusGroupLabels } from '@/../shared/services/statusTranslations'

export default {
  computed: {
    ...mapGetters('access', [
      'canAccessLayer',
    ]),
    statusOptions() {
      return Object.entries(statusGroupLabels)
        .filter(([status]) => this.canAccessLayer({ id: `chargingpoints-${status}` }))
        .map(([status, label]) => ({
          value: status,
          text: label,
        }))
    },
  },
}
