<template>
  <div class="MunicipalityPanel mt-3 mb-4 flex-grow-1">
    <div class="position-sticky">
      <PanelHeader
        :enable-save="difference"
        :busy="busy"
        @save="handleSaveEvent"
      />
      <Tabs
        :tabs="tabs"
        :active="activeTab"
        @selected="handleTabClick"
      />
    </div>

    <Feedback
      v-if="!isStored"
      class="mx-4 mt-4"
      :dismissible="false"
      :feedback="{
        message: 'Let op! Deze configuratie is nog nooit opgeslagen.',
        variant: 'warning'
      }"
    />
    <Feedback
      v-if="activeTab === 'users'"
      class="mx-4 mt-4"
      :dismissible="false"
      :feedback="{ variant: 'warning' }"
    >
      <strong class="mr-3">Wijzigingen worden meteen doorgevoerd.</strong>
      <span>Door het maken van wijzigingen kan de sessie van de gebruiker onderbroken worden.</span>
    </Feedback>

    <section
      v-if="activeTab === 'layers'"
      class="MunicipalityPanel__Section d-flex flex-column p-4"
    >
      <LayerConfigPanel
        :value="layers"
        class="mt-4 pb-5"
        @input="handleLayersChange"
      />
    </section>

    <section
      v-if="activeTab === 'users'"
      class="MunicipalityPanel__Section d-flex flex-column p-4"
    >
      <UserPanel
        :code="getActiveMunicipality"
        class="mt-4 pb-5"
      />
    </section>

    <section
      v-if="activeTab === 'monitoring'"
      class="MunicipalityPanel__Section d-flex flex-column p-4"
    >
      <MonitoringPanel
        :values="monitoring"
        class="mt-4 pb-5"
        @input="handleMonitoringChange"
      />
    </section>

    <section
      v-if="activeTab === 'prognose'"
      class="MunicipalityPanel__Section d-flex flex-column p-4"
    >
      <FeaturePanel
        :values="features"
        :has-layers="!noActiveLayers"
        class="mt-4 pb-5"
        @input="handleFeaturesChange"
      />
      <PrognosePanel
        :values="prognose"
        class="mt-4 pb-5"
        @input="handlePrognoseChange"
      />
    </section>

    <section
      v-if="activeTab === 'chargingpoints'"
      class="MunicipalityPanel__Section d-flex flex-column p-4"
    >
      <AssignValidatorsPanel />
    </section>

    <section
      v-if="activeTab === 'participation'"
      class="MunicipalityPanel__Section d-flex flex-column p-4"
    >
      <ParticipationPanel
        :values="participation"
        class="mt-4 pb-5"
        @input="handleParticipationChange"
      />
    </section>
  </div>
</template>

<script>

import PanelHeader from '@/components/common/PanelHeader'
import LayerConfigPanel from '@/components/admin/municipalities/LayerConfigPanel'
import FeaturePanel from '@/components/admin/municipalities/FeaturePanel'
import PrognosePanel from '@/components/admin/municipalities/PrognosePanel'
import UserPanel from '@/components/admin/municipalities/UserPanel'
import ParticipationPanel from '@/components/admin/municipalities/ParticipationPanel'
import AssignValidatorsPanel from '@/components/admin/chargingpoints/AssignValidatorsPanel'
import MonitoringPanel from '@/components/admin/municipalities/MonitoringPanel'

import Tabs from '@/components/common/Tabs'
import Feedback from '@/components/form/Feedback'

import { labelByCode, slugByCode } from '@/services/municipalities'
// use raw data
import { layers as layerDetails } from '@/data/layerDetails'

import userMixin from '@/mixins/common/userMixin'

import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import { Bugfender } from '@bugfender/sdk'
import { checkStatus, returnJson } from '@/helpers/api'
import * as uuid from 'uuid'

export default {
  components: {
    AssignValidatorsPanel,
    PanelHeader,
    LayerConfigPanel,
    UserPanel,
    FeaturePanel,
    PrognosePanel,
    ParticipationPanel,
    MonitoringPanel,
    Tabs,
    Feedback,
  },
  mixins: [userMixin],
  data() {
    return {
      // UI States
      activeTab: 'prognose',
      tabs: [
        {
          label: 'Features & Prognose Instellingen',
          id: 'prognose',
        },
        {
          label: 'Kaartlagen',
          id: 'layers',
        },
        {
          label: 'Gebruikers',
          id: 'users',
        },
        {
          label: 'Validatie',
          id: 'chargingpoints',
        },
        {
          label: 'Participatie',
          id: 'participation',
        },
        {
          label: 'Monitoring',
          id: 'monitoring',
        },
      ],
      difference: false,
      busy: false,

      // Datasets for the panels
      layers: {},
      prognose: {},
      monitoring: {},
      planmode: {},
      participation: {},
      upload: {},
      factsheet: {},
      export: {},
      phases: [],
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('config', {
      config: 'config',
      participationConfigs: 'participationConfigs',
      isStored: 'isStored',
      mapOptions: 'mapOptions',
      configYears: 'years',
      configDefaultYear: 'defaultYear',
      configLimit: 'limit',
      configModels: 'models',
      configBreakpoints: 'breakpoints',
      configLayers: 'layers',
      configPlanmode: 'planmode',
      configPrognoseEnabled: 'isPrognoseEnabled',
      configUploadEnabled: 'isUploadEnabled',
      configFactsheetEnabled: 'isFactsheetEnabled',
      configExportEnabled: 'isExportEnabled',
      configMonitoringEnabled: 'isMonitoringEnabled',
      configMonitoringLastMonth: 'lastMonth',
      configMonitoringCpos: 'cpos',
      configThresholds: 'thresholds',
    }),
    /**
     * The model data for the Features panel
     */
    features() {
      return {
        prognose: this.prognose.enabled,
        planmode: this.planmode.enabled,
        upload: this.upload.enabled,
        factsheet: this.factsheet.enabled,
        export: this.export.enabled,
        phases: this.phases,
      }
    },
    /**
     * The config structure, as stored in FaunaDB.
     */
    data() {
      return {
        code: this.getActiveMunicipality,
        name: labelByCode({
          code: this.getActiveMunicipality,
        }),
        // Either the upload portal or the prognose needs to be available for a municipality to be active
        enabled: (this.upload.enabled || this.prognose.enabled),
        planmode: this.planmode.enabled,
        upload: this.upload.enabled,
        factsheet: this.factsheet.enabled,
        phases: this.phases,
        export: {
          enabled: this.export.enabled,
        },
        monitoring: {
          enabled: this.monitoring.enabled,
          lastMonth: this.monitoring.lastMonth,
          cpos: this.monitoring.cpos,
          thresholds: this.monitoring.thresholds,
        },
        participation: this.participation.map(config => ({
          uuid: config.uuid,
          enabled: config.enabled,
          interactionEnabled: config.interactionEnabled,
          suggestionsEnabled: config.suggestionsEnabled,
          defaultLayerVisibility: config.defaultLayerVisibility,
          slug: config.slug,
          openDate: config.openDate,
          closeDate: config.closeDate,
          previewCode: config.previewCode,
          previewEnabled: config.previewEnabled,
          showMessageAfterClose: config.showMessageAfterClose,
          logoBackgroundColor: config.logoBackgroundColor,
          translationsEnabled: config.translationsEnabled ?? false,
          statuses: config.statuses,
          phases: config.phases,
          cpos: config.cpos,
          introductionText: config.introductionText,
          sidebarText: config.sidebarText,
          geography: config.geography,
        })),
        prognose: {
          enabled: this.prognose.enabled,
          limit: this.prognose.limit,
          years: this.prognose.years,
          defaultYear: this.prognose.defaultYear,
          breakpoints: [
            this.prognose.breakpoint_one,
            this.prognose.breakpoint_two,
            this.prognose.breakpoint_three,
          ],
          models: this.prognose.models,
        },
        map: this.mapOptions,
        layers: this.layers,
      }
    },
    /**
     * Detect when there is no layer configuration at all
     */
    noActiveLayers() {
      return Object.values(this.layers)
        .filter(layer => layer.status !== 'hidden').length === 0
    },
  },
  watch: {
    /**
     * Upon changing the active municipality, the config is reloaded
     *  TODO: Implement warning notice if anything has changed?
     *  TODO: WebWorkers (Cloudflare workers - evmaps@journeyworks.nl account) to support multiple users editing a config simulteanously
     */
    getActiveMunicipality() {
      this.loadConfig()
    },

    /**
     * Reload if anything changes in the central config store (e.g. config is succesfully saved)
     *  We want to show the actual stored changes. Should something have gone wrong it should be
     *  noticeable right away.
     */
    config() {
      this.loadConfig()
    },
    noActiveLayers() {
      if (this.noActiveLayers) {
        this.prognose.enabled = false
      }
    },
  },
  created() {
    this.loadConfig()
  },
  methods: {
    ...mapMutations('config', [
      'updateOrAddConfig',
    ]),
    /**
     * Handle a change in tabs
     */
    handleTabClick({ id }) {
      this.activeTab = id
    },
    /**
     * Get configuration details from the config store
     */
    loadConfig() {
      this.prognose = {
        enabled: this.configPrognoseEnabled,
        years: this.configYears,
        defaultYear: this.configDefaultYear,
        limit: this.configLimit,
        models: this.configModels,
        breakpoint_one: this.configBreakpoints[0],
        breakpoint_two: this.configBreakpoints[1],
        breakpoint_three: this.configBreakpoints[2],
      }

      this.planmode = {
        enabled: !! this.configPlanmode,
      }
      this.upload = {
        enabled: !! this.configUploadEnabled,
      }
      this.factsheet = {
        enabled: !! this.configFactsheetEnabled,
      }
      this.export = {
        enabled: !! this.configExportEnabled,
      }
      this.monitoring = {
        enabled: !! this.configMonitoringEnabled,
        lastMonth: this.configMonitoringLastMonth,
        cpos: this.configMonitoringCpos,
        thresholds: this.configThresholds,
      }

      const defaultIntroductionText = `
        <h1>Welkom!</h1>

        <p>De komende jaren neemt elektrisch rijden enorm toe. De gemeente bereidt zich hierop voor. Hieronder leest u hoe wij dit doen.</p>

        <h3>De gemeente breidt het netwerk van openbare laadpalen in GEMEENTE de komende jaren uit</h3>
        <p>In GEMEENTE zijn er een groot aantal openbare laadpunten voor elektrische auto's. Door de groei van het aantal elektrische auto's stijgt ook de behoefte aan meer laadpunten. De openbare laadpalen zijn bedoeld voor elektrische auto's die niet op privéterrein opgeladen kunnen worden.</p>

        <h3>U kunt meedenken over waar deze laadpalen moeten komen</h3>
        <p>Hiervoor hebben we deze plankaart opgesteld. Hierin staan alle geplande laadpalen. Ook kunt u hier uw mening geven over de geplande locaties. Wanneer uw reactie voor <strong>EINDDATUM</strong> binnen is houden wij hier rekening mee bij het opstellen van de definitieve plankaart.</p>

        <h3>Pas na een aanvraag komt er een laadpaal</h3>
        <p>Er kunnen nog locaties wijzigen of vervallen. De definitieve locaties worden vastgesteld met een verkeersbesluit. Zolang er nog geen laadpaal is, blijven de parkeerplaatsen voor iedereen beschikbaar. </p>
        <p>Pas na een aanvraag van een inwoner wordt een laadpaal geplaatst. Hierbij wordt één parkeerplaats aangewezen als oplaadplaats. Bij voldoende gebruik van de paal wordt ook een tweede parkeerplaats bij de laadpaal een oplaadplaats. </p>

        <h3>Neem gerust contact met ons op</h3>
        <p>Heeft u nog vragen? Wij beantwoorden ze graag. U kunt contact opnemen via <a href="mailto:GEMEENTE@VOORBEELD.NL">GEMEENTE@VOORBEELD.NL</a> onder vermelding van het laadpaalnummer. U kunt ook eerst de veelgestelde vragen bekijken op onze website: <a href="HTTPS://WWW.GEMEENTE.NL" target="_blank">GEMEENTE.NL</a>.</p>
      `.trim().split('\n').map(line => line.trim()).join('\n')

      const defaultSidebarText = `
        <p>Heeft u een vraag of opmerking over deze locatie? Wij horen graag van u! U kunt contact opnemen via <a href="mailto:GEMEENTE@VOORBEELD.NL">GEMEENTE@VOORBEELD.NL</a> U kunt ook eerst de veelgestelde vragen bekijken op <a href="HTTPS://WWW.VOORBEELD.NL" target="_blank">VOORBEELD.NL</a>.</p>
      `.trim().split('\n').map(line => line.trim()).join('\n')

      // Set a default participation config if there are none
      const participationConfigs = this.participationConfigs.length ? this.participationConfigs : [{}]
      this.participation = participationConfigs.map(config => ({
        uuid: config.uuid ?? uuid.v4(),
        enabled: !!config.enabled,
        interactionEnabled: !!config.interactionEnabled,
        suggestionsEnabled: !!config.suggestionsEnabled,
        defaultLayerVisibility: {
          suggestion: !!config.defaultLayerVisibility?.suggestion,
        },
        slug: config.slug ?? slugByCode({ code: this.getActiveMunicipality }),
        openDate: config.openDate,
        closeDate: config.closeDate,
        previewCode: config.previewCode ?? (Math.random() + 1).toString(36).substring(6),
        previewEnabled: config.previewEnabled ?? false,
        showMessageAfterClose: !!config.showMessageAfterClose,
        logoBackgroundColor: config.logoBackgroundColor ?? '#FFFFFF',
        translationsEnabled: config.translationsEnabled ?? false,
        statuses: config.statuses ?? ['realized', 'in-progress', 'definitive'],
        phases: config.phases ?? [],
        cpos: config.cpos ?? [],
        introductionText: config.introductionText ?? defaultIntroductionText,
        sidebarText: config.sidebarText ?? defaultSidebarText,
        geography: config.geography,
      }))

      this.layers = this.configLayers
      this.phases = this.config.phases

      // Ensure that all context layers are present as Observable properties. This allows the UI to function properly
      // TODO: Find a better way to get the same result
      layerDetails.filter(layer => ['context'].includes(layer.type)).forEach(layer => {
        if (this.layers[layer.id]) return
        Vue.set(
          this.layers,
          layer.id,
          {
            source: '',
            status: 'hidden',
            url: '',
          },
        )
      })

      if (this.noActiveLayers) {
        this.prognose.enabled = false
      }

      /**
       * Normally re-loading the config disables the save button, because there is no dirty data.
       *  However, in case of a config that has not yet been stored, and has been provided with
       *  layer data (automated or manually), the save button is enabled immediately.
       *  Addition: unless the upload portal mode is enabled
       */
      this.difference = ! this.isStored && (! this.noActiveLayers || this.upload.enabled)

      // Update the UI State
      this.busy = false
    },
    /**
     * Handle config changes from the panel
     */
    handleLayersChange(layers) {
      this.layers = layers

      this.difference = true
    },
    handlePrognoseChange(data) {
      this.prognose = data
      this.difference = true
    },
    handleMonitoringChange(data) {
      this.monitoring = data
      this.difference = true
    },
    handleFeaturesChange({ data, difference }) {
      if (difference) {
        this.planmode.enabled = data.planmode
        this.prognose.enabled = data.prognose
        this.upload.enabled = data.upload
        this.factsheet.enabled = data.factsheet
        this.export.enabled = data.export
        this.phases = data.phases
      }
      this.difference = difference
    },
    handleParticipationChange(data) {
      this.participation = data
      this.difference = true
    },

    /**
     * Store the municipality data
     */
    async handleSaveEvent () {
      this.busy = true

      const token = await this.$auth.getTokenSilently()
      const response = await fetch('/api/configsave', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(this.data),
      })
        .then(await checkStatus)
        .then(returnJson)
        .catch(e => {
          this.$notify({
            type: 'error',
            title: 'Niet gelukt!',
            text: 'Er ging iets mis tijdens het opslaan van de configuratie.',
          })
          Bugfender.error('config updaten niet gelukt: ', e)
        })

      if (response.config) {
        this.updateOrAddConfig({
          config: response.config,
        })
      }
    },
  },
}
</script>

<style lang="scss">

.MunicipalityPanel {
  .position-sticky {
    top: 0;
    z-index: 3;
  }
  header {
    background: var(--primary);
    color: white;
    font-size: 1.5rem;
  }

  form {
    label {
      font-size: 1.1rem;
    }
    small {
      outline: none !important;
      font-size: 1rem;
    }
  }
}
</style>
