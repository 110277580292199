<template>
  <div class="Pages w-100 h-100 py-5 mh-100">
    <div v-if="!isLoadingFauna">
      <FactsheetControls
        :pdf-title="pdfTitle"
        :download-disabled="!hasFactsheetData"
      >
        <div class="d-flex align-items-center m-3">
          <strong class="mr-2">Weergave:</strong>
          <InlineToggle
            :options="[{label: 'Dag', value: 'day'}, {label: 'Nacht', value: 'night'}, {label: 'Totaal', value: 'total'}]"
            @selected="handleSelected"
          />
        </div>
        <!--      <div class="d-flex align-items-center m-3">
        <strong class="mr-2">Jaartallen:</strong>
        <multiselect
          :value="selection.years"
          :options="selectOptionsYears"
          :multiple="true"
          :close-on-select="false"
          placeholder="Selecteer er max. 3"
          @select="addSelectedYear"
          @remove="removeSelectedYear"
        />
      </div>-->
        <div class="d-flex align-items-center m-3">
          <strong class="mr-2">Bedrijventerreinen:</strong>
          <multiselect
            :value="selection.parks"
            :options="selectOptionsParks"
            :multiple="true"
            :close-on-select="false"
            placeholder="Selecteer"
            :preselect-first="true"
            @select="addSelectedPark"
            @remove="removeSelectedPark"
          >
            <template #selection="{ values, isOpen }">
              <span
                v-if="values.length"
                v-show="! isOpen"
              >
                {{ values.length }} geselecteerd
              </span>
            </template>
          </multiselect>

          <b-button
            variant="outline-primary"
            size="sm"
            class="ml-2"
            @click="selectAllParks"
          >
            Selecteer alle
          </b-button>

          <b-button
            variant="outline-danger"
            size="sm"
            class="ml-2"
            @click="deselectAllParks"
          >
            Verwijder
          </b-button>
        </div>
      </FactsheetControls>

      <FactsheetPage
        v-if="hasFactsheetData && hasParksSelected"
        ref="factsheet"
        :landscape="true"
        :marginless="true"
        :title="factsheetTitle"
        :sub-title="factsheetSubTitle"
        :timestamp="timestamp"
        custom-logo="factsheet/GO-RAL-logo.png"
      >
        <!-- DEEL 1 -->
        <FactsheetSection
          title="Geregistreerde voertuigen"
          title-right="Bestelwagens, Trucks en Bakwagens"
          icon="factsheet/hashtag.png"
          icon-right="factsheet/e-truck.png"
        >
          <div class="PieChart-Wrapper pb-3">
            <PieChart
              title="Geregistreerde voertuigen"
              :labels="['Bestel', 'Truck', 'Bak']"
              :data="pieChartRegisteredVehicles"
            />
          </div>

          <div class="Text flex-shrink-1 pt-3">
            <div>Gevestigde bedrijven: {{ companyCount }}</div>
            <div>Bedrijven met logistieke voertuigen: {{ companyWithVehiclesCount }}</div>
            <div>Kavels: {{ lotCount }}</div>
            <div>Aantal onderstations: {{ substations }}</div>
          </div>

          <div class="Text flex-shrink-1 pt-3">
            <div>Beschikbare ruimte collectief: {{ spaceCollective }} km&sup2;</div>
            <div>Behoefte aan collectieve laadinfra: {{ demandCollective }} EV's</div>
            <div>Netbeheerder(s): {{ networkOperator }}</div>

            <span class="factsheet-more-info"> Meer info? <a href="mailto:GO-RAL@overijssel.nl">GO-RAL@overijssel.nl</a> </span>
          </div>
        </FactsheetSection>

        <!-- DEEL 2 -->
        <FactsheetSection
          title="Toekomstige behoefte laadpunten"
          title-right="Vermogensvraag (MW)"
          icon="factsheet/charging-station.png"
          icon-right="factsheet/max-power.png"
        >
          <div class="BarChart mb-1">
            <BarChart
              title="Toekomstige behoefte laadpunten"
              :labels="['Jaar', 'E-bestel', 'E-truck', 'E-bak']"
              :chart-data="chartChargepoints"
            />
          </div>

          <div class="BarChart mb-1">
            <BarChart
              title="Vermogensvraag"
              :labels="['Jaar', 'E-bestel', 'E-truck', 'E-bak']"
              :chart-data="chartPowerDemand"
            />
          </div>
        </FactsheetSection>

        <!-- DEEL 3 -->
        <div class="factsheet-section-wrapper factsheet-section-wrapper--1">
          <FactsheetSection
            title="Prognose logistiek GO-RAL*"
            title-right="Megawattuur per jaar"
            icon="factsheet/e-truck.png"
            icon-right="factsheet/flash-with-arrows.png"
          >
            <div class="BarChart mb-1">
              <BarChart
                title="Toename elektrische auto's"
                :labels="['Jaar', 'E-bestel', 'E-truck', 'E-bak']"
                :chart-data="chartExpectedVehicles"
              />
            </div>

            <div class="BarChart mb-1">
              <BarChart
                title="Megawattuur per jaar"
                :labels="['Jaar', 'E-bestel', 'E-truck', 'E-bak']"
                :chart-data="chartConsumptionDemand"
              />
            </div>
          </FactsheetSection>
        </div>

        <template #footer>
          <div class="factsheet-source">
            * {{ source }} <br class="mb-2">
          </div>
        </template>
      </FactsheetPage>

      <FactsheetPage
        v-else-if="hasFactsheetData && ! hasParksSelected"
        :landscape="true"
        :footer="false"
      >
        <h1
          class="text-center pt-5"
          v-text="'Selecteer op zijn minst een bedrijventerrein'"
        />
      </FactsheetPage>

      <FactsheetPage
        v-else
        :landscape="true"
        :footer="false"
      >
        <h1 class="text-center pt-5">
          Er is voor {{ municipalityLabel }} nog geen factsheet bedrijventerreinen beschikbaar
        </h1>
      </FactsheetPage>
    </div>
    <div
      v-else
      class="w-100 d-flex align-items-center justify-content-center"
    >
      <b-spinner
        class="mr-3"
        label="Loading..."
      />
      <span>Loading...</span>
    </div>

    <div
      v-if="isExporting"
      class="factsheet-export-control-panel"
    >
      <div class="factsheet-export-header">
        <h2>Factsheet Export</h2>
      </div>

      <div class="factsheet-export-body">
        Gemeente: {{ factsheetExportMixin_counterMunicipalitiesCurrent }} / {{ factsheetExportMixin_counterMunicipalitiesTotal }}

        <div class="factsheet-export-total-pdfs">
          Terrein: {{ factsheetExportMixin_counterParkCurrent }} / {{ factsheetExportMixin_counterParksTotalInMunicipality }}
        </div>

        <div class="factsheet-export-total-pdfs">
          Totaal PDFS: {{ factsheetExportMixin_counterPdfsTotal }}
        </div>

        <b-button
          variant="danger"
          class="ml-4"
          @click="$factsheetExportMixin_handleStop"
        >
          Stop
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { image } from '@/helpers/assets'

import InlineToggle from '@/components/common/InlineToggle'
import FactsheetPage from '@/components/factsheet/FactsheetPage'
import FactsheetSection from '@/components/factsheet/FactsheetSection'
import FactsheetControls from '@/components/factsheet/FactsheetControls'
import BarChart from '@/components/factsheet/bedrijventerreinen/BarChart'
import PieChart from '@/components/factsheet/bedrijventerreinen/PieChart'

import Multiselect from 'vue-multiselect'

import { labelByCode } from '@/services/municipalities'
import { mapMutations, mapGetters } from 'vuex'
import { checkStatus, returnJson } from '@/helpers/api'
import { computeFactsheetBedrijventerreinenData } from '@/helpers/bedrijventerreinen'

import factsheetExportMixin from '@/mixins/factsheet/factsheet-export-mixin'

export default {
  components: {
    InlineToggle,
    Multiselect,
    FactsheetPage,
    FactsheetSection,
    FactsheetControls,
    BarChart,
    PieChart,
  },
  filters: {
    roundedBase: function (value, display) {
      if (!value) return ''

      if (display === 'detailed') {
        return value.toFixed(1).replace('.', ',')
      }

      return Math.round(value)
    },
  },
  mixins: [factsheetExportMixin],
  data() {
    return {
      /**
       * The displayed factsheet layout. Can be 'day', 'night' or 'total'
       * todo:: refactor display to: `period`
       */
      period: 'day',
      model: 'goral_bedrijventerreinen',
      isLoadingFauna: false,
      data: [],
      selection: {
        years: [],
        parks: [], // todo:: remove from here, test data
      },
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('deployment', [
      'DeploymentCode',
      'getPrognoseModels',
    ]),
    logoGORAL() {
      return image({ name: 'factsheet/GO-RAL-logo.png' })
    },
    hasParksSelected() {
      return this.selection.parks.length > 0
    },
    hasMultipleParksSelected() {
      return this.selection.parks.length > 1
    },
    factsheetTitle() {
      if (this.hasMultipleParksSelected) {
        return `Factsheet ${this.municipalityLabel}`
      }

      return `Factsheet ${this.selection.parks[0]}`
    },
    factsheetSubTitle() {
      if (this.selection.parks.length === this.parks.length) {
        return 'Alle bedrijventerreinen'
      }

      if (this.hasMultipleParksSelected) {
        let MAX_PARKS_TO_DISPLAY = 3

        return this.selection.parks.length > MAX_PARKS_TO_DISPLAY
            ? this.selection.parks
              .slice(0, MAX_PARKS_TO_DISPLAY)
              .join(', ')
              + ',...'
            : this.selection.parks.join(', ')
      }

      return null
    },
    pdfTitle() {
      const PERIOD_LABELS = {
        day: 'Dag',
        night: 'Nacht',
        total: 'Totaal',
      }

      const layout = PERIOD_LABELS[this.period]
      return `Factsheet ${this.municipalityLabel} (${layout}) - ${this.selection.parks[0]}.pdf`
    },
    // todo:: if there are different models for the factsheet, this is how to do it
    /*modelOptions() {
      const availableModels = Object.keys(this.data?.factsheets || [])

      return this.getPrognoseModels
          .filter(model => availableModels.includes(model.toLowerCase()))
          .map(model => ({ label: model, value: model.toLowerCase() }))
    },*/
    municipalityLabel() {
      return labelByCode({ code: this.getActiveMunicipality })
    },
    forecastYearNumbers() {
      const defaultYears = [
        '2025',
        '2030',
        '2035',
      ]

      if (this.selection.years.length === 0) {
        return defaultYears
      }

      // how many years are necessary to be filled up with, and which are free to use
      const countFillupYears =  defaultYears.length - this.selection.years.length
      const availableYears = defaultYears.filter(year => this.selection.years.includes(year) === false)

      const fillupYears = []
      for (let i = 0; i < countFillupYears; i++) {
        fillupYears.push(availableYears.shift())
      }

      return this.selection.years
        .concat(fillupYears)
        .sort((a, b) => a - b)
        .map(year => year.toString())
    },
    hasFactsheetData() {
      return !! this.data?.factsheets?.[this.model]
    },
    factsheetData() {
      if (! this.hasFactsheetData) return

      // todo:: make `this.model` reactive if needed
      return this.data.factsheets[this.model]
    },
    timestamp() {
      return this.factsheetData.timestamp || ''
    },
    source() {
      return this.factsheetData.source || 'Geen bron beschikbaar'
    },
    parks() {
      if (! this.hasFactsheetData) return []

      return Object.values(this.factsheetData.bedrijventerreinen)
    },
    factsheetDataAggregated() {
      return computeFactsheetBedrijventerreinenData({
        period: this.period,
        years: this.forecastYearNumbers,
        selectedParks: this.parks.filter(park => this.selection.parks.includes(park.label)),
      })
    },
    /**
     * Charts data
     */
    chartExpectedVehicles() {
      return this.factsheetDataAggregated.expectedVehicles
    },
    chartChargepoints() {
      return this.factsheetDataAggregated.chargepoints
    },
    chartPowerDemand() {
      return this.factsheetDataAggregated.mw_per_year
    },
    chartConsumptionDemand() {
      return this.factsheetDataAggregated.mwh_per_year
    },
    companyCount() {
      return this.factsheetDataAggregated.companyCount
    },
    companyWithVehiclesCount() {
      return this.factsheetDataAggregated.companyWithVehiclesCount
    },
    pieChartRegisteredVehicles() {
      return this.factsheetDataAggregated.registeredVehicles
    },
    lotCount() {
      return this.factsheetDataAggregated.lotCount
    },
    substations() {
      return this.factsheetDataAggregated.substations
    },
    networkOperator() {
      return this.factsheetDataAggregated.networkOperator
    },
    spaceCollective() {
      return this.factsheetDataAggregated.spaceCollective
    },
    demandCollective() {
      return this.factsheetDataAggregated.demandCollective
    },
    selectOptionsYears() {
      return [
        '2025',
        '2030',
        '2035',
      ]
    },
    selectOptionsParks() {
      return this.parks.map(park => park.label)
    },
  },
  watch: {
    getActiveMunicipality() {
      this.fetchFactsheetData()
    },
    modelOptions(next) {
      if (! next.length > 0) {
        return
      }

      this.model = next[0]?.value
    },
  },
  created() {
    this.fetchFactsheetData()
  },
  methods: {
    ...mapMutations('app', ['setAppReady']),
    image,
    /**
     * fetch the data
     */
    async fetchFactsheetData() {
      this.isLoadingFauna = true

      // RESET
      this.selection.parks = []
      this.selection.parks = []
      this.data = []

      try {
        const token = await this.$auth.getTokenSilently()
        const response = await fetch('/api/factsheetload', {
          method: 'POST',
          headers: {
            authorization: 'Bearer ' + token,
          },
          body: JSON.stringify({ code: this.getActiveMunicipality, deploymentCode: this.DeploymentCode }),
        })
          .then(await checkStatus)
          .then(returnJson)

        this.data = response.data
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Error: ', e)
      }

      this.setAppReady({ value: true })
      this.isLoadingFauna = false
    },
    /**
     * handle year selection, max 3 years
     */
    addSelectedYear(year) {
      if (this.selection.years.length < 3) {
        this.selection.years.push(year)
      }
    },
    removeSelectedYear(year) {
      if (this.selection.years.includes(year)) {
        this.selection.years = this.selection.years.filter(entry => entry !== year)
      }
    },
    /**
     * handle park selection
     */
    addSelectedPark(park) {
      if (this.selection.parks.includes(park)) return

      this.selection.parks.push(park)
    },
    removeSelectedPark(park) {
      if (this.selection.parks.includes(park)) {
        this.selection.parks = this.selection.parks.filter(entry => entry !== park)
      }
    },
    selectAllParks() {
      this.selection.parks = this.parks.map(park => park.label)
    },
    deselectAllParks() {
      this.selection.parks = []
    },
    /**
     * Handle the period (day/night/total) selection
     */
    handleSelected({ selected }) {
      this.period = selected
    },
  },
}
</script>

<style lang="scss">
.Pages {
  position: relative;
  overflow-y: scroll;
}

.Factsheet__Main {
  table {
    width: 100%;

    td {
      padding: 6px 0
    }
  }

  .BarChart {
    margin-top: -5px;
  }

  .primary {
    width: 14px;
    height: 14px;
    background: var(--primary);
  }

  .secondary {
    width: 15px;
    height: 15px;
    background: var(--secondary);
  }

  .registered-vehicles-text {
    margin-left: -50px;
    z-index: 1;
    position: relative;
  }

  .multiselect {
    width: 200px;

    &--active {
      position: absolute;
      width: 500px
    }
  }

  .factsheet-section-wrapper--1 {
    margin-bottom: 20px;
  }

  .PieChart-Wrapper {
    width: 310px;
    display: flex;
    justify-content: center;
  }

  .PieChart {
    margin-top: -20px;
    margin-bottom: -20px;
    height: 200px;
    overflow: hidden;
    z-index: 0;
  }

  .factsheet-more-info {
    display: inline-block;
    margin-top: 3rem;
    padding-top: .5rem;
    border-top: 1px solid gray;
    width: 100%;
    text-align: right;
  }

  .Factsheet__Footer {
    .Footer {
      &__Logo {
        height: auto;
        max-width: 170px;
      }
      &__Text {
        height: auto;

        > div.factsheet-source {
          width: 704.8px;
        }
      }
    }
  }

  .Text--fallback {
    margin-top: 2.4rem;
  }
}

.factsheet-export {
  &-control-panel {
    position: fixed;
    z-index: 1000;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);

    width: 700px;
    height: 80px;
    padding: 1rem 0 .5rem;
    box-sizing: content-box;

    background: white;
    border: 1px solid var(--primary);
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 #dfe2e5;
  }

  &-header {
    display: flex;
    justify-content: center;
  }

  &-body {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &-total-pdfs {
    margin-left: 20px;
  }
}
</style>
