<template>
  <div :class="`chargingpoint-group ${layer.config.statusGroup} ${!isLegend && 'not-legend'}`">
    <div class="d-flex justify-content-between">
      <span
        v-for="(accessibilityType, accessibilityTypeKey, accessibilityTypeIndex) in chargerAccessibilityTypes"
        :key="accessibilityTypeKey"
      >
        {{ accessibilityType }}
        <div
          v-for="(chargerCategory, chargerCategoryIndex) in chargerCategories"
          :key="`category-${chargerCategoryIndex}`"
          class="icon-category"
        >
          <span
            v-if="accessibilityTypeIndex === 0"
            class="category-label"
          >{{ chargerCategory }}</span>
          <SvgLegendCategoryIcon
            :icon-folder="iconFolders[chargerCategoryIndex]"
            :charger-type="accessibilityTypeKey"
            :icon-type="layer.config.iconType"
            :layer="bindLayerToIcon({ accessibilityTypeKey, chargerType: iconFolders[chargerCategoryIndex] })"
            :is-legend="isLegend"
            :selected-status="selectedStatus"
            @handleLayerVisibility="updateLayerVisibility"
          />
        </div>
      </span>
    </div>
  </div>
</template>
<script>
import { chargerAccessibilityTypes } from '../../../shared/services/statusTranslations'
import { CHARGINGPOINT_CAPACITY  } from '../../../shared/valueholders/chargingpoint-statuses'
import SvgLegendCategoryIcon from '@/components/common/SvgLegendCategoryIcon'

export default {
  name: 'ChargingpointsLayerGroup',
  components: {
    SvgLegendCategoryIcon,
  },
  props: {
    layer: {
      type: Object,
      required: true,
    },
    groupLayers: {
      type: Array,
      default: () => [],
    },
    isLegend: {
      type: Boolean,
      default: true,
    },
    selectedStatus: {
      type: String,
      default: '',
    },
    groupLayers: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      chargerCategories: ['Regulier', 'Snelladen', 'Laadplein'],
      iconFolders: Object.values(CHARGINGPOINT_CAPACITY),
      chargerAccessibilityTypes,
    }
  },
  methods: {
    // Until all layers are made //
    bindLayerToIcon ({ accessibilityTypeKey, chargerType }) {
      return this.groupLayers.find(layer =>
                layer.config.accessibility === accessibilityTypeKey &&
                layer.config.capacity === chargerType)
    },
    updateLayerVisibility ({ layer }) {
      if (layer) {
        this.$emit('input', { value: !layer.visible, layer })
      }
    },
  },
}
</script>
<style lang="scss">
  .chargingpoint-group {
    color: var(--primary-text);
    font-size: 12px;
    > div {
      flex: 1;
      margin-left: 5em;
      > span {
        text-align: center;
      }
    }

    .icon-category {
      position: relative;
      text-align: center;
      padding: 0.8em 0;
      .category-label {
        position: absolute;
        left: -5em;
        top: 50%;
        transform: translateY(-50%);
      }
      svg {
        cursor: pointer;
        width: 25px;
        height: 25px;
        &.point {
          height: 30px;
        }
      }
    }
    &.not-legend {
      transform: scale(0.9);
      margin-bottom: 1em;
      .icon-category {
        padding: 0.5em 0;
      }
      > div {
        margin-left: 4em;
      }
      &.alert {
        padding: 0;
        border: none;
      }
    }
  }
  .mapboxgl-popup {
    min-width: 260px !important;
    width: 260px;
  }

</style>
