<template>
  <div class="Page--Password h-100 d-flex flex-column align-items-center justify-content-center">
    <b-card
      v-if="loaded"
      tag="article"
      :img-src="image"
      class="mt-5"
      style="max-width: 300px;"
    >
      <p>
        Beste {{ username }},
      </p>
      <p>
        Uw tijdelijke wachtwoord is 3x gebruikt of meer dan 7 dagen oud en daardoor verlopen. U moet daarom een nieuw wachtwoord aanmaken.
      </p>
      <p>
        Dit nieuwe wachtwoord zal niet verlopen. U kunt uw nieuwe wachtwoord later nog wel wijzigen.
      </p>
      <PasswordBox class="mt-4" />
    </b-card>

    <a
      v-if="loaded"
      class="text-muted mt-3"
      style="cursor: pointer"
      @click="logout"
    >
      Uitloggen
    </a>
    <div
      v-else
      class="h-100 w-100 d-flex flex-column justify-content-center align-items-center"
    >
      <!-- TODO: Make this looking nicer & offer a back button to Home / Contact address -->
      <div class="d-flex align-items-center">
        <b-spinner
          class="mr-3"
          label="Loading..."
        />
        <span>Loading...</span>
      </div>
      <a
        class="text-muted mt-5"
        style="cursor: pointer"
        @click="logout"
      >
        Uitloggen
      </a>
    </div>
  </div>
</template>

<script>

import { image } from '@/helpers/assets'
import PasswordBox from '@/components/profile/PasswordBox'

export default {
  name: 'EnforcePassword',
  components: { PasswordBox },
  data() {
    return {
      image: image({ name: 'evtools.png' }),
    }
  },
  computed: {
    loaded() {
      return this.$auth.isAuthenticated
    },

    username() {
      return this.$auth.user.name
    },
  },
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
  },
}
</script>

<style lang="scss">


.Page--Password article {
  width: calc(450px + 2.5rem);
  max-width: calc(450px + 2.5rem);

  .card-img {
    background-color: var(--primary);
    padding: 0.5rem 0;
  }
}
</style>
