<template>
  <div
    v-if="noCommentsMessage"
    class="NoParticipationComments"
    v-text="noCommentsMessage"
  />
  <div v-else>
    <header class="ParticipationHeader">
      <Feedback
        v-if="!isParticipationEnabled"
        class="mb-3"
        :dismissible="false"
        :feedback="{ variant: 'warning' }"
      >
        <strong>Dit is demo data</strong> <br>
        <span>Bij interesse neem contact met ons op via <MailtoSupport /></span>
      </Feedback>
      <Feedback
        v-if="notASuggestionMessage"
        class="mb-3"
        :dismissible="false"
        :feedback="{ variant: 'warning' }"
      >
        <span v-text="notASuggestionMessage" />
      </Feedback>
      <div class="ParticipatieSentiment">
        <div class="ParticipatieSentimentText">
          <div v-text="`${negativeCommentsCount} negatieve reactie${negativeCommentsCount === 1 ? '' : 's'}`" />
          <div v-text="`${positiveCommentsCount} positieve reactie${positiveCommentsCount === 1 ? '' : 's'}`" />
        </div>
        <div
          v-if="!commentsCountWithSentiment"
          class="ParticipatieSentimentIndicator NoComments"
        />
        <div
          v-else
          class="ParticipatieSentimentIndicator"
        >
          <span
            v-if="negativeCommentsCount"
            class="pl-2"
            v-text="`${Math.round(negativeCommentsRelative * 100)}%`"
          />
          <div
            v-if="positiveCommentsCount"
            class="Positive pr-2"
            :class="{ 'AllPositive': !negativeCommentsCount }"
            :style="`width: ${positiveCommentsCount / commentsCountWithSentiment * 100}%`"
            v-text="`${Math.round(positiveCommentsRelative * 100)}%`"
          />
        </div>
      </div>

      <div
        class="ParticipationCommentsCount"
        v-text="`${commentsCount} reactie${commentsCount === 1 ? '' : 's'}`"
      />
    </header>
    <ul class="ParticipationComments">
      <ParticipationCommentsItem
        v-for="comment in participationComments"
        :key="comment.ref['@ref'].id"
        :comment="comment"
        :is-demo="!isParticipationEnabled"
        @update-status="status => handleUpdateStatus({ comment, status})"
      />
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { PARTICIPATION_SENTIMENT } from '@/../shared/valueholders/participation-sentiment'
import fakeParticipationComments from '@/data/fakeParticipationComments'

import Feedback from '@/components/form/Feedback'
import MailtoSupport from '@/components/common/MailtoSupport'
import ParticipationCommentsItem from './ParticipationCommentsItem'

export default {
  name: 'ParticipationComments',
  components: {
    Feedback,
    MailtoSupport,
    ParticipationCommentsItem,
  },
  props: {
    chargingpoint: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('config', [
      'isParticipationEnabled',
    ]),
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('planmode', [
      'getParticipationCommentsBySelectedChargingpoint',
    ]),
    notASuggestionMessage() {
      const notASuggestionMessages = {
        'rejected': 'Deze locatie is al afgewezen',
        'in-progress': 'Deze locatie is al in voorbereiding',
        'realized': 'Deze locatie is al gerealiseerd',
        'realized-semipublic': 'Deze locatie is al gerealiseerd',
        'fastcharger-realized-semipublic': 'Deze snellader is al gerealiseerd',
        'fastcharger-rejected': 'Deze snellader is al afgewezen',
        'fastcharger-realized': 'Deze snellader is al gerealiseerd',
        'charging-hub-rejected': 'Dit laadplein is al afgewezen',
      }

      const status = this.chargingpoint.data.properties.status
      const notASuggestionMessage = notASuggestionMessages[status]

      if (notASuggestionMessage) {
        return notASuggestionMessage
      }

      return false
    },
    noCommentsMessage() {
      if (this.isLoading) {
        return 'Reacties worden geladen...'
      }

      if (this.participationComments.length) {
        return false
      }

      const statusMessages = {
        'alert': 'Op deze locatie kan niet gereageerd worden',
        'rejected': 'Op afgewezen locaties kan niet gereageerd worden',
        'in-progress': 'Op locaties in voorbereiding kan niet gereageerd worden',
        'realized': 'Op bestaande locaties kan niet gereageerd worden',
        'realized-private': 'Op bestaande locaties kan niet gereageerd worden',
        'fastcharger-realized-private': 'Op bestaande snelladers kan niet gereageerd worden',
        'fastcharger-rejected': 'Op afgewezen snelladers kan niet gereageerd worden',
        'fastcharger-realized': 'Op bestaande snelladers kan niet gereageerd worden',
        'charging-hub-rejected': 'Op afgewezen laadpleinen kan niet gereageerd worden',
      }

      const status = this.chargingpoint.data.properties.status
      const statusMessage = statusMessages[status]

      if (statusMessage) {
        return statusMessage
      }

      return 'Deze locatie heeft nog geen reacties'
    },
    commentsCount() {
      return this.participationComments.length
    },
    positiveCommentsCount() {
      return this.participationComments.filter(({ data }) => data.sentiment === PARTICIPATION_SENTIMENT.POSITIVE).length
    },
    negativeCommentsCount() {
      return this.participationComments.filter(({ data }) => data.sentiment === PARTICIPATION_SENTIMENT.NEGATIVE).length
    },
    commentsCountWithSentiment() {
      return this.positiveCommentsCount + this.negativeCommentsCount
    },
    positiveCommentsRelative() {
      return this.positiveCommentsCount / this.commentsCountWithSentiment
    },
    negativeCommentsRelative() {
      return this.negativeCommentsCount / this.commentsCountWithSentiment
    },
    participationComments() {
      const comments = this.isParticipationEnabled
        ? this.getParticipationCommentsBySelectedChargingpoint
        : fakeParticipationComments

      return comments
        .filter(comment => !comment.data.deleted_at)
        .sort((a, b) => b.data.created_at - a.data.created_at)
    },
  },
  watch: {
    chargingpoint: {
      immediate: true,
      handler () {
        if (this.isParticipationEnabled) {
          this.fetchParticipationComments()
        }
      },
    },
  },
  methods: {
    ...mapActions('planmode', [
      'addOrUpdateComment',
      'addOrUpdateChargingPoint',
      'deleteComment',
      'fetchParticipationCommentsByChargingpointUuid',
      'updateCommentStatus',
    ]),
    async fetchParticipationComments() {
      this.isLoading = true
      await this.fetchParticipationCommentsByChargingpointUuid({
        code: this.getActiveMunicipality,
        chargingpointUuid: this.chargingpoint.data.uuid,
      })
      this.isLoading = false
    },
    toLocalDate({ timestamp }) {
      const format = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }

      let date = new Date(timestamp)

      return date.toLocaleString([], format)
    },
    handleUpdateStatus({ comment, status }) {
      this.updateCommentStatus({
        comment,
        status,
      })
    },
  },
}
</script>

<style lang="scss">


.NoParticipationComments {
  padding: 2rem 0;
  text-align: center;
  color: gray;
}

.ParticipationHeader {
  border-bottom: 1px solid var(--border-color);

  .ParticipationCommentsCount {
    padding: 0.5rem 0;
    margin-right: 0.5rem;
    font-size: 0.9rem;
    text-align: right;
  }

  .ParticipatieSentiment {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border-color);

    .ParticipatieSentimentText {
      display: flex;
      justify-content: space-between;
      color: dimgray;
      font-size: 0.9rem;
    }

    .ParticipatieSentimentIndicator {
      display: flex;
      justify-content: space-between;
      height: 1.4rem;
      width: 100%;
      background-color: #dc3545;
      border-radius: 0.25rem;
      color: white;
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1.4rem;

      &.NoComments {
        background-color: lightgray;
      }

      .Positive {
        height: 100%;
        background-color: #5d8c30;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        text-align: right;

        &.AllPositive {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
      }
    }
  }
}

.ParticipationComments {
  padding-left: 0;
  list-style: none;
}
</style>
