/**
 * Import Dependency
 */

/**
 * Import API
 */

import { checkStatus, returnJson } from '@/helpers/api'
import Vue from 'vue'
import { Bugfender } from '@bugfender/sdk'

/**
 * Declare Variable
 */
const state = {
  locationData: [],
  selectedLocation: null,
}

const MAX_RETRIES = 3

const getters = {
  getSelectedLocationData: (state) => state.locationData.find(location => location.locationUuid === state.selectedLocation),
  getFilteredLocationUuids: (state) => ({ key, unit, from, to }) => {
    if (unit?.trim() === '%') {
      from = from / 100
      to = to / 100
    }

    return state.locationData
      .filter(
        entry => Object.values(entry.aggregated)
          .filter((month) => month[key] >= from && month[key] <= to)
          .length > 0,
      )
      .map(connectionPoint => connectionPoint.locationUuid)
  },
}

const actions = {
  async fetchConnectionPointsByUuid({ commit }, { uuid, code }) {
    const token = await this.$auth.getTokenSilently()

    return await fetch(`${process.env.VUE_APP_MONITORING_API_URL}/location`, {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + token,
        'X-From': 'maps',
      },
      body: JSON.stringify({ uuid, code }),
    })
      .then(await checkStatus)
      .then(returnJson)
      .then(response => {
        if (! response.data) {
          return
        }

        response.data.forEach(data => commit('upsertLocationData', { data }))
      })
      .catch((e) => {
        Bugfender.error('fetchConnectionPointsByUuid: ', e)

        Vue.notify({
          type: 'error',
          title: 'Er is iets misgegaan!',
          text: 'Monitoring data kon niet worden opgehaald',
        })
      })
  },
  async fetchAllUsageReports({ commit, dispatch }, { code, retry = 1 }) {
    const token = await this.$auth.getTokenSilently()

    try {
      return await fetch(`${process.env.VUE_APP_MONITORING_API_URL}/municipality`, {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
          'X-From': 'maps',
        },
        body: JSON.stringify({ code }),
      })
        .then(await checkStatus)
        .then(returnJson)
        .then(response => {
          if (!response.data) {
            return
          }

          commit('setLocationData', { data: response.data })

          // If response is empty the user most likely does not have access to monitoring
          if (response.data.length > 0) {
            Vue.notify({
              type: 'success',
              title: 'Klaar!',
              text: 'Monitoring data is opgehaald',
            })
          }
        })
    } catch (e) {
      retry++

      Vue.notify({
        type: 'error',
        title: 'Er is iets misgegaan!',
        text: 'Monitoring data kon niet worden opgehaald',
      })

      Bugfender.error('fetchAllUsageReports: ', e)

      if (retry > MAX_RETRIES) return

      return dispatch('fetchAllUsageReports', { code, retry })
    }
  },
  selectLocation({ commit }, { location }) {
    commit('setSelectedLocation', { location })
  },
  deselectLocation({ commit }) {
    commit('setSelectedLocation', { location: null })
  },
}

const mutations = {
  upsertLocationData: function (state, { data }) {
    let index = state.locationData.findIndex(stateLocationData => data.locationUuid === stateLocationData.locationUuid)
    if (index === -1) {
      state.locationData.push(data)
      return
    }

    state.locationData[index] = data
  },
  setLocationData: function (state, { data }) {
    state.locationData = data
  },
  setSelectedLocation(state, { location }) {
    state.selectedLocation = location
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
