<template>
  <b-list-group
    class="Tabs"
    horizontal
  >
    <b-list-group-item
      v-for="(tab, index) in tabs"
      :key="index"
      class="px-4"
      :active="tab.id === activeTab"
      button
      @click="handleClick({ tab })"
    >
      {{ tab.label }}
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    active: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeTab: '',
    }
  },
  watch: {
    active() {
      this.activeTab = this.active
    },
    activeTab(id) {
      this.$emit('selected', { id })
    },
  },
  created() {
    if (this.active) {
      this.activeTab = this.active
    } else {
      this.activeTab = this.tabs[0].id
    }
  },
  methods: {
    handleClick({ tab }) {
      this.activeTab = tab.id
    },
  },
}
</script>

<style lang="scss">

.Tabs {
  .list-group-item {
    flex-shrink: 1;
    font-size: 1.25rem;
    background: var(--grey);
    border-color: var(--grey);
    color: var(--grey-darker-40);
    outline: none !important;
    border-radius: 0 !important;
  }
  .list-group-item {
    &:hover,
    &:focus {
      color: var(--grey-darker-40);
      background: var(--grey-darker-10);
      border-color: var(--grey-darker-10);
    }
    
  }
  .list-group-item.active {
    background: white;
    border-color: white;
    color: #495057;
    border-radius: 0;
  }
}
</style>
