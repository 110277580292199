<template>
  <div class="LayerConfigPanel">
    <div class="d-flex">
      <aside class="LayerConfigPanel__Aside d-flex flex-column flex-shrink-0 mr-3">
        <h3 class="LayerConfigPanel__Title">
          Kaartlagen
        </h3>
        <div class="w-75 mt-3 flex-grow-1 d-flex flex-column justify-content-between">
          <div>
            <p>
              Tip: Kaartlagen zijn te herkennen aan de gemeente code. Deze code staat naast de 'opslaan' knop.
            </p>
            <p>
              Tip: Iedere kaartlaag heeft een standaard naam.
            </p>

            <b-button
              class="mt-3"
              @click="handleAutoMatch"
            >
              Automatisch matchen
            </b-button>
            <p class="mt-3">
              Gebruik deze knop om alle lege velden automatisch te laten matchen met de in Mapbox beschikbare kaartlagen.
            </p>
          </div>

          <div>
            <p class="mt-5 font-weight-bold">
              Betekenis van de status opties
            </p>
            <CollapsableSegment
              class="mt-3"
              label="Primair"
              :visible="false"
            >
              Deze lagen staan boven aan de legenda, en de lagen zijn zichtbaar bij het inladen van de kaart.
            </CollapsableSegment>
            <CollapsableSegment
              class="mt-3"
              label="Secundair"
              :visible="false"
            >
              Deze lagen zijn verwerkt in het extra uitklap deel van de legenda, en de lagen zijn bij het inladen van de kaart niet zichtbaar.
            </CollapsableSegment>
            <CollapsableSegment
              class="mt-3"
              label="Verborgen"
              :visible="false"
            >
              Deze laag is totaal onbeschikbaar en ook niet zichtbaar in de legenda.
            </CollapsableSegment>
            <CollapsableSegment
              class="mt-3"
              label="Promo"
              :visible="false"
            >
              Gebruikers kunnen deze laag niet inschakelen, maar hij is wel zichtbaar in het extra uitklap deel van de legenda.
            </CollapsableSegment>
          </div>
        </div>
      </aside>

      <div class="LayerConfigPanel__Main flex-grow-1 flex-shrink-1">
        <div class="d-flex align-items-center">
          <span class="PrognosePanel__Label mr-3" />
          <div class="LayerConfigPanel__Status flex-shrink-0 mr-3">
            Status
          </div>
          <div class="flex-grow-1 flex-shrink-0 mr-3">
            Mapbox Referentie
          </div>
        </div>

        <div
          v-for="layer in layers"
          :key="layer.id"
          class="d-flex align-items-center mt-4 flex-shrink-1"
        >
          <span class="LayerConfigPanel__Label mr-3">
            {{ layer.label }}
          </span>
          <span class="LayerConfigPanel__Status flex-shrink-0 mr-3">
            <form @submit="() => null">
              <SimpleSelect
                :disabled="layer.url === '' || !layer.url || (layer.required && layer.status !== 'hidden')"
                :value="layer.status"
                v-bind="statusfieldconfig"
                @input="status => updateStatus(layer, status)"
              />
            </form>
          </span>
          <vSelect
            class="vSelect flex-shrink-0 mr-3"
            :options="mapboxOptions"
            :clearable="!layer.required"
            :value="layer.source"
            @input="source => updateSource(layer, source)"
          />

          <template v-if="layer.tooltip">
            <SvgIcon
              :id="layer.id"
              class="SvgIcon__Info"
              icon="info-circle-regular"
            />
            <b-tooltip
              :target="layer.id"
              triggers="hover"
            >
              {{ layer.tooltip }}
            </b-tooltip>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import vSelect from '@/components/form/vSelect'
import SimpleSelect from '@/components/form/SimpleSelect'
import SvgIcon from '@/components/common/SvgIcon'
import CollapsableSegment from '@/components/common/CollapsableSegment'

import { layers as layerDetails } from '@/data/layerDetails'
import { configLayers, simplifyTilesets } from '@/services/configGenerator'
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  name: 'LayerConfigPanel',
  components: {
    vSelect,
    SimpleSelect,
    SvgIcon,
    CollapsableSegment,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statusfieldconfig: {
        options: [{
          text: 'Primair',
          value: 'primary',
        }, {
          text: 'Secundair',
          value: 'secondary',
        }, {
          text: 'Verborgen',
          value: 'hidden',
        }, {
          text: 'Promo',
          value: 'promo',
          disabled: true,
        }],
      },
    }
  },
  computed: {
    ...mapGetters('deployment', [
      'DeploymentCode',
    ]),
    ...mapGetters('tilesets', [
      'tilesets',
    ]),
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    mapboxOptions() {
      return this.tilesets.map(tileset => tileset.name)
    },
    layers() {
      /**
       * Go over the list of layers specified in the source code
       *  If layer config data exists, we load the layer config data
       *  If not, we add default values
       */
      return layerDetails
        .slice()
        .filter(layer => layer.type === 'results' || layer.type === 'context' || (layer.type === 'forecast' && layer.config.isConfigurable))
        .map(layer => {
          const layerData = this.value[layer.id]
            ? this.value[layer.id]
            : {
              source: '',
              url: '',
              status: 'hidden',
            }
          return Object.assign({}, layer, layerData)
        })
    },
  },
  methods: {
    /**
     * Get the label of a layer based on the layer id (slug)
     */
    idToLabel({ id }) {
      let layer = layerDetails.find(layer => layer.id === id)
      return layer ? layer.label : 'Onbekende kaartlaag'
    },
    /**
     * Handle a change in the MapBox reference field of a specific layer
     */
    updateSource(layer, source) {
      let layers = Object.assign(this.value)

      // Source connection was deleted
      if (!source) {
        layers[layer.id] = Object.assign(layers[layer.id], {
          source: '',
          url: '',
          status: 'hidden',
        })

      // A source connection was set
      } else {
        let tileset = this.tilesets.find(tileset => tileset.name === source)
        let details = layerDetails.find(details => details.id === layer.id)

        if (tileset) {
          if (layers[layer.id]) {
            Vue.set(
              layers,
              layer.id,
              Object.assign(layers[layer.id], {
                source: tileset.name,
                url: `mapbox://${tileset.id}`,
                status: details ? details.status : layers[layer.id].status,
              }),
            )
          } else {
            Vue.set(
              layers,
              layer.id,
              {
                source: tileset.name,
                url: `mapbox://${tileset.id}`,
                status: details ? details.status : 'secondary',
              },
            )
          }
        }
      }

      this.$emit('input', layers)
    },

    /**
     * Update the status of a single layer
     */
    updateStatus(layer, status) {
      let layers = Object.assign(this.value)
      if (layers[layer.id]) {
        layers[layer.id] = Object.assign(layers[layer.id], {
          status,
        })
      } else {
        layers[layer.id] = {
          source: '',
          url: '',
          status: 'hidden',
        }
      }

      this.$emit('input', layers)
    },

    /**
     * Try to automatically match any empty layers with the available tilesets
     */
    handleAutoMatch() {

      let autoLayers = configLayers({
        deployment: this.DeploymentCode,
        code: this.getActiveMunicipality,
        tilesets: simplifyTilesets({ tilesets: this.tilesets }),
      })

      let layers = Object.keys(this.value).reduce((result, key) => {
        result[key] = this.value[key].source !== ''
          ? this.value[key]
          : (autoLayers[key] || {
            source: '',
            url: '',
            status: 'hidden',
          })
        return result
      }, {})

      this.$emit('input', layers)
    },
  },
}
</script>
<style lang="scss">
.LayerConfigPanel {

  &__Aside {
    width: 200px;

    @media (min-width: 1280px) {
      width: 275px;
    }
  }

  &__Main {
    font-size: 1.25rem;

    .form-control {
      width: 100px;
      font-size: 1.25rem;
    }
  }
  &__Label {
    flex-shrink: 0;
    width: 125px;

    @media (min-width: 1440px) {
      width: 175px;
    }
  }
  .SvgIcon {
    flex-shrink: 0;
    font-size: 1.75rem;


    &__Info {
      display: none;
      @media (min-width: 1280px) {
        display: inline-block;
      }
    }

  }
  &__Status {
    min-width: 100px;
  }

  .vSelect.vSelect {
    min-width: 350px;
    flex-grow: 1;
  }


  &__Info {
    strong {
      display: inline-block;
      min-width: 120px;
    }
  }

}
</style>
