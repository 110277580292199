const ATTACHMENT_ID = {
  TOP: 'top',
  SIDE: 'side',
  BULK: 'bulk',
}

const validAttachmentIDs = Object.values(ATTACHMENT_ID).filter(id => id !== ATTACHMENT_ID.BULK)

const attachmentIDToDescription = ({ id }) => {
  let options = {
    top: 'Bovenaanzicht',
    side: 'Zijaanzicht',
  }

  return options[id] || 'Onbekend'
}

module.exports = {
  ATTACHMENT_ID,
  validAttachmentIDs,
  attachmentIDToDescription,
}
