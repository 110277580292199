<template>
  <NotionRenderer
    v-if="page"
    :block-map="page"
    :map-image-url="imageFormatter"
    full-page
  />
</template>

<script>
import { NotionRenderer } from 'vue-notion'

export default {
  components: {
    NotionRenderer,
  },
  data () {
    return {
      page: null,
    }
  },
  watch: {
    '$route.params.slug': {
      immediate: true,
      async handler (slug) {        
        if (slug) {
          // Fetch the manual page
          const token = await this.$auth.getTokenSilently()
          const response = await fetch('/api/manual', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ slug }),
          })
          
          // Store the page
          const { page } = await response.json()
          this.page = page
        }
      },
    },
  },
  methods: {
    imageFormatter(imageUrl, block) {
      const encodedImageUrl = encodeURIComponent(imageUrl)
      const wrappedEncodedImageUrl = encodeURIComponent(`https://www.notion.so/image/${encodedImageUrl}?table=${block.value.parent_table}&id=${block.value.id}`)
      
      return `/api/manual-image?image=${wrappedEncodedImageUrl}`
    }
  }
}
</script>

<style lang="scss">
.notion {
  max-width: 50rem;
  margin: 0 auto;
  padding: 2rem 1.5rem 7.5rem 1.5rem; // The large bottom padding is required to ensure all long pages are fully visible and there is some space leftover

  &-page-cover {
    width: 100%;
  }

  &-page-icon-cover {
    display: none;
  }

  &-title {
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 1.1;
    margin-bottom: 1rem;
  }
  
  .notion-asset-wrapper {
    div {
      padding-bottom: 0 !important; // Required, otherwise there will be whitespace with the height of the image below the image itself
      
      img {
        max-width: 100%;
      }
    }
  }
}
</style>
