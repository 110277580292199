<template>
  <div class="UploadDashboard d-flex flex-column w-100 align-items-center">
    <div class="UploadPanelWrapper mt-5 mb-4 flex-grow-1">
      <PanelHeader
        :enable-save="enableSave"
        :busy="busy"
        btnlabel=""
        @save="handleSaveEvent"
      />

      <div v-if="superuser">
        <Tabs
          :tabs="tabs"
          :active="activeTab"
          @selected="handleTabClick"
        />
      </div>

      <section
        v-if="activeTab === 'upload'"
        class="UploadDashboardPanel__Section d-flex flex-column p-4"
      >
        <Feedback
          v-if="!dismissed"
          :feedback="{ variant: 'logo'}"
          class="mx-4 mt-4 pt-4 pb-4"
          @dismissed="dismissed = true"
        >
          <div style="max-width: 800px">
            Beste {{ username }},
            <br><br>
            Via dit online portaal kunt u de benodigde gemeentelijke data uploaden ten behoeve van het opstellen van de EV Maps.
            <br>
            Door deze informatie met ons te delen kunnen we samen tot een beter analyse resultaat komen.
            <br><br>
            Met vriendelijke groet,
            <br>
            EVtools
            <br><br>
            <span class="text-muted">
              Specifieke vragen over de EV Maps? Mail ons op  <MailtoSupport />.
            </span>
          </div>
        </Feedback>

        <Feedback
          class="mx-4 mt-4"
          :feedback="feedback"
          :dismissible="false"
        />

        <div class="d-flex flex-column p-4">
          <div class="UploadPanel d-flex mt-3 pb-3">
            <aside class="UploadPanel__Aside flex-shrink-0 mr-3">
              <h3 class="UploadPanel__Title">
                GIS bestanden
              </h3>
              <div class="w-75 mt-3">
                Door deze informatie met ons te delen kunnen we samen tot een beter analyse resultaat komen.
              </div>
            </aside>

            <div class="UploadPanel__Main flex-grow-1 mb-3">
              <div class="d-flex align-items-start pb-4">
                <span class="UploadPanel__Label mr-3">
                  Bomenregister
                </span>
                <span
                  class="UploadPanel__Input mr-3 flex-grow-1"
                  data-intructions="Format: .geojson, .zip files met de volgende shapefile bestanden (.dbf, .prj, .shp, .shx), eventueel .xlsx met x- en y-coordinaten"
                >
                  <b-form-file
                    v-model="bomen"
                    :disabled="busy"
                    accept=".geojson, .xlsx, .zip"
                    placeholder="Selecteer of sleep een bestand..."
                    drop-placeholder="Sleep hier heen..."
                  />
                </span>
                <b-button
                  :disabled="!enableSave"
                  variant="cta"
                  class="ml-3"
                  @click="handleSaveEvent"
                >
                  Uploaden
                </b-button>
              </div>

              <div class="d-flex align-items-start pb-4 mt-5 mb-4">
                <span class="UploadPanel__Label mr-3">
                  Grondeigendom gemeente
                </span>
                <span
                  class="UploadPanel__Input mr-3 flex-grow-1"
                  data-intructions="Format: .geojson, .zip files met de volgende shapefile bestanden (.dbf, .prj, .shp, .shx)"
                >
                  <b-form-file
                    v-model="grond"
                    :disabled="busy"
                    accept=".geojson, .zip"
                    placeholder="Selecteer of sleep een bestand..."
                    drop-placeholder="Sleep hier heen..."
                  />
                </span>
                <b-button
                  :disabled="!enableSave"
                  variant="cta"
                  class="ml-3"
                  @click="handleSaveEvent"
                >
                  Uploaden
                </b-button>
              </div>
            </div>
          </div>

          <div class="UploadPanel d-flex mt-5 pb-3">
            <aside class="UploadPanel__Aside flex-shrink-0 mr-3">
              <h3 class="UploadPanel__Title">
                Gebiedsontwikkeling
              </h3>
              <div class="w-75 mt-3">
                Als er in de komende jaren gebiedsontwikkelingen plaatsvinden of nieuwbouwwijken worden gerealiseerd  in {{ municipalityLabel }}, dan kan hier informatie worden aangeleverd zodat dit inzichtelijk wordt in EVMaps.
              </div>
            </aside>

            <div class="UploadPanel__Main flex-grow-1">
              <div class="d-flex align-items-start mb-4">
                <span class="UploadPanel__Label mr-3">
                  Specificaties gebiedsontwikkeling
                </span>
                <span
                  class="UploadPanel__Input mr-3 flex-grow-1"
                  data-intructions=".xlsx met daarin specificaties gebiedsontwikkeling op basis van de template."
                >
                  <b-form-file
                    v-model="specificatiesGebiedsontwikkeling"
                    :disabled="busy"
                    accept=".xlsx"
                    placeholder="Selecteer of sleep een bestand..."
                    drop-placeholder="Sleep hier heen..."
                  />
                </span>
                <b-button
                  :disabled="!enableSave"
                  variant="cta"
                  class="ml-3"
                  @click="handleSaveEvent"
                >
                  Uploaden
                </b-button>
              </div>
              <div class="d-flex align-items-start mb-4">
                <span class="UploadPanel__Label mr-3" />
                <a
                  class="UploadPanel__Download"
                  href="/format_specificaties_herontwikkeling_basis.xlsx"
                >
                  Download hier het specificaties gebiedsontwikkeling basis template
                </a>
              </div>

              <div class="d-flex align-items-start pb-4 mt-3 mb-4">
                <span class="UploadPanel__Label mr-3">
                  Geografische intekening gebiedsontwikkeling
                </span>
                <span
                  class="UploadPanel__Input mr-3 flex-grow-1"
                  data-intructions="Format: .geojson, .zip files met de volgende shapefile bestanden (.dbf, .prj, .shp, .shx)"
                >
                  <b-form-file
                    v-model="gebiedsontwikkeling"
                    :disabled="busy"
                    accept=".geojson, .zip"
                    placeholder="Selecteer of sleep een bestand..."
                    drop-placeholder="Sleep hier heen..."
                  />
                </span>
                <b-button
                  :disabled="!enableSave"
                  variant="cta"
                  class="ml-3"
                  @click="handleSaveEvent"
                >
                  Uploaden
                </b-button>
              </div>
            </div>
          </div>

          <div class="UploadPanel d-flex mt-5 pb-3">
            <aside class="UploadPanel__Aside flex-shrink-0 mr-3">
              <h3 class="UploadPanel__Title">
                Laadpalen
              </h3>
              <div class="w-75 mt-3">
                Locaties die {{ municipalityLabel }} reeds op het oog heeft voor het plaatsen van publieke laadpalen.
              </div>
            </aside>

            <div class="UploadPanel__Main flex-grow-1">
              <div class="d-flex align-items-start mb-4">
                <span class="UploadPanel__Label mr-3">
                  Geplande Laadpalen
                </span>
                <span
                  class="UploadPanel__Input mr-3 flex-grow-1"
                  data-intructions=".xlsx met x- en y-coordinaten op basis van de template."
                >
                  <b-form-file
                    v-model="laadpalen"
                    :disabled="busy"
                    accept=".geojson, .xlsx, .zip"
                    placeholder="Selecteer of sleep een bestand..."
                    drop-placeholder="Sleep hier heen..."
                  />
                </span>
                <b-button
                  :disabled="!enableSave"
                  variant="cta"
                  class="ml-3"
                  @click="handleSaveEvent"
                >
                  Uploaden
                </b-button>
              </div>
              <div class="d-flex align-items-start mt-5 mb-4">
                <span class="UploadPanel__Label mr-3" />
                <a
                  class="UploadPanel__Download"
                  href="/format_geplande_laadpalen.xlsx"
                >
                  Download hier het laadpalen template
                </a>
              </div>
            </div>
          </div>

          <div class="UploadPanel d-flex mt-5 pb-5">
            <aside class="UploadPanel__Aside flex-shrink-0 mr-3">
              <h3 class="UploadPanel__Title">
                Beleidsdocument
              </h3>
              <div class="w-75 mt-3">
                Documentatie over uw beleid, zoals EV-doelstellingen of gebiedsontwikkelingen. Dit helpt ons inzicht te krijgen in uw gemeentelijk laadbeleid.
              </div>
            </aside>

            <div class="UploadPanel__Main flex-grow-1">
              <div class="d-flex align-items-start mb-4">
                <span class="UploadPanel__Label mr-3">
                  Beleidsdocument
                </span>
                <span
                  class="UploadPanel__Input mr-3 flex-grow-1"
                  data-intructions="Format: .pdf, .docx of een .zip voor meerdere documenten"
                >
                  <b-form-file
                    v-model="document"
                    :disabled="busy"
                    accept=".pdf, .docx, .zip"
                    placeholder="Selecteer of sleep een bestand..."
                    drop-placeholder="Sleep hier heen..."
                  />
                </span>
                <b-button
                  :disabled="!enableSave"
                  variant="cta"
                  class="ml-3"
                  @click="handleSaveEvent"
                >
                  Uploaden
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- superusers only section -->
      <section
        v-if="activeTab === 'superuser-upload'"
        class="UploadDashboardPanel__Section d-flex flex-column p-4"
      >
        <Feedback
          v-if="!dismissed"
          :feedback="{ variant: 'logo'}"
          class="mx-4 mt-4 pt-4 pb-4"
          @dismissed="dismissed = true"
        >
          <div>
            Deze sectie is alleen zichtbaar voor superusers
          </div>
        </Feedback>

        <Feedback
          class="mx-4 mt-4"
          :feedback="{ variant: 'warning'}"
          :dismissible="false"
        >
          <div>
            Per laadpaal wordt alleen het laatste bestandje opgeslagen.
          </div>
        </Feedback>

        <SuperuserUploadPanel />
      </section>
    </div>
  </div>
</template>

<script>
import PanelHeader from '@/components/common/PanelHeader'
import Feedback from '@/components/form/Feedback'
import MailtoSupport from '@/components/common/MailtoSupport'

import { mapState, mapGetters, mapMutations } from 'vuex'

import { slugByCode, labelByCode } from '@/services/municipalities'
import SuperuserUploadPanel from '@/components/upload/SuperuserUploadPanel'
import Tabs from '@/components/common/Tabs'

import privilegesMixin from '@/mixins/common/privilegesMixin'

export default {
  name: 'UploadPanel',
  components: {
    Tabs,
    SuperuserUploadPanel,
    MailtoSupport,
    PanelHeader, Feedback,
  },
  mixins: [privilegesMixin],
  data() {
    return {
      busy: false,
      activeTab: 'upload',
      tabs: [
        {
          label: 'Gemeentelijke upload',
          id: 'upload',
        },
        {
          label: 'Superuser upload',
          id: 'superuser-upload',
        },
      ],

      bomen: null,
      grond: null,
      gebiedsontwikkeling: null,
      specificatiesGebiedsontwikkeling: null,
      laadpalen: null,
      document: null,

      dismissed: false,
      feedback: {
        message: 'Per bestand wordt het meest recent geüploade bestand bewaard. Het is niet nodig om alle bestanden gelijktijdig te uploaden.',
        variant: 'warning',
      },
    }
  },
  computed: {
    ...mapState(['dropbox']),
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('deployment', [
      'getEmailAddressSupport',
    ]),
    hasFiles() {
      return !! (this.bomen || this.grond || this.gebiedsontwikkeling || this.specificatiesGebiedsontwikkeling || this.laadpalen || this.document)
    },
    enableSave() {
      return ! this.busy && this.hasFiles
    },
    username() {
      return this.$auth.user.name
    },
    municipalityLabel() {
      return labelByCode({ code: this.getActiveMunicipality })
    },
    uploadPortalRootDirectory() {
      return `/EVMaps-UploadPortal/${process.env.VUE_APP_DROPBOX_ROOT || 'development'}`
    },
  },
  created() {
    if (!this.dropbox) {
      this.getDropboxToken()
    }
  },
  methods: {
    ...mapMutations(['setDropbox']),
    handleTabClick({ id }) {
      this.activeTab = id
    },
    /**
     * TODO: This is a temporary solution
     */
    getDropboxToken: async function() {
      if (! this.dropbox) {
        const token = await this.$auth.getTokenSilently()
        const api = await fetch('/api/dropbox', {
          headers: {
            authorization: 'Bearer ' + token,
          },
        })

        let response = await api.json()
        this.setDropbox({
          token: response.token,
        })
      }
    },
    handleSaveEvent: async function() {

      if (! this.dropbox) {
        this.feedback = {
          message: `Er kon geen connectie worden gemaakt met de opslag locatie. Neem a.u.b. contact met ons op via ${this.getEmailAddressSupport}`,
          variant: 'danger',
        }
        return
      }

      this.busy = true

      let code = this.getActiveMunicipality
      let municipality = slugByCode({ code })
      let files = [{
        file: this.bomen,
        label: 'Bomenregister',
        filename: !this.bomen ? null : `${this.uploadPortalRootDirectory}/EVMaps-Upload-Kaartlagen/${municipality}-gem${code}_bomenregister.${this.getFileExtension({ name: this.bomen.name })}`,
      }, {
        file: this.grond,
        label: 'Grondeigendom',
        filename: !this.grond ? null : `${this.uploadPortalRootDirectory}/EVMaps-Upload-Kaartlagen/${municipality}-gem${code}_gemeentelijke_eigendom.${this.getFileExtension({ name: this.grond.name })}`,
      }, {
        file: this.gebiedsontwikkeling,
        label: 'Gebiedsontwikkeling',
        filename: !this.gebiedsontwikkeling ? null : `${this.uploadPortalRootDirectory}/EVMaps-Upload-Kaartlagen/${municipality}-gem${code}_gebiedsontwikkeling_.${this.getFileExtension({ name: this.gebiedsontwikkeling.name })}`,
      }, {
        file: this.specificatiesGebiedsontwikkeling,
        label: 'Specificaties gebiedsontwikkeling',
        filename: !this.specificatiesGebiedsontwikkeling ? null : `${this.uploadPortalRootDirectory}/EVMaps-Upload-Kaartlagen/${municipality}-gem${code}_specificaties_gebiedsontwikkeling_.${this.getFileExtension({ name: this.specificatiesGebiedsontwikkeling.name })}`,
      }, {
        file: this.laadpalen,
        label: 'Geplande laadpalen',
        filename: !this.laadpalen ? null : `${this.uploadPortalRootDirectory}/EVMaps-Upload-GeplandeLaadpalen/${municipality}-gem${code}_geplande_laadpalen.${this.getFileExtension({ name: this.laadpalen.name })}`,
      }, {
        file: this.document,
        label: 'Beleidsdocument',
        filename: !this.document ? null : `${this.uploadPortalRootDirectory}/EVMaps-Upload-Beleidsdocumenten/${municipality}-gem${code}_beleidsdocument.${this.getFileExtension({ name: this.document.name })}`,
      }]

      files = files.filter(fileinfo => fileinfo.file)

      if (files.length === 0) {

        this.busy = false
        this.resetInputs()
        return
      } else {
        this.feedback = {
          message: `Het uploaden van ${files.length > 1 ? 'de' : 'het'} ${files.length} bestand${files.length > 1 ? 'en' : ''} kan even duren.`,
          variant: 'info',
        }
      }

      // Keep track of results
      let failures = []
      let done = 0

      let uploadFile = async (fileinfo) => {
        try {
          let response = await fetch('https://content.dropboxapi.com/2/files/upload', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${this.dropbox}`,
              'Content-Type': 'application/octet-stream',
              'Dropbox-API-Arg': `{"path":"${fileinfo.filename}","mode":{".tag":"overwrite"}}`,
            },
            body: fileinfo.file,
          })

          if (response.status !== 200) {
            failures.push(fileinfo.label)
          }

          done++
          if (done === files.length) {
            this.busy = false
            this.resetInputs()
            this.reportResults({ done, failures })
          }

        } catch (e) {
          failures.push(fileinfo.label)
          done++

          if (done === files.length) {
            this.busy = false
            this.resetInputs()
            this.reportResults({ done, failures })
          }
        }
      }
      /**
       * Upload all files, with a 100ms delay after each file to avoid API limits
       */
      files.forEach((fileinfo, index) => {
        setTimeout(function(){
          uploadFile(files[index])
        }, index * 100)
      })
    },
    /**
     * Get the file extension
     */
    getFileExtension({ name }) {
      return name.substr(name.lastIndexOf('.') + 1)
    },
    resetInputs() {
      this.bomen = null
      this.grond = null
      this.gebiedsontwikkeling = null
      this.specificatiesGebiedsontwikkeling = null
      this.laadpalen = null
      this.document = null
    },
    reportResults({ done, failures }) {
      if (failures.length === 0) {
        this.feedback = {
          message: `${done === 1 ? 'Het bestand is' : 'De bestanden zijn'} opgeslagen.`,
          variant: 'success',
        }
      } else if (failures.length === done) {
        this.feedback = {
          message: 'Geen van de bestanden is met succes geupload.',
          variant: 'warning',
        }
      } else {
        let mislukt = `${failures.join(', ')} ${failures.length === 1 ? 'is' : 'zijn'} niet aangekomen.`
        this.feedback = {
          message: `Van de ${ done } bestanden ${ (done - failures.length) === 1 ? 'is' : 'zijn'} er ${(done - failures.length)} geupload. ${mislukt}`,
          variant: 'warning',
        }
      }
    },
  },
}
</script>

<style lang="scss">


/**
 * This component combines the html & css of three layered admin components,
 *  because it doesn't need any of the logic of those components
 * TODO: Fix html & CSS so that it makes sense as one component
 */
.UploadDashboard {
  &__Panel, &__Select {
    // width: calc(100% - 2rem);
    min-width: 1100px;
    max-width: 1100px;
  }
  &__Panel {
    // border: 1px solid red;
    background: white;
    box-shadow: 0px 1px 3px 0px #dfe2e5;
  }
  .vSelect {
    min-width: 300px;
  }
}
.UploadPanelWrapper {

  min-width: 1100px;
  max-width: 1100px;
  background: white;
  box-shadow: 0px 1px 3px 0px #dfe2e5;

  header {
    background: var(--primary); // darken(var(--grey), 25%);
    color: white;
    font-size: 1.5rem;
  }

  form {
    label {
      font-size: 1.1rem;
    }
    small {
      outline: none !important;
      font-size: 1rem;
    }
  }
}
.UploadPanel {
  &__Aside {
    width: 275px;
  }
  &__Main {
    font-size: 1.25rem;

    .form-control {
      width: 100px;
      font-size: 1.25rem;
    }
  }
  &__Label {
    width: 175px;
  }
  &__Input {
    position: relative;
    width: 360px;

    &:after {
      content: attr(data-intructions);
      position: absolute;
      top: 3.1rem;
      font-size: 1rem;
      left: 0;
      color: #7F8FA4;

    }
  }
  &__Download {
    text-decoration: underline;
    color: var(--cta);
  }
  .SvgIcon {
    flex-shrink: 0;
    font-size: 1.75rem;
  }

  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: 'Selecteer';
  }
}
</style>
