<template>
  <div class="MonitoringPanel d-flex">
    <aside class="MonitoringPanel__Aside flex-shrink-0 mr-3">
      <h3 class="MonitoringPanel__Title">
        Monitoring
      </h3>
      <div class="w-75 mt-3">
        Stel de grenswaardes in voor het monitoring tabblad.
      </div>
    </aside>

    <div class="MonitoringPanel__Main flex-grow-1 flex-shrink-1">
      <div class="d-flex align-items-center">
        <span class="MonitoringPanel__Label mr-3">
          Monitoring
        </span>
        <ToggleSwitch
          class="mb-0"
          :value="data.enabled"
          @input="newValue => data.enabled = newValue"
        />
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="MonitoringPanel__Label mr-3">
          Laatst getoonde maand
        </span>
        <b-input-group
          class="flex-shrink-1 mr-3"
        >
          <b-input
            v-model="data.lastMonth"
            type="month"
            :state="isLastMonthValid"
            debounce="500"
            placeholder="YYYY-MM"
          />
          <b-form-invalid-feedback>
            Gebruik YYYY-MM
          </b-form-invalid-feedback>
        </b-input-group>
        <span class="flex-grow-1" />
        <SvgIcon
          id="lastMonth"
          icon="info-circle-regular"
          tooltip=""
        />
        <b-tooltip
          target="lastMonth"
          triggers="hover"
        >
          Selecteer hier de laatste maand waar monitoring data voor getoond moet worden. Dit wordt onder andere gebruikt om te bepalen welke maanden getoond worden in de sidebar, maar heeft effect op de hele suite.
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="MonitoringPanel__Label mr-3">
          CPO's
        </span>
        <vSelect
          v-model="cpos"
          class="flex-grow-1 mr-3"
          multiple
          :options="cpoOptions"
        />
        <SvgIcon
          id="cpos"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="cpos"
          triggers="hover"
        >
          Selecteer de CPO's die actief zijn binnen deze gemeente.
        </b-tooltip>
      </div>

      <div class="MonitoringPanel__Divider" />

      <div class="d-flex align-items-center mt-4">
        <span class="MonitoringPanel__Label mr-3">
          Bezettingsgraad grens
        </span>
        <ToggleSwitch
          class="mb-0"
          :value="data.thresholds.occupancy.enabled"
          @input="newValue => data.thresholds.occupancy.enabled = newValue"
        />
      </div>
      <div class="d-flex align-items-center mt-4">
        <span class="MonitoringPanel__Label mr-3">
          <!-- Bezettingsgraad grens -->
        </span>
        <b-input-group
          class="flex-shrink-1 mr-3"
          append="%"
        >
          <b-input
            v-model="data.thresholds.occupancy.value"
            type="number"
            :placeholder="`${DEFAULT_THRESHOLD.OCCUPANCY}`"
            :disabled="!data.thresholds.occupancy.enabled"
          />
        </b-input-group>
        <span class="flex-grow-1" />
        <SvgIcon
          id="thresholdOccupancy"
          icon="info-circle-regular"
          tooltip=""
        />
        <b-tooltip
          target="thresholdOccupancy"
          triggers="hover"
        >
          Dit wordt gebruikt als grens voor de bezettingsgraad
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="MonitoringPanel__Label mr-3">
          Geladen kWh grens
        </span>
        <ToggleSwitch
          class="mb-0"
          :value="data.thresholds.chargedVolume.enabled"
          @input="newValue => data.thresholds.chargedVolume.enabled = newValue"
        />
      </div>
      <div class="d-flex align-items-center mt-4">
        <span class="MonitoringPanel__Label mr-3">
          <!-- Geladen kWh grens -->
        </span>
        <b-input-group
          class="flex-shrink-1 mr-3"
          append="kWh per laadpunt"
        >
          <b-input
            v-model="data.thresholds.chargedVolume.value"
            type="number"
            :placeholder="`${DEFAULT_THRESHOLD.CHARGED_VOLUME}`"
            :disabled="!data.thresholds.chargedVolume.enabled"
          />
        </b-input-group>
        <SvgIcon
          id="thresholdChargedVolume"
          icon="info-circle-regular"
          tooltip=""
        />
        <b-tooltip
          target="thresholdChargedVolume"
          triggers="hover"
        >
          Dit wordt gebruikt als grens voor de geladen kWh grafiek. De grens voor een laadpaal wordt berekend aan de hand van deze waarde en het aantal laadpunten.
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="MonitoringPanel__Label mr-3">
          Sessies grens
        </span>
        <ToggleSwitch
          class="mb-0"
          :value="data.thresholds.session.enabled"
          @input="newValue => data.thresholds.session.enabled = newValue"
        />
      </div>
      <div class="d-flex align-items-center mt-4">
        <span class="MonitoringPanel__Label mr-3">
          <!-- Sessies grens -->
        </span>
        <b-input-group
          class="flex-shrink-1 mr-3"
          append="# per laadpunt"
        >
          <b-input
            v-model="data.thresholds.session.value"
            type="number"
            :placeholder="`${DEFAULT_THRESHOLD.SESSION}`"
            :disabled="!data.thresholds.session.enabled"
          />
        </b-input-group>
        <span class="flex-grow-1" />
        <SvgIcon
          id="thresholdSession"
          icon="info-circle-regular"
          tooltip=""
        />
        <b-tooltip
          target="thresholdSession"
          triggers="hover"
        >
          Dit wordt gebruikt als grens voor de aantal sessies grafiek. De grens voor een laadpaal wordt berekend aan de hand van deze waarde en het aantal laadpunten.
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import vSelect from '@/components/form/vSelect'
import SvgIcon from '@/components/common/SvgIcon'
import ToggleSwitch from '@/components/form/ToggleSwitch'
import { cpos } from '@/data/cpos'
import { DEFAULT_THRESHOLD } from '@/../shared/valueholders/monitoring'

export default {
  name: 'MonitoringPanel',
  components: {
    vSelect,
    SvgIcon,
    ToggleSwitch,
  },
  props: {
    /**
     * The data model values
     */
    values: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // The data models
      data: {
        enabled: true,
        lastMonth: '',
        cpos: [],
        thresholds: {
          occupancy: {
            enabled: true,
            value: '',
          },
          chargedVolume: {
            enabled: true,
            value: '',
          },
          session: {
            enabled: true,
            value: '',
          },
        },
      },

      trackChanges: true,
    }
  },
  computed: {
    ...mapGetters('deployment', ['DeploymentConfig']),
    // Fallback for browsers that don't support input[type='month']
    isLastMonthValid() {
      if (this.data.lastMonth === '') return null
      return !!this.data.lastMonth.match(/^\d{4}-\d{2}$/)
    },
    cpoOptions() {
      return cpos
        .filter(cpo => cpo.countries.includes(this.DeploymentConfig.country))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(cpo => ({
          label: cpo.name,
          value: cpo.uuid,
        }))
    },
    cpos: {
      get() {
        return this.cpoOptions.filter(({ value }) => this.data.cpos.includes(value))
      },
      set(newCpos) {
        this.data.cpos = newCpos.map(({ value }) => value)
      },
    },
  },
  watch: {
    /**
     * Values passed via props override the data, without signaling input changes
     */
    values() {
      this.setValues()
    },
    /**
     * When any input changes, pass it on
     */
    data: {
      handler(data) {
        if (this.trackChanges) {
          this.$emit('input', data)
        }
      },
      deep: true,
    },
  },
  created() {
    this.setValues()
    this.DEFAULT_THRESHOLD = DEFAULT_THRESHOLD
  },
  methods: {
    /**
     * Set prop values as new data, without signaling input changes
     */
    setValues() {
      this.trackChanges = false
      this.data = {
        ...this.values,
      }

      this.$nextTick(() => {
        this.trackChanges = true
      })
    },
  },
}
</script>

<style lang="scss">
.MonitoringPanel {
  &__Aside {
    width: 200px;

    @media (min-width: 1280px) {
      width: 275px;
    }
  }
  &__Main {
    font-size: 1.25rem;
  }
  &__Label {
    flex-shrink: 0;
    width: 175px;
  }
  &__Divider {
    border-top: solid 1px lightgray;
    margin: 1.5rem 2rem 0 2rem;
  }

  .SvgIcon {
    flex-shrink: 0;
    font-size: 1.75rem;
  }
}
</style>
