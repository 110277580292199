<template>
  <div class="Chart Chart--area">
    <div class="Chart__Header">
      <div class="Chart__Header__Icon">
        <SvgIcon icon="users-regular" />
      </div>
      {{ title }}
    </div>
    <GChart
      v-if="showChart"
      type="AreaChart"
      :data="chartData"
      :options="chartOptions"
    />
    <p
      v-else
      class="mb-4"
    >
      Geen data beschikbaar
    </p>
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'

import SvgIcon from '@/components/common/SvgIcon'
import GoogleChartEntry from '@/components/map/sidebar/models/GoogleChartEntry'

import { animationDuration, labelColor, monochromeColors } from '@/helpers/charts'

import chartMixins from '@/mixins/monitoring/chartMixins'

export default {
  name: 'AreaChart',
  components: { SvgIcon, GChart },
  mixins: [chartMixins],
  props: {
    data: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({
        limit: null,
      }),
    },
  },
  computed: {
    // if every month is anonymized we don't show the graph at all
    showChart() {
      if (Object.values(this.data).every(entry => entry.anonymized)) {
        return false
      }

      return true
    },
    chartOptions() {
      const viewWindow = this.$_chartMixin_calculateViewWindow(this.options.limit, ...Object.values(this.data).map(entry => entry.unique_users))

      return {
        legend: { position: 'none' },
        hAxis: { textPosition: 'bottom', textStyle: { color: labelColor } },
        vAxis: { textStyle: { bold: true, color: labelColor }, viewWindow: { max: viewWindow, min: 0 } },
        colors: monochromeColors,
        height: 140,
        width: 266,
        chartArea: { left: 30, top: 10, right: 20, bottom: 35 },
        animation: {
          duration: animationDuration,
        },
        tooltip: { isHtml: true },
      }
    },
    chartData() {
      let chartSerieLabels = [
        'Maand',
        this.title,
        { role: 'annotation' },
        { role: 'tooltip', p: { html: true } },
      ]

      return Object.values(this.data)
        .reverse()
        .reduce((result, entry) => {
          let preppedEntry = []

          if (entry.unique_users === null) {
            preppedEntry = GoogleChartEntry.createEmpty()
          } else {
            let value = entry.unique_users
            let annotation = `${entry.unique_users}`
            let tooltip = `<strong>${entry.label}</strong> <br /> Unieke gebruikers: <strong>${entry.unique_users}</strong>`

            if (entry.anonymized) {
              value = 0
              annotation = 'Geen data'
              tooltip = 'Voor deze maand staat er helaas <br /> <strong>geen data</strong> beschikbaar'
            }

            preppedEntry = GoogleChartEntry.create(
              entry.label,
              value,
              annotation,
              tooltip,
            )
          }

          result.push(preppedEntry.toArray())

          return result
        }, [chartSerieLabels])
    },
  },
}
</script>
