<template>
  <div class="Page--Terms h-100 d-flex flex-column align-items-center justify-content-center">
    <b-card
      tag="article"
      :img-src="image"
    >
      <b-card-text class="mt-3 mb-3">
        <h2>Gebruikersvoorwaarden</h2>
        <TermsText />
      </b-card-text>
      <template #footer>
        <span
          v-if="loading"
          class="text-muted mr-3"
        >Laden...</span>
        <span
          v-else-if="hasAcceptedTerms"
          class="text-muted mr-3"
        >Je bent al akkoord gegaan</span>
        <b-button
          variant="primary"
          :disabled="loading"
          @click="handleAccept"
        >
          {{ hasAcceptedTerms ? "Ga door" : "Ik ga akkoord" }}
        </b-button>
      </template>
    </b-card>
    <a
      class="text-muted mt-3"
      style="cursor: pointer"
      @click="logout"
    >
      Uitloggen
    </a>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { Bugfender } from '@bugfender/sdk'
import { image } from '@/helpers/assets'
import { checkStatus } from '@/helpers/api'
import TermsText, { VERSION } from '@/components/TermsText.vue'

export default {
  name: 'Terms',
  components: {
    TermsText,
  },
  data() {
    return {
      loading: false,
      image: image({ name: 'evtools.png' }),
    }
  },
  computed: {
    ...mapGetters('currentUser', [
      'hasAcceptedTerms',
    ]),
  },
  watch: {
    hasAcceptedTerms() {
      if (this.hasAcceptedTerms) {
        this.redirect()
      }
    },
  },
  methods: {
    ...mapMutations('currentUser', [
      'setTermsAccepted',
    ]),
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
    redirect() {
      const redirectTo = this.$route.query.redirect
      this.$router.push(redirectTo ? redirectTo : '/')
    },
    async handleAccept() {
      if (this.hasAcceptedTerms) {
        this.redirect()
      } else {
        this.loading = true
        try {
          const token = await this.$auth.getTokenSilently()
          await fetch('/api/useracceptterms', {
            method: 'POST',
            headers: {
              authorization: 'Bearer ' + token,
            },
            body: JSON.stringify({ version: VERSION }),
          })
            .then(await checkStatus)
            .then(() => {
              this.setTermsAccepted()
              this.$notify({
                type: 'success',
                title: 'Gelukt!',
                text: 'De voorwaarden zijn geaccepteerd.',
              })
              this.loading = false
            })

        } catch (e) {
          Bugfender.error('Error in accepting terms: ', e)
        }
      }
    },
  },
}
</script>

<style lang="scss">


.Page--Terms {
  .card {
    max-height: 85%;
    max-width: 600px;

    .card-img {
      background-color: var(--primary);
      padding: 0 6rem;
    }

    .card-body {
      overflow-y: auto;
    }

    .card-footer {
      text-align: right;
    }
  }
}
</style>
