<template>
  <TileDataContent
    :fids="fids"
    :forecast-label="accessLabel"
    :data-by-id="dataById"
  >
    <template #content-header>
      <TileDataContentHeader>
        <template #second-column>
          E-Voertuigen
        </template>
        <template #third-column>
          Laadpunten
        </template>
      </TileDataContentHeader>
    </template>

    <template #content>
      <TileDataContentRowThreeColumn :highlight="decisive.includes('inhabitantChargers')">
        <template #first-column>
          Bewoners
        </template>
        <template #second-column>
          {{ inhabitants | decimalComma }}{{ atLimit(inhabitants) ? '+': '' }}
        </template>
        <template #third-column>
          {{ inhabitantChargers | decimalComma }}
        </template>
      </TileDataContentRowThreeColumn>

      <TileDataContentRowThreeColumn :highlight="decisive.includes('visitorChargers')">
        <template #first-column>
          Bezoekers
        </template>
        <template #second-column>
          {{ visitors | decimalComma }}{{ atLimit(visitors) ? '+': '' }}
        </template>
        <template #third-column>
          {{ visitorChargers | decimalComma }}
        </template>
      </TileDataContentRowThreeColumn>

      <TileDataContentRowThreeColumn :highlight="decisive.includes('commuterChargers')">
        <template #first-column>
          Forenzen
        </template>
        <template #second-column>
          {{ commuters | decimalComma }}{{ atLimit(commuters) ? '+': '' }}
        </template>
        <template #third-column>
          {{ commuterChargers | decimalComma }}
        </template>
      </TileDataContentRowThreeColumn>
    </template>
  </TileDataContent>
</template>

<script>

import { decielen } from '@/data/inkomensdecielen'
import { evPerChargingPoint } from '@/data/evPerChargingPoint'

import { mapGetters, mapMutations } from 'vuex'
import TileDataContent from '@/components/map/tiles/TileDataContent'
import TileDataContentRowThreeColumn from '@/components/map/tiles/TileDataContentRowThreeColumn'
import TileDataContentHeader from '@/components/map/tiles/TileDataContentHeader'

export default {
  components: { TileDataContentHeader, TileDataContentRowThreeColumn, TileDataContent },
  filters: {
    decimalComma: function (value) {
      return `${value}`.replace('.', ',') // Convert the value to a string, since in some edge cases it will be an integer
    },
  },
  props: {
    loaded: {
      type: Boolean,
      value: false,
    },
  },
  data() {
    return {
      // This data is obtained from the prognose layer on click
      dataById: {},

      fids: [],
    }
  },
  computed: {
    ...mapGetters('prognose', [
      'getHighlighted',
    ]),
    ...mapGetters('config', {
      configLimit: 'limit',
    }),
    ...mapGetters('scenarios', [
      'model', 'year', 'access', 'forecast',
    ]),
    ...mapGetters('planmode', {
      isPlanModeActive: 'isActive',
    }),
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('deployment', [
      'DeploymentCode',
    ]),

    limit() {
      return parseInt(this.configLimit, 10) * this.fids.length
    },

    BewonersD1() {
      return this.fids.reduce((total, fid) => {
        return total + (
          this.dataById[fid].BewonersD1 * this.parkingFactor({ factor: this.dataById[fid].Pubparkeer })
        )
      }, 0)
    },
    BewonersD2() {
      return this.fids.reduce((total, fid) => {
        return total + (
          this.dataById[fid].BewonersD2 * this.parkingFactor({ factor: this.dataById[fid].Pubparkeer })
        )
      }, 0)
    },
    BewonersD3() {
      return this.fids.reduce((total, fid) => {
        return total + (
          this.dataById[fid].BewonersD3 * this.parkingFactor({ factor: this.dataById[fid].Pubparkeer })
        )
      }, 0)
    },
    BewonersD4() {
      return this.fids.reduce((total, fid) => {
        return total + (
          this.dataById[fid].BewonersD4 * this.parkingFactor({ factor: this.dataById[fid].Pubparkeer })
        )
      }, 0)
    },
    BewonersD5() {
      return this.fids.reduce((total, fid) => {
        return total + (
          this.dataById[fid].BewonersD5 * this.parkingFactor({ factor: this.dataById[fid].Pubparkeer })
        )
      }, 0)
    },
    Bezoekers() {
      return this.fids.reduce((total, fid) => {
        return total + (
          this.dataById[fid].Bezoekers * this.parkingFactor({ factor: this.dataById[fid].Pubparkeer })
        )
      }, 0)
    },
    Forenzen() {
      return this.fids.reduce((total, fid) => {
        return total + (
          this.dataById[fid].Forenzen * this.parkingFactor({ factor: this.dataById[fid].Pubparkeer })
        )
      }, 0)
    },

    // Access label
    accessLabel() {
      return this.access === 'public' ? 'Publiek' : 'Privaat'
    },

    yearFactor() {
      return decielen[this.model].D4[this.year] || 0
    },

    // Figures
    inhabitants() {
      let base = 0

      base = base + this.BewonersD1 * decielen[this.model].D1[this.year]
      base = base + this.BewonersD2 * decielen[this.model].D2[this.year]
      base = base + this.BewonersD3 * decielen[this.model].D3[this.year]
      base = base + this.BewonersD4 * decielen[this.model].D4[this.year]
      base = base + this.BewonersD5 * decielen[this.model].D5[this.year]

      return this.applyLimit(this.oneDecimal((base || 0) )) // * this.parkingFactor
    },
    commuters() {
      return this.applyLimit(this.oneDecimal((this.Forenzen || 0) * this.yearFactor )) // * this.parkingFactor
    },
    visitors() {
      return this.applyLimit(this.oneDecimal((this.Bezoekers || 0) * this.yearFactor )) // * this.parkingFactor
    },

    inhabitantChargers() {
      return (this.inhabitants / evPerChargingPoint[this.DeploymentCode][this.access][this.year].inhabitants).toFixed(1)
    },
    commuterChargers() {
      return (this.commuters / evPerChargingPoint[this.DeploymentCode][this.access][this.year].commuters).toFixed(1)
    },
    visitorChargers() {
      return (this.visitors / evPerChargingPoint[this.DeploymentCode][this.access][this.year].visitors).toFixed(1)
    },

    // Decisive
    decisive() {
      let highscore = Math.max(this.inhabitantChargers, this.commuterChargers, this.visitorChargers)
      return ['inhabitantChargers', 'commuterChargers', 'visitorChargers'].filter((key) => {
        return (parseFloat(this[key], 10) === highscore)
      })
    },
  },
  watch: {
    loaded() {
      this.activate()
    },
    /**
     * Clear the selected tile when the municipality changes
     */
    getActiveMunicipality() {
      this.fids = []
    },
    fids() {
      this.setHighlighted({ fids: this.fids })
    },
  },
  created() {
    if (this.loaded) {
      this.activate()
    }
  },
  beforeDestroy() {
    if (this.loaded) {
      this.$store.map.off('click', 'hexagons', this.handleClick)
      this.$store.map.off('contextmenu', 'hexagons', this.handleContext)
      this.$store.map.off('mouseenter', 'hexagons', this.mouseEnter)
      this.$store.map.off('mouseleave', 'hexagons', this.mouseLeave)
    }
  },
  methods: {
    ...mapMutations('prognose', [
      'setHighlighted',
    ]),
    activate() {
      this.$store.map.on('click', 'hexagons', this.handleClick)

      // TODO: Figure out a fix to avoid this
      if (navigator.appVersion.indexOf('Win') === -1) { // OSX
        this.$store.map.on('contextmenu', 'hexagons', this.handleContext)
      }

      // Cursor on hover
      this.$store.map.on('mouseenter', 'hexagons', this.mouseEnter)
      this.$store.map.on('mouseleave', 'hexagons', this.mouseLeave)
    },
    // parking factor
    parkingFactor({ factor }) {
      return this.access === 'public'
        ? factor || 0
        : (1 - factor)
    },
    oneDecimal(num) {
      return (Math.round((num * 10)) / 10).toFixed(1)
    },
    atLimit(num) {
      return num === this.limit
    },
    applyLimit(num) {
      return num > this.limit ? this.limit : num
    },
    handleClick(e) {
      // Ignore prognose interaction if the planmode is active
      if (e.features.length === 0 || this.isPlanModeActive || e._defaultPrevented) {
        return
      }

      if (
        (e.originalEvent.ctrlKey || e.originalEvent.button == 2) &&
        navigator.appVersion.indexOf('Win') === -1 // Continue on Windows OS
      ) {
        return
      }

      this.selectTile(e)
    },
    handleContext(e) {
      // Ignore prognose interaction if the planmode is active
      if (e.features.length === 0 || this.isPlanModeActive || e._defaultPrevented) {
        return
      }

      if ( ! (e.originalEvent.ctrlKey || e.originalEvent.button == 2)) {
        return
      }

      this.selectTile(e)
    },
    selectTile(e) {
      let props = e.features[0].properties
      // Obtain the data from the prognose layer
      let data = {};
      [
        'BewonersD1',
        'BewonersD2',
        'BewonersD3',
        'BewonersD4',
        'BewonersD5',
        'Bezoekers',
        'Forenzen',
        'Pubparkeer',
        'fid',
      ].forEach((key) => {
        data[key] = props[key] || 0
      })

      if (! data.fid) return

      // Holding CTRL
      if ((e.originalEvent.ctrlKey || e.originalEvent.button == 2)) {
        if (! this.fids.includes(data.fid)) {
          this.dataById[data.fid] = data
          this.fids.push(data.fid)

        } else {
          let index = this.fids.indexOf(data.fid)
          this.fids.splice(index, 1)
          delete this.dataById[data.fid]
        }
      } else {
        if (! this.fids.includes(data.fid) || this.fids.length > 1) {
          // Not emptying data object, as that would lead to computation errors
          this.dataById[data.fid] = data
          this.fids = [data.fid]
        } else {
          this.fids = []
          this.dataById = {}
        }
      }
    },
    mouseEnter() {
      if (this.isPlanModeActive) return

      this.$store.map.getCanvas().style.cursor = 'pointer'
    },
    mouseLeave() {
      if (this.isPlanModeActive) return

      this.$store.map.getCanvas().style.cursor = ''
    },
  },
}
</script>
