import { codeToSlug } from '@/services/municipalities'
import { sleep } from '@/helpers/timing'
import { mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      isExporting: false,
      stop: false,
      // state needed to display current progress in municipality
      current: {
        park: 0,
        parksTotal: 0,
      },
      // state needed for the total counter
      counter: {
        municipalityCurrent: 0,
        municipalitiesTotal: 0,
        municipalitiesWithDownload: 0,
        pdfsTotal: 0,
      },
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    // export the counter object per counter as computed so that they are read only and prefixed with factsheetExportMixin_
    factsheetExportMixin_counterMunicipalitiesCurrent() {
      return this.counter.municipalityCurrent || 0
    },
    factsheetExportMixin_counterParkCurrent() {
      return this.current.park
    },
    factsheetExportMixin_counterParksTotalInMunicipality() {
      return this.current.parksTotal
    },
    factsheetExportMixin_counterMunicipalitiesTotal() {
      return this.counter.municipalitiesTotal
    },
    factsheetExportMixin_counterPdfsTotal() {
      return this.counter.pdfsTotal
    },
  },
  created() {
    this.handleFactsheetExport()
  },
  watch: {
    getActiveMunicipality() {
      this.handleFactsheetExport()
    },
  },
  methods: {
    ...mapMutations('access', [
      'setActiveMunicipality',
    ]),
    async $factsheetExportMixin_handleStop() {
      this.stop = true
      localStorage.setItem('export-factsheets-in-progress', 'false')
      localStorage.setItem('export-factsheets-canceled', 'true')

      await this.$router.push({ name: 'FactsheetExport' })
    },
    /**
     * EXPORT ALL FACTSHEETS CONFIGURED IN LOCALSTORAGE
     *
     * to be configured in the FactsheetExport.vue component
     */
    async handleFactsheetExport() {
      this.isExporting = localStorage.getItem('export-factsheets-in-progress')

      // do nothing if not currently exporting
      if (this.isExporting !== 'true') return

      // set timeout to give $refs time to load and fetch data
      await sleep({ ms: 1000 })

      const slug = codeToSlug({ code: this.getActiveMunicipality })

      // eslint-disable-next-line no-console
      console.log(`export factsheets for municipality: ${slug}`)

      const itemsToExport = JSON.parse(localStorage.getItem('export-factsheets-data'))

      // perform some checks
      if (! itemsToExport?.length) {
        // eslint-disable-next-line no-console
        console.log('no exports do be done')
        await this.$router.push({ name: 'FactsheetExport' })
        return
      }

      if (! itemsToExport.includes(slug)) {
        // eslint-disable-next-line no-console
        console.log('municipality not included for download')
        await this.goToNextPage({ slug, itemsToExport })
        return
      }

      if (! this.factsheetData) {
        // eslint-disable-next-line no-console
        console.log('municipality has no factsheet data')
        await this.goToNextPage({ slug, itemsToExport })
        return
      }

      // load and initialize data
      const counter = JSON.parse(localStorage.getItem('export-factsheets-counter'))
      if (counter) {
        Object.entries(counter).forEach(([key, value]) => (this.counter[key] = value))
      }

      // update current park counter
      this.current.park = 0
      this.current.parksTotal = this.parks.length

      // increment counter, set default if not initialized
      this.counter.municipalityCurrent = (this.counter.municipalityCurrent || 0) + 1
      this.counter.municipalitiesWithDownload += 1

      // set period to total
      // todo:: could be configurable
      this.period = 'total'

      console.log('this.stop', this.stop)
      // after that loop through all parks and print them, one by one
      for (let i = 0; i < this.parks.length; i++) {
        // select next park; todo:: this is only for bedrijventerreinen necessary, make optional if other types of factsheets need to be exported as well
        this.selection.parks = [this.parks[i].label]

        // give the charts time to adjust
        await sleep({ ms: 1500 })

        // actually print
        await this.print()

        // update current park and pdf counter
        this.current.park = i + 1
        this.counter.pdfsTotal += 1

        if (this.stop) {
          break
        }

        // update counter localStorage, to keep always keep track of the progress
        localStorage.setItem('export-factsheets-counter', JSON.stringify(this.counter))
      }

      await this.goToNextPage({ slug, itemsToExport })
    },

    async goToNextPage({ slug, itemsToExport }) {
      if (this.stop) {
        return
      }

      // after having downloaded, all factsheets, or no data to download, remove municipality and redirect to next in line
      itemsToExport.splice(itemsToExport.indexOf(slug), 1)
      localStorage.setItem('export-factsheets-data', JSON.stringify(itemsToExport))

      // if there are no more items to export, redirect to the export page
      if (! itemsToExport.length) {
        localStorage.setItem('export-factsheets-success', 'true')
        localStorage.removeItem('export-factsheets-data')
        await this.$router.push({ name: 'FactsheetExport' })
      }

      slug = itemsToExport[0]

      await sleep({ ms: 500 })

      this.setActiveMunicipality({ slug })
    },

    // print, copied from FactsheetControls.vue -> Opslaan als PDF
    async print() {
      // Dynamically import jspdf and html2canvas
      const { default: jsPDF } = await import('jspdf')
      const { default: html2canvas } = await import('html2canvas')

      const factsheet = this.$refs.factsheet
      if (!factsheet) {
        return
      }

      const canvas = await html2canvas(factsheet.$el, { scale: 5 })

      const context = canvas.getContext('2d')
      context['imageSmoothingEnabled'] = false /* standard */
      context['mozImageSmoothingEnabled'] = false /* Firefox */
      context['oImageSmoothingEnabled'] = false /* Opera */
      context['webkitImageSmoothingEnabled'] = false /* Safari */
      context['msImageSmoothingEnabled'] = false /* IE */

      const doc = new jsPDF({ orientation: 'landscape' })
      const width = Math.floor(doc.internal.pageSize.getWidth())
      const height = Math.floor(doc.internal.pageSize.getHeight())
      doc.addImage(canvas, 'JPEG', 0, 0, width, height)
      doc.save(this.pdfTitle)
    },
  },
}
