<template>
  <div class="Chart Chart--area">
    <div class="Chart__Header">
      <div class="Chart__Header__Icon">
        <SvgIcon icon="align-left-solid" />
      </div>
      {{ title }}
    </div>
    <GChart
      type="AreaChart"
      :data="chartData"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'

import SvgIcon from '@/components/common/SvgIcon'
import GoogleChartEntry from '@/components/map/sidebar/models/GoogleChartEntry'

import { animationDuration, labelColor, monochromeColors, thresholdConfiguration } from '@/helpers/charts'

import chartMixins from '@/mixins/monitoring/chartMixins'
import { DEFAULT_THRESHOLD } from '@/../shared/valueholders/monitoring'

export default {
  name: 'AreaChart',
  components: { SvgIcon, GChart },
  mixins: [chartMixins],
  props: {
    data: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({
        thresholdEnabled: true,
        threshold: DEFAULT_THRESHOLD.SESSION,
      }),
    },
  },
  computed: {
    chartOptions() {
      const viewWindow = this.$_chartMixin_calculateViewWindow(this.options.threshold, ...Object.values(this.data).map(entry => entry.session_count))

      return {
        legend: { position: 'none' },
        hAxis: { textPosition: 'bottom', textStyle: { color: labelColor } },
        vAxis: { textStyle: { bold: true, color: labelColor }, viewWindow: { max: viewWindow, min: 0 } },
        colors: monochromeColors,
        height: 140,
        width: 266,
        chartArea: { left: 30, top: 10, right: 20, bottom: 35 },
        series: {
          1: thresholdConfiguration,
        },
        animation: {
          duration: animationDuration,
        },
        tooltip: { isHtml: true },
      }
    },
    chartData() {
      let chartSerieLabels = [
        'Maand',
        this.title,
        { role: 'annotation' },
        { role: 'tooltip', p: { html: true } },
      ]

      if (this.options.thresholdEnabled) {
        chartSerieLabels = [
          ...chartSerieLabels,
          { role: 'style' },
          'Limiet',
          { role: 'tooltip', p: { html: true } },
        ]
      }

      const result = [
        chartSerieLabels,
      ]

      Object.values(this.data)
        .reverse()
        .forEach((entry) => {
          const preppedEntry = entry.session_count === null ?
            GoogleChartEntry.createEmpty() :
            GoogleChartEntry.create(
              entry.label,
              entry.session_count,
              `${entry.session_count}`,
              `<strong>${entry.label}</strong> <br /> Aantal sessies: <strong>${entry.session_count}</strong>`,
            )

          if (this.options.thresholdEnabled) {
            preppedEntry.addLimit(this.options.threshold, `${this.options.threshold}`)
            result.push(
              preppedEntry.toArrayWithLimit(),
            )
          } else {
            result.push(preppedEntry.toArray())
          }
        })

      return result
    },
  },
}
</script>
