<template>
  <div
    v-if="visible"
    class="LegendWrapper"
  >
    <b-button
      :pressed.sync="isOpen"
      text="Legenda"
      variant="primary"
      class="btn-map-view"
      v-text="isOpen ? 'Sluit legenda' : 'Open legenda'"
    />
    <div
      v-if="isOpen"
      class="Legend"
    >
      <InMapLegendList
        v-if="legendItems"
        :items="legendItems"
      />
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixins/common/userMixin'
import InMapLegendList from '@/components/map/InMapLegendList'
import { mapGetters } from 'vuex'

export default {
  name: 'MapLegend',
  components: { InMapLegendList },
  mixins: [userMixin],
  props: {
    loaded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isOpen: !window.innerWidth || window.innerWidth > 600,
    }
  },
  computed: {
    ...mapGetters('layers', {
      visible: 'isLegendInMapVisible',
      getLayerDetailsById: 'getLayerDetailsById',
      layers: 'getLegendInMapLayers',
    }),
    legendItems() {
      return this.layers.reduce((total, layer) => {
        if (layer.legendItems) {
          return [...total, ...layer.legendItems]
        }

        if (layer.layers) {
          return [...total, ...layer.layers.map(id => this.getLayerDetailsById({ id }))]
        }

        return total
      }, []).filter(layer => !!layer)
    }
  },
}
</script>

<style lang="scss">
.LegendWrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 0.7rem;
  padding-right: 0.7rem;

  .Legend {
    margin-top: 0.3rem;
    background-color: white;
    padding: 0.3rem 1.5rem;
    border-radius: 0.25rem;
    border: 1px solid lightgray;
    filter: drop-shadow(0 1px 2px #222);

    &-Item {
      &-ColorIndicator {
        display: block;
        height: 15px;
        width: 15px;
        opacity: .5;
      }
    }
  }
}
</style>
