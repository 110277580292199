const SOURCE = {
  GOOGLE: 'Google',
  ECO_MOVEMENT: 'EcoMovement',
  MANUAL: 'Manual',
  MRAE: 'import-mrae',
  VATTENFALL: 'import-vattenfall',
  DEN_BOSCH: 'import-den-bosch',
  RHDHV: 'import-royal-haskoning',
  LPN: 'import-lpn',
  OVERMORGEN: 'import-overmorgen',
}

const SOURCES = Object.keys(SOURCE)

const SOURCE_TYPE = {
  FILE: 'file',
  API: 'api',
}
const SOURCE_TYPES = Object.keys(SOURCE_TYPE)

const sourcesToSlugs = {
  [SOURCE.GOOGLE]: 'Google Geocoder resultaat',
  [SOURCE.ECO_MOVEMENT]: 'EcoMovement dataset',
  [SOURCE.MANUAL]: 'Handmatige invoer',
  [SOURCE.MRAE]: 'Import MRA-E',
  [SOURCE.VATTENFALL]: 'Import Vattenfall',
  [SOURCE.DEN_BOSCH]: 'Import Gemeente Den Bosch',
  [SOURCE.RHDHV]: 'Import Royal HaskoningDHV',
  [SOURCE.LPN]: 'Import Laadpaal Nodig',
  [SOURCE.OVERMORGEN]: 'Import SPOL',
}

const sourceToSlug = ({ source }) => sourcesToSlugs[source]

module.exports = {
  SOURCE,
  SOURCES,
  SOURCE_TYPE,
  SOURCE_TYPES,
  sourceToSlug,
}
