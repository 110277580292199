<template>
  <div class="NewUserRow d-flex align-items-center mt-4">
    <span class="NewUserRow__Label mr-3">
      {{ user.name }}
    </span>
    <span class="NewUserRow__Email flex-grow-1 mr-3">
      {{ user.email }}
    </span>
    <span class="NewUserRow__Role mr-3">
      <SimpleSelect
        v-model="role"
        :disabled="processing"
        :options="options"
      />
    </span>

    <SvgIcon
      icon="plus-regular"
      tooltip=""
      @click.native="handleAddRole"
    />
  </div>
</template>

<script>

import SvgIcon from '@/components/common/SvgIcon'
import SimpleSelect from '@/components/form/SimpleSelect'
import { USER_ROLE, userRoleOptions } from '@/../shared/valueholders/userRoles'

/**
 * This component is used in the UserPanel component
 */
export default {
  name: 'NewUserRow',
  components: { SvgIcon, SimpleSelect },
  props: {
    user: {
      type: Object,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      /**
       * Used to indicate a role change is still being processed.
       */
      processing: false,
      role: USER_ROLE.GUEST,

      options: userRoleOptions,
    }
  },
  computed: {
    /**
     * Simplify data access
     */
    meta() {
      return this.user.app_metadata.evmaps
    },
    isSuperUser() {
      return this.meta.superuser
    },
  },
  watch: {
    /**
     * When we get new user details it means the user has been updated
     */
    user: {
      handler() {
        this.processing = false
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    /**
     * Add the role to the user
     */
    handleAddRole() {
      if (this.processing) return

      this.processing = true

      this.$emit('roleChange', {
        user: this.user,
        role: this.role,
      })
    },
  },
}
</script>

<style lang="scss">


.NewUserRow {
  &__Label {
    width: 175px;
  }
  &__Role {
    width: 150px;
  }
  .SvgIcon {
    cursor: pointer;

    &:hover {
      color: var(--primary)
    }
  }
}
</style>
