d<template>
  <div class="AssignValidatorsPanel d-flex">
    <aside class="AssignValidatorsPanel__Aside flex-shrink-0 mr-4">
      <h3 class="AssignValidatorsPanel__Title">
        Validator instellingen
      </h3>
      <div class="mt-3">
        <Form
          ref="form"
          @submit.prevent
        >
          <div class="mt-3">
            <strong>Standaard profiel validatoren</strong>
            <p> {{ defaultProfileName }} </p>
          </div>
          <div class="d-flex justify-content-between">
            <span>Input overnemen</span>
            <b-form-checkbox
              v-model="locked"
              name="check-button"
              switch
            />
            <span>vastzetten</span>
          </div>
          <FormField
            v-model="fields.beheerder.value"
            v-bind="fields.beheerder"
            :options="beheerderOptions"
          />
          <div class="d-flex justify-content-between">
            <span>Overschrijven</span>
            <b-form-checkbox
              v-model="additional"
              name="check-button"
              switch
            />
            <span>Aanvullen</span>
          </div>
          <div
            id="add-validator-btn"
            class="d-flex align-items-center add-validator"
          >
            <FormField
              v-model="fields.validatie.value"
              v-bind="fields.validatie"
              :disabled="locked || isAddingValidatorsDisabled"
              :options="validatorOptions"
            />
            <b-icon-plus
              variant="dark"
              class="add-validator__icon u-clickable ml-2 mt-1"
              @click="handleAddValidator"
            />
          </div>
          <b-tooltip
            v-if="isAddingValidatorsDisabled"
            target="add-validator-btn"
            triggers="hover"
          >
            {{ isLockedForEditingMessage }}
          </b-tooltip>
        </Form>
        <div class="the-validators">
          <ul
            v-if="newValidators.length > 0"
            class="list-unstyled"
          >
            <li
              v-for="(validator, index) in newValidators"
              :key="validator.user_id"
              class="mb-1 d-flex"
            >
              {{ validator.email }}
              <b-icon-trash
                variant="dark"
                class="the-validators__validator__icon u-clickable pull-right"
                @click="$_chargingpointValidatorsMixin_removeValidator(index)"
              />
            </li>
          </ul>
          <p
            v-else
            class="text-muted"
          >
            Geen validatoren geselecteerd.
          </p>
        </div>

        <div class="actions">
          <b-button
            type="submit"
            variant="primary"
            @click="handleSubmit"
          >
            Toewijzen
          </b-button>

          <b-button
            type="submit"
            variant="outline-primary"
            class="ml-1"
            @click="resetForm"
          >
            Reset
          </b-button>

          <b-dropdown
            id="dropdown-1"
            variant="outline-primary"
            text="Profiel"
            class="ml-1"
          >
            <b-dropdown-item @click="handleSetDefaultSetting">
              Standaard instellen
            </b-dropdown-item>
            <b-dropdown-item @click="handleSaveSetting">
              Opslaan als
            </b-dropdown-item>
            <b-dropdown-item @click="handleLoadSetting">
              Laden
            </b-dropdown-item>
            <b-dropdown-item @click="handleDeleteSetting">
              Verwijderen
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="mt-3">
          <strong>Instructies</strong>
          <p>
            Kies eerst de laadpaal die je wilt updaten. Je kunt gebruik maken van SHIFT om een reeks te selecteren,
            of van CTRL/CMD om meerdere laadpalen te selecteren/deselecteren.
          </p>
          <p>
            Is er al een beheerder en/of validatoren toegewezen dan wordt dit als selectie overgenomen.
          </p>
          <p>
            Het selecteren van een beheerder is optioneel. Als je een beheerder toewijst, mag alleen die gebruiker de status van de locatie veranderen (bijv. van 'locatievoorstel' naar 'gevalideerd locatievoorstel'). Als je geen beheerder toewijst, mogen alle gebruikers (behalve gasten) de status van de locatie aanpassen.
          </p>
          <p>
            Je kunt een of meerdere validatoren toevoegen door op het plusje te klikken.
          </p>
          <p>
            Zet de schakelaar op ‘Overschrijven’ als je van de geselecteerde laadpalen de huidige ingestelde beheerder en validatoren wilt overschrijven met de nieuwe beheerder en validatoren die je links hebt gekozen. Zet de schakelaar op ‘Aanvullen’ als je van de geselecteerde laadpalende de huidig ingestelde beheerder en validatoren wilt behouden en daar alleen de validatoren die je links hebt gekozen <strong>extra</strong> aan toe wil voegen.
          </p>
          <p>
            Zet de schakelaar op ‘Input overnemen’ als je van de geselecteerde laadpalen de huidig ingestelde beheerder en validatoren over wilt nemen in je selectie links. Zet de schakelaar op ‘Input vastzetten’ als je onafhankelijk van de geselecteerde laadpalen een beheerder en validatoren wil selecteren.
          </p>
          <p>
            Gebruik het knopje 'Toewijzen' om de aanpassing door te voeren.
          </p>
          <p>
            Onder het knopje 'Profiel' kunnen de instellingen worden opgeslagen of geladen. Als je een profiel als standaard instelt, worden deze gegevens automatisch toegevoegd aan nieuw geprikte locatievoorstellen.
          </p>
        </div>
      </div>
    </aside>

    <div class="AssignValidatorsPanel__Main flex-grow-1 flex-shrink-1">
      <div v-if="itemsLoaded">
        <ChargingPointsTable
          :is-busy="isLoadingFauna"
          :items="items"
          @selected-items="addSelectedItems"
        />
      </div>
      <p
        v-else-if="isLoadingFauna"
        class="text-muted"
      >
        Loading...
      </p>
      <p
        v-else
        class="text-muted"
      >
        Geen voorgestelde of geplande laadpalen
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { BIconPlus, BIconTrash } from 'bootstrap-vue'

import Form from '@/components/form/Form'
import FormField from '@/components/form/FormField'
import ChargingPointsTable from '@/components/admin/chargingpoints/ChargingPointsTable'

import chargingpointsLoadMixin from '@/mixins/chargingpoint/chargingpointsLoadMixin'
import chargingpointEditMixin from '@/mixins/chargingpoint/chargingpointEditMixin'
import chargingpointValidatorsMixin from '@/mixins/chargingpoint/chargingpointValidatorsMixin'
import userMixin from '@/mixins/common/userMixin'

import { STAKEHOLDER_TYPE } from '@/../shared/valueholders/stakeholder-types'
import { stripSpecialChars } from '@/helpers/string'
import { statusSlugToLabel } from '@/../shared/services/statusTranslations'

export default {
  components: { Form, FormField, BIconPlus, BIconTrash, ChargingPointsTable },
  mixins: [chargingpointEditMixin, chargingpointValidatorsMixin, chargingpointsLoadMixin, userMixin],
  data() {
    return {
      selected: [],
      selectedItems: [],
      additional: false,
      locked: false,
      settingNewProfileId: null,
      settingIsNewDefaultProfile: false,
      settingLoadProfileId: null,
      settingDeleteProfileId: null,
      settingDefaultProfileId: null,
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('planmode', [
      'getPlannedChargingPoints',
    ]),
    ...mapGetters('config', [
      'getValidationSettingProfile',
      'getDefaultValidationSettingProfile',
    ]),
    itemsLoaded() {
      return ! this.isLoadingFauna && this.items.length > 0
    },
    items() {
      const chargingPoints = JSON.parse(JSON.stringify(this.getPlannedChargingPoints)) // Remove reference to stored chargingpoints, otherwise we lose properties we delete (remark)
      return chargingPoints
        .filter(chargingpoint => ! chargingpoint.data.deleted_at)
        .sort((a, b) => (a.data.properties.id - b.data.properties.id))
        .map(chargingpoint => {
          let cpo = null
          let validators = ''

          if (chargingpoint.data.properties.stakeholders) {
            cpo = chargingpoint.data.properties.stakeholders.find(stakeholder => stakeholder.type === STAKEHOLDER_TYPE.CPO)
          }

          if (chargingpoint.data.properties.validators) {
            validators = Object.values(chargingpoint.data.properties.validators)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(validator => validator.name)
          }

          // so that filter is not searching in remarks
          delete chargingpoint.data.properties.remark

          return {
            status: chargingpoint.data.properties.status,
            statusTranslated: statusSlugToLabel({ status: chargingpoint.data.properties.status }),
            id: chargingpoint.data.properties.id,
            address: chargingpoint.data.address?.simple_address || 'Onbekend',
            planner: chargingpoint.data.properties.user?.name || 'Onbekend',
            cpo: cpo?.name || null,
            beheerder: chargingpoint.data.properties.beheerder?.name || '',
            validators: validators,
            chargingpoint,
            isLockedForEditing: chargingpoint.data.isLockedForEditing || chargingpoint.data.properties.isWorkflowCreated,
          }
        })
    },
    isAddingValidatorsDisabled () {
      // Lock adding a validator if Workflow created or VOLT location
      return this.selectedItems.some(item => !!item.isLockedForEditing)
    },
    defaultProfileName() {
      return this.getDefaultValidationSettingProfile?.name || 'geen'
    },
    isLockedForEditingMessage () {
      return 'Validators kunnen niet worden toegevoegd op RHDHV Volt of Workflow gecreëerde locaties.'
    }
  },
  watch: {
    additional: function (newVal) {
      this.locked = newVal
    },
    getActiveMunicipality() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  beforeDestroy() {
    this.resetForm()
  },
  methods: {
    ...mapActions('config', [
      'saveConfig',
    ]),
    async init() {
      this.resetForm()

      await this.$_chargingpointValidatorsMixin_fetchMunicipalityUsers({
        code: this.getActiveMunicipality,
      })

      await this.$_chargingpointsLoadMixin_loadChargingPoints({
        code: this.getActiveMunicipality,
      })

      this.settingDefaultProfileId = this.getDefaultValidationSettingProfile?.id || null
    },
    handleAddValidator() {
      if (this.isAddingValidatorsDisabled) {
        this.$notify({
          type: 'warn',
          title: 'Validatoren toevoegen niet mogelijk!',
          text: 'Een van de geselecteerde locaties is gekoppeld aan van RHDHV (Volt) via de API of gecreëerd in Workflow applicatie. Het is daarom niet mogelijk deze locatie te bewerken.',
        })

        return
      }

      this.$_chargingpointValidatorsMixin_addValidator()
    },
    handleSubmit() {
      const messageVNode = this.renderConfirmBoxContent()
      const title = this.additional ? 'Bevestig het aanvullen van deze validatoren.' : 'Bevestig het instellen van deze configuratie.'

      this.$bvModal.msgBoxConfirm([messageVNode], {
        title: title,
        okVariant: 'danger',
        okTitle: 'Bevestigen',
        cancelTitle: 'Annuleren',
      })
        .then(confirmed => {
          if (confirmed) {
            this.isBusy = true

            Promise.all(
              this.selectedItems.map(async item => {
                const chargingpoint = item.chargingpoint

                let beheerder = this.$_chargingpointValidatorsMixin_removeOrUpdateBeheerder()

                // validators are overwritten by default, only merge if they are additionally added
                let validators = this.newValidators
                if (this.additional) {
                  validators = chargingpoint.data.properties.validators || []

                  const toAdd = this.newValidators.filter(validator => {
                    return validators.findIndex(oldValidator => {
                      return validator.user_id === oldValidator.user_id
                    }) === -1
                  })

                  validators = validators.concat(toAdd)

                  beheerder = chargingpoint.data.properties.beheerder
                }

                return await this.$_chargingpointEditMixin_save({
                  data: {
                    code: chargingpoint.data.code,
                    ref: chargingpoint.ref, // full ref
                    id: chargingpoint.data.properties.id,
                    stakeholders: chargingpoint.data.properties.stakeholders,
                    address: chargingpoint.data.address,
                    status: chargingpoint.data.properties.status,
                    user: {
                      name: chargingpoint.data.properties.user_name ?? chargingpoint.data.properties.user.name,
                    },
                    coordinates: chargingpoint.data.coordinates,
                    remark: chargingpoint.data.properties.remark,
                    beheerder: beheerder,
                    validators: validators,
                    predecessor: chargingpoint.data.properties.predecessor,
                  },
                })
              }),
            ).then(() => {
              this.resetForm()
              this.isBusy = false
            })
          }
        })
    },
    handleSaveSetting() {
      const node = this.renderSettingSaveBoxContent()

      const beheerder = this.$_chargingpointValidatorsMixin_removeOrUpdateBeheerder()
      const validators = this.newValidators

      if (! beheerder && ! validators.length > 0) {
        this.$notify({
          type: 'warn',
          title: 'We missen nog iets!',
          text: 'Er zijn geen instellingen gekozen.',
        })

        return
      }

      this.$bvModal.msgBoxConfirm([node], {
        title: 'Instellingen opslaan',
        okVariant: 'primary',
        cancelVariant: 'outline-primary',
        okTitle: 'Opslaan',
        cancelTitle: 'Annuleren',
      })
        .then(async confirmed => {
          if (confirmed) {
            if (! this.settingNewProfileId) {
              this.$notify({
                type: 'warn',
                title: 'We missen nog iets!',
                text: 'Er is geen profielnaam gekozen.',
              })

              return
            }

            const id = stripSpecialChars({ string: this.settingNewProfileId.toLowerCase() })

            const data = {
              code: this.getActiveMunicipality,
              validationSettingProfiles: {
                [id]: {
                  id,
                  name: this.settingNewProfileId,
                  beheerder,
                  validators,
                },
              },
            }

            if (this.settingIsNewDefaultProfile) {
              data.validationSettingDefaultProfileId = id
              this.settingDefaultProfileId = id
            }

            this.isLoadingFauna = true

            try {
              await this.saveConfig({ data })

              this.$notify({
                type: 'success',
                title: 'Gelukt!',
                text: 'Nieuw profiel is opgeslagen.',
              })

            } catch (e) {
              this.$notify({
                type: 'error',
                title: 'Niet gelukt!',
                text: 'Er ging iets mis tijdens het opslaan van het profiel.',
              })
            }

            this.resetSettingForms()

            this.isLoadingFauna = false
          }
        })
    },
    handleSetDefaultSetting() {
      const node = this.renderSetDefaultSettingBoxContent()

      if (this.validationSettingOptions.length <= 0) {
        this.$notify({
          type: 'warn',
          title: 'Niet mogelijk!',
          text: 'Er zijn geen profielen opgeslagen.',
        })

        return
      }

      this.$bvModal.msgBoxConfirm([node], {
        title: 'Standaard profiel kiezen',
        okVariant: 'primary',
        cancelVariant: 'outline-primary',
        okTitle: 'Opslaan',
        cancelTitle: 'Annuleren',
      })
        .then(async confirmed => {
          if (confirmed) {
            const data = {
              code: this.getActiveMunicipality,
              validationSettingDefaultProfileId: this.settingDefaultProfileId,
            }

            this.isLoadingFauna = true

            try {
              await this.saveConfig({ data })

              this.$notify({
                type: 'success',
                title: 'Gelukt!',
                text: 'Het profiel is als standaard opgeslagen.',
              })

            } catch (e) {
              this.$notify({
                type: 'error',
                title: 'Niet gelukt!',
                text: 'Er ging iets mis tijdens het opslaan van het nieuwe standaard profiel.',
              })
            }

            this.isLoadingFauna = false
          }
        })
    },
    handleLoadSetting() {
      const node = this.renderSettingLoadBoxContent()

      if (this.validationSettingOptions.length <= 0) {
        this.$notify({
          type: 'warn',
          title: 'Niet mogelijk!',
          text: 'Er zijn geen profielen opgeslagen.',
        })

        return
      }

      this.$bvModal.msgBoxConfirm([node], {
        title: 'Profiel laden',
        okVariant: 'primary',
        cancelVariant: 'outline-primary',
        okTitle: 'Kiezen',
        cancelTitle: 'Annuleren',
      })
        .then(async confirmed => {
          if (confirmed) {
            if (! this.settingLoadProfileId) {
              this.$notify({
                type: 'warn',
                title: 'We missen nog iets!',
                text: 'Er is geen profiel gekozen om te laden.',
              })

              return
            }

            this.resetForm()

            const settings = this.getValidationSettingProfile({ profile: this.settingLoadProfileId })

            this.newValidators = settings.validators
            this.fields.beheerder.value = settings.beheerder?.user_id || null
            this.locked = true
          }
        })
    },
    handleDeleteSetting() {
      const node = this.renderSettingDeleteBoxContent()

      if (this.validationSettingOptions.length <= 0) {
        this.$notify({
          type: 'warn',
          title: 'Niet mogelijk!',
          text: 'Er zijn geen profielen opgeslagen.',
        })

        return
      }

      this.$bvModal.msgBoxConfirm([node], {
        title: 'Profiel verwijderen',
        okVariant: 'danger',
        cancelVariant: 'outline-primary',
        okTitle: 'Verwijderen',
        cancelTitle: 'Annuleren',
      })
        .then(async confirmed => {
          if (confirmed) {
            if (confirmed) {
              if (! this.settingDeleteProfileId) {
                this.$notify({
                  type: 'warn',
                  title: 'We missen nog iets!',
                  text: 'Er is geen profiel gekozen.',
                })

                return
              }

              const id = this.settingDeleteProfileId

              const data = {
                code: this.getActiveMunicipality,
                validationSettingProfiles: {
                  [id]: null,
                },
              }

              if (this.settingDefaultProfileId === this.settingDeleteProfileId) {
                data.validationSettingDefaultProfileId = null
              }

              this.isLoadingFauna = true

              try {
                await this.saveConfig({ data })
                this.$notify({
                  type: 'success',
                  title: 'Gelukt!',
                  text: 'Het profiel is verwijderd.',
                })
              } catch (e) {
                this.$notify({
                  type: 'error',
                  title: 'Niet gelukt!',
                  text: 'Er ging iets mis tijdens het verwijderen van het profiel.',
                })
              }

              this.resetForm()

              this.isLoadingFauna = false
            }
          }
        })
    },
    resetForm() {
      this.newValidators = []
      this.fields.beheerder.value = null
      this.fields.validatie.value = null

      this.additional = false
      this.locked = false

      /* this to reset the "new input"-validation after loading the data into the form */
      this.$nextTick(() => this.$refs.form?.resetValidation())
    },
    resetSettingForms() {
      this.settingNewProfileId = null
      this.settingIsNewDefaultProfile = false
      this.settingLoadProfileId = null
      this.settingDeleteProfileId = null
    },
    addSelectedItems(items) {
      this.selectedItems = items

      // don't load data to dropdowns if locked
      if (this.locked) {
        return
      }

      if (! items) {
        return
      }

      this.resetForm()

      if (items.length === 1) {
        this.newValidators = [...items[0].chargingpoint.data.properties.validators || []]
        this.fields.beheerder.value = items[0].chargingpoint.data.properties.beheerder?.user_id || null
      }
    },

    renderBeheerder() {
      const h = this.$createElement

      const beheerder = this.usersByCode.find(user => user.user_id === this.fields.beheerder.value)

      if (beheerder === undefined) {
        return h('div', { class: 'text-muted' }, 'Geen beheerder')
      }

      return h('div', beheerder.name)
    },
    renderValidators() {
      const h = this.$createElement

      if (this.newValidators.length === 0) {
        return h('div', { class: 'text-muted' }, 'Geen validatoren')
      }

      const rendererValidators = []
      this.newValidators.forEach(validator => rendererValidators.push(
        h('li', [validator.name]),
      ))

      return h('ul', { class: 'list-unstyled' }, rendererValidators)
    },
    renderChargingpoints() {
      const h = this.$createElement

      if (this.selectedItems.length === 0) {
        return h('div', { class: 'text-muted' }, 'Geen laadpalen geselecteerd')
      }

      const chargingpoints = []

      this.selectedItems.forEach(chargingpoint => chargingpoints.push(
          h('div', [`${chargingpoint.id} - ${chargingpoint.address}`]),
      ))

      return h('ul', { class: 'list-unstyled' }, chargingpoints)
    },
    renderConfirmBoxContent() {
      const h = this.$createElement

      return h('b-row', [
        h('b-col', [
          this.additional ? null : h('div', { class: 'mb-3' }, [
            h('strong', ['Beheerder']),
            this.renderBeheerder(),
          ]),
          h('div', [
            h('strong', ['Validatoren']),
            this.renderValidators(),
          ]),
        ]),
        h('b-col', [
          h('div', [
            h('strong', ['Laadpalen']),
            this.renderChargingpoints(),
          ]),
        ]),
      ])
    },
    renderSetDefaultSettingBoxContent() {
      const h = this.$createElement
      const vm = this

      return h(
        'b-container', [
          h('b-col', [
            h('div', [
              h('p', ['Kies een profiel']),
              h('strong', ['Profielen']),
              h('b-form-select', {
                props: {
                  options: vm.validationSettingOptions,
                  value: vm.settingDefaultProfileId,
                },
                scopedSlots: {
                  first: function () {
                    return h('option', {
                      domProps: {
                        value: null,
                        text: 'geen standaard',
                      },
                    })
                  },
                },
                on: {
                  input: function (value) {
                    vm.settingDefaultProfileId = value
                  },
                },
              }),
            ]),
          ]),
        ],
      )
    },
    renderSettingSaveBoxContent() {
      const h = this.$createElement
      const vm = this

      return h(
        'b-container', [
          h('b-row', [
            h('b-col', [
              this.additional ? null : h('div', { class: 'mb-3' }, [
                h('strong', ['Beheerder']),
                this.renderBeheerder(),
              ]),
            ]),
            h('b-col', [
              h('div', [
                h('strong', ['Validatoren']),
                this.renderValidators(),
              ]),
            ]),
          ]),
          h('b-row', [
            h('b-col', [
              h('div', [
                h('strong', ['Profielnaam']),
                h('b-input', {
                  props: {
                    value: vm.settingNewProfileId,
                  },
                  on: {
                    input: function (value) {
                      vm.settingNewProfileId = value
                    },
                  },
                }),
                h('b-form-checkbox', {
                  props: {
                    checked: vm.settingIsNewDefaultProfile,
                    name: 'set-as-default',
                    id: 'set-as-default',
                  },
                  on: {
                    input: function (value) {
                      vm.settingIsNewDefaultProfile = value
                    },
                  },
                }, ['Instellen als standaard']),
              ]),
            ]),
          ]),
        ],
      )
    },
    renderSettingLoadBoxContent() {
      const h = this.$createElement
      const vm = this

      return h('b-row', [
        h('b-col', [
          h('div', [
            h('p', ['Kies een profiel']),
            h('strong', ['Profielen']),
            h('b-form-select', {
              props: {
                options: vm.validationSettingOptions,
                value: vm.settingLoadProfileId,
              },
              on: {
                input: function (value) {
                  vm.settingLoadProfileId = value
                },
              },
            }),
          ]),
        ]),
      ])
    },
    renderSettingDeleteBoxContent() {
      const h = this.$createElement
      const vm = this

      return h('b-row', [
        h('b-col', [
          h('div', [
            h('p', ['Kies een profiel']),
            h('strong', ['Profielen']),
            h('b-form-select', {
              props: {
                options: vm.validationSettingOptions,
                value: vm.settingDeleteProfileId,
              },
              on: {
                input: function (value) {
                  vm.settingDeleteProfileId = value
                },
              },
            }),
          ]),
        ]),
      ])
    },
  },
}
</script>

<style lang="scss" scoped>
.AssignValidatorsPanel {
  &__Aside {
    width: 200px;

    @media (min-width: 1280px) {
      width: 275px;
    }
  }

  .form-group.FormField {
    width: 100%;
  }

  .custom-switch {
    width: 25px;
  }
}
</style>
