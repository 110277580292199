<template>
  <div class="Chart Chart--column">
    <div class="Chart__Header">
      <div class="Chart__Header__Icon">
        <SvgIcon icon="chart-bar-regular" />
      </div>
      {{ title }}
    </div>
    <GChart
      type="ColumnChart"
      :data="chartData"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'
import SvgIcon from '@/components/common/SvgIcon'
import { animationDuration, labelColor, barThresholdConfiguration } from '@/helpers/charts'
import chartMixins from '@/mixins/monitoring/chartMixins'
import { wholeNumber } from '@/helpers/number'
import GoogleChartEntry from '@/components/map/sidebar/models/GoogleChartEntry'
import { DEFAULT_THRESHOLD } from '@/../shared/valueholders/monitoring'
import getCssProperty from '@/helpers/cssProperty'

export default {
  name: 'ColumnChart',
  components: { SvgIcon, GChart },
  mixins: [chartMixins],
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({
        thresholdEnabled: true,
        threshold: DEFAULT_THRESHOLD.OCCUPANCY,
      }),
    },
  },
  computed: {
    chartOptions() {
      return {
        legend: { position: 'none' },
        hAxis: { textStyle: { bold: true, color: labelColor } },
        vAxis: { format: 'percent', textStyle: { bold: true, color: labelColor }, viewWindow: { max: 1, min: 0 } },
        colors: [getCssProperty('rate-limit-low')],
        bar: { groupWidth: '60%' },
        height: 140,
        width: 266,
        chartArea: { left: 30, top: 10, right: 20 },
        series: {
          1: barThresholdConfiguration,
        },
        animation: {
          duration: animationDuration,
        },
        tooltip: { isHtml: true },
        ignoreBounds: true,
      }
    },
    chartData() {
      /*
      * role: 'annotation' = text on bar
      * role: 'tooltip', first for tooltip of the bars, second for tooltip of the threshold line
      */
      let chartSerieLabels = [
        'Maand',
        this.title,
        { role: 'annotation' },
        { role: 'tooltip', p: { html: true } },
      ]

      if (this.options.thresholdEnabled) {
        chartSerieLabels = [
          ...chartSerieLabels,
          { role: 'style' },
          'Limiet',
          { role: 'tooltip', p: { html: true } },
        ]
      }

      const result = [
        chartSerieLabels,
      ]

      Object.values(this.data)
        .reverse()
        .forEach((entry) => {
          const wholeNumberValue = wholeNumber(entry.occupancy_rate * 100)

          const preppedEntry = entry.occupancy_rate === null ?
            GoogleChartEntry.createEmpty() :
            GoogleChartEntry.create(
              entry.label,
              entry.occupancy_rate,
              `${wholeNumberValue}%`,
              `<strong>${entry.label}</strong> <br /> Bezettingsgraad: <strong>${wholeNumberValue}%</strong>`,
            )

          if (this.options.thresholdEnabled) {
            preppedEntry.addLimit(this.options.threshold/100, `${this.options.threshold}%`)
            result.push(
              preppedEntry.toArrayWithLimit(),
            )
          } else {
            result.push(preppedEntry.toArray())
          }

        })

      return result
    },
  },
}
</script>
