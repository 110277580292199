<template>
  <div class="ProfileCard pt-4">
    <p class="d-flex align-items-center justify-content-between px-4">
      <strong>
        Uw gebruikersprofiel
      </strong>
      <span
        class="ProfileCard__logout d-inline-flex"
        @click="logout"
      >
        <span>Uitloggen</span>
        <SvgIcon
          class="ml-2"
          icon="sign-out-regular"
        />
      </span>
    </p>
    <p class="d-flex align-items-center px-4">
      <SvgIcon
        icon="user-regular"
        :has-fill="false"
        class="mr-3"
      />
      <span class="d-flex flex-wrap flex-shrink-1">
        {{ currentUserName }}
      </span>
    </p>
    <p class="d-flex align-items-center px-4">
      <SvgIcon
        icon="envelope-regular"
        :has-fill="false"
        class="mr-3"
      />
      <span class="d-flex flex-wrap flex-shrink-1">
        {{ currentUserEmail }}
      </span>
    </p>
    <p class="d-flex align-items-center px-4">
      <SvgIcon
        icon="user-shield-regular"
        :has-fill="false"
        class="mr-2"
      />
      <span class="d-flex flex-wrap flex-shrink-1">
        {{ currentUserRoleLabel }}
      </span>
    </p>

    <PasswordBox @processing="handleProcessing" />
  </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon'
import PasswordBox from '@/components/profile/PasswordBox'

import { EventBus } from '@/services/eventbus'
import userMixin from '@/mixins/common/userMixin'

export default {
  name: 'ProfileCard',
  components: { PasswordBox, SvgIcon },
  mixins: [userMixin],
  data() {
    return {
      busy: false,
    }
  },
  created() {
    EventBus.$on('overlay-click', this.maybeClose)
  },
  beforeDestroy() {
    EventBus.$off('overlay-click', this.maybeClose)
  },
  methods: {
    maybeClose() {
      if (this.busy) return

      EventBus.$emit('overlay-hide')
    },
    handleProcessing({ busy }) {
      this.busy = busy
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
  },
}
</script>

<style lang="scss">
.ProfileCard {
  position: relative;
  background: white;
  z-index: 999999;
  opacity: 0.999;
  min-width: 450px;
  max-width: 450px;

  p, .SvgIcon.SvgIcon {
    font-size: 1.15rem;
    cursor: auto;
  }
  &__logout, &__logout .SvgIcon {
    // position: absolute;
    // top: 1.5rem;
    // right: 1rem;
    cursor: pointer !important;

    &:hover {
      color: #b04300
    }
  }
}
</style>
