import { reduceUserInformation } from '@/helpers/user'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      newValidators: [],
      fields: {
        beheerder: {
          label: 'Beheerder',
          value: null,
          type: 'select',
        },
        validatie: {
          label: 'Validatie',
          value: null,
          type: 'select',
        },
      },
    }
  },
  computed: {
    ...mapGetters('config', [
      'getValidationSettings',
      'getDefaultValidationSettingProfile',
    ]),
    usersByCode() {
      const usersByCode = this.getUsersByCode({ code: this.getActiveMunicipality })

      if (! usersByCode) {
        return []
      }

      return usersByCode.sort((a, b) => a.name.localeCompare(b.name))
    },
    usersByCodeAsOptions() {
      return this.usersByCode.map((user) => ({ value: user.user_id, text: user.email }))
    },
    validatorOptions() {
      return this.usersByCodeAsOptions.filter(
        userOption => ! Object.values(this.newValidators).some(validator => validator.user_id === userOption.value),
      )
    },
    beheerderOptions() {
      const deleteBeheerder = { value: null, text: 'geen beheerder' }
      return [deleteBeheerder, ...this.usersByCodeAsOptions]
    },
    validationSettingOptions() {
      return this.getValidationSettings.map(setting => ({
        value: setting.id,
        text: this.getDefaultValidationSettingProfile?.name === setting.name ? `${setting.name} (standaard)` : setting.name,
      }))
    },
  },
  created: async function() {
    /* if not already done, fetch municipality users for validation / beheer options */
    if (this.usersByCode.length === 0) {
      await this.$_chargingpointValidatorsMixin_fetchMunicipalityUsers()
    }
  },
  methods: {
    ...mapActions('users', ['fetchMunicipalityUsers']),
    $_chargingpointValidatorsMixin_fetchMunicipalityUsers: async function() {

      if (! this.superuser) {
        return
      }

      this.isLoadingFauna = true

      await this.fetchMunicipalityUsers({ code: this.getActiveMunicipality })

      this.isLoadingFauna = false
    },
    /* add validator if not already in array */
    $_chargingpointValidatorsMixin_addValidator() {
      if (this.fields.validatie.value === null) {
        return
      }

      if (this.newValidators.findIndex(option => option.user_id === this.fields.validatie.value) !== -1) {
        return
      }

      const user = this.usersByCode.find(user => user.user_id === this.fields.validatie.value)

      if (user === undefined) {
        return
      }

      this.newValidators.push(reduceUserInformation(user))
    },
    $_chargingpointValidatorsMixin_removeValidator(index) {
      this.newValidators.splice(index, 1)
    },
    $_chargingpointValidatorsMixin_removeOrUpdateBeheerder() {
      let beheerder = this.usersByCode.find(user => user.user_id === this.fields.beheerder.value)
      if (beheerder !== undefined) {
        beheerder = reduceUserInformation(beheerder)
      }

      return beheerder || null
    },
  },
}
