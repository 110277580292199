<template>
  <div class="ActiveUserRow d-flex align-items-center mt-4">
    <span class="ActiveUserRow__Label mr-3">
      {{ user.name }}
    </span>
    <span class="ActiveUserRow__Email flex-grow-1 mr-3">
      {{ user.email }}
    </span>
    <span class="ActiveUserRow__Role mr-3">
      <SimpleSelect
        v-model="role"
        :disabled="processing"
        :options="options"
      />
    </span>

    <SvgIcon
      id="removeRole"
      icon="trash-alt-regular"
      @click.native="handleRemoveRole"
    />
    <b-tooltip
      target="removeRole"
      triggers="hover"
    >
      Verwijder de rol van deze gebruiker voor deze gemeente.
    </b-tooltip>
  </div>
</template>

<script>

import SvgIcon from '@/components/common/SvgIcon'
import SimpleSelect from '@/components/form/SimpleSelect'

import { USER_ROLE, userRoleOptions } from '@/../shared/valueholders/userRoles'

/**
 * This component is used in the UserPanel component
 */
export default {
  name: 'ActiveUserRow',
  components: { SvgIcon, SimpleSelect },
  props: {
    user: {
      type: Object,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      /**
       * Used to indicate a role change is still being processed.
       */
      processing: false,

      options: userRoleOptions,
    }
  },
  computed: {
    /**
     * Simplify data access
     */
    meta() {
      return this.user.app_metadata.evmaps
    },
    isSuperUser() {
      return this.meta.superuser
    },
    role: {
      get() {
        let selected = USER_ROLE.GUEST
        // Go over the possible roles
        this.meta.roles.forEach(role => {
          if (role.municipalities.includes(this.code)) {
            selected = role.role
          }
        })
        return selected
      },
      set(role) {
        this.processing = true

        this.$emit('roleChange', {
          user: this.user,
          role,
        })
      },
    },
  },
  watch: {
    /**
     * When we get new user details it means the user has been updated
     */
    user: {
      handler() {
        this.processing = false
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    /**
     * Simply set the role to null to remove a role
     */
    handleRemoveRole() {
      if (this.processing) return

      this.processing = true

      this.$emit('roleChange', {
        user: this.user,
        role: null,
      })
    },
  },
}
</script>

<style lang="scss">


.ActiveUserRow {
  &__Label {
    width: 175px;
  }
  &__Role {
    width: 150px;
  }
  .SvgIcon {
    cursor: pointer;

    &:hover {
      color: var(--cta);
    }
  }
}
</style>
