<template>
  <b-container
    fluid
    class="p-0"
  >
    <b-row class="mb-3">
      <b-col md="4">
        <b-button
          variant="outline-secondary"
          size="sm"
          class="mr-1"
          @click="selectAllRows"
        >
          alles selecteren
        </b-button>
        <b-button
          variant="outline-secondary"
          size="sm"
          @click="clearSelected"
        >
          deselecteren
        </b-button>
      </b-col>

      <b-col md="4">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type om te zoeken"
            />

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          label="Per pagina"
          label-for="per-page-select"
          label-cols-lg="4"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      ref="selectableTable"
      :items="items"
      :busy="isBusy"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="debouncedFilter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      select-mode="range"
      selectable
      sort-icon-left
      show-empty
      small
      fixed
      @filtered="onFiltered"
      @row-selected="onRowSelected"
    >
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Geselecteerd</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Niet geselecteerd</span>
        </template>
      </template>

      <template #table-colgroup="">
        <col
          key="status"
          style="width: 30px"
        >
        <col
          key="id"
          style="width: 40px"
        >
        <col
          key="address"
          style="width: 180px"
        >
        <col
          key="planner"
          style="width: 125px"
        >
        <col
          key="cpo"
          style="width: 100px"
        >
        <col
          key="beheerder"
          style="width: 90px"
        >
        <col
          key="validators"
          style="width: 119px"
        >
      </template>

      <template #cell(status)="row">
        <img
          width="25"
          :src="icons[row.value]"
        >
      </template>

      <template #cell(validators)="row">
        <div class="d-flex flex-wrap">
          <div
            v-for="name in row.value"
            :key="name"
            class="hexagon mr-1 d-flex justify-content-center"
          >
            {{ name | initials }}
          </div>
        </div>
      </template>

      <template #cell(beheerder)="row">
        <div
          v-if="row.value"
          class="d-flex justify-content-center"
        >
          <div class="hexagon mr-1 d-flex justify-content-center">
            {{ row.value | initials }}
          </div>
        </div>
      </template>

      <template #empty>
        <h4>Geen laadpalen</h4>
      </template>

      <template #emptyfiltered>
        <h4>Filter te klein</h4>
      </template>
    </b-table>

    <b-row
      v-if="perPage > 0"
      align-h="center"
    >
      <b-col lg="6">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { debounce } from '@/helpers/input'
import { image } from '@/helpers/assets'
import { CHARGINGPOINT_CATEGORY } from '@/../shared/valueholders/chargingpoint-statuses'

export default {
  filters: {
    initials: function (value) {
      let lastWord = value.split(' ').pop()

      let firstLetter = value.charAt(0).toUpperCase()
      let secondLetter = lastWord.charAt(0).toUpperCase()

      return firstLetter + secondLetter
    },
  },
  props: {
    isBusy: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: 'status', label: '', sortable: true, sortDirection: 'asc' },
        { key: 'id', label: 'id', sortable: true, sortDirection: 'asc' },
        { key: 'address', label: 'Adres', sortable: true, sortDirection: 'asc' },
        { key: 'planner', label: 'Planner', sortable: true, sortDirection: 'asc' },
        { key: 'cpo', label: 'CPO', sortable: true, sortDirection: 'asc' },
        { key: 'beheerder', label: 'Beheerder', sortable: true, sortDirection: 'asc' },
        { key: 'validators', label: 'Validatoren', sortable: true, sortDirection: 'asc' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [50, 100, 150, { value: 0, text: 'Toon alle' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      debouncedFilter: null,
      filterOn: [],
      selected: null,
    }
  },
  computed: {
    icons() {
      const icons = {}

      CHARGINGPOINT_CATEGORY.PLANNED.forEach(status => icons[status] = image({ name: `chargingpoint/svg/${status}.svg` }))

      return icons
    },
    sortOptions() {
    // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
  },
  watch: {
    filter: debounce(function (newVal) {
      this.debouncedFilter = newVal
    }, 350),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
      this.emitItems()
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
      this.emitItems()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
      this.emitItems()
    },
    emitItems() {
      this.$emit('selected-items', this.selected)
    },
    onFiltered(filteredItems) {
    // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss" scoped>


th {
  font-weight: 600;
}
.table.b-table.table-sm > thead > tr > [aria-sort].b-table-sort-icon-left, .table.b-table.table-sm > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  padding-left: 1.5rem
}

.hexagon {
  position: relative;
  width: 30px;
  height: 17.2px;
  margin: 8.6px 0;
  background-color: var(--primary);
  color: white;
}

.hexagon:before,
.hexagon:after {
  content: "";
  position: absolute;
  width: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

.hexagon:before {
  bottom: 100%;
  border-bottom: 8.6px solid var(--primary);
}

.hexagon:after {
  top: 100%;
  width: 0;
  left: 0;
  border-top: 8.6px solid var(--primary);
}
</style>
