<template>
  <ul class="m-0 p-0 mb-3 list-unstyled">
    <li
      v-for="item in legendItems"
      :key="item.label"
      class="Legend-Item d-flex my-3 align-items-center"
    >
      <!--  display one of both, with the preference for the icon -->
      <img
        v-if="item.icon"
        :src="item.icon.slug"
        :width="item.icon.width"
      >
      <div
        v-else-if="item.color"
        class="Legend-Item-ColorIndicator"
        :style="`background-color: ${item.color}`"
      />

      <!-- always display the label -->
      <div
        class="ml-2 mr-3 flex-grow-1"
        v-text="item.label"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'InMapLegendList',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    legendItems() {
      return this.items.filter(item => item.label && (item.icon || item.color))
    },
  },
}
</script>
