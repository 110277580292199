<template>
  <div class="UserCreation d-flex flex-column flex-shrink-1">
    <div class="mt-2 d-flex">
      <div class="d-flex UserCreation__Inputwrapper">
        <label
          for="name"
          class="UserCreation__Label"
        >Naam</label>
        <input
          id="name"
          v-model="name"
        >
      </div>
      <div class="d-flex UserCreation__Inputwrapper">
        <label
          for="email"
          class="UserCreation__Label ml-4"
        >E-mail</label>
        <input
          id="email"
          v-model="email"
          class="ml-3"
        >
      </div>
    </div>

    <div class="mt-2 d-flex">
      <div class="d-flex UserCreation__Inputwrapper">
        <label
          for="password"
          class="UserCreation__Label"
        >Wachtwoord</label>
        <input
          id="password"
          v-model="password"
        >
      </div>
      <div class="d-flex UserCreation__Inputwrapper flex-grow-1">
        <label
          for="role"
          class="UserCreation__Label ml-4"
        >Rol</label>
        <span class="flex-grow-1"><SimpleSelect
          id="role"
          v-model="role"
          class="w-100 ml-3"
          :options="options"
        /></span>
      </div>
    </div>

    <b-alert
      class="mt-3 w-50"
      variant="info"
      show
    >
      <small>Wachtwoord eisen vindt u in het gebruikersprofiel.</small>
    </b-alert>

    <div class="w-100 mt-3 pr-4  d-flex">
      <b-button
        class="mr-3"
        :disabled="!valid"
        variant="cta"
        @click="handleSave"
      >
        Gebruiker aanmaken
      </b-button>
    </div>
  </div>
</template>

<script>
import SimpleSelect from '@/components/form/SimpleSelect'
import { USER_ROLE, userRoleOptions } from '@/../shared/valueholders/userRoles'

// import { generatePassword } from '@/services/password'

export default {
  name: 'UserCreation',
  components: { SimpleSelect },
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      email: '',
      password: '',
      role: USER_ROLE.GUEST,

      options: userRoleOptions,
    }
  },
  computed: {
    /**
     * TODO: Actual validation
     */
    valid() {
      return this.name !== '' && this.email !== '' && this.role !== '' && this.password !== ''
    },
  },
  methods: {
    handleSave() {
      let user = {
        name: this.name,
        email: this.email,
        password: this.password, //  || generatePassword()
        app_metadata: {
          evmaps: {
            superuser: false,
            roles: [],
          },
        },
      }

      // Add the roles, and add the municipality code to the selected role
      this.options.forEach(option => {
        let role = {
          role: option.value,
          municipalities: [],
        }
        if (option.value === this.role) {
          role.municipalities.push(this.code)
        }

        user.app_metadata.evmaps.roles.push(role)
      })

      this.$emit('createUser', {
        code: this.code,
        user,
      })
    },
  },
}
</script>

<style lang="scss">
.UserCreation {
  .SimpleSelect {
    max-width: 224px;
  }
  &__Label {
    width: 125px;
  }
  &__Inputwrapper {
    flex-direction: column;
  }
}
</style>
