<template>
  <div>
    <vSelect
      ref="vSelect"
      :class="['vSelect', { isNotClearable }]"
      v-bind="$attrs"
      :value="value"
      :disabled="disabled"
      :clearable="clearable"
      v-on="$listeners"
    >
      <template #open-indicator="{ attributes }">
        <SvgIcon
          v-bind="attributes"
          icon="chevron-down-regular"
        />
      </template>

      <template v-if="isCRUD" #selected-option-container="{ option }">
        <vSelectEditableTags 
          v-bind="$props"
          :option="option"
          v-on="$listeners"
        />
      </template>

      <template v-else #option="option">
        <slot
          name="option"
          :option="option"
        >
          {{ option.label }}
        </slot>
      </template>

      <template #no-options="{ search, searching, loading }">
        {{  noOptionsMessage }}
        <div v-if="isCRUD" class="small">Typ en maak een nieuwe aan.</div>
      </template>
    </vSelect>
    <small v-if="description">{{ description }}</small>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import SvgIcon from '@/components/common/SvgIcon.vue'
import vSelectEditableTags from '@/components/form/vSelectEditableTags.vue'

export default {
  name: 'VSelect',
  components: { SvgIcon, vSelect, vSelectEditableTags },
  props: {
    value: {
      type: [String, Number, Array],
      default: '',
    },
    selectGroupLabel: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: '',
    },
    isCRUD: {
      type: Boolean,
      default: false
    },
    menuActions: {
      type: Object,
      default: () => {}
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    noOptionsMessage () {
      return this.isCRUD && this.value.length 
        ? `Alle ${this.selectGroupLabel} zij al geactiveerd!` 
        : `Geen ${this.selectGroupLabel || 'opties'} gevonden!`
    },
    isNotClearable () {
      return this.disabled || !this.clearable
    }
  }
}
</script>

<style lang="scss">
.vSelect {
  flex-shrink: 1;

  * {
    flex-shrink: 1;
  }
  &.isNotClearable {
    .vs__selected {
      .vs__deselect {
        display: none;
      }
    }
  }
}
.vs__dropdown-toggle {
  background: white;
  padding: 2px 0 5px;
}
.vs__dropdown-toggle .SvgIcon.SvgIcon {
  font-size: 1rem;
}
</style>
