const CHARGINGPOINT_STATUS = {
  DEFINITIVE: 'definitive',
  SUGGESTION: 'suggestion',
  REJECTED: 'rejected',
  REALIZED: 'realized',
  REALIZED_SEMIPUBLIC: 'realized-semipublic',
  REALIZED_PRIVATE: 'realized-private',
  IN_PROGRESS: 'in-progress',
  ALERT: 'alert',

// fastchargers
  FASTCHARGER_DEFINITIVE: 'fastcharger-definitive',

  FASTCHARGER_SUGGESTION: 'fastcharger-suggestion',
  FASTCHARGER_SUGGESTION_SEMIPUBLIC: 'fastcharger-suggestion-semipublic',
  FASTCHARGER_SUGGESTION_PRIVATE: 'fastcharger-suggestion-private',
  FASTCHARGER_REJECTED: 'fastcharger-rejected',
  FASTCHARGER_REALIZED: 'fastcharger-realized',
  FASTCHARGER_REALIZED_SEMIPUBLIC: 'fastcharger-realized-semipublic',
  FASTCHARGER_REALIZED_PRIVATE: 'fastcharger-realized-private',
  FASTCHARGER_IN_PROGRESS: 'fastcharger-in-progress',

// charging-hub
  CHARGING_HUB_DEFINITIVE: 'charging-hub-definitive',
  CHARGING_HUB_SUGGESTION: 'charging-hub-suggestion',
  CHARGING_HUB_REJECTED: 'charging-hub-rejected',
  CHARGING_HUB_REALIZED: 'charging-hub-realized',
  CHARGING_HUB_REALIZED_SEMIPUBLIC: 'charging-hub-realized-semipublic',
  CHARGING_HUB_REALIZED_PRIVATE: 'charging-hub-realized-private',
  CHARGING_HUB_IN_PROGRESS: 'charging-hub-in-progress',
}

const CHARGINGPOINT_STATUSES = Object.values(CHARGINGPOINT_STATUS)

const CHARGINGPOINT_TYPE = {
  REALIZED: [
    CHARGINGPOINT_STATUS.REALIZED,
    CHARGINGPOINT_STATUS.REALIZED_SEMIPUBLIC,
    CHARGINGPOINT_STATUS.REALIZED_PRIVATE,
    CHARGINGPOINT_STATUS.FASTCHARGER_REALIZED,
    CHARGINGPOINT_STATUS.FASTCHARGER_REALIZED_SEMIPUBLIC,
    CHARGINGPOINT_STATUS.FASTCHARGER_REALIZED_PRIVATE,
    CHARGINGPOINT_STATUS.CHARGING_HUB_REALIZED,
    CHARGINGPOINT_STATUS.CHARGING_HUB_REALIZED_PRIVATE,
    CHARGINGPOINT_STATUS.CHARGING_HUB_REALIZED_SEMIPUBLIC,

  ],
  IN_PROGRESS: [
    CHARGINGPOINT_STATUS.IN_PROGRESS,
    CHARGINGPOINT_STATUS.FASTCHARGER_IN_PROGRESS,
    CHARGINGPOINT_STATUS.CHARGING_HUB_IN_PROGRESS,
  ],
  SUGGESTION: [
    CHARGINGPOINT_STATUS.SUGGESTION,
    CHARGINGPOINT_STATUS.FASTCHARGER_SUGGESTION,
    CHARGINGPOINT_STATUS.CHARGING_HUB_SUGGESTION,
  ],
  DEFINITIVE: [
    CHARGINGPOINT_STATUS.DEFINITIVE,
    CHARGINGPOINT_STATUS.FASTCHARGER_DEFINITIVE,
    CHARGINGPOINT_STATUS.CHARGING_HUB_DEFINITIVE,
  ],
  REJECTED: [
    CHARGINGPOINT_STATUS.REJECTED,
    CHARGINGPOINT_STATUS.FASTCHARGER_REJECTED,
    CHARGINGPOINT_STATUS.CHARGING_HUB_REJECTED,
  ],
}

const CHARGINGPOINT_CATEGORY = {
  VALIDATIONS: [
    ...CHARGINGPOINT_TYPE.SUGGESTION,
    ...CHARGINGPOINT_TYPE.DEFINITIVE,
    ...CHARGINGPOINT_TYPE.REJECTED,
  ],
  PLANNED: [
    ...CHARGINGPOINT_TYPE.DEFINITIVE,
    ...CHARGINGPOINT_TYPE.SUGGESTION,
  ],
  CHARGING_HUB: [
    CHARGINGPOINT_STATUS.CHARGING_HUB_REALIZED_SEMIPUBLIC,
    CHARGINGPOINT_STATUS.CHARGING_HUB_REALIZED_PRIVATE,
    CHARGINGPOINT_STATUS.CHARGING_HUB_REALIZED,
    CHARGINGPOINT_STATUS.CHARGING_HUB_IN_PROGRESS,
    CHARGINGPOINT_STATUS.CHARGING_HUB_SUGGESTION,
    CHARGINGPOINT_STATUS.CHARGING_HUB_DEFINITIVE,
    CHARGINGPOINT_STATUS.CHARGING_HUB_REJECTED,

  ],
  FASTCHARGER: [
    CHARGINGPOINT_STATUS.FASTCHARGER_SUGGESTION,
    CHARGINGPOINT_STATUS.FASTCHARGER_DEFINITIVE,
    CHARGINGPOINT_STATUS.FASTCHARGER_REJECTED,
    CHARGINGPOINT_STATUS.FASTCHARGER_IN_PROGRESS,
    CHARGINGPOINT_STATUS.FASTCHARGER_REALIZED,
    CHARGINGPOINT_STATUS.FASTCHARGER_REALIZED_PRIVATE,
    CHARGINGPOINT_STATUS.FASTCHARGER_REALIZED_SEMIPUBLIC,

  ],
  REALIZATION_PHASE: [
    ...CHARGINGPOINT_TYPE.REALIZED,
    ...CHARGINGPOINT_TYPE.IN_PROGRESS,
  ],
}

const CHARGINGPOINT_ACCESSIBILITY = {
  PUBLIC: 'public',
  SEMIPUBLIC: 'semipublic',
  PRIVATE: 'private',
}

const CHARGINGPOINT_CAPACITY = {
  REGULAR: 'regular',
  FASTCHARGER: 'fastcharger',
  CHARGING_HUB: 'charging-hub',
}

module.exports = {
  CHARGINGPOINT_STATUSES,
  CHARGINGPOINT_STATUS,
  CHARGINGPOINT_TYPE,
  CHARGINGPOINT_CATEGORY,
  CHARGINGPOINT_CAPACITY,
  CHARGINGPOINT_ACCESSIBILITY,
}
