/**
 * Import Dependency
 */

/**
 * Import API
 */

import { CHARGINGPOINT_CATEGORY, CHARGINGPOINT_TYPE } from '@/../shared/valueholders/chargingpoint-statuses'
import { FILTER_TYPE } from '@/../shared/valueholders/filter-types'
import { checkIfPathExists } from '@/helpers/object'
import { getInstance } from '@/auth'
import { validate } from 'uuid'

/**
 * Declare Variable
 */

const state = {
  filters: {
    'priority': {
      id: 'priority',
      type: FILTER_TYPE.RANGE_FILTER,
      label: 'Prioriteit',
      tag: 'Prioriteit ',
      icon: 'chart-bar-regular',
      unit: '',
      min: null,
      max: null,
      from: null,
      to: null,
      active: false,
      visible: true,
      filterFnName: 'priorityFilter',
    },

    'priority-fases': {
      id: 'priority-fases',
      type: FILTER_TYPE.SELECT_FILTER,
      label: null,
      subline: 'Fases',
      active: false,
      visible: true,
      filterFnName: 'priorityFaseFilter',
      // default filter values, that should always be available for all municipalities
      options: [{
        id: 'priority-fase-1',
        label: 'Fase 1',
        tag: 'Fase 1',
        from: 1,
        to: 1,
        visible: false,
      }, {
        id: 'priority-fase-2',
        label: 'Fase 2',
        tag: 'Fase 2',
        from: 2,
        to: 2,
        visible: false,
      }],
      // active values to filter on; todo:: maybe better name would be selection?
      values: [],
    },

    'cpo-list': {
      id: 'cpo-list',
      type: FILTER_TYPE.SELECT_FILTER,
      label: null,
      subline: 'CPO\'s',
      active: false,
      visible: true,
      filterFnName: 'cpoFilter',
      options: [],
      values: [],
      hasTooltip: true,
    },

    'power': {
      id: 'power',
      type: FILTER_TYPE.RANGE_FILTER,
      label: 'Power (kW)',
      tag: 'Power ',
      icon: 'plug-solid',
      unit: 'kW',
      min: null,
      max: null,
      from: null,
      to: null,
      active: false,
      visible: true,
      filterFnName: 'powerFilter',
    },
    'to-validate': {
      id: 'to-validate',
      type: FILTER_TYPE.TOGGLE_FILTER,
      label: 'Door mij te valideren',
      subline: 'Nog te valideren',
      tag: 'door mij te valideren',
      icon: 'user-check-solid',
      active: false,
      visible: true,
      filterFnName: 'toValidateFilter',
    },
    'already-validated': {
      id: 'already-validated',
      type: FILTER_TYPE.TOGGLE_FILTER,
      label: 'Door mij reeds gevalideerd',
      subline: 'Reeds gevalideerd',
      tag: 'door mij reeds gevalideerd',
      icon: 'check-double-solid',
      active: false,
      visible: true,
      filterFnName: 'alreadyValidatedFilter',
    },
    'to-decide': {
      id: 'to-decide',
      type: FILTER_TYPE.TOGGLE_FILTER,
      label: 'Beslissingen',
      subline: 'nog te nemen',
      tag: 'door mij te beslissen',
      icon: 'exchange-alt-solid',
      active: false,
      visible: true,
      filterFnName: 'toDecideFilter',
    },
    'monitoring-occupancy-rate': {
      id: 'monitoring-occupancy-rate',
      type: FILTER_TYPE.RANGE_FILTER,
      label: 'Bezettingsgraad',
      tag: 'Bezettingsgraad',
      icon: 'chart-bar-regular',
      unit: '%',
      min: null,
      max: null,
      from: null,
      to: null,
      active: false,
      visible: false,
      filterFnName: 'occupancyRateFilter',
    },
    'monitoring-unique-users': {
      id: 'monitoring-unique-users',
      type: FILTER_TYPE.RANGE_FILTER,
      label: 'Unieke gebruikers',
      tag: 'Unieke gebruikers',
      icon: 'users-regular',
      unit: '',
      min: null,
      max: null,
      from: null,
      to: null,
      active: false,
      visible: false,
      filterFnName: 'uniqueUsersFilter',
    },
    'monitoring-session-count': {
      id: 'monitoring-session-count',
      type: FILTER_TYPE.RANGE_FILTER,
      label: 'Aantal sessies',
      tag: 'Aantal sessies',
      icon: 'plug-solid',
      unit: '',
      min: null,
      max: null,
      from: null,
      to: null,
      active: false,
      visible: false,
      filterFnName: 'sessionFilter',
    },
    'monitoring-charged': {
      id: 'monitoring-charged',
      type: FILTER_TYPE.RANGE_FILTER,
      label: 'Geladen kWh',
      tag: 'Geladen kWh',
      icon: 'users-regular',
      unit: 'kWh',
      min: null,
      max: null,
      from: null,
      to: null,
      active: false,
      visible: false,
      filterFnName: 'chargedFilter',
    },
    'participation-has-comments': {
      id: 'participation-has-comments',
      type: FILTER_TYPE.TOGGLE_FILTER,
      label: 'Participatie',
      subline: 'Heeft reacties',
      tag: 'Heeft participatie reacties',
      icon: 'users-regular',
      active: false,
      visible: true,
      filterFnName: 'hasParticipationCommentsFilter',
    },
    'participation-has-unfinished-comments': {
      id: 'participation-has-unfinished-comments',
      type: FILTER_TYPE.TOGGLE_FILTER,
      subline: 'Heeft onverwerkte reacties',
      tag: 'Heeft onverwerkte participatie reacties',
      active: false,
      visible: true,
      filterFnName: 'hasUnfinishedParticipationCommentsFilter',
    },
    'duplicates': {
      id: 'duplicates',
      type: FILTER_TYPE.TOGGLE_FILTER,
      label: 'Duplicaten',
      subline: 'Is mogelijk duplicaat',
      tag: 'Is mogelijk duplicaat',
      icon: 'merge-regular',
      active: false,
      visible: true,
      filterFnName: 'hasPossibleDuplicates',
    },
  },
}

const getters = {
  getFilters (state, getters) {
    return Object.values(state.filters).map(filter => ({
      ...filter,
      filterFn: filter.filterFnName ? getters['getFilterFns'][filter.filterFnName] : null,
    }))
  },
  getFilterById: state => ({ id }) => state.filters[id],
  getFiltersByPrefix: state => ({ prefix }) => Object.values(state.filters).filter(filter => filter.id.startsWith(prefix)),
  getActiveFilters: (_, getters) => getters['getFilters'].filter(filter => filter.active),
  isAnyFilterActive: (_, getters) => getters['getActiveFilters'].length > 0,
  getFilterFns: (_, getters, rootState, rootGetters) => ({
    rangeFilter({ chargingpoints, filter }) {
      return chargingpoints.filter(chargingpoint => {
        if (! checkIfPathExists({ object: chargingpoint, path: filter.path })) {
          return false
        }

        let key = chargingpoint.data[filter.path] // todo:: make this work getNestedDataByPath

        return (key >= parseInt(filter.from, 10) && key <= parseInt(filter.to, 10))
      })
    },

    priorityFilter({ chargingpoints, filter }) {
      return chargingpoints.filter(chargingpoint => {
        // Required data
        if (! chargingpoint.data.prio || ! chargingpoint.data.prio.order) {
          return false
        }

        // Check the position in the prioritized planning
        let order = chargingpoint.data.prio.order

        return (order >= parseInt(filter.from, 10) && order <= parseInt(filter.to, 10))
      })
    },

    powerFilter({ chargingpoints, filter }) {
      return chargingpoints.filter(chargingpoint => {
        // Required data
        if (! chargingpoint.data.properties.power) {
          return false
        }

        const power = chargingpoint.data.properties.power

        return (power >= parseInt(filter.from * 1000, 10) && power <= parseInt(filter.to * 1000, 10))
      })
    },

    priorityFaseFilter({ chargingpoints, filter }) {
      return chargingpoints.filter(chargingpoint => {
        // Required data
        if (! chargingpoint.data.prio) {
          return false
        }

        return filter.values.some(phase => {
          const isCustomPhase = validate(phase.id)

          // if custom phase check if uuid equals to that of the chargingpoint
          if (isCustomPhase) {
            return chargingpoint.data.prio.customPhase === phase.id
          }

          // if not custom phase, check if phase is in between
          else if (phase.from && phase.to) {
            const fase = chargingpoint.data.prio.fase ?? 0
            return (phase.from >= fase && phase.to <= fase)
          }

          return false
        })
      })
    },

    cpoFilter({ chargingpoints, filter }) {
      return chargingpoints.filter(chargingpoint => {
        // Atm. the filtering will be done only on stakeholders (database) and not on imported CP (operator prop b.v. EcoMovement)
        // Later on, by the import of data, mapping will be used to put CPO operator value to a stakeholder, while generating a UUID
        // and make the data equal (stakeholders)
        if (chargingpoint.data.properties.stakeholders?.length) {
          // In our database
          return filter.values.some(cpo => chargingpoint.data.properties.stakeholders.some(stakeholder => stakeholder.uuid === cpo.id))
        }

        // This was used to filter on both. imported (operator) and database data (stakeholders)
        // if (chargingpoint.data.properties.operator) {
        //   // Imported
        //   return filter.values.some(cpo => chargingpoint.data.properties.operator === cpo.label)
        // } else if (chargingpoint.data.properties.stakeholders?.length) {
        //   // In our database
        //   return filter.values.some(cpo => chargingpoint.data.properties.stakeholders.some(stakeholder => stakeholder.uuid === cpo.id))
        // }
      })
    },

    toValidateFilter({ chargingpoints }) {
      const authService = getInstance()

      return chargingpoints.filter(chargingpoint => {
        if (! CHARGINGPOINT_TYPE.SUGGESTION.includes(chargingpoint.data.properties.status)) {
          return false
        }

        if (! (chargingpoint.data.properties.validators?.length > 0)) {
          return false
        }

        const isValidator = chargingpoint.data.properties.validators?.findIndex(validator => validator.user_id === authService.user.sub) !== -1
        if (!isValidator) {
          return false
        }

        const hasVoted = rootGetters['planmode/hasUserAlreadyVoted']({
          chargingpointUuid: chargingpoint.data.uuid,
          userId: authService.user.sub,
        })
        const needsToBeVotedByValidator = hasVoted === false

        return needsToBeVotedByValidator
      })
    },

    hasParticipationCommentsFilter({ chargingpoints }) {
      return chargingpoints.filter(chargingpoint => {
        const getParticipationCommentsByChargingpointUuid = rootGetters['planmode/getParticipationCommentsByChargingpointUuid']
        const participationComments = getParticipationCommentsByChargingpointUuid({ chargingpointUuid: chargingpoint.data.uuid })
        return participationComments.length
      })
    },

    hasUnfinishedParticipationCommentsFilter({ chargingpoints }) {
      return chargingpoints.filter(chargingpoint => {
        const getParticipationCommentsByChargingpointUuid = rootGetters['planmode/getParticipationCommentsByChargingpointUuid']
        const comments = getParticipationCommentsByChargingpointUuid({ chargingpointUuid: chargingpoint.data.uuid })
        const unfinishedComments = comments.filter(({ data }) => ['new', 'todo'].includes(data.status))
        return unfinishedComments.length
      })
    },

    alreadyValidatedFilter({ chargingpoints }) {
      const authService = getInstance()

      return chargingpoints.filter(chargingpoint => {
        if (!CHARGINGPOINT_CATEGORY.VALIDATIONS.includes(chargingpoint.data.properties.status)) {
          return false
        }

        if (!(chargingpoint.data.properties.validators?.length > 0)) {
          return false
        }

        const isValidator = chargingpoint.data.properties.validators?.findIndex(validator => validator.user_id === authService.user.sub) !== -1
        if (!isValidator) {
          return false
        }

        const hasVoted = rootGetters['planmode/hasUserAlreadyVoted']({
          chargingpointUuid: chargingpoint.data.uuid,
          userId: authService.user.sub,
        })
        const hasAlreadyValidated = hasVoted === true

        return hasAlreadyValidated
      })
    },

    toDecideFilter({ chargingpoints }) {
      const authService = getInstance()

      return chargingpoints.filter(chargingpoint => {
        if (chargingpoint.data.properties.status !== 'suggestion') {
          return false
        }

        if (!chargingpoint.data.properties.beheerder) {
          return false
        }

        const isBeheerder = chargingpoint.data.properties.beheerder.user_id === authService.user.sub
        if (!isBeheerder) {
          return false
        }

        if (!chargingpoint.data.properties.isValidated) {
          return false
        }

        return true
      })
    },

    occupancyRateFilter({ chargingpoints, filter }) {
      const validLocationsUuids = rootGetters['monitoring/getFilteredLocationUuids']({
        key: 'occupancy_rate',
        from: filter.from,
        to: filter.to,
        unit: filter.unit,
      })

      return chargingpoints.filter(chargingpoint => validLocationsUuids.includes(chargingpoint.data.uuid))
    },
    uniqueUsersFilter({ chargingpoints, filter }) {
      const validLocationsUuids = rootGetters['monitoring/getFilteredLocationUuids']({
        key: 'unique_users',
        from: filter.from,
        to: filter.to,
        unit: filter.unit,
      })

      return chargingpoints.filter(chargingpoint => validLocationsUuids.includes(chargingpoint.data.uuid))
    },
    sessionFilter({ chargingpoints, filter }) {
      const validLocationsUuids = rootGetters['monitoring/getFilteredLocationUuids']({
        key: 'session_count',
        from: filter.from,
        to: filter.to,
        unit: filter.unit,
      })

      return chargingpoints.filter(chargingpoint => validLocationsUuids.includes(chargingpoint.data.uuid))
    },
    chargedFilter({ chargingpoints, filter }) {
      const validLocationsUuids = rootGetters['monitoring/getFilteredLocationUuids']({
        key: 'volume',
        from: filter.from,
        to: filter.to,
        unit: filter.unit,
      })

      return chargingpoints.filter(chargingpoint => validLocationsUuids.includes(chargingpoint.data.uuid))
    },

    hasPossibleDuplicates({ chargingpoints }) {
      const uuids = chargingpoints.reduce((uuids, chargingpoint) => {
        if (chargingpoint.data.possibleDuplicates?.length) {
          return [...uuids, chargingpoint.data.uuid, ...chargingpoint.data.possibleDuplicates]
        }
        return uuids
      }, [])
      return chargingpoints.filter(chargingpoint => uuids.includes(chargingpoint.data.uuid))
    },
  }),
}
const actions = {
  resetFilters({ dispatch, getters }) {
    getters['getFilters'].forEach(filter => dispatch('resetFilter', { id: filter.id }))
  },
  resetFilter({ getters, commit }, { id, optionID }) {
    const options =  {
      active: false,
    }

    const filter = getters['getFilterById']({ id })
    if (filter.type === FILTER_TYPE.RANGE_FILTER) {
      options.from = null
      options.to = null
    }

    if (filter.type === FILTER_TYPE.SELECT_FILTER) {
      options.values = filter.values.filter(value => value.id !== optionID)
      options.active = options.values.length > 0
    }

    commit('setFilterOptions', { id, options })
  },
  toggleFilter({ commit, getters }, { id }) {
    const options = { active: ! getters.getFilters[id].active }
    commit('setFilterOptions', { id, options })
  },
}
const mutations = {
  setFilterOptions(state, { id, options }) {
    Object.entries(options).forEach(([key, value]) => {
      state.filters[id][key] = value
    })
  },
  enableParticipationCommentsFilter() {
    state.filters['participation-has-comments'].active = true
  },
  disableParticipationCommentsFilter() {
    state.filters['participation-has-comments'].active = false
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
