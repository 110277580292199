<template>
  <SideBar class="ViewerSideBar flex-shrink-1">
    <Tabs
      v-if="isTabEnabled"
      :tabs="tabs"
      :active="activeTab"
      @selected="handleTabClick"
    />

    <!--
      The legend component affects the visibility of layers,
      so we load it in the background while the other tab is active.
      TODO: Move visibility logic to map components -->
    <Legend
      v-show="visibleTab === 'legend'"
      class="p-4"
    />

    <keep-alive>
      <PlanModeSearch v-if="visibleTab === 'search'" />
    </keep-alive>
  </SideBar>
</template>

<script>
import SideBar from '@/components/common/SideBar'
import Tabs from '@/components/common/Tabs'
import Legend from '@/components/sidebar/Legend'
import PlanModeSearch from '@/components/sidebar/PlanModeSearch'

import { mapGetters } from 'vuex'

import { isProxyByCode } from '@/services/municipalities'

export default {
  name: 'ViewerSideBar',
  components: {
    SideBar, Tabs, Legend, PlanModeSearch,
  },
  data() {
    return {
      activeTab: 'legend',
      tabs: [{
        label: 'Legenda',
        id: 'legend',
      }, {
        label: 'Laadlocaties',
        id: 'search',
      }],
    }
  },
  computed: {
    ...mapGetters('config', [
      'isPlanmodeEnabled',
    ]),
    ...mapGetters('access', [
      'canPreAccessPlanMode',
      'getActiveMunicipality',
    ]),
    /**
     * If the planmode is not active, the legend is the only tab to display
     */
    visibleTab() {
      return this.isTabEnabled ? this.activeTab : 'legend'
    },
    isTabEnabled() {
      return this.isPlanmodeEnabled || this.supersuser || this.canPreAccessPlanMode || isProxyByCode({ code: this.getActiveMunicipality })
    },
    supersuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
  },
  methods: {
    handleTabClick({ id }) {
      this.activeTab = id
    },
  },
}
</script>

<style lang="scss">
.ViewerSideBar {
  .SideBar__main {
    padding: 0 !important;
    flex-shrink: 1;
    max-height: calc(100% - 63px);
    overflow-y: visible;
    position: relative;
  }
  .Legend {
    flex-shrink: 1;
    flex-grow: 1;
    overflow-y: scroll;
    max-height: 100%;
  }
  .Tabs + .LegendWrapper .Legend {
    max-height: calc(100% - 49px);
  }
}
</style>
