<template>
  <div
    v-if="collapsed"
    class="PasswordBox d-flex flex-column"
  >
    <b-button
      squared
      variant="secondary"
      class="PasswordBox__Button"
      @click="openPasswordBox"
    >
      Wachtwoord wijzigen
    </b-button>
  </div>
  <div
    v-else
    class="PasswordBox d-flex flex-column pt-3"
  >
    <div class="px-4">
      <Feedback
        v-if="feedback.show"
        :feedback="feedback"
      />
      <form @submit="handleSubmit">
        <label
          class="PasswordBox__Label"
          for="new-password"
        >
          Nieuw wachtwoord
        </label>
        <b-input
          id="new-password"
          ref="password"
          v-model="password"
          :disabled="busy"
          class="PasswordBox__Input"
        />
      </form>
      <p class="PasswordBox__Instruction text-muted mt-2">
        Minimaal 12 tekens: letters, hoofdletters, cijfers en speciale karakters (e.g. !@#$%^&amp;*). Na het aanpassen moet u opnieuw inloggen.
      </p>
    </div>
    <b-button
      squared
      :disabled="busy"
      variant="cta"
      class="PasswordBox__Button mt-3"
      @click="handleSubmit"
    >
      Wachtwoord wijzigen
    </b-button>
  </div>
</template>

<script>

import Feedback from '@/components/form/Feedback'
import { passwordErrorTranslations } from '@/services/auth0Translations'

export default {
  name: 'PasswordBox',
  components: { Feedback },
  data() {
    return {
      busy: false,
      collapsed: true,

      password: null,
      feedback: {
        show: false,
        message: '',
        variant: 'danger',
      },
    }
  },
  watch: {
    busy(busy) {
      this.$emit('processing', { busy })
    },
  },
  methods: {
    openPasswordBox() {
      this.collapsed = false

      // Focus password input field
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleSubmit: async function(e) {
      e.preventDefault()

      if (this.password === '') {
        this.feedback = {
          show: true,
          message: 'Vul een wachtwoord in',
          variant: 'danger',
        }
        return
      }

      this.busy = true

      const token = await this.$auth.getTokenSilently()
      const api = await fetch('/api/userpassword', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          password: this.password,
        }),
      })

      let response = await api.json()

      if (response.message === 'Success') {
        this.feedback = {
          show: true,
          message: 'Geslaagd. U wordt doorverwezen in 3 seconden.',
          variant: 'success',
        }

        setTimeout(this.logout, 3000)
      }
      else {
        const message = passwordErrorTranslations({ errorMessage: response.message })

        this.feedback = {
          show: true,
          message,
          variant: 'danger',
        }
        this.busy = false
      }
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
  },
}
</script>

<style lang="scss">


.PasswordBox {
  color: var(--text-primary);
  background: var(--grey);

  .PasswordBox__Button {
    padding: 0.75rem;
  }
  .PasswordBox__Instruction {
    font-size: 0.9rem;
  }
}

</style>
