<template>
  <header class="PanelHeader d-flex justify-content-between py-3 px-4 align-items-center">
    <slot>
      <span
        v-if="superuser"
        class="PanelHeader__Indicator flex-grow-0 mr-3"
        :class="indicatorClass"
      />
      <span class="flex-grow-0">
        {{ provincie || '' }}
      </span>
      <span class="flex-grow-1 text-center">
        {{ label || '' }}
      </span>

      <span class="flex-schrink-0">
        {{ codeLabel }}
      </span>
    </slot>
    <b-button
      v-if="btnlabel !== ''"
      :disabled="!enableSave"
      variant="cta"
      class="ml-3"
      @click="handleSaveClick"
    >
      {{ btnlabel }}
    </b-button>
  </header>
</template>

<script>

import { mapGetters } from 'vuex'

import { labelByCode, provincieLabelByCode, codeLabelByCode } from '@/services/municipalities'

export default {
  name: 'PanelHeader',
  props: {
    enableSave: {
      type: Boolean,
      required: true,
    },
    /** TODO: Implement busy indicator  */
    busy: {
      type: Boolean,
      default: false,
    },
    btnlabel: {
      type: String,
      default: 'Opslaan',
    },
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('config', [
      'config',
      'isEnabled',
    ]),
    code() {
      return this.getActiveMunicipality
    },
    codeLabel() {
      return codeLabelByCode({
        code: this.getActiveMunicipality,
      })
    },
    label() {
      return labelByCode({
        code: this.getActiveMunicipality,
      })
    },
    provincie() {
      return provincieLabelByCode({
        code: this.getActiveMunicipality,
      })
    },
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
    indicatorState() {
      let config = this.config

      if (config && config.stored) {
        if (config.prognose.enabled) {
          return 'live'
        } else if (config.upload) {
          return 'upload-only'
        } else {
          return 'disabled'
        }
      }

      return 'draft'
    },
    indicatorClass() {
      let key = `PanelHeader__Indicator--${this.indicatorState}`
      let classes = {}
      classes[key] = true
      return classes
    },
  },
  methods: {
    handleSaveClick(){
      this.$emit('save')
    },
  },
}
</script>

<style lang="scss">
.PanelHeader__Indicator {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background: white;
  opacity: 0.7;

  &--live {
    background: green;
  }
  &--upload-only {
    background: orange
  }
  &--disabled {
    background: red;
  }
}
</style>
