<template>
  <b-form-group
    class="FormField"
    :state="state"
  >
    <!-- :label="label" -->
    <template #label>
      <span>{{ label }}</span>
      <span
        v-if="hasInfo"
        class="info ml-1"
        @click="openInfo"
      >
        <img
          style="margin-top: -2px; cursor: pointer"
          :src="icon({ name: 'info-circle-regular.svg' })"
          width="16"
          height="16"
        >
      </span>
    </template>

    <div v-if="type === 'datalist'">
      <b-form-input
        v-model="fieldValue"
        :type="'search'"
        :state="state"
        :accept="accept"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :disabled="isDisabled"
        list="my-list-id"
        trim
        @input="handleInput"
        @blur="handleBlur"
      />

      <datalist id="my-list-id">
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.text }}
        </option>
      </datalist>
    </div>

    <b-form-select
      v-else-if="type === 'select'"
      v-model="fieldValue"
      :options="options"
      :state="state"
      :disabled="isDisabled"
      @input="handleInput"
      @blur="handleBlur"
    />

    <div
      v-else-if="type === 'radio' && options.length === 2"
      class="FormField--choice d-flex align-items-center w-100"
    >
      <b-form-radio
        v-model="fieldValue"
        class="check"
        :state="state"
        :disabled="isDisabled"
        :value="options[0].value"
      >
        {{ options[0].text }}
      </b-form-radio>
      <b-form-radio
        v-model="fieldValue"
        class="ml-3 none"
        :state="state"
        :disabled="isDisabled"
        :value="options[1].value"
      >
        {{ options[1].text }}
      </b-form-radio>
    </div>

    <b-form-radio-group
      v-else-if="type === 'radio'"
      v-model="fieldValue"
      :options="options"
      :state="state"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :disabled="isDisabled"
      @input="handleInput"
      @blur="handleBlur"
    />

    <v-date-picker
      v-else-if="type === 'datepicker'"
      v-model="datepickerValue"
      locale="nl"
      :popover="{ visibility: 'click' }"
      @input="handleDatepickerInput"
    >
      <b-form-input
        v-model="fieldValue"
        type="text"
        :style="datepickerStyle"
        :state="state"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :disabled="isDisabled"
        trim
        @blur="handleDatepickerBlur"
      />
    </v-date-picker>

    <b-form-textarea
      v-else-if="type === 'textarea'"
      v-model="fieldValue"
      :type="type"
      :state="state"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :disabled="isDisabled"
      :rows="rows"
      trim
      @input="handleInput"
      @blur="handleBlur"
    />

    <b-form-file
      v-else-if="type === 'upload'"
      v-model="fieldValue"
      :state="state"
      :accept="accept"
      :browse-text="browseLabel"
      :placeholder="placeholder || 'Choose a file...'"
      :disabled="isDisabled"
      :drop-placeholder="dropInPlaceholder"
      @input="handleInput"
      @blur="handleBlur"
    />

    <b-input-group
      v-else
      :prepend="prepend"
      :append="append"
    >
      <b-form-input
        v-model="fieldValue"
        :type="type"
        :step="step"
        :state="state"
        :accept="accept"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :disabled="isDisabled"
        trim
        @input="handleInput"
        @blur="handleBlur"
      />
    </b-input-group>

    <template slot="invalid-feedback">
      {{ invalidFeedback }}
    </template>
  </b-form-group>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { icon } from '@/helpers/assets'

// TODO: https://github.com/charliekassel/vuejs-datepicker
// TODO: https://vue-multiselect.js.org

export default {
  name: 'FormField',
  mixins: [validationMixin],
  inject: ['registerFormField'],
  props: {
    value: {
      type: [String, Boolean, Number, Date, File],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Field is always disabled
     */
    permaDisabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    step: {
      type: String,
      default: '1',
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    placeholder: {
      type: String,
      default: '',
    },
    validationRules: {
      type: Object,
      default: () => {
        return {}
      },
    },
    // Makes it possible to disable validation
    novalidate: {
      type: Boolean,
      default: false,
    },
    // Used by `type === select & radio & datalist`
    options: {
      type: Array,
      default: () => {
        return []
      },
    },
    // Used by `type === text`
    append: {
      type: String,
      default: '',
    },
    prepend: {
      type: String,
      default: '',
    },
    // Used by `type === textarea`
    rows: {
      type: Number,
      default: 5,
    },
    // Used by `type === upload`
    browseLabel: {
      type: String,
      default: 'Browse',
    },
    dropInPlaceholder: {
      type: String,
      default: 'Drop file here...',
    },
    accept: {
      type: String,
      default: null,
    },
    // Info icon in label
    info: {
      type: String,
      default: null,
    },
    infoHeader: {
      type: String,
      default: null,
    },
    infoHTML: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fieldValue: '',
      datepickerValue: '',
      blurred: false,
    }
  },
  computed: {
    state() {
      if (this.novalidate) {
        return null
      }
      return this.$v.fieldValue.$dirty ? !this.$v.fieldValue.$error : null
    },
    isDisabled() {
      return this.disabled || this.permaDisabled
    },
    invalidFeedback() {
      let validator = this.$v.fieldValue

      // Go over the validation rules, and return the
      // name of the first rule that is broken
      let match = Object.keys(this.validationRules)
        .find((rule) => {
          return ! validator[rule]
        })

      if (match === -1) {
        return '' // apparently no rules are broken?
      }

      let params = validator.$params

      switch (match) {
        case 'email':
          return 'Voer a.u.b. een geldig e-mail adres in'
        case 'required':
          return 'Dit is een vereist veld'
        case 'minLength':
          return 'Uw invoer moet minimaal ' + params.minLength.min +' karakters zijn.'
        case 'maxLength':
          return 'Uw invoer mag maximaal ' + params.maxLength.max + ' karakters zijn.'
        case 'combinedHundred':
          return 'De verdeling moet samen uit komen op 100%'
      }

      return 'Controleer uw invoer a.u.b.'
    },
    /**
     * V-Calender overrides the validation border set by Bootstrap.
     *  These style enforce the Bootstrap styles
     */
    datepickerStyle() {
      // TODO: Use bootstrap variables
      let color = '#ced4da'
      switch (this.state) {
        case true:
          color = '#29CC8B'
          break
        case false:
          color = '#FF4E4E'
          break
      }
      return {
        'borderWidth': '1px',
        'borderColor': color,
      }
    },
    hasInfo() {
      return this.info
    },
  },
  watch: {
    /**
     * Observe changes to the value prop
     */
    value(newValue) {
      this.fieldValue = newValue
    },
    /**
     * Update Date Picker if appropriate
     *  And inform the parent when a date is invalid
     */
    fieldValue(newValue) {
      if (this.type === 'datepicker') {
        let date = new Date(newValue)
        if (! isNaN(date.getTime())) {
          this.datepickerValue = date
        } else {
          // NL formatting
          date = new Date(this.formatDate(newValue))
          if (! isNaN(date.getTime())) {
            this.datepickerValue = date
          } else {
            // Handle an invalid formatted date
            this.$emit('input', '')
          }
        }
      }
    },
  },
  created() {
    // On creation, set the internal field value
    this.fieldValue = this.value

    // If no value was passed, and this is a datepicker, default to today
    if (this.type === 'datepicker' && ! this.value) {
      this.fieldValue = this.formatDateToString(new Date())
    }

    // If contained within a Form component, register the form field
    if (this.registerFormField) {
      this.registerFormField(this)
    }
  },
  /**
   * Vuelidate validation rules. Set through the validationRules prop
   */
  validations() {
    return (this.validationRules)
      ? { fieldValue: this.validationRules }
      : {}
  },
  methods: {
    /**
     * Info modal
     */
    icon,
    openInfo() {
      this.openModal({
        title: this.infoHeader,
        content: this.infoHTML
          ? this.$createElement('div', {
            domProps: {
              innerHTML: this.info,
            },
          })
          : this.info,
      })
    },
    openModal({ title, content }) {
      this.$bvModal.msgBoxOk(
        content,
        {
          title,
          okVariant: 'secondary',
          headerClass: 'pl-5',
          bodyClass: 'pr-5 pl-5 pt-4 pb-4',
        },
      )
    },

    // Start validation after initial blur
    handleBlur() {
      if (this.blurred === false) {
        this.validate()
      }
      this.blurred = true
    },
    handleInput(value) {
      if (this.blurred || this.type === 'select') {
        this.validate()
      }
      this.$emit('input', value)
    },
    validate() {
      if (!this.novalidate) {
        this.$v.$touch()
      }
    },
    isValid() {
      // Not ideal, but otherwise form processing would cancel
      return this.novalidate ? true : !! this.state
    },
    resetValidation() {
      this.$v.$reset()
    },
    /*****
     * DATE PICKER
     */
    handleDatepickerBlur() {
      this.validate()
    },
    handleDatepickerInput(date) {
      this.fieldValue = this.formatDateToString(date)
      this.$emit('input', this.fieldValue)
    },
    formatDateToString(date) {
      let months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun',
        'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']
      return date.getDate()
        + ' ' + months[date.getMonth()]
        + ' ' + date.getFullYear()
    },
    // TODO: Not ideal, but works for now
    formatDate(date) { // e.g. 4 Jun 2019
      let months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun',
        'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']
      date = date.split(' ')
      return date[2] + '-'
        + ('0'+ (months.indexOf(date[1]) + 1)).slice(-2)
        + '-' + ('0' + date[0]).slice(-2)
    },
  },
}
</script>

<style lang="scss">
.FormField {
  font-size: 16px;
  position: relative;

  input {
    color: rgba(53, 64, 82, 0.5);
  }
  input:disabled {
    color: #495057 !important
  }

  label, legend {
    color: #7F8FA4;
    text-transform: uppercase;
    padding-bottom: 0;
  }

  .invalid-feedback {
    position: absolute;
    top: -1.75rem;
    text-align: right;
  }

  &--choice {
    height: 33px;

    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: transparent;
      background-color: transparent;
    }
    .custom-radio.check .custom-control-input:checked ~ .custom-control-label::after {
      background-color: white;
      // background-image: url('../../../assets/icons/Check-icon.svg');
      background-size: cover;
    }
    .custom-radio.none .custom-control-input:checked ~ .custom-control-label::after {
      background-color: white;
      // background-image: url('../../../assets/icons/None-icon.svg');
      background-size: cover;
    }
  }
}
</style>
