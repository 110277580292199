<template>
  <div class="TheComments">
    <div
      v-if="comments.length === 0 && isLoading"
      class="loading d-flex justify-content-center align-items-center m-5"
    >
      <b-spinner
        class="mr-3"
        variant="secondary"
      />
      <span>Loading...</span>
    </div>

    <div
      v-else
      id="commentForm"
      :class="{'highlight': isCommentFormHighlighted}"
      class="TheComments__container TC-Container"
    >
      <!-- TODO:: fix font-siz  pretty -->
      <form
        v-if="isAllowedToComment"
        class="TC-Container__input"
        @submit.stop.prevent="handleSaveComment"
      >
        <b-alert
          class="w-100"
          :show="error !== false"
          variant="danger"
        >
          {{ error }}
        </b-alert>
        <b-form-textarea
          id="commentTextArea"
          v-model="newComment"
          size="sm"
          placeholder="Plaats een opmerking"
          :disabled="disabled || editingId !== null"
          rows="1"
          max-rows="6"
        />
        <div class="w-100 d-flex justify-content-start">
          <ValidationVoter
            v-if="isAllowedToValidate"
            :validation-choice="validationChoice"
            @choice="setValidationChoice"
          />

          <b-button
            type="submit"
            size="sm"
            variant="primary"
            class="pull-right"
            :disabled="disabled || editingId !== null || isLockedForEditing"
          >
            Plaats opmerking
          </b-button>
        </div>
      </form>
      <header class="TC-Container__header d-flex justify-content-between align-items-center">
        <ValidationCounter />

        <div class="pull-right">
          <span class="mr-1">{{ commentCounter }}</span>
          <span>{{ commentCounter === 1 ? 'Opmerking' : 'Opmerkingen' }}</span>
        </div>
      </header>
      <section class="TC-Container__body">
        <ul>
          <li
            v-for="{ id : refId, name, isAllowedToEdit, isAllowedToRemove, isValidator, created_at, body, lines, validation, deleted_at, edited_at, edited_by } in comments"
            :key="refId"
            class="TC-Comment"
          >
            <div class="TC-Comment__meta d-flex justify-content-between">
              <div class="mr-1">
                <strong>{{ name }}</strong> <br>

                <div v-if="validation">
                  <template v-if="isValidator">
                    <div
                      v-if="validation === VALIDATION_VOTE.APPROVED"
                      class="TC-Comment__validation TC-Comment__validation--approved d-flex justify-content-start mt-1"
                    >
                      <b-icon-check class="TC-Comment__validation__icon" /> <strong>{{ validation }}</strong>
                    </div>
                    <div
                      v-else-if="validation === VALIDATION_VOTE.REJECTED"
                      class="TC-Comment__validation TC-Comment__validation--rejected d-flex justify-content-start mt-1"
                    >
                      <b-icon-x class="TC-Comment__validation__icon" /> <strong>{{ validation }}</strong>
                    </div>
                  </template>
                  <div v-else>
                    <b-tooltip
                      :target="'noValidatorAnymore-' + name"
                      triggers="hover"
                    >
                      deze gebruiker is geen validator meer, daarom telt zijn vote niet mee
                    </b-tooltip>
                    <span
                      :id="'noValidatorAnymore-' + name"
                      class="TC-Comment__subline"
                    >
                      <strong>{{ validation }}</strong>
                    </span>
                  </div>
                </div>
              </div>
              <span class="TC-Comment__date">{{ created_at }}</span>
            </div>

            <template v-if="editingId === refId">
              <ValidationVoter
                v-if="validation"
                class="mb-2"
                :validation-choice="validationChoice"
                @choice="setValidationChoice"
              />

              <p>
                <b-form-textarea
                  id="commentTextArea"
                  v-model="editComment"
                  size="sm"
                  :disabled="disabled"
                  rows="1"
                  max-rows="6"
                />
              </p>
            </template>
            <p
              v-else
              class="TC-Comment__body"
            >
              <span
                v-for="(line, index) in lines"
                :key="index"
              >
                <span>{{ line }}</span>
                <br>
              </span>

              <template v-if="deleted_at">
                <b-tooltip
                  :target="`${deleted_at}-${refId}`"
                  triggers="hover"
                >
                  {{ deleted_at }} <br> door {{ edited_by }}
                </b-tooltip>
                <span
                  v-if="deleted_at"
                  :id="`${deleted_at}-${refId}`"
                  class="TC-Comment__body__edited"
                >
                  (verwijderd)
                </span>
              </template>
              <template v-else-if="edited_at">
                <b-tooltip
                  v-if="edited_at"
                  :target="`${edited_at}-${refId}`"
                  triggers="hover"
                >
                  {{ edited_at }} <br> door {{ edited_by }}
                </b-tooltip>
                <span
                  v-if="edited_at"
                  :id="`${edited_at}-${refId}`"
                  class="TC-Comment__body__edited"
                >
                  (bewerkt)
                </span>
              </template>
            </p>

            <div
              v-if="editingId === refId"
              class="TC-Comment__edit d-flex justify-content-end mt-3"
            >
              <b-button
                size="sm mr-2"
                variant="outline-danger"
                :disabled="disabled"
                @click="handleCancelEditing"
              >
                Annuleren
              </b-button>
              <b-button
                size="sm"
                variant="dark"
                :disabled="disabled"
                @click="handleSaveEdit"
              >
                Opslaan
              </b-button>
            </div>
            <div
              v-else-if="isAllowedToEdit && !isLockedForEditing"
              class="TC-Comment__edit d-flex justify-content-end"
            >
              <span
                v-if="isAllowedToRemove"
                class="d-flex align-items-center u-clickable u-underline mr-2"
                :class="{'u-disabled' : disabled}"
                @click="handleDelete({ refId })"
              >
                <b-icon-trash class="mr-1" />
                <strong>Verwijderen</strong>
              </span>
              <span
                class="d-flex align-items-center u-clickable u-underline"
                :class="{'u-disabled' : disabled}"
                @click="handleStartEditing({ refId, body, validation })"
              >
                <b-icon-pencil-square class="mr-1" />
                <strong>Bewerken</strong>
              </span>
            </div>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { BIconPencilSquare, BIconTrash, BIconCheck, BIconX } from 'bootstrap-vue'

import { VALIDATION_VOTE } from '@/../shared/valueholders/validation-vote'
import ValidationCounter from '@/components/map/sidebar/ValidationCounter'
import ValidationVoter from '@/components/map/sidebar/ValidationVoter'
import InlineChargingpoint from '@/components/common/InlineChargingpoint'
import { multilineUnicodeString, getInvalidCharacters } from '@/services/validation'
import userMixin from '@/mixins/common/userMixin'
import privilegesMixin from '@/mixins/common/privilegesMixin'
import { EventBus } from '@/services/eventbus'
import { Bugfender } from '@bugfender/sdk'

export default {
  name: 'TheComments',
  components: {
    // InlineChargingpoint is renderer therefore the linter doesn't find it used.
    // eslint-disable-next-line vue/no-unused-components
    InlineChargingpoint,
    ValidationVoter,
    ValidationCounter,
    BIconPencilSquare,
    BIconCheck,
    BIconTrash,
    BIconX,
  },
  mixins: [privilegesMixin, userMixin],
  props: {
    chargingpoint: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newComment: null,
      validationChoice: null,

      disabled: false,
      error: false,
      isLoading: false,
      isCommentFormHighlighted: false,

      editingId: null,
      editComment: '',
    }
  },
  watch: {
    chargingpoint: {
      immediate: true,
      handler () {
        this.fetchCommentsData()
      },
    },
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('planmode', [
      'getSelectedChargingpoint',
      'getCommentsBySelectedChargingpoint',
      'getSuccessors',
      'getPredecessors',
      'getAllAlternativeLocationsByUuid',
      'getPredecessorsByUuid',
      'getFlaggedLocations',
    ]),
    ...mapGetters('users', [
      'getUsersByCode',
    ]),
    commentCounter() {
      return this.comments.length || 0
    },
    comments() {
      return this.getCommentsBySelectedChargingpoint
        .slice()
        .sort((a, b) => -a.data.Time.localeCompare(b.data.Time))
        .map((comment) => {
          const isValidator = this.chargingpoint.data.properties.validators?.findIndex(validator => validator.user_id === comment.data.UserId) !== -1

          return {
            id: comment.ref['@ref'].id,
            name: comment.data.UserName,
            userId: comment.data.UserId,
            body: comment.data.Message,
            lines: (comment.data.Message ?? '').match(/[^\r\n|\r|\n]+/g),
            validation: comment.data.validation ?? false,
            created_at: comment.data.Time ? this.toLocalDate({ isoString: comment.data.Time }) : 'Datum onbekend',
            edited_at: comment.data.edited_at ? this.toLocalDate({ isoString: comment.data.edited_at }) : false,
            deleted_at: comment.data.deleted_at ? this.toLocalDate({ isoString: comment.data.deleted_at }) : false,
            edited_by: comment.data.editor ? comment.data.editor.name : null,
            isAllowedToEdit: (comment.data.UserId === this.currentUserId) || this.superuser,
            isAllowedToRemove: (comment.data.UserId === this.currentUserId) || this.superuser,
            isValidator: isValidator || comment.data.UserName === 'System',
          }
        })
        .filter(comment => comment.validation || comment.deleted_at === false)
        // map deleted validations to a deleted hint
        .map(comment => {
          if (! (comment.validation && comment.deleted_at)) {
            return comment
          }

          return {
            ...comment,
            userId: null,
            name: 'System',
            validation: false,
            lines: `Het validatie oordeel van ${comment.name} is verwijderd.`.match(/[^\r\n|\r|\n]+/g),
            isAllowedToEdit: false,
            isAllowedToRemove: false,
          }
        })
    },
    validations() {
      return this.comments.filter(comment => comment.validation !== false)
    },
    isAllowedToComment() {
      // this.$auth.user TODO:: add permission check
      return true
    },
    isAllowedToValidate() {
      /* has validators assigned? */
      if (! this.chargingpoint.data.properties.validators) {
        return false
      }

      /* is user eligible to vote at all */
      if (this.chargingpoint.data.properties.validators.findIndex(validator => validator.user_id === this.currentUserId) === -1) {
        return false
      }

      /* user hasn't already voted */
      if (this.validations.findIndex(validation => validation.userId === this.currentUserId) !== -1) {
        return false
      }

      return true
    },
    isLockedForEditing () {
      return this.chargingpoint.data.isLockedForEditing
    },
  },
  created() {
    this.VALIDATION_VOTE = VALIDATION_VOTE
  },
  methods: {
    ...mapActions('planmode', [
      'addOrUpdateComments',
      'addOrUpdateChargingPoint',
      'deleteComment',
      'fetchCommentsByChargingpointUuid',
      'activatePlanmode',
      'planAlternativeLocationForUuid',
      'addToFlaggedLocations',
    ]),
    async fetchCommentsData() {
      this.isLoading = true
      await this.fetchCommentsByChargingpointUuid({ chargingpointUuid: this.chargingpoint.data.uuid })
      this.isLoading = false
    },
    toLocalDate({ isoString }) {
      const format = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }

      let [y, m, d, hh, mm, ss, ms] = isoString.match(/\d+/g)
      let date = new Date(Date.UTC(y, m - 1, d, hh, mm, ss, ms))

      return date.toLocaleString([], format)
    },
    async saveComment(data) {
      const token = await this.$auth.getTokenSilently()

      const api = await fetch('/api/commentsave', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(data),
      })

      return await api.json()
    },
    async fetchDeleteComment({ refId }) {
      const token = await this.$auth.getTokenSilently()

      const api = await fetch('/api/commentdelete', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({ code: this.getActiveMunicipality, refId }),
      })

      return await api.json()
    },
    handleSaveComment: async function () {
      if (this.isLockedForEditing) {
        return
      }

      let comment = this.newComment

      // if validation is approved, a comment is optional and will be automatically substituted, see: https://ev-it.atlassian.net/browse/TOOLS-1611
      if (! comment && this.validationChoice === VALIDATION_VOTE.APPROVED) {
        comment = 'Akkoord met locatie'
      }

      if (comment === null || comment.trim() === '') {
        this.error = 'Opmerking mag niet leeg zijn'
        return
      }

      this.disabled = true
      this.error = false

      if (! multilineUnicodeString(comment)) {
        this.error = `Uw invoer bevat karakters die uit veiligheidsoverweging worden geweigerd: ${getInvalidCharacters(comment)}`
        this.disabled = false
        return
      }

      const data = this.composeCommentData(comment)

      this.saveComment(data)
        .then(response => {
          this.updateCommentsAndChargingpointFromResponse(response)

          this.promptIfNecessary()

          this.validationChoice = null
          this.newComment = ''
          this.disabled = false
        })
        .catch((e) => {
          Bugfender.error('saving comment failed: ', e)

          this.error = 'De opmerking kon niet worden opgeslagen.'
          this.disabled = false
        })
    },
    updateCommentsAndChargingpointFromResponse(response) {
      if (response.error_description ) {
        throw response.error_description
      }

      if (response.chargingpoint) {
        this.addOrUpdateChargingPoint({ chargingpoint: response.chargingpoint })
      }

      if (response.comments?.length) {
        this.addOrUpdateComments({ comments: response.comments })
      }
    },
    composeCommentData(message, refId = null) {
      return {
        code: this.getActiveMunicipality,
        refId: refId,
        chargingpointUuid: this.chargingpoint.data.uuid,
        userId: this.currentUserId,
        userName: this.currentUserName,
        message: message,
        validation: this.validationChoice,
      }
    },
    handleStartEditing({ refId, body, validation }) {
      this.handleCancelEditing()
      this.validationChoice = validation ? validation : null
      this.isEditingValidation = validation ? validation : null
      this.editComment = body
      this.editingId = refId
    },
    handleCancelEditing() {
      this.validationChoice = null
      this.editingId = null
      this.editComment = ''
    },
    handleSaveEdit: async function () {
      if (this.editComment === null || this.editComment.trim() === '') {
        this.error = 'Opmerking mag niet leeg zijn'
        return
      }

      if (this.isEditingValidation && this.validationChoice === null) {
        this.error = 'Validatie oordeel mag niet leeg zijn'
        return
      }

      this.disabled = true
      this.error = false

      if (! multilineUnicodeString(this.editComment)) {
        this.error = `Uw invoer bevat karakters die uit veiligheidsoverweging worden geweigerd: ${getInvalidCharacters(this.editComment)}`
        this.disabled = false
        return
      }


      const data = this.composeCommentData(this.editComment, this.editingId)

      this.saveComment(data)
        .then(response => {
          this.updateCommentsAndChargingpointFromResponse(response)

          this.promptIfNecessary()

          this.handleCancelEditing()
          this.disabled = false
        })
        .catch(() => {
          this.error = 'De opmerking kon niet worden opgeslagen.'
          this.handleCancelEditing()
          this.disabled = false
        })
    },
    // TODO:: add isLoading state for deleting
    handleDelete: async function ({ refId }) {
      this.disabled = true
      this.error = false

      this.$bvModal.msgBoxConfirm('Bevestig het verwijderen van dit comment.', {
        okVariant: 'danger',
        okTitle: 'Bevestigen',
        cancelTitle: 'Annuleren',
      })
        .then(async (decision) => {
          if (! decision) {
            this.disabled = false
            return
          }

          this.fetchDeleteComment({ refId })
            .then(response => {
              this.updateCommentsAndChargingpointFromResponse(response)

              this.disabled = false
            })
            .catch(() => {
              this.error = 'De opmerking kon niet worden verwijderd.'
              this.disabled = false
            })
        })
    },
    setValidationChoice(choice) {
      if (this.validationChoice === choice) {
        this.validationChoice = null
        return
      }

      this.validationChoice = choice
    },
    promptIfNecessary() {
      // only prompt once per alternative location (group)
      if (this.getFlaggedLocations.has(this.chargingpoint.data.uuid)) return

      const origin = this.getPredecessorsByUuid({ uuid: this.chargingpoint.data.uuid }).slice().pop() ?? this.getSelectedChargingpoint
      const related = this.getAllAlternativeLocationsByUuid({ uuid: origin.data.uuid })
      const chargingpoints = [origin].concat(related)
      const total = chargingpoints.length

      if (total <=1 && this.validationChoice === VALIDATION_VOTE.REJECTED) {
        this.promptToPickAlternativeLocation()
        return
      }

      if (total > 1 && this.validationChoice === VALIDATION_VOTE.REJECTED) {
        this.promptAfterRejection({ chargingpoints })
      }

      if (total > 1 && this.validationChoice === VALIDATION_VOTE.APPROVED) {
        this.promptAfterApproval({ chargingpoints })
      }

      this.addToFlaggedLocations({ chargingpoints })
    },
    createPromptNode({ message, chargingpoints }) {
      const h = this.$createElement
      return h('div', [
        h('p', message),
        h('p', { class: 'mb-1 font-weight-bold' }, 'Alternatieve locatie(s):'),
        ...chargingpoints.map(successor => h('InlineChargingpoint', {
          props: {
            chargingpoint: successor,
          },
        })),
      ])
    },
    promptAfterRejection({ chargingpoints }) {
      const count = chargingpoints.length
      const message = `U dient ook de alternatieve locatie(s) te valideren. U dient één van de ${count} locaties goed te keuren om tot een goede dekking te komen. De andere locaties mag u afkeuren. Indien u locaties afkeurt dient u alternatieve locatie te plannen en deze te koppelen aan dit locatievoorstel.`
      const body = this.createPromptNode({ message, chargingpoints })

      this.$bvModal.msgBoxOk(
        [body],
        {
          title: 'Locatie afgekeurd',
          okVariant: 'primary',
          okTitle: 'Ok',
        },
      )
    },
    promptAfterApproval({ chargingpoints }) {
      const count = chargingpoints.length
      const message = `U heeft deze locatie goedgekeurd. Er zijn ook alternatieve locaties gekoppeld. U dient deze ook te bekijken. Bij voorkeur wordt één van de ${count} locaties goedgekeurd om tot een goede dekking te komen.`
      const body = this.createPromptNode({ message, chargingpoints })

      this.$bvModal.msgBoxOk(
        [body],
        {
          title: 'Locatie goedgekeurd',
          okVariant: 'primary',
          okTitle: 'Ok',
        },
      )
    },
    promptToPickAlternativeLocation() {
      this.$bvModal.msgBoxConfirm(
        'U heeft een locatie als eerste afgekeurd. We vragen u daarom een alternatieve locatie te plannen.',
        {
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
          cancelVariant: 'secondary',
          cancelTitle: 'Geen alternatief mogelijk',
          okVariant: 'danger',
          okTitle: 'Inplannen',
        },
      )
        .then(result => {
          if (result) {
            const { uuid, coordinates } = this.getSelectedChargingpoint.data

            EventBus.$emit('deselect-chargingpoint')

            this.activatePlanmode()
            this.planAlternativeLocationForUuid({ uuid })

            if (this.$store.map) {
              this.$store.map.flyTo({
                center: coordinates,
                zoom: 17,
              })
            }
          }

          if (! result) {
            this.newComment = 'Geen alternatief mogelijk, omdat...'
            this.isCommentFormHighlighted = true

            // remove after animations ended
            const ANIMATION_DURATION = 1020
            setTimeout(() => this.isCommentFormHighlighted = false, ANIMATION_DURATION)
          }
        })
    },
  },
}
</script>

<style lang="scss">


#commentForm {
  position: relative;

  &.highlight::before {
    content: "\2192";
    position: absolute;
    color: var(--primary);
    left: 25px;
    top: 70px;
    transform: rotate(293deg) scale(6.5);

    animation-duration: .5s;
    animation-name: move;
    animation-iteration-count: 2;
    transition-timing-function: ease-in-out;
  }
}

#commentTextArea {
  .highlight & {
    animation-duration: .5s;
    animation-name: blink;
    animation-iteration-count: 2;
    transition-timing-function: ease-in-out;
  }
}

@keyframes blink {
  0% { box-shadow: none; }

  50% { box-shadow: 0 0 3px 2px var(--primary); }

  100% { box-shadow: none; }
}

@keyframes move {
  0% { top: 70px; }

  50% { top: 80px; }

  100% { top: 70px; }
}

.u-clickable {
  cursor: pointer;
}

.pull-right {
  margin-left: auto
}

.no-outlines {
  box-shadow: none !important;
}

//GENERAL COMPONENT LAYOUT
$padding-sm: 6px;

.TheComments {
  position: relative;
  max-height: 100%;
}

//NAMESPACED COMMENT BLOCK LAYOUT
.TC-Container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto 1fr;
  max-width: 33vw;
  height: 100%;

  &__body {
    ul {
      padding-left: 0;
      list-style: none;
      margin-bottom: 0;
    }
  }

  &__header,
  &__input {
    padding: $padding-sm;
    border-bottom: 1px solid var(--border-color);
  }

  &__header {
    font-weight: 500;
    font-size: .9rem
  }

  &__input {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    justify-items: right;
    gap: 10px;
  }

  // standard bootstrap colors
  &__validation {
    &--approved {
      &.checked {
        color: #155724 !important;
        background: #d4edda !important;
        border-color: #c3e6cb !important;
      }

      &:hover {
        color: #fff !important;
        background-color: #28a745 !important;
        border-color: #28a745 !important;
      }
    }

    &--rejected {
      &.checked {
        color: #721c24 !important;
        background: #f8d7da !important;
        border-color: #f5c6cb !important;
      }

      &:hover {
        color: #fff !important;
        background-color: #dc3545 !important;
        border-color: #dc3545 !important;
      }
    }
  }
}

//NAMESPACED COMMENT LAYOUT
.TC-Comment {
  padding: $padding-sm; // TODO:: double check, maybe doesn't need the padding left + right, so with a long name, also the date fits
  border-bottom: 1px solid var(--border-color);

  p {
    margin-bottom: 0;
  }

  &:last-child {
    border: none;
  }

  &__meta {
    padding-bottom: $padding-sm;
  }

  &__date,
  &__subline {
    color: #bbbbbb;
    font-size: 0.82rem;
  }

  &__edit {
    font-size: 0.82rem;
  }

  &__body {
    &__edited {
      font-size: 0.9rem;
      color: #bbbbbb;
    }
  }

  &__validation {
    &--approved {
      color: #5d8c30 !important;
    }
    &--rejected {
      color: var(--danger);
    }

    &__icon {
      width: 20px;
      height: 20px;
      margin-top: -2px;
      margin-left: -4px;
    }
  }
}
</style>
