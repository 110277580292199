<template>
  <div v-if="! isEmpty" class="MenuGeneratorSegment">
    <h3>{{ item.label }}</h3>

    <template v-for="(item,index) in menu">
      <MenuGeneratorLink 
        v-if="item.type === 'link'" 
        :item="item" 
        :key="`link_${index}`" />
      <MenuGeneratorSegment 
        v-else-if="item.type === 'segment'" 
        :item="item" 
        :key="`segment_${index}`" 
        :depth="depth + 1" />
    </template>
  </div>
</template>

<script>
import MenuGeneratorLink from '@/components/manual/MenuGeneratorLink.vue'

export default {
  name: 'MenuGeneratorSegment',
  components: {
    MenuGeneratorLink,
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    depth: {
      type: Number,
      required: true
    }
  },
  computed: {
    menu() {
      return this.item.items || []
    },
    isEmpty() {
      return this.menu.length === 0
    }
  }
}
</script>

<style lang="scss">
.MenuGeneratorSegment {
  padding-top: 24px;
  padding-bottom: 12px;

  &:first-child {
    padding-top: 0;
  }

  h3 {
    font-size: 1rem;
    font-weight: bold;
  }
}
</style>