<template>
  <div class="ActiveTab MonitoringTab">
    <LocatieInformatieHeader :chargingpoint="chargingpoint">
      <h4>Monitoring</h4>

      <b-form-select
        v-if="isRealized && hasConnectionPoints"
        v-model="selectedPointToShow"
        class="mt-1"
        :options="connectionPointOptions"
      />
    </LocatieInformatieHeader>

    <div class="ActiveTab__Content">
      <p
        v-if="loading"
        class="text-muted"
      >
        Loading...
      </p>
      <template v-else>
        <template v-if="isRealized">
          <Feedback
            v-if="demo"
            class="mb-3"
            :dismissible="false"
            :feedback="{ variant: 'warning' }"
          >
            <strong>Dit is demo data</strong> <br>
            <span>Bij interesse neem contact met ons op via <MailtoSupport /></span>
          </Feedback>

          <div
            v-if="hasConnectionPoints"
            class="LocatieInformatie__Content"
          >
            <ChartOccupancyRate
              :data="data"
              :options="options.occupancy"
              title="Bezettingsgraad"
            />
            <ChartChargedVolume
              :data="data"
              :options="options.chargedVolume"
              title="Geladen kWh"
            />
            <ChartSessionCount
              :data="data"
              :options="options.session"
              title="Aantal sessies"
            />
            <ChartUniqueUsers
              :data="data"
              title="Unieke gebruikers"
            />
          </div>
          <p v-else>
            Geen monitoring data
          </p>
        </template>
        <p v-else>
          Monitoring is alleen beschikbaar voor gerealiseerde laadpalen.
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Feedback from '@/components/form/Feedback'
import LocatieInformatieHeader from '@/components/map/sidebar/LocatieInformatieHeader'
import ChartOccupancyRate from '@/components/map/sidebar/Charts/ChartOccupancyRate'
import ChartChargedVolume from '@/components/map/sidebar/Charts/ChartChargedVolume'
import ChartSessionCount from '@/components/map/sidebar/Charts/ChartSessionCount'
import ChartUniqueUsers from '@/components/map/sidebar/Charts/ChartUniqueUsers'
import monitoringDemoData from '@/data/monitoring-demo-data.json'
import MailtoSupport from '@/components/common/MailtoSupport'
import { CHARGINGPOINT_TYPE } from '@/../shared/valueholders/chargingpoint-statuses'
import { DEFAULT_THRESHOLD } from '@/../shared/valueholders/monitoring'

const LAADPAAL_SELECTED = 'laadpaal_is_selected'

export default {
  components: {
    ChartOccupancyRate,
    ChartChargedVolume,
    ChartSessionCount,
    ChartUniqueUsers,
    LocatieInformatieHeader,
    Feedback,
    MailtoSupport,
  },
  data() {
    return {
      demo: false,
      data: {},
      selectedPointToShow: LAADPAAL_SELECTED,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('config', [
      'isMonitoringEnabled',
      'thresholds',
    ]),
    ...mapGetters('planmode', {
      chargingpoint: 'getSelectedChargingpoint',
    }),
    ...mapGetters('monitoring', {
      locationData: 'getSelectedLocationData',
    }),
    ...mapGetters('access',  {
      activeMunicipality: 'getActiveMunicipality',
    }),
    connectionPoints() {
      if (! this.locationData) {
        return []
      }

      return this.locationData.connectionPoints
    },
    isRealized() {
      return CHARGINGPOINT_TYPE.REALIZED.includes(this.chargingpoint.data.properties.status)
    },
    hasConnectionPoints() {
      return this.connectionPoints.length > 0
    },
    thresholdOccupancy() {
      let threshold = parseInt(this.thresholds.occupancy.value)
      return Number.isNaN(threshold) ? DEFAULT_THRESHOLD.OCCUPANCY : threshold
    },
    thresholdChargedVolume() {
      let threshold = parseInt(this.thresholds.chargedVolume.value)
      threshold = Number.isNaN(threshold) ? DEFAULT_THRESHOLD.CHARGED_VOLUME : threshold

      if (this.selectedPointToShow === LAADPAAL_SELECTED) {
        return threshold * this.locationData.connectionPoints.length
      }

      return threshold
    },
    thresholdSession() {
      let threshold = parseInt(this.thresholds.session.value)
      threshold = Number.isNaN(threshold) ? DEFAULT_THRESHOLD.SESSION : threshold
      if (this.selectedPointToShow === LAADPAAL_SELECTED) {
        return threshold * this.locationData.connectionPoints.length
      }

      return threshold
    },
    options() {
      return {
        occupancy: {
          thresholdEnabled: this.thresholds.occupancy.enabled ?? true,
          threshold: this.thresholdOccupancy,
        },
        chargedVolume: {
          thresholdEnabled: this.thresholds.chargedVolume.enabled ?? true,
          threshold: this.thresholdChargedVolume,
        },
        session: {
          thresholdEnabled: this.thresholds.session.enabled ?? true,
          threshold: this.thresholdSession,
        },
      }
    },
    connectionPointOptions() {
      const inflectedLabel = this.locationData.connectionPoints.length > 1 ? 'laadpunten' : 'laadpunt'

      const options = this.locationData.connectionPoints
        .map(entry => (
          {
            value: entry.charge_point_id,
            text: entry.charge_point_id,
          }
        ))
        .sort((a, b) => a.text.localeCompare(b.text))

      options.unshift({ value: LAADPAAL_SELECTED, text: `Locatie (${this.locationData.connectionPoints.length} ${inflectedLabel})` })

      return options
    },
  },
  watch: {
    selectedPointToShow(selection) {
      this.loadData(selection)
    },
    chargingpoint() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  destroyed() {
    this.deselectLocation()
  },
  methods: {
    ...mapActions('monitoring', [
      'fetchConnectionPointsByUuid',
    ]),
    ...mapMutations('monitoring', [
      'upsertLocationData',
    ]),
    ...mapActions('monitoring', [
      'selectLocation',
      'deselectLocation',
    ]),
    init() {
      // Monitoring is only for realized chargingpoints
      if (!this.isRealized) {
        return
      }

      if (this.isMonitoringEnabled === false) {
        this.initWithDemoData()
        return
      }

      this.initWithRealData()
    },
    async initWithDemoData() {
      this.demo = true

      this.selectLocation({ location: 'DEMO-LAADPUNT' })

      // if data is already loaded
      if (this.locationData && this.locationData.connectionPoints.length > 0) {
        this.useAggregatedData()
        return
      }

      this.loading = true

      this.upsertLocationData({ data: monitoringDemoData })

      this.useAggregatedData()

      this.loading = false
    },
    async initWithRealData() {
      this.selectLocation({ location: this.chargingpoint.data.uuid })

      // if data is already loaded
      if (this.locationData && this.locationData.connectionPoints.length > 0) {
        this.useAggregatedData()
        return
      }

      this.loading = true

      await this.fetchConnectionPointsByUuid({ uuid: this.chargingpoint.data.uuid, code: this.activeMunicipality })

      this.useAggregatedData()

      this.loading = false
    },
    loadData(selection) {
      this.selectedPointToShow = selection

      if (selection === LAADPAAL_SELECTED) {
        this.useAggregatedData()
        return
      }

      const index = this.locationData.connectionPoints.findIndex(connectionPoint => connectionPoint.charge_point_id === selection)

      if (index === -1) {
        this.useAggregatedData()
        return
      }

      this.data = this.locationData.connectionPoints[index].data
    },
    useAggregatedData() {
      this.data = this.locationData?.aggregated
    },
  },
}
</script>

<style lang="scss">
  .MonitoringTab {
    &.ActiveTab {
      overflow-x: hidden;
    }

    &__SelectDropdown {
      position: absolute;
      right: 60px;
      top: 8px;
    }
  }

  .Chart {
    &__Header {
      display: flex;
      font-size: 1.15rem;

      &__Icon {
        width: 25px;
        height: 25px;
        display: flex;
        margin-right: 3px;
      }
    }
  }

  .ggl-tooltip {
    white-space: nowrap;
    padding: 5px;
  }

  // fix flickering tooltip: https://github.com/google/google-visualization-issues/issues/2162
  div.google-visualization-tooltip { pointer-events: none }
  svg > g > g:last-child { pointer-events: none }
  svg > g:last-child > g:last-child { pointer-events: none }
</style>
