<template>
  <div
    v-if="superuser && loaded"
    class="Page--Import Import h-100 d-flex flex-column flex-grow-1"
  >
    <ImportNavBar />
    <div class="Import__Main d-flex flex-grow-1">
      <div class="Import__Content d-flex position-relative flex-grow-1 flex-shrink-0">
        <FactsheetExportPanel />
      </div>
    </div>
    <Overlay />
  </div>
  <div
    v-else
    class="h-100 w-100 d-flex justify-content-center align-items-center"
  >
    <!-- TODO: Make this looking nicer & offer a back button to Home / Contact address -->
    <div class="d-flex align-items-center">
      <b-spinner
        class="mr-3"
        label="Loading..."
      />
      <span>Loading...</span>
    </div>
  </div>
</template>

<script>
/**
 * This component mainly takes care of the (responsive) layout
 */
import Overlay from '@/components/common/Overlay'
import FactsheetExportPanel from '@/components/export/FactsheetExportPanel'
import ImportNavBar from '@/components/navbar/ImportNavBar'
import { mapGetters } from 'vuex'
import privilegesMixin from '@/mixins/common/privilegesMixin'

export default {
  name: 'FactsheetExport',
  components: {
    FactsheetExportPanel,
    ImportNavBar,
    Overlay,
  },
  mixins: [privilegesMixin],
  data() {
    return {
      slug: '',
    }
  },
  computed: {
    ...mapGetters('config', [
      'loaded', 'hasConfig', 'getConfigBySlug',
    ]),
  },

  watch: {},

  /**
   * Load the municipality information
   */
  created() {
  },

  methods: {},
}
</script>

<style lang="scss">
.Import {
  &__Content {
    width: 100%;
  }
  &__Main {
    max-height: calc(100% - 80px);
    // overflow-y: scroll;
    overflow-y: scroll;
  }
}

</style>
