<template>
  <div class="PrognosePanel d-flex">
    <aside class="PrognosePanel__Aside flex-shrink-0 mr-3">
      <h3 class="PrognosePanel__Title">
        Prognose Instellingen
      </h3>
      <div class="w-75 mt-3">
        <!-- TODO:: add description? -->
      </div>
    </aside>

    <div class="PrognosePanel__Main flex-grow-1 flex-shrink-1">
      <div class="d-flex align-items-center">
        <span class="PrognosePanel__Label mr-3">
          Standaardjaar
        </span>
        <div class="flex-grow-1 mr-3">
          <vSelect
            v-model="data.defaultYear"
            :options="data.years"
          />
        </div>
        <SvgIcon
          id="defaultYear"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="defaultYear"
          triggers="hover"
        >
          Dit is het jaar dat standaard getoond wordt wanneer een gemeente wordt ingeladen
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="PrognosePanel__Label mr-3">
          Scenario jaren
        </span>
        <vSelect
          v-model="data.years"
          class="flex-grow-1 mr-3"
          multiple
          :options="options"
        />
        <SvgIcon
          id="scenarioYears"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="scenarioYears"
          triggers="hover"
        >
          Dit zijn de scenario jaren waarvan de gebruikers de prognose kunnen bekijken. Standaard: 2021, 2023, 2025
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="PrognosePanel__Label mr-3">
          Scenario modellen
        </span>
        <vSelect
          v-model="data.models"
          class="flex-grow-1 mr-3"
          multiple
          :options="modelOptions"
        />
        <SvgIcon
          id="scenarioModels"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="scenarioModels"
          triggers="hover"
        >
          De beschikbare scenario modellen
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="PrognosePanel__Label mr-3">
          EV Limiet
        </span>
        <b-input
          v-model="data.limit"
          type="number"
        />
        <span class="flex-grow-1" />
        <SvgIcon
          id="evLimit"
          icon="info-circle-regular"
          tooltip=""
        />
        <b-tooltip
          target="evLimit"
          triggers="hover"
        >
          De prognose kent uitschieters. Deze maximale waarde is waarop de prognose wordt afgekapt. Standaard 40. Voor grote steden wordt deze verhoogd.
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="PrognosePanel__Label mr-3">
          Legenda categorieën
        </span>

        <div class="PrognosePanel__Breakpoints d-flex flex-wrap flex-shrink-1">
          <span class="d-flex align-items-center mb-3">
            <span class="flex-shrink-0 mr-3">0</span>
            <span class="flex-shrink-0 mr-3">t/m</span>
            <b-input
              v-model="data.breakpoint_one"
              type="number"
              class="mr-3"
            />
            <span class="flex-shrink-0 mr-3"> t/m</span>
            <b-input
              v-model="data.breakpoint_two"
              type="number"
              class="mr-3"
            />
          </span>
          <span class="d-flex align-items-center mb-3">
            <span class="Breakpoints__Repeat flex-shrink-0 mr-3">
              {{ data.breakpoint_two }}
            </span>
            <span class="flex-shrink-0 mr-3"> t/m</span>
            <b-input
              v-model="data.breakpoint_three"
              type="number"
              class="mr-3"
            />
            <span class="flex-shrink-0 mr-2"> +</span>
          </span>
        </div>

        <span class="flex-grow-1" />
        <SvgIcon
          id="breakpoints"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="breakpoints"
          triggers="hover"
        >
          Dit zijn de legenda categorieën voor de prognose resultaten laag. Standaard 6, 12, 24. Voor grote steden worden deze verhoogd.
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from '@/components/form/vSelect'
import SvgIcon from '@/components/common/SvgIcon'

import { mapGetters } from 'vuex'

export default {
  name: 'PrognosePanel',
  components: {
    vSelect, SvgIcon,
  },
  props: {
    /**
     * The data model values
     */
    values: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: [...Array(11).keys()].map( i => i+2020 ),

      // The data models
      data: {
        enabled: true,
        years: [],
        defaultYear: null,
        models: [],
        limit: 0,
        breakpoint_one: null,
        breakpoint_two: null,
        breakpoint_three: null,
      },

      trackChanges: true,
    }
  },
  computed: {
    ...mapGetters('deployment', ['DeploymentCode']),
    ...mapGetters('deployment', { modelOptions: 'getPrognoseModels' }),
  },
  watch: {
    /**
     * Values passed via props override the data, without signaling input changes
     */
    values() {
      this.setValues()
    },
    /**
     * When any input changes, pass it on
     */
    data: {
      handler(data) {
        if (this.trackChanges) {
          this.$emit('input', data)
        }
      },
      deep: true,
    },
  },
  created() {
    this.setValues()
  },
  methods: {
    /**
     * Set prop values as new data, without signaling input changes
     */
    setValues() {
      this.trackChanges = false
      this.data = {
        ...this.values,
        defaultYear: this.values.defaultYear || this.values.years?.[0] || null,
      }

      this.$nextTick(() => {
        this.trackChanges = true
      })
    },
  },
}
</script>

<style lang="scss">
.PrognosePanel {
  &__Aside {
    width: 200px;

    @media (min-width: 1280px) {
      width: 275px;
    }
  }
  &__Main {
    font-size: 1.25rem;

    .form-control {
      width: 100px;
      font-size: 1.25rem;
    }
  }
  &__Breakpoints {
    .form-control {
      width: 100px;
    }
    .Breakpoints__Repeat {
      @media (min-width: 1410px) {
        display: none;
      }
    }
  }
  &__Label {
    flex-shrink: 0;
    width: 175px;
  }
  .SvgIcon {
    flex-shrink: 0;
    font-size: 1.75rem;
  }
  .mb-3 {
    margin-bottom: 0 !important;
  }
}
</style>
