<template>
  <div class="ActionSidebar open">
    <div class="ActiveTab InfoTab">
      <LocatieInformatieHeader
        class="pl-4"
        :chargingpoint="getSelectedChargingpoint"
      >
        <h4>Alternatieve locatie kiezen</h4>
      </LocatieInformatieHeader>

      <div class="LocatieInformatie__Content">
        <FuseSearch
          class="in-modal mt-4"
          :items="items"
          placeholder="Zoek op nummer of adres"
          @select="handleSelect"
        />
      </div>

      <div class="sticky-bottom d-flex justify-content-end pr-3">
        <b-button
          size="sm"
          variant="danger"
          @click="hideAlternativeLocations"
        >
          Annuleren
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FuseSearch from '@/components/common/FuseSearch'
import { CHARGINGPOINT_STATUS } from '../../../../shared/valueholders/chargingpoint-statuses'
import LocatieInformatieHeader from '@/components/map/sidebar/LocatieInformatieHeader'

export default {
  name: 'ActionSidebarSearch',
  components: { LocatieInformatieHeader, FuseSearch },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    ...mapGetters('planmode', ['getSelectedChargingpoint', 'getSelectedChargingpointUuid', 'getChargingPoints', 'isPlanningAlternativeLocation']),
    items() {
      return this.getChargingPoints
        .filter(chargingpoint => {
          if (! [CHARGINGPOINT_STATUS.REJECTED, CHARGINGPOINT_STATUS.SUGGESTION].includes(chargingpoint.data.properties.status)) return false
          if (chargingpoint.data.uuid === this.getSelectedChargingpointUuid) return false

          return true
        })
        .map(chargingpoint => ({
              id: chargingpoint.data.properties.id,
              address: chargingpoint.data.address?.simple_address || '',
              status: chargingpoint.data.properties.status,
              uuid: chargingpoint.data.uuid,
              get text() {
                return `${this.id}: ${this.address}`
              },
            }
        ))
    },
  },
  methods: {
    ...mapActions('planmode', [
      'hideAlternativeLocations',
      'planAlternativeLocationForUuid',
    ]),
    toggle() {
      this.open = !this.open
    },
    setActiveTab(tabName) {
      this.selectTab({ tabName })

      if (tabName !== null && this.open === false) {
        this.toggle()
      }
    },
    handleSelect({ uuid }) {
      this.planAlternativeLocationForUuid({ uuid })
      this.hideAlternativeLocations()
    },
  },
}
</script>

<style lang="scss" scoped>
.sticky-bottom {
  width: 100%
}
</style>
