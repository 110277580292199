<template>
  <div
    v-if="!isLoadingFauna"
    class="Pages w-100 h-100 py-5 mh-100"
  >
    <FactsheetControls
      :pdf-title="pdfTitle"
      :download-disabled="!hasFactsheetData"
    >
      <div class="d-flex align-items-center m-3">
        <strong class="mr-2">Weergave:</strong>
        <InlineToggle
          :options="[{label: 'Basis', value: 'basic'}, {label: 'Detail', value: 'detailed'}]"
          @selected="handleSelected"
        />
      </div>
      <div class="d-flex align-items-center m-3">
        <strong class="mr-2">Model:</strong>
        <InlineToggle
          v-if="modelOptions.length > 0"
          :options="modelOptions"
          @selected="handleSelectedModel"
        />

        <span v-else> - </span>
      </div>
      <div class="d-flex align-items-center m-3">
        <strong class="mr-2">Jaartallen:</strong>
        <multiselect
          :value="yearsSelected"
          :options="yearSelectOptions"
          :multiple="true"
          :close-on-select="false"
          placeholder="Selecteer er max. 3"
          @select="addSelectedYear"
          @remove="removeSelectedYear"
        />
      </div>
    </FactsheetControls>

    <FactsheetPage
      v-if="hasFactsheetData"
      ref="factsheet"
      :landscape="true"
      :marginless="true"
      :title="`Factsheet ${municipalityLabel}`"
      :timestamp="timestamp"
      small-logo
    >
      <!-- DEEL 1 -->
      <FactsheetSection
        title="Prognose elektrische auto's*"
        title-right="bewoners, bezoekers &amp; forenzen"
        icon="factsheet/car.png"
      >
        <div
          class="Circle pt-3 pb-3"
          style="width: 120px"
        >
          <div class="Circle">
            <span>{{ registeredVehicles }}</span>
          </div>
          <div># geregistreerde</div>
          <div>personenauto's</div>
        </div>
        <div class="BarChart mb-1">
          <BarChart
            v-if="display === 'basic'"
            :data="barChart"
          />
          <DetailedBarChart
            v-else
            :data="barChartDetailed"
          />
        </div>
        <div
          class="Text flex-shrink-1 pt-3"
          style="width: 278px"
        >
          <div>Verwachte aantal elektrische auto's die in</div>
          <div>uw gemeenten een (semi)publieke of private</div>
          <div>laadbehoefte kennen</div>
          <div>(bewoners, bezoekers &amp; forenzen)</div>
        </div>
      </FactsheetSection>


      <!-- DEEL 2 -->
      <FactsheetSection
        title="Toekomstige behoefte (semi) publieke laadpunten*"
        icon="factsheet/charging-station.png"
      >
        <div
          class="Circle pt-5"
          style="width: 120px"
        >
          <div class="Circle">
            <img
              width="25"
              :src="image({ name: 'factsheet/charging-station.png' })"
            >
          </div>
          <div class="pt-3">
            <strong>Huidig aantal laadpunten**</strong>
          </div>
          <div>{{ currentPublicChargers }} publiek</div>
          <div>{{ currentSemiPublicChargers }} semi-publiek</div>
        </div>

        <div class="BarChart mb-1">
          <ColumnChart
            v-if="display === 'basic'"
            :data="columnChart"
          />
          <DetailedColumnChart
            v-else
            :data="columnChartDetailed"
          />
        </div>

        <div
          class="Text flex-shrink-1 pt-3"
          style="width: 278px"
        >
          <div>Om uit te komen op de totale laadbehoefte is</div>
          <div class="pb-3">
            uitgegaan van het deelgebruik van het aantal elektrische voertuigen per <i>laadpunt</i>:
          </div>

          <table>
            <tr>
              <td />
              <td
                v-for="year in forecastYearNumbers"
                :key="`table-header-${year}`"
              >
                <strong> {{ year }} </strong>
              </td>
            </tr>
            <tr>
              <td><i>Bewoners</i></td>
              <td
                v-for="year in forecastYearNumbers"
                :key="`residents-${year}`"
              >
                1 op {{ chargingpointDualUsagePublicInhabitants[year] | roundedBase(display) }}
              </td>
            </tr>
            <tr>
              <td><i>Bezoekers</i></td>
              <td
                v-for="year in forecastYearNumbers"
                :key="`visitors-${year}`"
              >
                1 op {{ chargingpointDualUsagePublicVisitors[year] | roundedBase(display) }}
              </td>
            </tr>
            <tr>
              <td><i>Forenzen</i></td>
              <td
                v-for="year in forecastYearNumbers"
                :key="`commuters-${year}`"
              >
                1 op {{ chargingpointDualUsagePublicCommuters[year] | roundedBase(display) }}
              </td>
            </tr>
          </table>
        </div>
      </FactsheetSection>

      <!-- DEEL 3 -->
      <FactsheetSection
        title="Verdeling openbaar en privaat parkeren***"
        icon="factsheet/parking.png"
      >
        <div class="pt-3 d-flex justify-content-center flex-column">
          <div>Parkeren in de</div>
          <div>openbare ruimte</div>
        </div>
        <div class="pt-3 d-flex justify-content-center flex-column">
          <div class="primary" />
          <div class="pt-1">
            {{ donutChart[0][2] }}
          </div>
        </div>
        <DonutChart :data="donutChart" />
        <div class="pt-3 d-flex justify-content-center flex-column">
          <div class="secondary" />
          <div class="pt-1">
            {{ donutChart[1][2] }}
          </div>
        </div>
        <div class="pt-3 d-flex justify-content-center flex-column">
          <div>Eigen</div>
          <div>parkeer-</div>
          <div>voorziening</div>
        </div>
        <div
          class="Text flex-shrink-1 pt-3"
          style="width: 278px"
        >
          <div>Dubbelgebruik op (semi)publieke laadpunten</div>
          <div>betekent dat deze verhouding zich niet</div>
          <div>direct vertaalt in de aantallen laadpunten</div>
          <div>hierboven</div>
        </div>
      </FactsheetSection>
      <template #footer>
        <div>* {{ bron }}</div>
        <div>** Huidig aantal laadpunten o.b.v meest recente data van Eco-Movement</div>
        <div>*** Op basis van EVtools parkeerareaal-analyse m.b.v. open databronnen zoals BGT en eigen algoritmes</div>
      </template>
    </FactsheetPage>
    <FactsheetPage
      v-else
      :landscape="true"
    >
      <h1 class="text-center pt-5">
        Er is voor {{ municipalityLabel }} nog geen factsheet beschikbaar
      </h1>
    </FactsheetPage>
  </div>
  <div
    v-else
    class="w-100 d-flex align-items-center justify-content-center"
  >
    <b-spinner
      class="mr-3"
      label="Loading..."
    />
    <span>Loading...</span>
  </div>
</template>

<script>
import { image } from '@/helpers/assets'

import InlineToggle from '@/components/common/InlineToggle'
import FactsheetPage from '@/components/factsheet/FactsheetPage'
import FactsheetSection from '@/components/factsheet/FactsheetSection'
import FactsheetControls from '@/components/factsheet/FactsheetControls'
import BarChart from '@/components/factsheet/evmaps/BarChart'
import DetailedBarChart from '@/components/factsheet/evmaps/DetailedBarChart'
import ColumnChart from '@/components/factsheet/evmaps/ColumnChart'
import DetailedColumnChart from '@/components/factsheet/evmaps/DetailedColumnChart'
import DonutChart from '@/components/factsheet/evmaps/DonutChart'

import Multiselect from 'vue-multiselect'

import { numberWithDots } from '@/helpers/number'

import { labelByCode } from '@/services/municipalities'
import { mapMutations, mapGetters } from 'vuex'
import { checkStatus, returnJson } from '@/helpers/api'
import { computeFactsheetData } from '@/helpers/factsheet'

export default {
  components: {
    InlineToggle,
    Multiselect,
    FactsheetPage,
    FactsheetSection,
    FactsheetControls,
    BarChart,
    ColumnChart,
    DonutChart,
    DetailedBarChart,
    DetailedColumnChart,
  },
  filters: {
    roundedBase: function (value, display) {
      if (!value) return ''

      if (display === 'detailed') {
        return value.toFixed(1).replace('.', ',')
      }

      return Math.round(value)
    },
  },
  data() {
    return {
      /**
       * The displayed factsheet layout. Either basic or detailed
       */
      display: 'basic',
      model: null,
      isLoadingFauna: false,
      data: [],
      yearsSelected: [],
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('deployment', [
      'DeploymentCode',
      'getPrognoseModels',
    ]),
    pdfTitle() {
      const layout = this.display === 'basic' ? '' : '(detail) '
      return `Factsheet ${layout}- ${this.municipalityLabel} - EVtools.pdf`
    },
    modelOptions() {
      const availableModels = Object.keys(this.data?.factsheets || [])

      return this.getPrognoseModels
          .filter(model => availableModels.includes(model.toLowerCase()))
          .map(model => ({ label: model, value: model.toLowerCase() }))
    },
    municipalityLabel() {
      return labelByCode({ code: this.getActiveMunicipality })
    },
    forecastYearNumbers() {
      const defaultYears = [
        '2022',
        '2025',
        '2030',
      ]

      if (this.yearsSelected.length === 0) {
        return defaultYears
      }

      // how many years are necessary to be filled up with, and which are free to use
      const countFillupYears =  defaultYears.length - this.yearsSelected.length
      const availableYears = defaultYears.filter(year => this.yearsSelected.includes(year) === false)

      const fillupYears = []
      for (let i = 0; i < countFillupYears; i++) {
        fillupYears.push(availableYears.shift())
      }

      return this.yearsSelected
        .concat(fillupYears)
        .sort((a, b) => a - b)
        .map(year => year.toString())
    },
    factsheetData() {
      return computeFactsheetData({
        data: this.data?.factsheets?.[this.model].original,
        years: this.forecastYearNumbers,
      })
    },
    timestamp() {
      return this.data.timestamp
    },
    chargingpointDualUsagePublicInhabitants() {
      return this.factsheetData.chargingpointDualUsage?.public.inhabitants
    },
    chargingpointDualUsagePublicVisitors() {
      return this.factsheetData.chargingpointDualUsage?.public.visitors
    },
    chargingpointDualUsagePublicCommuters() {
      return this.factsheetData.chargingpointDualUsage?.public.commuters
    },
    hasFactsheetData() {
      return !!this.data
    },
    registeredVehicles() {
      return numberWithDots(this.factsheetData.registeredVehicles)
    },
    currentPublicChargers() {
      return Math.ceil(this.factsheetData.currentChargingpoints?.public || 0)
    },
    currentSemiPublicChargers() {
      return Math.ceil(this.factsheetData.currentChargingpoints?.company || 0)
    },
    /**
     * # Of EV's per year (public & private combined)
     */
    barChart() {
      return this.factsheetData.evCountTotal
    },
    barChartDetailed() {
      return this.factsheetData.evCountPerGroup
    },
    columnChart() {
      return this.factsheetData.chargerCountTotal
    },
    columnChartDetailed() {
      return this.factsheetData.chargerCountPerGroup
    },
    donutChart() {
      const priv = this.factsheetData.parking.private
      const pub = this.factsheetData.parking.public

      return [ // % Parkingspace
        ['parkeren in de openbare ruimte', (pub / 2), `${pub}%`],
        ['eigen perkaarvoorziening', (priv / 2), `${priv}%`],
        [null, 50],
      ]
    },
    bron() {
      const prognoses = {
        nal: 'Op basis van NAL prognose van 1.9 miljoen EVs in 2030 en een restrictie op 4 personenvoertuigen per huishouden (bron: RVO, NAL-prognoses en EVtools)',
        vl: 'Op basis van prognose Departement Mobiliteit en Openbare Werken (MOW) van 35.000 Charge Point Equivalent (CPE) in 2025',
        sparkcity: 'Op basis van SparkCity prognose van 2.5 miljoen EVs in 2030 en een restrictie op 4 personenvoertuigen per huishouden (bron: SparkCity model van TU Eindhoven en EVtools)',
      }

      return prognoses[this.model.toLowerCase()]
    },
    yearSelectOptions() {
      return [
        '2022',
        '2023',
        '2024',
        '2025',
        '2026',
        '2027',
        '2028',
        '2029',
        '2030',
      ]
    },
  },
  watch: {
    getActiveMunicipality() {
      this.fetchFactsheetData()
    },
    modelOptions(next) {
      if (!next.length > 0) {
        return
      }

      this.model = next[0]?.value
    },
  },
  created() {
    this.fetchFactsheetData()
  },
  methods: {
    ...mapMutations('app', ['setAppReady']),
    image,
    /**
     * fetch the data
     */
    async fetchFactsheetData() {
      this.isLoadingFauna = true

      try {
        const token = await this.$auth.getTokenSilently()
        const response = await fetch('/api/factsheetload', {
          method: 'POST',
          headers: {
            authorization: 'Bearer ' + token,
          },
          body: JSON.stringify({ code: this.getActiveMunicipality, deploymentCode: this.DeploymentCode }),
        })
          .then(await checkStatus)
          .then(returnJson)

        this.data = response.data
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Error: ', e)
      }

      this.setAppReady({ value: true })
      this.isLoadingFauna = false
    },
    /**
     * handle year selection, max 3 years
     */
    addSelectedYear(year) {
      if (this.yearsSelected.length < 3) {
        this.yearsSelected.push(year)
      }
    },
    removeSelectedYear(year) {
      if (this.yearsSelected.includes(year)) {
        this.yearsSelected = this.yearsSelected.filter(entry => entry !== year)
      }
    },
    /**
     * Handle the display (basis / detail) selection
     */
    handleSelected({ selected }) {
      this.display = selected
    },
    /**
     * Handle the model (NAL/SparkCity) selection
     */
    handleSelectedModel({ selected }) {
      this.model = selected
    },
  },
}
</script>

<style lang="scss">
.Pages {
  position: relative;
  overflow-y: scroll;
}

.Factsheet__Main {
  table {
    width: 100%;

    td {
      padding: 6px 0
    }
  }

  .Circle {
    text-align: center;

    .Circle {
      background: var(--secondary);
      width: 60px;
      height: 60px;
      border-radius: 50%;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      margin: 0 auto 3px;
    }
  }
  .primary {
    width: 14px;
    height: 14px;
    background: var(--primary);
  }
  .secondary {
    width: 15px;
    height: 15px;
    background: var(--secondary);
  }
}
</style>
