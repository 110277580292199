<template>
  <button 
    type="button" 
    :id="option.uuid || option.label" 
    :class="['vs__selected', { popoverActive }]" 
    @mousedown="$emit('select-disabled', true)"
  >
    {{option.label}} 

    <b-popover 
      ref="popover"
      :target="option.uuid || option.label" 
      triggers="click blur"
      placement="top"
      delay="0"
      class="item-actions"
      @show="handleMenuShow({ option })"
      @hide="handleMenuClose"
    >
      <template #title>
        Acties voor {{ option.label }}
        <b-button variant="light" @click="handleMenuClose({ option })">
          <SvgIcon
            icon="close"
          />
        </b-button>
      </template>
      <b-list-group>
        <b-list-group-item v-for="(item, key) in menuActions" 
          :key="key"
          :button="item.isButton"
          :disabled="isItemLocked({ item, option })"
          @click="item.isButton ? handleItemEvent({ event: key, selectedItem: option }) : null"
        >
          <SvgIcon
            :icon="item.icon"
            :class="key"
          />
          {{ item.label }}
          <div v-if="isItemLocked({ item, option })">
            <b-badge variant="secondary" :id="key" pill>
              {{ option.chargingpointsCount }}
            </b-badge>
            <b-tooltip
              :target="key"
              :noninteractive="true"
              triggers="hover"
            >
              {{ isLockedForDeleteMessage(option.chargingpointsCount) }}
            </b-tooltip>
          </div>

          <b-input-group v-if="item.hasFormInput" class="mt-3">
            <b-form-input v-model="activeItemLabel" ref="newNameInput" :state="inputState" @input="hasInput = true "/>
            <b-input-group-append>
              <b-button 
                variant="cta" 
                :disabled="isSaveDisabled" 
                @click="handleRenameItemName({ newItemLabel: activeItemLabel, selectedItem: option })"
              >
                &check;
              </b-button>
            </b-input-group-append>
            <b-form-invalid-feedback v-if="hasInput && isSaveDisabled">
              {{  inputValidationMessage }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-list-group-item>
      </b-list-group>
    </b-popover>
    <SvgIcon
      icon="dots-vertical"
    />
  </button>
</template>
<script>
import SvgIcon from '@/components/common/SvgIcon.vue'

export default {
  name: 'vSelectEditableTags',
  components: { SvgIcon },
  props: {
    option: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Array,
      default: () => []
    },
    menuActions: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSaveDisabled () {
      return !this.inputState
    },
    inputState () {
      return this.hasInput ? !this.inputValidationMessage : null
    },
    // TODO: Put validation in the parent component (generic) //
    inputValidationMessage () {
      if (!this.activeItemLabel.trim()) {
        return 'Dit veld kan niet leeg zijn!'
      } else if (this.value.some(item => item.label === this.activeItemLabel.trim() && item.label !== this.option.label)) {
        return 'Er is al een fase met deze naam!'
      } else if (this.hasInput && this.activeItemLabel.trim() === this.option.label) {
        return 'De invoer is gelijk aan de huidige naam!'
      }
    }
  },
  data () {
    return {
      activeItemLabel: '',
      hasInput: false,
      popoverActive: false
    }
  },
  methods: {
    handleMenuShow ({ option }) {
      this.activeItemLabel = option.label
      this.popoverActive = true
      this.$emit('select-disabled', false)
    },
    handleMenuClose ({ option }) {
      this.activeItemLabel = ''
      this.hasInput = false
      this.popoverActive = false
      this.$emit('select-disabled', false)
      
      if (option) {
        this.$refs.popover.$emit('close')
      }
    },
    handleItemEvent ({ event, selectedItem }) {
      this.newItemLabel = selectedItem.label

      this.$emit(`${event}-item`, { selectedItem })
      this.handleMenuClose({ option: selectedItem })
    },
    handleRenameItemName ({ newItemLabel, selectedItem }) {
      this.$emit('rename-item', { newItemLabel, selectedItem })
    },
    isLockedForDeleteMessage (count) {
      return `De fase wordt gebruikt op ${count} locatie${count > 1 ? 's' : ''} en kan daarom niet worden verwijderd!`
    },
    isItemLocked ({ item, option }) {
      return item.possibleLock && !!option.isLockedForDelete
    }
  }
}
</script>
<style lang="scss">
.vs__selected {
  outline: none !important;
  &.popoverActive {
    border-color: var(--cta);
    background: white;
  }
}
.popover {
  max-width: none !important;
  width: 400px;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  .popover-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 0.5em 0 0.5em 1em;
  }
  .popover-body {
    padding: 0;
    font-size: 14px;
    .list-group-item {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      color: var(--primary-text);
      .svg-container {
        margin-right: 0.5em;
        &.deactivate {
          font-size: 1.4rem;
          margin-right: 0.1em;
          position: relative;
          top: 2px;
        }
      }
      &.disabled {
        color: #a19f9f;
      }
      .mt-3 {
        flex: 0 1 100%;
        margin: 1em 0 !important;
      }
      .badge {
        margin: 2px 0 0 1em;
        pointer-events: auto;
        cursor: pointer;
      }
      .invalid-feedback {
        position: absolute;
        top: 100%;
        color: var(--danger);
      }
    }
    .form-control {
      &:focus {
        box-shadow: none;
      }
    }
  }
  .arrow {
    &:before, &:after {
      border-top-color: var(--cta) !important;
    }
  }
}
</style>