<template>
  <div v-if="condition">
    <div class="d-flex pt-2 justify-content-between align-items-center">
      <span class="mr-3">{{ filter.subline }}</span>
      <ToggleSwitch
        :value="filter.active"
        @input="$emit('input',{id: filter.id})"
      />
    </div>
  </div>
</template>

<script>
import ToggleSwitch from '@/components/form/ToggleSwitch'

export default {
  components: { ToggleSwitch },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    condition: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
}
</script>
