import privilegesMixin from '@/mixins/common/privilegesMixin'

import { cpos, getCpoById } from '@/data/cpos'
import { mapGetters } from 'vuex'
import { STAKEHOLDER_TYPE } from '@/../shared/valueholders/stakeholder-types'

export default {
  mixins: [privilegesMixin],
  data() {
    return {
      fields: {
        cpo: {
          label: 'Charge Point Operator',
          value: '',
          type: 'select',
        },
      },
    }
  },
  computed: {
    ...mapGetters('deployment', ['DeploymentConfig']),
    isAllowedToEditCpo() {
      return this.superuser || this.admin
    },
    cposByDeployment() {
      return cpos
        .filter(cpo => cpo.countries.includes(this.DeploymentConfig.country))
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    cpoSelectOptions() {
      return this.cposByDeployment.map(cpo => ({ value: cpo.uuid, text: cpo.name }))
    },
    currentCpo() {
      if (this.stakeholders === undefined) {
        return false
      }

      return this.stakeholders.find(stakeholder => stakeholder.type === STAKEHOLDER_TYPE.CPO)
    },
  },
  methods: {
    $_chargingpointCpoMixin_getCpoByFieldSelection() {
      return cpos.find(cpo => cpo.uuid === this.fields.cpo.value)
    },
    $_chargingpointCpoMixin_getCpoById(uuid) {
      return getCpoById(cpos, uuid)
    },
    $_chargingpointCpoMixin_removeOrUpdateCpoFromStakeholders() {
      // if there was a cpo already assigned, filter it out and add the selected one
      const stakeholders = this.stakeholders.filter(stakeholder => stakeholder.type !== STAKEHOLDER_TYPE.CPO)
      if (this.fields.cpo.value !== null) {
        const cpo = this.$_chargingpointCpoMixin_getCpoByFieldSelection()

        if (cpo) {
          stakeholders.push(cpo)
        }
      }

      return stakeholders
    },
  },
}
