<template>
  <div class="AlternativeLocation">
    <div class="mb-2">
      <strong>Alternatief voor
        <template v-if="isPlanModeActive">
          <b-button
            size="sm"
            variant="primary"
            :disabled="disabled"
            @click="showAlternativeLocations"
          >
            Kies
          </b-button>

          <b-button
            class="ml-1"
            size="sm"
            variant="danger"
            :disabled="disabled"
            @click="$emit('select', null)"
          >
            x
          </b-button>
        </template>
      </strong>

      <InlineChargingpoint
        v-if="predecessorChargingpoint && predecessorChargingpoint.data"
        :chargingpoint="predecessorChargingpoint"
      />
      <div
        v-else
        v-text="'Geen'"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InlineChargingpoint from '@/components/common/InlineChargingpoint'

export default {
  components: { InlineChargingpoint },
  props: {
    predecessor: {
      type: Object,
      required: false,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('planmode', {
      isPlanModeActive: 'isActive',
      getChargingPointByUuid: 'getChargingPointByUuid',
    }),
    predecessorChargingpoint() {
      if (this.predecessor) {
        return this.getChargingPointByUuid({ uuid: this.predecessor.uuid }) ?? false
      }

      return false
    },
  },
  methods: {
    ...mapActions('planmode', ['showAlternativeLocations']),
  },
}
</script>
