<template>
  <div class="UserPanel mt-3 mb-4 flex-grow-1">
    <PanelHeader
      :enable-save="difference"
      :busy="busy"
      @save="handleSaveEvent"
    >
      <span class="flex-grow-1 text-center">
        Gebruikersbeheer
      </span>
    </PanelHeader>

    <div>
      <Tabs
        :tabs="tabs"
        :active="activeTab"
        @selected="handleTabClick"
      />
    </div>

    <Feedback
      v-if="activeTab === 'users'"
      class="mx-4 mt-4"
      :dismissible="false"
      :feedback="{ variant: 'warning' }"
    >
      <strong class="mr-3">Wijzigingen worden meteen doorgevoerd.</strong>
      <span>Door het maken van wijzigingen kan de sessie van de gebruiker onderbroken worden.</span>
    </Feedback>

    <section
      v-if="activeTab === 'import'"
      class="UserPanel__Section d-flex flex-column p-4"
    >
      <UsersImportPanel class="mt-4 pb-5" />
    </section>

    <section
      v-if="activeTab === 'search'"
      class="UserPanel__Section d-flex flex-column p-4"
    >
      <p>Nog niet geïmplementeerd</p>
    </section>
  </div>
</template>

<script>

import PanelHeader from '@/components/common/PanelHeader'
import UsersImportPanel from '@/components/admin/users/UsersImportPanel'

import Tabs from '@/components/common/Tabs'
import Feedback from '@/components/form/Feedback'

// import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    PanelHeader, UsersImportPanel, Tabs, Feedback,
  },
  data() {
    return {
      activeTab: 'import',
      tabs: [{
        label: 'Importeren',
        id: 'import',
      }, {
        label: 'Zoeken',
        id: 'search',
      }],

      // UI States
      difference: false,
      busy: false,
    }
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  methods: {

    /**
     * Handle a change in tabs
     */
    handleTabClick({ id }) {
      this.activeTab = id
    },


    /**
     * Store the municipality data
     */
    handleSaveEvent: async function() {
      // eslint-disable-next-line no-console
      console.log('save')
    },
  },
}
</script>

<style lang="scss">


.UserPanel {
  header {
    background: var(--primary); // darken(var(--grey), 25%);
    color: white;
    font-size: 1.5rem;
  }

  form {
    label {
      font-size: 1.1rem;
    }
    small {
      outline: none !important;
      font-size: 1rem;
    }
  }
}
</style>
